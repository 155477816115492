import React, { useEffect, useState } from "react";
// import Sidebar from "../../../Includes/Sidebar";
import Helmet from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { AiOutlineTeam } from "react-icons/ai";
import { Card, Tag, Input, Table } from "antd";
import useCompanyTabs from "../../../Hooks/useCompanyTabs";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { convertToTitleCase, priceInCr } from "../../../../Utils/Util";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronLeft } from "../../../../Utils/Icons";
import { useNavigate } from "react-router-dom";
import { setSearchParamsDirDetails } from "./CompanyTab.Slice";
import Loader from "../../../Components/Loader";

const DirectorDetails = () => {
  const {
    companyDirectorDetails,
    onSearchDirDetails,
    isLoadingDir,
    handleSortChangeDirDetails,
  } = useCompanyTabs({ activeTab: "directorDetails" });
  const { searchParamsDirDetails } = useSelector(
    (state) => state?.companyTabSliceReducer
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSearching) {
      const handler = setTimeout(async () => {
        await onSearchDirDetails(searchParamsDirDetails);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [onSearchDirDetails, searchParamsDirDetails, isSearching]);

  const columnsTable = [
    {
      title: <div style={{ fontSize: "12px" }}>Company/LLP Name</div>,
      dataIndex: "company_name",
      key: "company_name",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "company_name");
                // }, 500)}
                value={searchParamsDirDetails.company_name}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      company_name: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "company_name",
          key: "company_name",
        },
      ],
      sorter: true,
    },
    {
      title: <div style={{ fontSize: "12px" }}>Designation (Category)</div>,
      dataIndex: "designation",
      key: "designation",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "designation");
                // }, 500)}

                value={searchParamsDirDetails.designation}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      designation: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "designation",
          key: "designation",
        },
      ],
      sorter: true,
    },
    {
      title: (
        <div style={{ fontSize: "12px" }}>
          Appointment At Current Designation On
        </div>
      ),
      dataIndex: "appointment_current_designation_date",
      key: "appointment_current_designation_date",
      sorter: true,
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                value={
                  searchParamsDirDetails.appointment_current_designation_date
                }
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      appointment_current_designation_date: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "appointment_current_designation_date",
          key: "appointment_current_designation_date",
        },
      ],
    },
    {
      title: <div style={{ fontSize: "12px" }}>Originally Appointed On</div>,
      sorter: true,
      dataIndex: "appointment_original_date",
      key: "appointment_original_date",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                value={searchParamsDirDetails.appointment_original_date}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      appointment_original_date: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "appointment_original_date",
          key: "appointment_original_date",
        },
      ],
    },
    {
      title: <div style={{ fontSize: "12px" }}>Cessation Date</div>,
      dataIndex: "cessation_date",
      key: "cessation_date",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "cessation_date");
                // }, 500)}

                value={searchParamsDirDetails.cessation_date}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      cessation_date: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "cessation_date",
          key: "cessation_date",
        },
      ],
      sorter: true,
    },
    {
      title: <div style={{ fontSize: "12px" }}>Company/LLP Status</div>,
      dataIndex: "company_status",
      key: "company_status",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "name");
                // }, 500)}

                value={searchParamsDirDetails.company_status}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      company_status: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "company_status",
          key: "company_status",
        },
      ],
      // sorter: true,
    },
    {
      title: (
        <div style={{ fontSize: "12px" }}>
          % Shareholding/ %Contribution Received
        </div>
      ),
      dataIndex: "share_holding_percent",
      key: "share_holding_percent",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "share_holding_percent");
                // }, 500)}

                value={searchParamsDirDetails.share_holding_percent}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      share_holding_percent: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "share_holding_percent",
          key: "share_holding_percent",
        },
      ],
      // sorter: true,
    },
    {
      title: (
        <div style={{ fontSize: "12px" }}>Total Open Charges (In Cr.)</div>
      ),
      dataIndex: "totalOpenCharges",
      key: "totalOpenCharges",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "totalOpenCharges");
                // }, 500)}

                value={searchParamsDirDetails.totalOpenCharges}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      totalOpenCharges: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "totalOpenCharges",
          key: "totalOpenCharges",
        },
      ],
      // sorter: true,
    },
    {
      title: <div style={{ fontSize: "12px" }}>Paid Up Capital (In Cr.)</div>,
      dataIndex: "paid_up_capital",
      key: "paid_up_capital",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "paid_up_capital");
                // }, 500)}

                value={searchParamsDirDetails.paid_up_capital}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      paid_up_capital: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "paid_up_capital",
          key: "paid_up_capital",
        },
      ],
      sorter: true,
    },
    {
      title: <div style={{ fontSize: "12px" }}>Date Of Incorporation</div>,
      dataIndex: "incorporation_date",
      key: "incorporation_date",
      children: [
        {
          title: (
            <div>
              <Input
                placeholder="Contains"
                // onChange={_.debounce((e) => {
                //   onSearchDirDetails(e.target.value, "incorporation_date");
                // }, 500)}

                value={searchParamsDirDetails.incorporation_date}
                onChange={(e) => {
                  setIsSearching(true);
                  dispatch(
                    setSearchParamsDirDetails({
                      ...searchParamsDirDetails,
                      incorporation_date: e.target.value,
                    })
                  );
                }}
                style={{ margin: "0.5em 0 0", width: "100%" }}
              />
            </div>
          ),
          dataIndex: "incorporation_date",
          key: "incorporation_date",
        },
      ],
      sorter: true,
    },
  ];

  const data =
    companyDirectorDetails?.directors?.[0]?.cloud_companies_directors_appointments?.filter((d) => d?.company_name)?.map(
      (data, index) => ({
        company_name: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-bolder m-0 p-0">
              {convertToTitleCase(data?.company_name) || "-"}
            </p>
          </span>
        ),
        appointment_current_designation_date: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {moment(data?.appointment_current_designation_date).format(
                "DD/MM/YYYY"
              )}
            </p>
          </span>
        ),
        appointment_original_date: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {data?.appointment_original_date !== ""
                ? moment(data?.appointment_original_date).format("DD/MM/YYYY")
                : "-"}
            </p>
          </span>
        ),
        cessation_date: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {data?.cessation_date !== ""
                ? moment(data?.cessation_date).format("DD/MM/YYYY")
                : "-"}
            </p>
          </span>
        ),
        company_status: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {convertToTitleCase(data?.cloud_companies?.[0]?.company_status)}
            </p>
          </span>
        ),
        share_holding_percent: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {data?.share_holding_percent !== "" && data?.share_holding_percent !== 0
                ? data?.share_holding_percent?.toFixed(3) + `%`
                : "-"}
            </p>
          </span>
        ),
        totalOpenCharges: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {data?.totalOpenCharges ? priceInCr(data?.totalOpenCharges) : "-"}
            </p>
          </span>
        ),
        paid_up_capital: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {data?.cloud_companies?.[0]?.paid_up_capital
                ? priceInCr(data?.cloud_companies?.[0]?.paid_up_capital || 0)
                : 0}
            </p>
          </span>
        ),
        incorporation_date: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">
              {moment(data?.cloud_companies?.[0]?.incorporation_date)?.format(
                "DD/MM/YYYY"
              ) === "Invalid date"
                ? data?.cloud_companies?.[0]?.incorporation_date
                : moment(
                  data?.cloud_companies?.[0]?.incorporation_date
                )?.format("DD/MM/YYYY")}
            </p>
          </span>
        ),
        designation: (
          <span style={{ fontSize: "12px" }}>
            <p className="fw-normal m-0 p-0">{data?.designation}</p>
          </span>
        ),
      })
    );

  const handleIconClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Search Result"}</title>
      </Helmet>
      {/* <div className="page-wrapper chiller-theme toggled"> */}
      <div className="content-wrapper bg-light comapny_pages" id="main-screen">
        <div className="height100-145 direct0r-details">
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <span
                  className="d-flex align-items-center"
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    // display: "inline-block",
                  }}
                  onClick={handleIconClick}
                >
                  <BsChevronLeft className="me-1 search-btn-back" />{" "}
                  <strong className="p-0 m-0 fw-bolder">Back</strong>
                </span>
                <div className="d-flex">
                  <div
                    className="me-2 ms-4"
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <AiOutlineTeam
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <strong className="fw-bolder ">
                        Director/Partner Profile
                      </strong>
                    </span>
                  </div>
                  <div
                    className="me-2"
                    style={{
                      display: "inline-flex",
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <strong className="fw-bolder ">
                        As On :{" "}
                        {companyDirectorDetails?.directors?.[0]?.as_on_date
                          ? moment(
                            companyDirectorDetails?.directors?.[0]
                              ?.as_on_date,
                            "DD/MM/YYYY"
                          )?.format("DD/MM/YYYY")
                          : ""}
                      </strong>
                    </span>
                  </div>
                </div>

                <div className="d-flex">
                  {/* <div
                      className="me-2"
                      style={{
                        display: "inline-flex",
                      }}>
                      <span style={{display: "flex", alignItems: "center"}}>
                        <Button className="viewButton" style={{display: "flex", alignItems: "center", fontSize: "12px"}}>
                          <FaRegFileExcel style={{width: "12px", height: "12px", marginRight: "5px"}} />
                          Export To Excel
                        </Button>
                      </span>
                    </div> */}
                </div>
              </div>
              <Card
                size="small"
                title={
                  <h6 className="mb-0 p-0 fw-bolder">
                    {companyDirectorDetails?.directors?.[0]?.name}
                  </h6>
                }
                className="mt-3"
              >
                <Row>
                  <Col md={4}>
                    <div className="mb-2">
                      <p className="m-0 p-0 list-title">DIN/DPIN</p>
                      <p className="m-0 p-0 list-details">{id}</p>
                    </div>
                    {/* <div className="mb-2">
                        <p className="m-0 p-0 list-title">Director On Board Of A Bank</p>
                        <p className="m-0 p-0 list-details">No</p>
                      </div> */}
                    <div className="mb-2">
                      <p className="m-0 p-0 list-title">DOB</p>
                      <p className="m-0 p-0 list-details">
                        {companyDirectorDetails?.directors?.[0]?.dob || "-"},{" "}
                        {companyDirectorDetails?.directors?.[0]?.dob &&
                          moment().diff(
                            moment(
                              companyDirectorDetails?.directors?.[0]?.dob,
                              "DD/MM/YYYY"
                            ),
                            "years"
                          ) + " Yrs"}
                      </p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <p className="m-0 p-0 list-title">Address</p>
                      <p className="m-0 p-0 list-details">
                        {companyDirectorDetails?.directors?.[0]?.address || "-"}
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="m-0 p-0 list-title">DIN Status</p>
                      <Tag className="list-details" color="green">
                        {companyDirectorDetails?.directors?.[0]?.din_status ||
                          "-"}
                      </Tag>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <p className="m-0 p-0 list-title">Nationality</p>
                      <p className="m-0 p-0 list-details">
                        {companyDirectorDetails?.directors?.[0]?.nationality ||
                          "-"}
                      </p>
                    </div>
                    <div className="mb-2">
                      <div className="mb-2">
                        <p className="m-0 p-0 list-title">Gender</p>
                        <p className="m-0 p-0 list-details">
                          {companyDirectorDetails?.directors?.[0]?.gender ||
                            "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Table
                size="small"
                className="my-custom-table mt-2"
                columns={columnsTable}
                dataSource={data}
                onChange={(pagination, filters, sorter) => {
                  setIsSearching(false);
                  handleSortChangeDirDetails({
                    sorter: sorter,
                    page: pagination?.current,
                    limit: pagination?.pageSize,
                  });
                }}
                loading={{
                  spinning: isLoadingDir,
                  indicator: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <Loader />
                    </div>
                  ),
                }}
                locale={{
                  emptyText: "No Directors details found.",
                }}
                pagination={false}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DirectorDetails;
