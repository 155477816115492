import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    paymentHistoryList: { data: [], count: 0 },
    transactionHistoryList: { data: [], count: 0 },
    transactionHistoryPagination: { page: 1, limit: 20 },
    paymentHistoryPagination: { page: 1, limit: 20 }
}

const paymentSlice = createSlice({
    initialState: initialState,
    name: "paymentHistory",
    reducers: {

        setPaymentHistoryList: (state, { payload }) => {
            state.paymentHistoryList = payload
        },
        setTransactionHistoryList: (state, { payload }) => {
            state.transactionHistoryList = payload
        },
        setPaymentHistoryPagination: (state, { payload }) => {
            state.paymentHistoryPagination = payload
        },
        setTransactionHistoryPagination: (state, { payload }) => {
            state.transactionHistoryPagination = payload
        },

    }
})
export const paymentReducer = paymentSlice.reducer
export const { setPaymentHistoryList, setPaymentHistoryPagination, setTransactionHistoryList, setTransactionHistoryPagination } = paymentSlice.actions