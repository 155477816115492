import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function NewsFullArticlePage() {
  const { selected_article_link } = useSelector((state) => state.news);
  console.log("selected_article_link: ", selected_article_link);
  return (
    <>
      <Helmet>
        <title>{"Expert Cloud - Full-Article"}</title>
      </Helmet>
      <div class="bg-light" id="main-screen">
        <div className="w-100 h-100">
          <iframe
            title="news"
            width="100%"
            height="100%"
            src={selected_article_link}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default NewsFullArticlePage;
