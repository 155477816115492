import { List, Tag, Tooltip, Popover } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import UnlockExpertButton from "../../../../Components/UnlockExpertButton";

function ExpertCard({ data, index, handleItemClick }) {
  const { selectedExpert } = useSelector((state) => state?.SearchPage);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const contentTag = (data) => (
    <>
      <div className="cstmTg">
        {data?.industry_tags?.slice(1)?.map((tag) => (
          <>
            {tag && (
              <Tag className="me-1 mb-1" size="large" key={tag}>
                {tag}
              </Tag>
            )}
          </>
        ))}
      </div>
    </>
  );

  return (
    <>
      <List.Item
        key={data?._id}
        onClick={() => handleItemClick(data)}
        className={`w-100 ${selectedExpert?._id === data?._id
          ? "clickable-list-item clicked"
          : "clickable-list-item"
          }`}
      >
        <div className="w-100">
          <div
            className="d-flex w-100 justify-content-between"
          // style={{ gap: "16px" }}
          >
            <div className="w-75">
              <Tooltip title={data?.currunt_designation}>
                <p
                  className="fw-bolder truncate-text mb-2"
                  style={{ fontSize: "12px" }}
                >
                  {data?.currunt_designation}
                </p>
              </Tooltip>
              <Tooltip title={data?.currunt_company}>
                <small className="fw-normal truncate-text mb-2">
                  {data?.currunt_company}
                </small>
              </Tooltip>
            </div>
            {/* <div className=""> */}
            <div
              className="text-end"
              onMouseEnter={() => setHoveredItemId(data?._id)}
              onMouseLeave={() => setHoveredItemId(null)}
            >
              <UnlockExpertButton data={data} hoveredItemId={hoveredItemId} unlockFunction={() => { }} />
              {/* {hoveredItemId === data?._id ? (
                  <>
                    <Tag
                      bordered={false}
                      className="tagContainer view"
                      style={{ marginLeft: "auto" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BsUnlock className="me-1 tag_icon" />
                        View
                      </span>
                    </Tag>
                  </>
                ) : (
                  <>
                    <Tag
                      bordered={false}
                      className="tagContainer"
                      style={{
                        borderColor: "rgb(76, 187, 23)",
                        color: "rgb(76, 187, 23)",
                        marginLeft: "auto",
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <PiCoinVerticalDuotone
                          className="tag_icon"
                          style={{ marginRight: "4px" }}
                        />
                        {creditsData?.credits_for_expert}{" "}
                      </span>
                    </Tag>
                  </>
                )} */}
            </div>
            {/* </div> */}
          </div>

          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="cstmTg">
              {data?.industry_tags?.slice(0, 3)?.map((tag, index) => (
                <>
                  {tag && (
                    <Tag className="me-1" size="large" key={index}>
                      {tag}
                    </Tag>
                  )}
                </>
              ))}

              {data?.industry_tags && data?.industry_tags?.length > 3 && (
                <Popover
                  placement="left"
                  content={contentTag(data)}
                  trigger="hover"
                  style={{ width: "100px" }}
                >
                  <Tag className="me-1" size="large">
                    +{data?.industry_tags.length - 3}
                  </Tag>
                </Popover>
              )}
            </div>
          </div>
        </div>
      </List.Item>
    </>
  );
}

export default ExpertCard;
