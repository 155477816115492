import React from 'react';

const ConditionalRender = ({ condition, children }) => {
  if (condition) {
    return children;
  } else {
    return null;
  }
};

export default ConditionalRender;