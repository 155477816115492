import React, {
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Input, Select, Form, Modal, Divider, Radio } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "../App.css";
import API_PATH from "../Constants/api-path";
import { Col, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenCreatProject } from "../NewDesign/Pages/V2/Dashboard/Project.Slice";
import useProject from "../NewDesign/Hooks/useProject";
import DOMPurify from 'dompurify';

import "./CommonModal.css"
import { Country } from "country-state-city";
const { Option } = Select
const selectOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

export const CreateProjectModal = ({
  selectedExpertId,
  getProjectList
}) => {

  const loginDetails = JSON.parse(localStorage.getItem("userData"));
  const [startDate, setStartDate] = useState("");
  const [value, setValue] = useState("");
  const [inputList, setInputList] = useState([{ company: "" }]);
  const [selectList, setSelectList] = useState([{ country: "India" }]);
  const [emailList, setEmailList] = useState([{ email: "" }]);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [key, setKey] = useState("");
  const { isOpenCreateProject } = useSelector((state) => state.project);
  const { AddExpertProject, mutateProjectList } = useProject();
  const [form] = Form.useForm();
  const creditsData = useSelector((state) => state?.user?.all_credits);

  const plainOptions = [
    {
      label: 'Consulting Calls',
      value: 'consulting_calls',
    },
    {
      label: 'Primary Research Transcripts',
      value: 'primary_research_transcripts',
    },
    {
      label: 'Both',
      value: 'both',
    },
  ];
  const [radioValue, setRadioValue] = useState('consulting_calls');
  const [country, setCountry] = useState('');
  const [industryOptions, setIndustOptions] = useState([]);
  const [industry, setIndustry] = useState('');

  const onChange = ({ target: { value } }) => {
    setRadioValue(value);
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const editorRef = useRef();
  const dispatch = useDispatch();

  const handleClose = () => {
    form.resetFields();
    setStartDate("");
    setValue("")
    setProjectDetails(
      {
        project_title: "",
        expected_calls: "",
        no_of_transcript: ""
      }
    )
    setInputList([{ company: "" }])
    setEmailList([{ email: "" }])
    dispatch(setIsOpenCreatProject(false));
  };

  const [projectDetails, setProjectDetails] = useState({
    project_title: "",
    expected_calls: "",
    no_of_transcript: "",
    area_of_focus: ""
  });

  const handelChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    const cleanInput = DOMPurify.sanitize(value);
    setProjectDetails({ ...projectDetails, [key]: cleanInput });
  };

  const handelChangeExpectCall = (value) => {
    let currentValue = value;
    setProjectDetails({ ...projectDetails, expected_calls: currentValue });
  };
  const handelChangeTranscript = (value) => {
    let currentValue = value;
    setProjectDetails({ ...projectDetails, no_of_transcript: currentValue });
  };

  const handleEditorChange = (content) => {
    setValue(content);
  };

  // const getProjects = useCallback(() => {
  //   // setLoading1(true);
  //   axios
  //     .get(`${API_PATH.PRJECT_LIST}/${currentPage}`, {
  //       headers: headers,
  //     })

  //     .then((res) => {
  //       setLoading1(true);

  //       setAlldatas(res.data.count);
  //     })
  //     .catch((error) => {
  //       // setLoading1(false);
  //     });
  // }, [currentPage, headers])

  // useEffect(() => {
  //   getProjects();
  // }, [currentPage, getProjects]);

  const handleInputChangeCompany = (e, index) => {
    const { name, value } = e.target;
    const cleanInput = DOMPurify.sanitize(value);
    const list = [...inputList];
    list[index][name] = cleanInput;
    setInputList(list);
  };

  const handleInputChangeEmail = (e, index) => {
    const { name, value } = e.target;
    const list = [...emailList];
    const cleanInput = DOMPurify.sanitize(value);
    list[index][name] = cleanInput;
    checkEmail(list[index].email);
    setEmailList(list);
  };

  const checkEmail = (email) => {
    //let emailData = [];

    //for (var Email in emailList) {
    const regEx = /[@]/;
    if (!regEx.test(email)) {
      //setChekemail(true)
      setMessage("");
    } else {

      return setMessage("Email is invalid");
    }
    // }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (data, index) => {
    if (data === "input") {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    } else if (data === "select") {
      const list = [...selectList];
      list.splice(index, 1);
      setSelectList(list);
    } else if (data === "email") {
      const list = [...emailList];
      list.splice(index, 1);
      setEmailList(list);
    }
  };

  // handle click event of the Add button
  const handleAddClick = (data) => {
    if (data === "input") {
      setInputList([...inputList, { company: "" }]);
    } else if (data === "select") {
      setSelectList([...selectList, { country: "" }]);
    } else if (data === "email") {
      setEmailList([...emailList, { email: "" }]);
    }
  };

  const handleSubmit = async () => {
    setSpinner(true);
    let countryData = [];
    let companyData = [];
    let emailData = [];
    if (loginDetails?.user?.email) {
      emailData?.push(loginDetails?.user?.email?.split("@")[0]);
    } else {
      // console.error("Email is not defined in loginDetails.user.email");
    }

    for (var Country in selectList) {
      countryData?.push(selectList[Country]?.country);
    }

    for (var Company in inputList) {
      companyData?.push(inputList[Company]?.company);
    }

    for (var Email in emailList) {
      if (
        emailList[Email]?.email !== emailData[0] &&
        emailList[Email]?.email !== ""
      ) {
        emailData?.push(emailList[Email]?.email);
      }
    }

    let payload = {
      project_title: projectDetails?.project_title,
      aread_of_focus: projectDetails?.area_of_focus,
      user_id: loginDetails?.user?._id,
      invite_team: emailData?.join(","),
      country: country?.length > 0 ? country?.join(",") : [], // selected from dropdown  //!1
      target_companies: companyData?.join(","),
      question: value,
      project_deadline:
        startDate && !isNaN(startDate.getTime())
          ? startDate.toISOString()
          : undefined,
      expected_calls: projectDetails?.expected_calls,
      transcript_required: projectDetails?.no_of_transcript,
      request_for: radioValue === "primary_research_transcripts" ? "transcript" : radioValue === "consulting_calls" ? "expert" : "both",
      expert_industry: industry?.length > 0 ? industry?.join(",") : [],//!2
    };
    let checkArray = []
    if (radioValue === "consulting_calls") {
      checkArray = [payload.project_title, payload.area_of_focus, payload.expected_calls, payload.question, payload.project_deadline]
    } else if (radioValue === "primary_research_transcripts") {
      checkArray = [payload.project_title, payload.area_of_focus, payload.transcript_required, payload.question, payload.project_deadline]
    } else {
      checkArray = [payload.project_title, payload.area_of_focus, payload.expected_calls, payload.transcript_required, payload.question, payload.project_deadline]
    }

    if (checkArray.includes("") || payload.expert_industry?.length === 0 || payload.country?.length === 0) {
      setSpinner(false);
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

    }


    try {
      const res = await fetch(`${API_PATH?.CREATE_PROJECT}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      const result = await res.json();

      if (result?.status === true && result?.message === "Insert successfully.") {
        form.resetFields();
        setStartDate("");
        setValue("")
        setProjectDetails(
          {
            project_title: "",
            expected_calls: "",
            no_of_transcript: ""
          }
        )
        setInputList([{ company: "" }])
        setEmailList([{ email: "" }])
        setSpinner(false);

        if (selectedExpertId) {
          AddExpertProject({
            project_id: result?.data,
            expert_id: selectedExpertId,
          });
        }
        mutateProjectList("projectList");
        if (getProjectList) {
          getProjectList()
        }

        dispatch(setIsOpenCreatProject(false));
        return toast.success(
          "Project added successfully. Our team will be in touch with the experts availability, shortly. For any other queries, please feel free to write to us at info@nextyn.com",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      } else {
        setSpinner(false);
        dispatch(setIsOpenCreatProject(false));

        const errorMessage = result?.message || "An unexpected error occurred";
        return toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      dispatch(setIsOpenCreatProject(false));
      console.error(error, "error-->");
      setSpinner(false);
      return toast.error("Something went wrong. Server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

  };
  const GetIndustryList = async (industOption) => {
    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setIndustOptions(result?.data);
      }
    } catch (error) {
      console.error("Industry Error", error);
    }
  };
  const countries = Country?.getAllCountries();
  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );
  const updatedIndustries = useMemo(
    () =>
      industryOptions?.map((industry, index) => {
        return (
          <Option key={industry?._id} value={industry?._id}>
            {industry?.category_name}
          </Option>
        );
      }),
    [industryOptions]
  );
  const once = useRef(false)
  React.useEffect(() => {
    if (!once.current) {
      once.current = true
      GetIndustryList()
    }
  }, [])

  const callSearchOnce = useRef(true)
  React.useEffect(() => {
    // ----------NEW COMMENTED-------------------
    let handler;
    if (
      !callSearchOnce.current && once.current
    ) {
      handler = setTimeout(async () => {
        callSearchOnce.current = true;
        GetIndustryList(key);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [key])


  return (
    <Modal
      width={840}
      centered
      title={<div className="">
        <h4 className="modalHeader mb-1">Add Project (Custom request experts/transcripts)</h4>
        <p class="text-muted" style={{ fontSize: "13px" }}>
          Please share details below, to help us custom recruit an expert
          for your needs.
        </p>
        <p class="text-muted" style={{ fontSize: "13px" }}>
          {creditsData?.credits_for_expert} credits will be deducted when an expert is requested. The credits will be refunded if the call does not get scheduled.
        </p>
      </div>}
      open={isOpenCreateProject}
      onCancel={handleClose}
      footer={[
        <Button
          className="me-2"
          key="back"

          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={spinner}
          style={{
            backgroundColor: "rgb(76, 187, 23)",
            borderColor: "rgb(76, 187, 23)",
            color: "#fff",
          }}
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>,
      ]}

    >
      <Divider style={{ marginBlock: 10 }} />
      <Form form={form} layout="vertical" autoComplete="off">
        <Row>
          <Col>
            <Form.Item
              className="fw-bolder"
              label="Project Title"
              name="project_title"
              rules={[{ required: true, message: "Project title is required" }]}
            >
              <Input
                type="text"
                size="large"
                name="project_title"
                onChange={
                  handelChange
                  // setInputValue(e.target.value);
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              className="fw-bolder"
              // label="Project Title"
              // name="project_radio"
              rules={[{ required: true, message: "Project title is required" }]}
            >
              <Radio.Group options={plainOptions} onChange={onChange} value={radioValue} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {(radioValue === "consulting_calls" || radioValue === "both") && <Col md={radioValue !== "both" ? 6 : 4}>
            <Form.Item
              label={"Number of Expected Calls"}
              className="fw-bolder"
              name="expected_calls"
              rules={[{ required: true, message: "Expected calls is required" }]}
            >
              <Select
                name="expected_calls"
                style={{
                  width: "100%",
                }}
                size="large"
                onChange={handelChangeExpectCall}
                options={selectOptions}
              />
            </Form.Item>
          </Col>}
          {(radioValue === "primary_research_transcripts" || radioValue === "both") && <Col md={radioValue !== "both" ? 6 : 4}>
            <Form.Item
              label={"Number of Expected Transcripts"}
              className="fw-bolder"
              name="no_of_transcripts"
              rules={[{ required: true, message: "Expected Transcripts is required" }]}
            >
              <Select
                name="no_of_transcripts"
                style={{
                  width: "100%",
                }}
                size="large"
                onChange={handelChangeTranscript}
                options={selectOptions}
              />
            </Form.Item>
          </Col>}
          <Col md={radioValue !== "both" ? 6 : 4}>
            <Form.Item
              name="project_deadline"
              className="fw-bolder"
              label="Project Deadline "
              rules={[{ required: true, message: "Project deadline is required" }]}
            >
              <DatePicker
                style={{ cursor: "default", height: "40px", width: "100%" }}
                inputProps={{ readOnly: true }}
                name="project_deadline"
                className="form-select react_datepicker"
                minDate={new Date()}
                selected={startDate}
                placeholderText="DD/MM/YYYY"
                onChange={setStartDate}
                dateFormat="dd/MM/yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <div className="mb-0">
              <Form.Item
                className="fw-bolder"
                name={"geography_experts"}
                label="Geography of experts"
                rules={[{ required: true, message: "Geography is required" }]}
              >
                {/* <Input
                  // value={geo?.value}
                  style={{ flex: 1, marginRight: "8px" }}
                  defaultValue="India"
                  size="large"
                  readOnly
                  disabled
                ></Input> */}
                <Select
                  name="country"
                  size="large"
                  mode="multiple"
                  placeholder="Country"
                  allowClear
                  autoComplete="off"
                  showSearch
                  value={country}
                  onChange={(value) => {
                    setCountry(value)
                  }}
                >
                  {updatedCountries}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="mb-0">
              <Form.Item
                className="fw-bolder"
                name={"industry_experts"}
                label="Industry of experts"
                rules={[{ required: true, message: "Industry is required" }]}
              >
                {/* <Input
                  // value={geo?.value}
                  style={{ flex: 1, marginRight: "8px" }}
                  defaultValue="India"
                  size="large"
                  readOnly
                  disabled
                ></Input> */}
                <Select
                  name="industry"
                  size="large"
                  mode="multiple"
                  placeholder="Industry"
                  allowClear
                  autoComplete="off"
                  showSearch
                  onSearch={(value) => {
                    callSearchOnce.current = false
                    setKey(value)
                  }}
                  value={industry}
                  onChange={(value) => {
                    setIndustry(value)
                  }}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) !== -1
                  }
                >
                  {updatedIndustries}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} md={4}>
            {inputList?.map((x, i) => {
              return (
                <div className="mb-0" key={i}>
                  <Form.Item
                    className="fw-bolder"
                    name={"company"}
                    label="Target Companies"
                    rules={[{ required: true, message: "Target companies is required" }]}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Input
                        size="large"
                        name="company"
                        value={x?.company}
                        onChange={(e) => handleInputChangeCompany(e, i)}
                        style={{ flex: 1 }}
                      ></Input>
                    </div>
                  </Form.Item>
                </div>
              );
            })}
          </Col>
        </Row>
        <Row>
          <div className="mb-0">
            <Form.Item
              className="fw-bolder"
              name={"area_of_focus"}
              label="Areas of Focus"
              rules={[{ required: true, message: "Aread of Focus is required" }]}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Input
                  size="large"
                  name="area_of_focus"
                  onChange={handelChange}
                  style={{ flex: 1 }}
                ></Input>
              </div>
            </Form.Item>
          </div>
        </Row>
        <Row>
          <Col md={12}>
            {emailList.map((x, i) => {
              return (
                <div key={i}>
                  <Form.Item
                    // name="project_deadline"
                    name="email"
                    className="fw-bolder"
                    label="Invite your team (Optional) "
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Input
                        className="addRightInput"
                        style={{
                          width: "100%",
                          flex: 1,
                          marginRight: "8px",
                        }}
                        size="large"
                        name="email"
                        value={x?.email}
                        onChange={(e) => handleInputChangeEmail(e, i)}
                        addonAfter={
                          "@" + loginDetails?.user?.email?.split("@")[1]
                        }
                        defaultValue={x?.email}
                      />

                      {emailList.length !== 1 && (
                        <AiOutlineMinusCircle
                          style={{
                            fontSize: "22px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveClick("email", i)}
                        />
                      )}
                      {emailList.length - 1 === i && (
                        <AiOutlinePlusCircle
                          style={{
                            fontSize: "22px",
                            cursor: "pointer",
                            color: "#5746EB",
                          }}
                          onClick={() => handleAddClick("email")}
                        />
                      )}
                    </div>
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </Form.Item>
                </div>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col className="projectEditor mt-2 min-h-150">
            <Form.Item
              className="fw-bolder"
              name={"description"}
              rules={[{ required: true, message: "description is required" }]}
            >
              <CKEditor
                editor={Editor}
                data={value}
                onReady={(editor) => {
                  editorRef.current = editor;
                }}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                  ],
                  placeholder:
                    "Please share an overview or any questions for your request. For research transcripts, please let us know if there are any specific topics/questions that you need us to ask the expert.",
                  // extraPlugins: 'autogrow',
                  // autoGrow_bottomSpace: 0,
                  autoGrow_minHeight: 800, // Set a fixed minimum height
                  autoGrow_maxHeight: 500, // Set a fixed maximum height
                }}
                onChange={(event, editor) => {
                  const newData = editor.getData();
                  handleEditorChange(newData, "overview");
                }}
                onBlur={(editor) => {
                  // console.log("Blur.", editor);
                }}
                onFocus={(editor) => {
                  // console.log("Focus.", editor);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginBlock: 10 }} />
    </Modal>
  )
  // return (
  //   <>
  //     <Modal
  //       show={isOpenCreateProject}
  //       onHide={handleClose}
  //       backdropClassName={window.location.pathname === "/credits" ? "bg-dark opacity-75" : "bg-dark opacity-50"}
  //       backdrop="static"
  //       keyboard={false}
  //       centered
  //       size={"lg"}
  //       scrollable={true}
  //       dialogClassName={"bootStrapModal"}
  //       contentClassName={"bootStrapModalContent"}
  //     >
  //       <Modal.Header>
  //         <Modal.Title>
  //           <MdOutlineClose
  //             className="position-absolute"
  //             style={{ right: "15px", top: "15px", fontSize: "25px", cursor: "pointer" }}
  //             onClick={handleClose}
  //           />
  //           <div className="">
  //             <h4 className="modalHeader mb-2">Add Project</h4>
  //             <p class="text-muted" style={{ fontSize: "13px" }}>
  //               Please share details below, to help us custom recruit an expert
  //               for your needs.
  //             </p>
  //             <p class="text-muted" style={{ fontSize: "13px" }}>
  //               {creditsData?.credits_for_expert} credits will be deducted when an expert is requested. The credits will be refunded if the call does not get scheduled.
  //             </p>
  //           </div>
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body className="position-relative">
  //         {/* <MdOutlineClose
  //           className="position-absolute"
  //           style={{ right: "15px", top: "15px", fontSize: "25px" }}
  //           onClick={handleClose}
  //         />
  //         <div className="text-center">
  //           <h4 className="modalHeader">Add Project</h4>
  //         </div> */}

  //         <Form form={form} layout="vertical" autoComplete="off">
  //           <Row>
  //             <Col>
  //               <Form.Item
  //                 className="fw-bolder"
  //                 label="Project Title"
  //                 rules={[{ required: true }]}
  //               >
  //                 <Input
  //                   type="text"
  //                   size="large"
  //                   name="project_title"
  //                   onChange={
  //                     handelChange
  //                     // setInputValue(e.target.value);
  //                   }
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col>
  //               {/* <Form.Item label="Client Name" rules={[{ required: true }]}>
  //               <Input
  //                 type="text"
  //                 size="large"
  //                 name="client_name"
  //                 value={addData?.client_name}
  //                 onChange={(event) =>
  //                   handleChange(event.target.value, "client_name")
  //                 }
  //               />
  //             </Form.Item> */}

  //               <Form.Item
  //                 label="Number of Expected Calls"
  //                 className="fw-bolder"
  //               >
  //                 <Select
  //                   style={{
  //                     width: "100%",
  //                   }}
  //                   size="large"
  //                   onChange={handelChangeExpectCall}
  //                   options={selectOptions}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col md={6}>
  //               <Form.Item
  //                 // name="project_deadline"
  //                 className="fw-bolder"
  //                 label="Project Deadline "
  //                 rules={[{ required: true }]}
  //               >
  //                 <DatePicker
  //                   style={{ cursor: "default", height: "40px", width: "100%" }}
  //                   inputProps={{ readOnly: true }}
  //                   className="form-select react_datepicker"
  //                   minDate={new Date()}
  //                   selected={startDate}
  //                   placeholderText="DD/MM/YYYY"
  //                   onChange={setStartDate}
  //                   dateFormat="dd/MM/yyyy"
  //                   onKeyDown={(e) => {
  //                     e.preventDefault();
  //                   }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col xs={12} md={6}>
  //               <div className="mb-0">
  //                 <Form.Item
  //                   className="fw-bolder"
  //                   label="Geography of experts"
  //                   rules={[{ required: true }]}
  //                 >
  //                   <Input
  //                     // value={geo?.value}
  //                     style={{ flex: 1, marginRight: "8px" }}
  //                     defaultValue="India"
  //                     size="large"
  //                     readOnly
  //                     disabled
  //                   ></Input>
  //                 </Form.Item>
  //               </div>
  //             </Col>
  //             <Col xs={12} md={6}>
  //               {inputList?.map((x, i) => {
  //                 return (
  //                   <div className="mb-0" key={i}>
  //                     <Form.Item
  //                       className="fw-bolder"
  //                       label="Target Companies"
  //                       rules={[{ required: true }]}
  //                     >
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           width: "100%",
  //                         }}
  //                       >
  //                         <Input
  //                           size="large"
  //                           name="company"
  //                           value={x?.company}
  //                           onChange={(e) => handleInputChangeCompany(e, i)}
  //                           style={{ flex: 1 }}
  //                         ></Input>
  //                       </div>
  //                     </Form.Item>
  //                   </div>
  //                 );
  //               })}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col md={12}>
  //               {emailList.map((x, i) => {
  //                 return (
  //                   <div key={i}>
  //                     <Form.Item
  //                       // name="project_deadline"
  //                       name="email"
  //                       className="fw-bolder"
  //                       label="Invite your team (Optional) "
  //                     >
  //                       <div
  //                         style={{
  //                           display: "flex",
  //                           alignItems: "center",
  //                           width: "100%",
  //                         }}
  //                       >
  //                         <Input
  //                           className="addRightInput"
  //                           style={{
  //                             width: "100%",
  //                             flex: 1,
  //                             marginRight: "8px",
  //                           }}
  //                           size="large"
  //                           name="email"
  //                           value={x?.email}
  //                           onChange={(e) => handleInputChangeEmail(e, i)}
  //                           addonAfter={
  //                             "@" + loginDetails?.user?.email?.split("@")[1]
  //                           }
  //                           defaultValue={x?.email}
  //                         />

  //                         {emailList.length !== 1 && (
  //                           <AiOutlineMinusCircle
  //                             style={{
  //                               fontSize: "22px",
  //                               color: "red",
  //                               cursor: "pointer",
  //                             }}
  //                             onClick={() => handleRemoveClick("email", i)}
  //                           />
  //                         )}
  //                         {emailList.length - 1 === i && (
  //                           <AiOutlinePlusCircle
  //                             style={{
  //                               fontSize: "22px",
  //                               cursor: "pointer",
  //                               color: "#5746EB",
  //                             }}
  //                             onClick={() => handleAddClick("email")}
  //                           />
  //                         )}
  //                       </div>
  //                       {message === "" ? (
  //                         <></>
  //                       ) : (
  //                         <p className="text-danger">{message}</p>
  //                       )}
  //                     </Form.Item>
  //                   </div>
  //                 );
  //               })}
  //             </Col>
  //           </Row>
  //           <Row>
  //             <Col className="projectEditor mt-2 min-h-150">
  //               <CKEditor
  //                 editor={Editor}
  //                 data={value}
  //                 onReady={(editor) => {
  //                   editorRef.current = editor;
  //                 }}
  //                 config={{
  //                   toolbar: [
  //                     "bold",
  //                     "italic",
  //                     "link",
  //                     "bulletedList",
  //                     "numberedList",
  //                   ],
  //                   placeholder:
  //                     "Please share an overview or any questions for your request",
  //                   // extraPlugins: 'autogrow',
  //                   // autoGrow_bottomSpace: 0,
  //                   autoGrow_minHeight: 800, // Set a fixed minimum height
  //                   autoGrow_maxHeight: 500, // Set a fixed maximum height
  //                 }}
  //                 onChange={(event, editor) => {
  //                   const newData = editor.getData();
  //                   handleEditorChange(newData, "overview");
  //                 }}
  //                 onBlur={(editor) => {
  //                   // console.log("Blur.", editor);
  //                 }}
  //                 onFocus={(editor) => {
  //                   // console.log("Focus.", editor);
  //                 }}
  //               />
  //             </Col>
  //           </Row>
  //         </Form>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <div>
  //           <Button
  //             className="me-2"
  //             key="back"

  //             onClick={() => {
  //               handleClose();
  //             }}
  //           >
  //             Cancel
  //           </Button>
  //           <Button
  //             key="submit"
  //             disabled={spinner}
  //             style={{
  //               backgroundColor: "rgb(76, 187, 23)",
  //               borderColor: "rgb(76, 187, 23)",
  //               color: "#fff",
  //             }}
  //             onClick={() => handleSubmit()}
  //           >
  //             Submit
  //           </Button>
  //         </div>
  //       </Modal.Footer>
  //     </Modal>
  //   </>
  // );
};
