import { Tooltip, Tag } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import company_icon3 from "../../../assests/images/company_notfound.png";
import { BsChevronLeft } from "../../../Utils/Icons";
import { Col, Row } from "react-bootstrap";
import "../Dashboard/Dashboard.css";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useUser from "../../Hooks/useUser";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import { Button } from "antd";
import useProject from "../../Hooks/useProject";
import { ProjectListModal } from "../../../components/ProjectListModal";
import { CreateProjectModal } from "../../../components/CreateProjectModal";
import useDashboard from "../../Hooks/useDashboard";

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const customColors = ["#5746ec"];

function ExpertProfile() {
  document.title = "Nextyn IQ -  Expert Profile";
  const { AddExpertProject, getProjectListById, isLoading, mutateProjectList } =
    useProject();
  const { projectListById } = useSelector((state) => state.project);
  const history = useNavigate();
  const { getExpertById } = useDashboard(); //PREV useExpert()
  const { expertprofile } = useSelector((state) => state?.expert);
  const creditsData = useSelector((state) => state.user.all_credits);

  const { spendCredits } = useUser();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getExpertById(id);
    }
  }, [id, getExpertById]);

  const dispatch = useDispatch();

  var currentExperience = expertprofile[0]?.current_experience || [];
  var previousExperience = expertprofile[0]?.previous_experience || [];

  var experienceDetails = [...currentExperience, ...previousExperience];

  var experience = [];
  for (var i = 0; i < experienceDetails?.length; i++) {
    experience?.push(experienceDetails[i]);
  }

  var CN = [];
  function uniqueData(data) {
    var companyName = [];
    for (var i = 0; i < data.length; i++) {
      if (companyName.indexOf(data[i].company_name) == -1) {
        companyName.push(data[i].company_name);
      }
    }
    CN.push(...companyName);
    return companyName;
  }
  uniqueData(experience);
  var store = [];

  for (let i = 0; i < CN.length; i++) {
    let local = [];
    let print = [];
    for (let j = 0; j < experience.length; j++) {
      if (CN[i] == experience[j].company_name) {
        let obj = {};
        obj.title = experience[j].company_name;
        obj.dateFrom = experience[j].date_from;
        obj.dateTill = experience[j].date_till;
        obj.whatWork = experience[j].what_work;
        obj.location = experience[j].location;
        obj.tillPresent = experience[j].till_present;
        obj.logoUrl = experience[j].logo_url;
        obj.designation = experience[j].career_title;
        local.push(obj);
      }
    }
    print.push(CN[i], local);
    store.push(print);
  }

  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const handleSpendCredit = (id, category) => {
    spendCredits({
      id,
      category,
      credits_spend: creditsData?.credits_for_expert,

    });
  };

  const Convert_Month_Year = (months) => {
    var month = months + 1;

    var year = Math.floor(month / 12);
    //console.log(year);
    if (year === 0) {
      var month_year = (month % 12) + " mos";
      return month_year;
    } else if (month % 12 === 0) {
      var month_year = year + " yrs ";
      return month_year;
    } else {
      var month_year = year + " yrs " + (month % 12) + " mos";
      return month_year;
    }
  };

  function expertSummery(data) {
    if (data !== null && data !== "" && data !== undefined) {
      // Split the input data into an array of paragraphs based on numeric indicators
      const paragraphs = data?.split(/\d+\.\s+/);

      return (
        <div
          className="comon-divs-re"
          style={{ width: "100%", paddingRight: "20px" }}
        >
          <div className="pvs-header__title-container">
            <h2 className="pvs-header__title text-heading-large">
              <span
                aria-hidden="true"
                style={{ fontSize: "20px", fontWeight: "400" }}
              >
                Summary
              </span>
            </h2>
          </div>

          <div className="inline-show-more-text">
            {paragraphs?.map((paragraph, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {paragraph?.trim()}
              </p>
            ))}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  return (
    <>
      <div class="height-100 bg-light" id="main-screen">
        <main className="body-total content-wrapper float-start w-100">
          <div className="height100-145">
            <div className="bk-div float-start w-100">
              <div className="body-expart-div request-page projects-scope">
                <h4 className="d-flex align-items-center">
                  <Link to={"#"} onClick={() => history(-1)}>
                    <BsChevronLeft className="me-3 search-btn-back" />
                  </Link>
                  Expert Profile
                </h4>
                <div className="mt-4">
                  <section className="content">
                    <div className="findExperts">
                      <div className="linkedinBody">
                        <div className="comon-divs-re d-md-flex align-items-center justify-content-between findExperts">
                          <div className="col-lg-10">
                            <div className="d-flex align-items-center">
                              <span style={{ minWidth: "95px" }}>
                                {expertprofile[0]?.current_experience[0]
                                  ?.logo_url !== null &&
                                  expertprofile[0]?.current_experience[0]
                                    ?.logo_url !== "" ? (
                                  <img
                                    src={
                                      expertprofile[0]?.current_experience[0]
                                        ?.logo_url
                                    }
                                    alt="logo"
                                    className="me-2"
                                    style={{
                                      width: "95px",
                                      borderRadius: "5px",
                                      maxWidth: "95px",
                                    }}
                                    onError={addDefaultSrc}
                                  />
                                ) : (
                                  <img
                                    src={company_icon3}
                                    alt="logo1"
                                    className="me-2"
                                    style={{
                                      width: "95px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                )}
                              </span>
                              <div className="ms-2">
                                <h4
                                  className="find_companyName"
                                  style={{ marginBottom: "10px" }}
                                >
                                  {expertprofile[0]?.currunt_company}
                                </h4>
                                <h5 className="linkH5">
                                  {expertprofile[0]?.currunt_designation !==
                                    null ? (
                                    <>{expertprofile[0]?.currunt_designation}</>
                                  ) : (
                                    <></>
                                  )}
                                </h5>
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "13px",
                                    color: "gray",
                                  }}
                                >
                                  <i
                                    className="las la-globe-americas me-2"
                                    style={{ color: "#5746ec" }}
                                  ></i>
                                  {expertprofile[0]?.country}
                                </p>
                              </div>
                            </div>

                            <Row>
                              <Col>
                                {expertprofile[0]?.industry !== null &&
                                  expertprofile[0]?.industry !== undefined ? (
                                  <div className="d-flex next-list mt-3 align-items-top">
                                    <span className="me-2">
                                      {customColors?.map((color) => (
                                        <Tooltip
                                          placement="top"
                                          // color={color}
                                          title={text}
                                        >
                                          <svg
                                            style={{
                                              fontSize: "20px",
                                              color: "rgb(87, 70, 236)",
                                            }}
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g>
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0z"
                                              ></path>
                                              <path d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z"></path>
                                            </g>
                                          </svg>
                                        </Tooltip>
                                      ))}
                                    </span>

                                    <div>
                                      {ConstantsArray(
                                        expertprofile[0]?.industry
                                      ).map((curElem, id) => {
                                        return (
                                          <Tag
                                            // color="blue"
                                            bordered={false}
                                            key={id}
                                          >
                                            {curElem}
                                          </Tag>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col>
                                {expertprofile[0]?.tags !== null &&
                                  expertprofile[0]?.tags !== undefined ? (
                                  <div className="d-flex next-list align-items-top">
                                    <span className="me-2">
                                      {customColors.map((color) => (
                                        <Tooltip
                                          placement="top"
                                          // color={color}
                                          title={text2}
                                        >
                                          <svg
                                            style={{
                                              fontSize: "20px",
                                              color: "rgb(87, 70, 236)",
                                            }}
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-width="1.5"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                            ></path>
                                          </svg>
                                        </Tooltip>
                                      ))}
                                    </span>

                                    <div>
                                      {ConstantsArray(
                                        expertprofile[0]?.tags
                                      ).map((curElem, id) => {
                                        //console.log(curElem.length);
                                        //if (curElem.length > 1) {
                                        return (
                                          <Tag
                                            // color="volcano"
                                            bordered={false}
                                            key={id}
                                          >
                                            {curElem}
                                          </Tag>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="col-lg-2">
                            <div className="mt-5 mt-md-0 text-center pe-3 right-divs-2">
                              <div>
                                {projectListById?.data &&
                                  projectListById?.data?.length > 0 ? (
                                  <Button
                                    className="btn viewButton text-end"
                                    // style={{
                                    //   fontSize: "12px",
                                    //   padding: "",
                                    // }}
                                    onClick={() => {
                                      dispatch(setIsOpenProject(true));
                                    }}
                                  >
                                    Request this Expert
                                  </Button>
                                ) : (
                                  <Button
                                    className="btn viewButton text-end"
                                    onClick={() => {
                                      dispatch(setIsOpenCreatProject(true));
                                      dispatch(setIsOpenProject(false));
                                      // closeProjectModal(false)
                                    }}
                                  >
                                    {"Request this Expert"}
                                  </Button>
                                )}
                                {/* <Button
                                  className="btn viewButton text-end"
                                  onClick={() => {
                                    dispatch(setIsOpenCreatProject(true))
                                    dispatch(setIsOpenProject(false));
                                    // closeProjectModal(false)
                                  }}
                                >
                                  {"Request this Expert"}
                                </Button> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {store?.length > 0 ? (
                          <div
                            className="comon-divs-re"
                            style={{ width: "100%", paddingRight: "20px" }}
                          >
                            <div className="pvs-header__title-container">
                              <h2 className="pvs-header__title text-heading-large">
                                <span
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Experience
                                </span>
                              </h2>
                            </div>
                            <div className="inline-show-more-text">
                              {store.map((curElem, id) => {
                                //console.log(curElem);
                                // console.log(
                                //   curElem[1][curElem[1].length - 1]
                                //     .dateFrom
                                // );

                                return (
                                  <>
                                    <div className="companies" key={id}>
                                      <div className="companyLogo mb-4">
                                        {curElem[1][0].logoUrl !== null &&
                                          curElem[1][0].logoUrl !== "" ? (
                                          <img
                                            src={curElem[1][0].logoUrl}
                                            alt="logo"
                                            onError={addDefaultSrc}
                                          />
                                        ) : (
                                          <img
                                            src={company_icon3}
                                            alt="logo1"
                                          />
                                        )}

                                        <div style={{ marginLeft: "20px" }}>
                                          <h5
                                            className="companyName"
                                            style={{ fontSize: "18px" }}
                                          >
                                            {curElem[0]}
                                          </h5>
                                          {curElem[1][0].tillPresent ===
                                            "yes" ? (
                                            <p className="totalExperience">
                                              {Convert_Month_Year(
                                                getMonthDifference(
                                                  new Date(
                                                    curElem[1][
                                                      curElem[1].length - 1
                                                    ].dateFrom
                                                  ),
                                                  new Date(Date.now())
                                                )
                                              )}
                                            </p>
                                          ) : (
                                            <p className="totalExperience">
                                              {Convert_Month_Year(
                                                getMonthDifference(
                                                  new Date(
                                                    curElem[1][
                                                      curElem[1].length - 1
                                                    ].dateFrom
                                                  ),
                                                  new Date(
                                                    curElem[1][0].dateTill
                                                  )
                                                )
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="experienceWrapper">
                                        {curElem[1].map((curElem, id) => {
                                          return (
                                            <div
                                              className="experienceInfo"
                                              key={id}
                                            >
                                              <h5
                                                className="companyName"
                                                style={{
                                                  fontSize: "18px",
                                                }}
                                              >
                                                {curElem.designation}
                                              </h5>
                                              <p className="text-body-small inline t-black--light break-words">
                                                {curElem.dateFrom +
                                                  " - " +
                                                  (curElem.dateTill === null ||
                                                    curElem.dateTill === ""
                                                    ? "Present"
                                                    : curElem.dateTill)}
                                              </p>
                                              <div
                                                style={{
                                                  maxWidth: "92%",
                                                }}
                                              >
                                                {curElem.what_work !== null ? (
                                                  <p className="experienceContent">
                                                    {curElem?.whatWork}
                                                  </p>
                                                ) : (
                                                  <></>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {expertSummery(expertprofile[0]?.expert_ai_sumary)}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ProjectListModal
        selectedExpertId={id}
        isLoading={isLoading}
        country={expertprofile?.[0]?.country}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
        selectedExpertId={id}
      />
    </>
  );
}

export default ExpertProfile;
