import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import $ from "jquery";
import React, { useRef, useState } from "react";
import Helmet from "react-helmet";
import { FaChevronRight } from "react-icons/fa";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import downArrow from "../assests/images/down-arrow.png";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";

const config = {
  readonly: false,
  height: "280px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

function HelpSupport() {
  document.title = "Expert Cloud -  Help & Support";
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [ModalToggle, setModalToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);
  // console.log(value);

  const [email, setEmail] = useState("");
  const inputRef = useRef();

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  // console.log(email);
  function handelchange(e) {
    let email1 = e.target.value;
    const regEx = /[/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    if (!regEx.test(email1.toLowerCase())) {
      //setChekemail(true)
      // console.log("Email is invalid");
      return setMessage("Email is invalid");
    } else {
      //console.log("empty")
      setMessage("");
      setEmail(e.target.value);
      let emailId = email.split("@");
      if (
        emailId[1] === "gmail.com" ||
        emailId[1] === "outlook.com" ||
        emailId[1] === "yahoo.com" ||
        emailId[1] === "inbox.com" ||
        emailId[1] === "icloud.com" ||
        emailId[1] === "mail.com"
      ) {
        //console.log("here");
        return setMessage("Please Provide Valid Email");
      } else {
        setMessage("");
      }
      //return
    }
  }

  const handleEditorChange = (content) => {
    console.log(content, "content");
    setValue(content);
  };

  const headers = {
    "x-access-token": userData.access_token,
  };
  const handelSubmit = async () => {
    //console.log(message);
    function RemoveHtml(myString) {
      return myString.replace(/(<([^>]+)>)/gi, "");
    }
    let emailId = email.split("@");

    let payload = {
      message: RemoveHtml(value),
      clientEmail: email,
    };
    //console.log(payload);
    setSpinner(true);

    if (message === "" && value === "") {
      setSpinner(false);
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      document.getElementById("submit").disabled = true;
      await axios
        .post(`${API_PATH.SEND_EMAIL}`, payload, { headers: headers })
        .then((res) => {
          if (res.status === 200) {
            inputRef.current.value = "";
            //console.log(res);
            setSpinner(false);
            document.getElementById("submit").disabled = false;
            setValue("");

            return toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          //console.log(error);
          return toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Help & Support | Expert Cloud"}</title>
      </Helmet>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="inside-support">
                  <h2> Help & Support</h2>
                  <span className="mt-4" />

                  <button
                    type="button"
                    className="btn support-btn mt-4"
                    data-bs-toggle="modal"
                    data-bs-target="#ResubmitModal"
                  >
                    <img src="images/support-page-icon.png" alt="support" />
                    <span>
                      {" "}
                      <b className="d-block"> Submit your email request </b>
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labor */}
                    </span>
                    <FaChevronRight className="ms-auto d-table" />
                  </button>
                </div>

                {/* <div className="faq-section mt-5">
                  <h2> FAQs </h2>
                  <FaqSection />
                </div> */}
              </div>
            </div>
          </div>
          <div className="chatWithUs">
            <h4>Have a query?</h4>
            <h4>Chat with us</h4>
            <img src={downArrow} />
          </div>
        </main>
      </div>

      <div
        className="modal fade projects-modal"
        id="ResubmitModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{ marginTop: "-10px" }}>
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <h2 className="text-center mx-auto"> Submit Your Request </h2>
                </div>

                <p className="text-center">
                  {" "}
                  Our team will try and respond to you as soon as possible.
                  <br />
                  {/* Replies usually take upto 1 business day. */}
                </p>

                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email Address *</label>
                      <input
                        ref={inputRef}
                        type="text"
                        className="form-control"
                        // placeholder="Registered Email Address"
                        onChange={(e) => {
                          handelchange(e);
                        }}
                        name="email"
                      />
                      {message === "" ? (
                        <></>
                      ) : (
                        <p className="text-danger">{message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Description *</label>
                      <div className="mainl">
                        {/* <textarea
                          className="form-control d-none"
                          placeholder="Project brief"
                          value={value}
                        ></textarea>
                        <figure className="mainl-c d-none">
                          <img src="images/mail-pic.png" />
                        </figure> */}
                        <div className="">
                          <Editor
                            apiKey="4zsbw146cia5a0qmlr6rucoz820ppv27r55qepau8vvaz016"
                            initialValue=""
                            branding={false}
                            setValue={setValue}
                            value={value}
                            id="notesEditor"
                            onEditorChange={handleEditorChange}
                            init={{
                              height: 233,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | ",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        </div>
                        {/* <MailEditor
                          value={value}
                          setValue={setValue}
                          config={config}
                        /> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3 d-flex justify-content-center">
                    {message === "" ? (
                      <button
                        className="btn viewButton col-lg-5 mx-auto"
                        onClick={handelSubmit}
                        // data-bs-toggle="modal"
                        // data-bs-target="#ResubmitModal"
                        id="submit"
                      >
                        {spinner ? (
                          <>
                            <Spinner
                              size="sm"
                              style={{
                                width: "1rem",
                                height: "1rem",
                                marginRight: "5px",
                              }}
                            />
                            Submit
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn viewButton col-lg-5 mx-auto"
                        onClick={handelSubmit}
                        disabled
                        data-bs-toggle="modal"
                        data-bs-target="#ResubmitModal"
                      >
                        {" "}
                        Submit{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpSupport;
