import { Input, Tabs } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranscript from "../../Hooks/useTranscript";
import useUser from "../../Hooks/useUser";
import useTranscriptChat from "../../Hooks/useTranscriptChat";
import UnlockedTranscript from "./UnlockedTranscript";
import LockedTranscript from "./LockedTranscript";
import useProject from "../../Hooks/useProject";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { BiLibrary } from "react-icons/bi";
import { LuUnlock } from "react-icons/lu";
import {
  setSearchVal,
  setTranscriptData,
  setTranscriptPagination,
} from "../Dashboard/Transcript.Slice";
import SelectionOptionTranscript from "./SelectionOptionTranscript";

function TranscriptTable() {
  const [unlock, setUnlock] = React.useState(false);
  const { isLoading, isValidating, fetchTranscriptData } =
    useTranscript(unlock);
  const history = useNavigate();
  const { spendCredits } = useUser();
  const { AddExpertProject, getProjectListById, mutateProjectList } =
    useProject();
  const { formatDuration, formatDate } = useTranscriptChat();
  const { data, unlockedData, lockedData, searchVal } = useSelector(
    (state) => state.transcript
  );
  const creditsData = useSelector((state) => state.user.all_credits);

  function replaceTxt(txt, para) {
    txt = txt?.toUpperCase();
    for (var i = 0; i < para?.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }

  const goToDetailsPage = (curElem) => {
    history({
      pathname: `/transcript-details/${curElem?._id}`,
      state: { curElem },
    });
  };

  const handleSpendCredit = (transcript_id) => {
    spendCredits({
      transcript_id: transcript_id,
      credits_spend: creditsData?.credits_for_transcript,
    });
  };
  const dispatch = useDispatch();
  const functions = {
    goToDetailsPage,
    handleSpendCredit,
    replaceTxt,
    formatDuration,
    formatDate,
    spendCredits,
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    fetchTranscriptData,
  };
  const items = [
    {
      key: "1",
      label: (
        <span className="d-flex justify-content-center align-items-center gap-1">
          <span>
            {" "}
            <BiLibrary style={{ fontSize: "20px" }} />
          </span>
          <span>Transcripts Library</span>
        </span>
      ),
      children: (
        <LockedTranscript
          functions={functions}
          data={lockedData?.data}
          count={lockedData?.count}
          isLoading={isLoading}
          isValidating={isValidating}
        />
      ),
    },
    {
      key: "2",
      label: (
        <span className="d-flex justify-content-center align-items-center gap-1">
          <span>
            {" "}
            <LuUnlock style={{ fontSize: "17px" }} />
          </span>
          <span>Unlocked Transcripts</span>
        </span>
      ),
      children: (
        <UnlockedTranscript
          functions={functions}
          data={unlockedData?.data}
          count={unlockedData?.count}
          isLoading={isLoading}
          isValidating={isValidating}
        />
      ),
    },
  ];

  const onChange = React.useCallback(
    (key) => {
      dispatch(setTranscriptData([]));
      if (key === "2") {
        dispatch(setTranscriptPagination({ page: 1, limit: 20 }));
        dispatch(setSearchVal(""));
        setUnlock(true);
      }
      if (key === "1") {
        dispatch(setTranscriptPagination({ page: 1, limit: 20 }));
        dispatch(setSearchVal(""));
        setUnlock(false);
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    const handler = setTimeout(async () => {
      fetchTranscriptData({ search: true, key: searchVal?.trim() });
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [fetchTranscriptData, searchVal]);
  return (
    <>
      <Row className="mt-2 mb-2">
        <Col>
          <h5 style={{ fontWeight: 600 }}>
            <span>Transcripts</span>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Search transcripts library"
            value={searchVal}
            onChange={(e) => {
              let searchValue = e?.target?.value
              dispatch(setSearchVal(searchValue));
              // handleSearchInputChange(e);
            }}
            prefix={<SearchOutlined />}
            style={{ marginBottom: 16 }}
            size="large"
          />
        </Col>
      </Row>
      {/* <div className={"d-block"}>
        <SelectionOptionTranscript
        // stateList={{
        //   current_company,
        //   previous_company,
        //   email,
        //   industry,
        //   geography,
        //   designation,
        //   experties,
        //   searchRgx,
        //   currentPage,
        // }}
        // stateFunctionList={{
        //   setCompany,
        //   setPreviousCompany,
        //   setEmail,
        //   setIndustry,
        //   setGeography,
        //   setDesignation,
        //   setExperties,
        //   setCurrentPage,
        //   setWordEntered,
        //   setSearchRgx,
        // }}
        // handleSearchExperts={handleSearchExperts}
        // stateListFilter={stateListFilter}
        // stateFunctionListFilter={stateFunctionListFilter}
        // callOnce={callOnce}
        // callFilter={callFilter}
        // getExpertsOptions={getExpertsOptions}
        // filterRef={filterRef}
        />
      </div> */}

      <Row className="heightTranscript">
        <Col>
          <Tabs items={items} onChange={onChange} />
        </Col>
      </Row>
    </>
  );
}

export default TranscriptTable;
