import env from "./env";

const API_URL = {
  LOGINURL: `${env.BASE_URL}/user/client-login`,
  GET_USER_DATA: `${env.BASE_URL}/user/getUserData`,
  OTPURL: `${env.BASE_URL}`,
  STEP: `${env.BASE_URL}`,

  // dashboard apis
  EXPERT_LIST: `${env.BASE_URL}/expert-list/get-all-expert`, // /pagenumber
  EXPERT_OPTION: `${env.BASE_URL}/expert-list/get-all-option`,
  SHARE_EXPERT: `${env.BASE_URL}/expert-list/update`, // /pagenumber
  DELETE_SHARE_EXPERT: `${env.BASE_URL}/expert-list/delete`,
  GETSINGLE_EXPERT: `${env.BASE_URL}/expert-list/get-one-expert`,
  EXPERT_SCHEDULE: `${env.BASE_URL}/expert-list/get-one-expert-schedule`,
  ADD_REQUESTED_EXPERT: `${env.BASE_URL}/expert-list/add-requested-expert`,
  GET_ASSEMBLY_RESPONSE: `${env.BASE_URL}/zoom-meeting/get-meeting-assembly-response`,

  //demoregistration apis
  DEMO_POST: `${env.BASE_URL}/demo-user/add`,

  // change password
  CHANGE_PASSWORD: `${env.BASE_URL}/user/update`,

  MEETING_DELETE: `${env.BASE_URL}/zoom-recording/delete`,

  // project apis
  PRJECT_LIST: `${env.BASE_URL_PLAT}/client/all-project`,
  // PRJECT_DETAILS: `${env.SQL_HOST}/client_project_detail`,
  PRJECT_STATUS_CHANGE: `${env.BASE_URL_PLAT}/client/project-status`,

  // user management
  USER_MANAGEMENT: `${env.BASE_URL}/user/get-all-user`, //
  USER_MANAGEMENT_POST: `${env.BASE_URL}/user/create-user`, //
  USER_MANAGEMENT_DELETE: `${env.BASE_URL}/user/delete-user`, //
  USER_MANAGEMENT_UPDATE: `${env.BASE_URL}/user/update`, //

  //roles
  ROLE_MANAGEMENT: `${env.BASE_URL}/role/list`, //
  ROLE_MANAGEMENT_POST: `${env.BASE_URL}/role/add`, //
  ROLE_MANAGEMENT_UPDATE: `${env.BASE_URL}/role/update`, //
  ROLE_MANAGEMENT_DELETE: `${env.BASE_URL}/role/delete`, //

  // Client_Project overview details
  Client_Project_Expert_List: `${env.BASE_URL_PLAT}/project/project-expert-list`,
  PROJECT_EXPERT_GET: `${env.BASE_URL_PLAT}/project/project-expert-data`,
  // CLIENT_ADMIN_CHAT_DATA: `${env.BASE_URL_PLAT}/admin/chat-data-by-project`,
  ONE_EXPERT: `${env.BASE_URL}/user/send-mail-to-expert`,
  ONE_EXPERT_STARRED: `${env.BASE_URL}/expert-list/add-starred-expert`,
  ALL_STARRED_EXPERT: `${env.BASE_URL}/expert-list/get-all-stared-expert`,
  CLIENT_PROJECT_DETAILS: `${env.BASE_URL_PLAT}/client/project-by-id`,
  CLIENT_ADD_TEAM_MEMBER: `${env.BASE_URL_PLAT}/client/invite-team`,
  REMOVE_INVITES: `${env.BASE_URL_PLAT}/client/remove-invite-team`,
  // CHAT_INSERT: `${env.BASE_URL_PLAT}/admin/chat-insert`,
  // CHAT_SEEN: `${env.SQL_HOST}/admin_msg_seen`,
  // CHAT_CLIENT_SEEN: `${env.SQL_HOST}/client_msg_seen`,
  // CHAT_NEW_MESSAGE: `${env.SQL_HOST}/get_client_chat_msg`,
  // ALL_CLIENT_PROJECT_EXPERT_LIST: `${env.SQL_HOST}/client_all_project_expert_list`,
  PROJECT_EXPERT_DELETE: `${env.BASE_URL_PLAT}/project/delete-expert`,
  ALL_CLIENT_PROJECT_EXPERT_LIST: `${env.BASE_URL_PLAT}/client/requested-call`,

  //chat
  CHAT_INSERT: `${env.BASE_URL_PLAT}/admin/chat-insert`,
  CLIENT_ADMIN_CHAT_DATA: `${env.BASE_URL_PLAT}/admin/chat-data-by-project`,
  CHAT_DELETE: `${env.BASE_URL_PLAT}/admin/chat-delete`,
  CHAT_EDIIT: `${env.BASE_URL_PLAT}/admin/chat-update`,
  CHAT_SEEN: `${env.BASE_URL_PLAT}/client/chat-seen`,
  CHAT_SEEN_USER_DATA: `${env.BASE_URL_PLAT}/client/chat-seen`,

  PRJECT_DETAILS: `${env.BASE_URL_PLAT}/project/project-by-id`,

  //Get Project Expert List
  PROJECT_EXPERT_LIST: `${env.BASE_URL_PLAT}/project/project-expert-list`,

  //news apis
  NEWS_LIST: `${env.BASE_URL}/news/getNews`, //

  CATEGORY_UPDATE: `${env.BASE_URL}/user/update`,

  //ExpertDetails
  // Expert_Details: `${env.SQL_HOST}/expert_details`,
  // EXPERT_POPUP_DETAILS: `${env.SQL_HOST}/expert_popup_details`,
  // CLIENT_SCHEDULE: `${env.SQL_HOST}/client_schedule`,
  // CLIENT_SCHEDULECANCEL: `${env.SQL_HOST}/client_scheduleCancel`,
  // CLIENT_DECLINE: `${env.SQL_HOST}/client_declined`,

  //collaboration
  NOTE_LIST: `${env.BASE_URL}/note/getNotes`, //
  RECENTLY_VIEWD: `${env.BASE_URL}/note/getRecentlyViewd`, //
  NOTE_CREATE: `${env.BASE_URL}/note/createNote`, //
  NOTE_DETAILS: `${env.BASE_URL}/note/getSingleNote`, //
  NOTE_UPDATE: `${env.BASE_URL}/note/updateNote`, //?NoteID="PASS THE ID HERE"
  NOTE_DELETE: `${env.BASE_URL}/note/deleteNote`, //?NoteID="PASS THE ID HERE"
  NOTE_PERMISSION: `${env.BASE_URL}/note/updateNotePermission`, // /noteid/currentuserId mothod --put
  REMOVE_NOTE: `${env.BASE_URL}/note/removeNote`, //?noteId='' ,userId=''  mothod --delete

  CONTENT_LIST: `${env.BASE_URL}/content/getContents`, // /userId
  CONTENT_CREATE: `${env.BASE_URL}/content/createContent`, //
  CONTENT_DETAILS: `${env.BASE_URL}/content/getSingleContent`, //ContentID
  CONTENT_UPDATE: `${env.BASE_URL}/content/updateContent`, // ?ContentID="PASS THE ID HERE"
  CONTENT_DELETE: `${env.BASE_URL}/content/deleteContent`, //?ContentID="PASS THE ID HERE"
  CONTENT_PERMISSION: `${env.BASE_URL}/content/updateContentPermission`, // /ContentID/currentuserId mothod --put
  REMOVE_CONTENT: `${env.BASE_URL}/content/removeContent`, //?contentId='' ,userId=''  mothod --delete

  COVER_LIST: `${env.BASE_URL}/cover/getCovers`, //
  COVER_CREATE: `${env.BASE_URL}/cover/createCover`, //
  COVER_DETAILS: `${env.BASE_URL}/cover/getSingleCover`, //
  COVER_UPDATE: `${env.BASE_URL}/cover/updateCover`, //?coverID="PASS THE ID HERE"
  COVER_DELETE: `${env.BASE_URL}/cover/deleteCover`, //?coverID="PASS THE ID HERE"
  COVER_PERMISSION: `${env.BASE_URL}/cover/updateCoverPermission`, // /coverID/currentuserId mothod --put
  REMOVE_COVER: `${env.BASE_URL}/cover/removeCover`, //?coverId='' ,userId=''  mothod --delete

  MEETING_LIST: `${env.BASE_URL}/zoom-meeting/getMeetings`, //params companydomain
  MEETING_ID_GET: `${env.BASE_URL}/zoom-recording/getOne`, //params companydomain
  MEETING_ID_UPDATE: `${env.BASE_URL}/zoom-recording/update`, //params companydomain

  SLEEVE_LIST: `${env.BASE_URL}/sleeve/getSleeve`, // /userId
  SLEEVE_CREATE: `${env.BASE_URL}/sleeve/createSleeve`, //
  SLEEVE_DETAILS: `${env.BASE_URL}/sleeve/getSingleSleeve`, // /sleeveId
  SLEEVE_UPDATE: `${env.BASE_URL}/sleeve/updateSleeve`, // /sleeveId
  SLEEVE_DELETE: `${env.BASE_URL}/sleeve/deleteSleeve`, // /sleeveId
  SLEEVE_PERMISSION: `${env.BASE_URL}/sleeve/updateSleevePermission`, // /sleeveId/currentuserId mothod --put
  REMOVE_SLEEVE: `${env.BASE_URL}/sleeve/removeSleeve`, //?sleeveId='' ,userId=''  mothod --delete

  //Conversation
  APP_ID: "19A8592E-92E0-4320-8F57-E9C919F47B17",
  Api_Token: "d19dd8f5462dbdc20a1ad1f47dbbf99e01313da3",

  //Help & Support
  SEND_EMAIL: `${env.BASE_URL}/user/send-mail`, //

  //get expire time
  EXPIRE_TIME: `${env.BASE_URL}/user/get-expire-time`,
  ADD_EXPERT_INDUSTRY: `${env.BASE_URL_PLAT}/expert/all-industry`,

  //project list
  LIVE_PROJECT: `${env.BASE_URL_PLAT}/client/live-project`,
  HOLD_PROJECT: `${env.BASE_URL_PLAT}/client/archived-project`,
  COMPLETE_PROJECT: `${env.BASE_URL_PLAT}/client/completed-project`,
  PROJECT_INVITE_DELETE: `${env.BASE_URL_PLAT}/client/remove-invite-team`,
  PROJECT_STATUS: `${env.BASE_URL_PLAT}/project/project-expert-status`,

  //find-experts
  CREATE_PROJECT: `${env.BASE_URL_PLAT}/client/add-project`,

  //upload file to s3
  UPLOAD_MEDIA: `${env.BASE_URL}/s3upload/uploadFile`, //

  //forget password
  FORGOT_PASSWORD: `${env.BASE_URL}/user/forgot-password`,
  RESET_PASSWORD: `${env.BASE_URL}/user/reset-password`,
  // call list
  CALL_LIST: `${env.BASE_URL_PLAT}/client/completed-call`,

  //credit management
  GET_AVAILABLE_CREDITS: `${env.BASE_URL}/credit-management/get-available-credits`,
  GET_ALL_CREDITS: `${env.BASE_URL}/credit-management/get-credit-usage-value`,
  SPEND_CREDITS: `${env.BASE_URL}/credit-management/credit-spend`,

  //elastic search
  ELASTIC_SEARCH_BY_QUERY: `${env.BASE_URL}/elastic/search`,
  ELASTIC_SEARCH: `${env.BASE_URL}/elastic/posts`,

  GET_NOTIFICATION_BY_SOCKET: `${env.BASE_URL}/notification/user-notification`, // / currentuserId METHOD -- GET
  UPDATE_NOTIFICATION_READ_STATUS: `${env.BASE_URL}/notification/update-notification`, // METHOD -- GET

  //NEWS
  GET_FINANCE_NEWS: `${env.BASE_URL}/news/list-serp-api-news-data`,
  GET_FINANCE_NEWS_AI_SUMMARY: `${env.BASE_URL}/news/fetch-ai-summary`,

  //Transcript
  GET_TRANSCRIPT_DATA: `${env.BASE_URL}/cloud-zoom-meeting`,
  GET_TRANSCRIPT_DATA_BY_ID: `${env.BASE_URL}/cloud-zoom-meeting/get-single-transcript`,
  GET_TRANSCRIPT_CHAT_DATA: `${env.BASE_URL}/cloud-zoom-meeting/get-chat-answer`,
  GET_TRANSCRIPT_AI_SUMMARY: `${env.BASE_URL}/cloud-zoom-meeting/generate-ai-summary`,
  GET_TRANSACTION_HISTORY_BY_ID: `${env.BASE_URL}/credit-management/credit-transaction-list`,
  GET_PAYMENT_HISTORY_BY_ID: `${env.BASE_URL}/credit-management/credit-payment-list`,

  //Experts
  GET_EXPERT_DATA: `${env.BASE_URL}/expert`,
  GET_EXPERT_DATA_BY_ID: `${env.BASE_URL}/expert/expert-by-id`,
  EXPERT_FILTER_SEARCH: `${env.BASE_URL}/expert/expert-filter-search`,
  EXPERT_FILTER_OPTION: `${env.BASE_URL}/expert/expert-filter-list`,

  //WatchList
  GET_EXPERT_WATCHLIST_DATA: `${env.BASE_URL}/expert/get-expert-from-wishlist`,
  GET_TRANS_WATCHLIST_DATA: `${env.BASE_URL}/cloud-zoom-meeting/get-meeting-from-wishlist`,
  GET_NEWS_WATCHLIST_DATA: `${env.BASE_URL}/news/get-news-from-wishlist`,
  GET_WATCHLIST_DATA: `${env.BASE_URL}/watchlist/get-watchlist`,
  ADD_COMPANY_TO_WATCHLIST: `${env.BASE_URL}/watchlist/add-company-to-watchlist`,
  ADD_KEYWORD_TO_WATCHLIST: `${env.BASE_URL}/watchlist/add-keyword-to-watchlist`,
  REMOVE_COMPANY_FROM_WATCHLIST: `${env.BASE_URL}/watchlist/remove-company-from-watchlist`,
  REMOVE_KEYWORD_FROM_WATCHLIST: `${env.BASE_URL}/watchlist/remove-keyword-from-watchlist`,

  //Company Search
  GET_COMPANY_DATA_BY_ID: `${env.BASE_URL}/search/get-company-data`,
  GET_EXPERT_SEARCH_DATA_BY_ID: `${env.BASE_URL}/search/get-company-expert`,
  GET_TRANSCRIPT_SEARCH_DATA_BY_ID: `${env.BASE_URL}/search/get-company-transcript`,
  GET_NEWS_SEARCH_DATA_BY_ID: `${env.BASE_URL}/search/get-company-news`,
  GET_YEARLY_STANDALONE_FINANCE_DATA: `${env.BASE_URL}/search/get-yearly-standalone-finance-data`,
  GET_YEARLY_CONSOLIDATED_FINANCE_DATA: `${env.BASE_URL}/search/get-yearly-consolidated-finance-data`,
  GET_QUARTERLY_CONSOLIDATED_FINANCE_DATA: `${env.BASE_URL}/search/get-quarterly-consolidated-finance-data`,
  GET_QUARTERLY_STANDALONE_FINANCE_DATA: `${env.BASE_URL}/search/get-quarterly-standalone-finance-data`,
  REQUEST_FINANCIALS: `${env.BASE_URL}/search/add-cloud-company-request`,
  CHECK_UNLOCKING_STATUS: `${env.BASE_URL}/search/check-company-unlock-status`,
  GET_COMPANY_SHAREHOLDINGS_ALLOTMENTS_HISTORY: `${env.BASE_URL}/search/get-company-shareholdings-allotments-history`,
  GET_COMPANY_ASSOCIATE_PARTLY_TRANSACTION: `${env.BASE_URL}/search/get-company-associates-related-party-txn`,
  GET_COMPANY_ASSOCIATE_DATA: `${env.BASE_URL}/search/get-company-associates`,
  GET_COMPANY_COMPLIANCE_GST_BY_YEAR: `${env.BASE_URL}/search/get-company-compliance-gst-by-year`,
  GET_COMPANY_COMPLIANCE_EPF_BY_YEAR: `${env.BASE_URL}/search/get-companies-compliance-epf-list-by-year`,
  GET_COMPANY_COMPLIANCE_GST_LIST_ESTABLISHMENT: `${env.BASE_URL}/search/get-company-compliance-gst-list-establishments`,
  GET_COMPANY_COMPLIANCE_EPF_LIST_ESTABLISHMENT: `${env.BASE_URL}/search/get-company-compliance-epf-list-establishments`,
  GET_DETAILS_OF_EPF_ESTABLISHMENT: `${env.BASE_URL}/search/get-details-of-epf-establishments`,
  GET_DETAILS_OF_GST_ESTABLISHMENT: `${env.BASE_URL}/search/get-details-of-establishments`,
  GET_LITIGATIONS_TABS_COUNT: `${env.BASE_URL}/search/get-litigations-tabs-count`,
  GET_LITIGATIONS_COURT_CASE: `${env.BASE_URL}/search/get-litigations-court-cases`,
  GET_LITIGATIONS_CREDIT_BURO: `${env.BASE_URL}/search/get-litigations-credit-bureau`,
  GET_LITIGATIONS_DRT: `${env.BASE_URL}/search/get-litigations-drt`,
  GET_LITIGATIONS_SAT: `${env.BASE_URL}/search/get-litigations-sat`,
  GET_LITIGATIONS_DISTRICT_COURT_CASE: `${env.BASE_URL}/search/get-litigations-district-court-cases`,
  GET_LITIGATIONS_NCLT: `${env.BASE_URL}/search/get-litigations-nclt`,
  GET_CORPORATE_ANNOUNCEMENT: `${env.BASE_URL}/search/get-company-announcements`,
  GET_STOCK_PRICE: `${env.BASE_URL}/search/get-stock-price`,
  GET_DIRECTOR_DETAILS_BY_DIN: `${env.BASE_URL}/search/get-company-directors-by-din`,
  GET_CONFERENCE_CALLS: `${env.BASE_URL}/search/get-company-conference-calls`,

  //project
  GET_PROJECT_LIST_BY_ID: `${env.BASE_URL_PLAT}/client/live-project`,
  GET_COMPANY_LIST_BY_SEARCH: `${env.BASE_URL}/search`,
  ADD_EXPERT_PROJECT: `${env.BASE_URL}/projects/add-projects-expert`,

  KEYWORD_SEARCH_TRANSCRIPT: `${env.BASE_URL}/search/keyword-search-transcript`,
  KEYWORD_SEARCH_EXPERT: `${env.BASE_URL}/search/keyword-search-expert`,
  KEYWORD_SEARCH_NEWS: `${env.BASE_URL}/search/keyword-search-news`,
  CHECK_KEYWORD_WATCHLISTED: `${env.BASE_URL}/watchlist/check-keyword-watchlisted`,

  //Company Pages
  COMPANY_DIRECTORS_API: `${env.BASE_URL}/search/get-company-directors`,
  COMPANY_AUDITORS_API: `${env.BASE_URL}/search/get-company-auditors`,
  COMPANY_CHARGES_API: `${env.BASE_URL}/search/get-company-charge-list`,
  COMPANY_RATINGS_API: `${env.BASE_URL}/search/get-company-credit-ratings`,
};

export default API_URL;
