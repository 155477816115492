import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    projectListById: { data: [], count: 0 },
    isOpenCreateProject: false,
    isOpenProject: false
}

const projectSlice = createSlice({
    initialState: initialState,
    name: "ProjectListById",
    reducers: {

        setProjectListById: (state, { payload }) => {
            state.projectListById = payload
        },
        setIsOpenCreatProject: (state, { payload }) => {
            state.isOpenCreateProject = payload
        },
        setIsOpenProject: (state, { payload }) => {
            state.isOpenProject = payload
        }

    }
})
export const projectReducer = projectSlice.reducer
export const { setProjectListById, setIsOpenCreatProject,
    setIsOpenProject } = projectSlice.actions