import React, { useEffect } from "react";
import MobileMenu from "../../../MobileMenu";

import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = () => {
  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages support-page">
          <div className="inside-support">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h5 style={{ fontWeight: 600 }}>Change Password</h5>
              </div>
            </div>
          </div>
          <div className="faq-section mt-4">
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
