import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

function Layout({ hideHeaderPaths = [] }) {
  const { pathname } = useLocation();
  let path = pathname?.split("/")[1];
  const uri = hideHeaderPaths.filter((p) => p?.url === path);
  return (
    <>
      {uri[0]?.type === "hide" ? (
        <Sidebar skipHeader />
      ) : uri[0]?.type === "cls" ? (
        <Sidebar cls />
      ) : (
        <Sidebar />
      )}
      <Outlet />
    </>
  );
}

export default Layout;
