import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../../../App.css";
import "../../../assests/css/custom.css";
import API_PATH from "../../../Constants/api-path";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Col, Modal, Row } from "react-bootstrap";
import {
  Form,
  Dropdown,
  Menu,
  List,
  Select,
  Input,
  Spin,
  Button,
  Tooltip,
  Space,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  MdDelete,
  MdModeEditOutline,
  MdOutlineClose,
  MdBorderColor,
} from "react-icons/md";
import AvatarGroup from "react-avatar-group";
import Loader from "../../Components/Loader";

function UserMangementTable({ data, deleteUser, load, getUsers }) {
  const [showCreateModal, setCreateModal] = useState(false);
  const [updateUserId, setUpdateUserId] = useState("");
  const [isUserId, setIsUserId] = useState("");
  const [message, setMessage] = useState("");
  const [rolesData, setRolesData] = useState([""]);
  const [spinner, setSpinner] = useState(false);
  const onceCall = React.useRef(false);
  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    password: "",
    role_id: null,
    registrationType: "cloud",
    contact_number_full: "",
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const headers = React.useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );
  const [form] = Form.useForm();

  const updateUserModal = useCallback(async () => {
    try {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regEx.test(addUser.email.toLowerCase())) {
        //setChekemail(true)
        //console.log("Email is invalid");
        return setMessage("Email is invalid");
      } else {
        //console.log("empty")
        setMessage("");
        let email = addUser.email.split("@");
        if (
          email[1] === "gmail.com" ||
          email[1] === "outlook.com" ||
          email[1] === "yahoo.com" ||
          email[1] === "inbox.com" ||
          email[1] === "icloud.com" ||
          email[1] === "mail.com"
        ) {
          return setMessage("Please Provide Valid Email");
        } else {
          setMessage("");
        }
      }

      setSpinner(true);
      const res = await fetch(
        `${API_PATH.USER_MANAGEMENT_UPDATE}/${updateUserId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstNmae: addUser.firstNmae,
            email: addUser.email,
            contact_number: addUser.contact_number,
            contact_code: addUser.contact_code,
            country: addUser.country,
            role_id: addUser.role_id,
            name: addUser.firstNmae,
          }),
        }
      );

      if (res.status === 200) {
        setSpinner(false);
        setCreateModal(false);
        getUsers();
        return toast.success("User Updated Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        setSpinner(false);
        setCreateModal(false);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setSpinner(false);
      setCreateModal(false);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [
    addUser.contact_code,
    addUser.contact_number,
    addUser.country,
    addUser.email,
    addUser.firstNmae,
    addUser.role_id,
    getUsers,
    updateUserId,
  ]);

  const getUserList = useCallback(async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      setRolesData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [headers, userData?.user?.company_id?._id]);

  useEffect(() => {
    if (!onceCall.current) {
      onceCall.current = true;
      getUserList();
    }
  }, [getUserList]);

  const setUserModal = useCallback((curElem) => {
    setUpdateUserId(curElem?._id);
    setAddUser({
      firstNmae: curElem?.name,
      email: curElem?.email,
      contact_code: curElem?.contact_code,
      contact_number: curElem?.contact_number,
      country: curElem?.country,
      password: "",
      role_id: curElem?.role_id ? curElem?.role_id?._id : null,
      registrationType: "cloud",
      contact_number_full: curElem?.contact_code + curElem?.contact_number,
    });
  }, []);

  const getUserId = useCallback((id) => {
    setIsUserId(id);
  }, []);

  const deleteUserModal = useCallback(async () => {
    await deleteUser(isUserId);
    setCreateModal(false);
  }, [deleteUser, isUserId]);

  const [modalMode, setModalMode] = useState("");

  const openModal = (mode) => {
    setModalMode(mode);
    setCreateModal(true);
  };

  const menu = (curElem) => (
    <Menu>
      {permission?.userManagement?.update && (
        <Menu.Item
          key="edit"
          onClick={() => {
            setUserModal(curElem);
            openModal("edit");
          }}
        >
          <span style={{ fontSize: "14px" }}>
            <MdModeEditOutline style={{ marginRight: "8px" }} />
            Edit
          </span>
        </Menu.Item>
      )}
      {permission?.userManagement?.delete && (
        <Menu.Item
          key="delete"
          onClick={() => {
            getUserId(curElem?._id);
            openModal("delete");
          }}
        >
          <span>
            <MdDelete style={{ marginRight: "8px" }} />
            Delete
          </span>
        </Menu.Item>
      )}
    </Menu>
  );

  const convertDate = useCallback((date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  }, []);

  const countryOptions = [
    { code: "+1", name: "United States" },
    { code: "+44", name: "United Kingdom" },
    { code: "+61", name: "Australia" },
    { code: "+33", name: "France" },
    { code: "+49", name: "Germany" },
    { code: "+81", name: "Japan" },
    { code: "+91", name: "India" },
    { code: "+86", name: "China" },
    { code: "+55", name: "Brazil" },
    { code: "+52", name: "Mexico" },
    { code: "+39", name: "Italy" },
    { code: "+7", name: "Russia" },
    { code: "+82", name: "South Korea" },
    { code: "+351", name: "Portugal" },
    { code: "+34", name: "Spain" },
    { code: "+54", name: "Argentina" },
    { code: "+63", name: "Philippines" },
    { code: "+234", name: "Nigeria" },
    { code: "+62", name: "Indonesia" },
    { code: "+55", name: "Anguilla" },
    { code: "+1264", name: "Antigua and Barbuda" },
    { code: "+54", name: "Argentina" },
    { code: "+374", name: "Armenia" },
    { code: "+297", name: "Aruba" },
    { code: "+61", name: "Australia" },
    { code: "+43", name: "Austria" },
    { code: "+994", name: "Azerbaijan" },
    { code: "+1242", name: "Bahamas" },
    { code: "+973", name: "Bahrain" },
    { code: "+880", name: "Bangladesh" },
    { code: "+1246", name: "Barbados" },
    { code: "+375", name: "Belarus" },
    { code: "+32", name: "Belgium" },
    { code: "+501", name: "Belize" },
    { code: "+229", name: "Benin" },
    { code: "+1441", name: "Bermuda" },
    { code: "+975", name: "Bhutan" },
    { code: "+591", name: "Bolivia" },
    { code: "+387", name: "Bosnia and Herzegovina" },
    { code: "+267", name: "Botswana" },
    { code: "+55", name: "Brazil" },
    { code: "+246", name: "British Indian Ocean Territory" },
    { code: "+1284", name: "British Virgin Islands" },
    { code: "+673", name: "Brunei" },
    { code: "+359", name: "Bulgaria" },
    { code: "+226", name: "Burkina Faso" },
    { code: "+257", name: "Burundi" },
    { code: "+855", name: "Cambodia" },
    { code: "+237", name: "Cameroon" },
    { code: "+1", name: "Canada" },
    { code: "+238", name: "Cape Verde" },
    { code: "+599", name: "Caribbean Netherlands" },
    { code: "+1345", name: "Cayman Islands" },
    { code: "+236", name: "Central African Republic" },
    { code: "+235", name: "Chad" },
    { code: "+56", name: "Chile" },
    { code: "+86", name: "China" },
    { code: "+57", name: "Colombia" },
    { code: "+269", name: "Comoros" },
    { code: "+242", name: "Congo - Brazzaville" },
    { code: "+243", name: "Congo - Kinshasa" },
    { code: "+682", name: "Cook Islands" },
    { code: "+506", name: "Costa Rica" },
    { code: "+225", name: "Côte d’Ivoire" },
    { code: "+385", name: "Croatia" },
    { code: "+53", name: "Cuba" },
    { code: "+599", name: "Curaçao" },
    { code: "+357", name: "Cyprus" },
    { code: "+420", name: "Czech Republic" },
    { code: "+45", name: "Denmark" },
    { code: "+253", name: "Djibouti" },
    { code: "+1767", name: "Dominica" },
    { code: "+1809", name: "Dominican Republic" },
    { code: "+670", name: "East Timor" },
    { code: "+593", name: "Ecuador" },
    { code: "+20", name: "Egypt" },
    { code: "+503", name: "El Salvador" },
    { code: "+240", name: "Equatorial Guinea" },
    { code: "+291", name: "Eritrea" },
    { code: "+372", name: "Estonia" },
    { code: "+251", name: "Ethiopia" },
    { code: "+500", name: "Falkland Islands" },
    { code: "+298", name: "Faroe Islands" },
    { code: "+679", name: "Fiji" },
    { code: "+358", name: "Finland" },
    { code: "+33", name: "France" },
    { code: "+594", name: "French Guiana" },
    { code: "+689", name: "French Polynesia" },
    { code: "+241", name: "Gabon" },
    { code: "+220", name: "Gambia" },
    { code: "+995", name: "Georgia" },
    { code: "+49", name: "Germany" },
    { code: "+233", name: "Ghana" },
    { code: "+350", name: "Gibraltar" },
    { code: "+30", name: "Greece" },
    { code: "+299", name: "Greenland" },
    { code: "+1473", name: "Grenada" },
    { code: "+590", name: "Guadeloupe" },
    { code: "+1671", name: "Guam" },
    { code: "+502", name: "Guatemala" },
    { code: "+44", name: "Guernsey" },
    { code: "+224", name: "Guinea" },
    { code: "+245", name: "Guinea-Bissau" },
    { code: "+592", name: "Guyana" },
    { code: "+509", name: "Haiti" },
    { code: "+504", name: "Honduras" },
    { code: "+852", name: "Hong Kong SAR China" },
    { code: "+36", name: "Hungary" },
    { code: "+354", name: "Iceland" },
    { code: "+91", name: "India" },
    { code: "+62", name: "Indonesia" },
    { code: "+98", name: "Iran" },
    { code: "+964", name: "Iraq" },
    { code: "+353", name: "Ireland" },
    { code: "+44", name: "Isle of Man" },
    { code: "+972", name: "Israel" },
    { code: "+39", name: "Italy" },
    { code: "+1876", name: "Jamaica" },
    { code: "+81", name: "Japan" },
    { code: "+44", name: "Jersey" },
    { code: "+962", name: "Jordan" },
    { code: "+7", name: "Kazakhstan" },
    { code: "+254", name: "Kenya" },
    { code: "+686", name: "Kiribati" },
    { code: "+383", name: "Kosovo" },
    { code: "+965", name: "Kuwait" },
    { code: "+996", name: "Kyrgyzstan" },
    { code: "+856", name: "Laos" },
    { code: "+371", name: "Latvia" },
    { code: "+961", name: "Lebanon" },
    { code: "+266", name: "Lesotho" },
    { code: "+231", name: "Liberia" },
    { code: "+218", name: "Libya" },
    { code: "+423", name: "Liechtenstein" },
    { code: "+370", name: "Lithuania" },
    { code: "+352", name: "Luxembourg" },
    { code: "+853", name: "Macau SAR China" },
    { code: "+389", name: "Macedonia" },
    { code: "+261", name: "Madagascar" },
    { code: "+265", name: "Malawi" },
    { code: "+60", name: "Malaysia" },
    { code: "+960", name: "Maldives" },
    { code: "+223", name: "Mali" },
    { code: "+356", name: "Malta" },
    { code: "+692", name: "Marshall Islands" },
    { code: "+596", name: "Martinique" },
    { code: "+222", name: "Mauritania" },
    { code: "+230", name: "Mauritius" },
    { code: "+262", name: "Mayotte" },
    { code: "+52", name: "Mexico" },
    { code: "+691", name: "Micronesia" },
    { code: "+373", name: "Moldova" },
    { code: "+377", name: "Monaco" },
    { code: "+976", name: "Mongolia" },
    { code: "+382", name: "Montenegro" },
    { code: "+1664", name: "Montserrat" },
    { code: "+212", name: "Morocco" },
    { code: "+258", name: "Mozambique" },
    { code: "+95", name: "Myanmar (Burma)" },
    { code: "+264", name: "Namibia" },
    { code: "+674", name: "Nauru" },
    { code: "+977", name: "Nepal" },
    { code: "+31", name: "Netherlands" },
    { code: "+687", name: "New Caledonia" },
    { code: "+64", name: "New Zealand" },
    { code: "+505", name: "Nicaragua" },
    { code: "+227", name: "Niger" },
    { code: "+234", name: "Nigeria" },
    { code: "+683", name: "Niue" },
    { code: "+672", name: "Norfolk Island" },
    { code: "+850", name: "North Korea" },
    { code: "+1670", name: "Northern Mariana Islands" },
    { code: "+47", name: "Norway" },
    { code: "+968", name: "Oman" },
    { code: "+92", name: "Pakistan" },
    { code: "+680", name: "Palau" },
    { code: "+970", name: "Palestinian Territories" },
    { code: "+507", name: "Panama" },
    { code: "+675", name: "Papua New Guinea" },
    { code: "+595", name: "Paraguay" },
    { code: "+51", name: "Peru" },
    { code: "+63", name: "Philippines" },
    { code: "+48", name: "Poland" },
    { code: "+351", name: "Portugal" },
    { code: "+1", name: "Puerto Rico" },
    { code: "+974", name: "Qatar" },
    { code: "+262", name: "Réunion" },
    { code: "+40", name: "Romania" },
    { code: "+7", name: "Russia" },
    { code: "+250", name: "Rwanda" },
    { code: "+590", name: "Saint Barthélemy" },
    { code: "+290", name: "Saint Helena" },
    { code: "+1869", name: "Saint Kitts and Nevis" },
    { code: "+1758", name: "Saint Lucia" },
    { code: "+590", name: "Saint Martin" },
    { code: "+508", name: "Saint Pierre and Miquelon" },
    { code: "+1784", name: "Saint Vincent and the Grenadines" },
    { code: "+685", name: "Samoa" },
    { code: "+378", name: "San Marino" },
    { code: "+239", name: "São Tomé and Príncipe" },
    { code: "+966", name: "Saudi Arabia" },
    { code: "+221", name: "Senegal" },
    { code: "+381", name: "Serbia" },
    { code: "+248", name: "Seychelles" },
    { code: "+232", name: "Sierra Leone" },
    { code: "+65", name: "Singapore" },
    { code: "+1721", name: "Sint Maarten" },
    { code: "+421", name: "Slovakia" },
    { code: "+386", name: "Slovenia" },
    { code: "+677", name: "Solomon Islands" },
    { code: "+252", name: "Somalia" },
    { code: "+27", name: "South Africa" },
    { code: "+82", name: "South Korea" },
    { code: "+211", name: "South Sudan" },
    { code: "+34", name: "Spain" },
    { code: "+94", name: "Sri Lanka" },
    { code: "+249", name: "Sudan" },
    { code: "+597", name: "Suriname" },
    { code: "+47", name: "Svalbard and Jan Mayen" },
    { code: "+268", name: "Swaziland" },
    { code: "+46", name: "Sweden" },
    { code: "+41", name: "Switzerland" },
    { code: "+963", name: "Syria" },
    { code: "+886", name: "Taiwan" },
    { code: "+992", name: "Tajikistan" },
    { code: "+255", name: "Tanzania" },
    { code: "+66", name: "Thailand" },
    { code: "+670", name: "Timor-Leste" },
    { code: "+228", name: "Togo" },
    { code: "+690", name: "Tokelau" },
    { code: "+676", name: "Tonga" },
    { code: "+1868", name: "Trinidad and Tobago" },
    { code: "+216", name: "Tunisia" },
    { code: "+90", name: "Turkey" },
    { code: "+993", name: "Turkmenistan" },
    { code: "+1649", name: "Turks and Caicos Islands" },
    { code: "+688", name: "Tuvalu" },
    { code: "+1340", name: "U.S. Virgin Islands" },
    { code: "+256", name: "Uganda" },
    { code: "+380", name: "Ukraine" },
    { code: "+971", name: "United Arab Emirates" },
    { code: "+44", name: "United Kingdom" },
    { code: "+1", name: "United States" },
    { code: "+598", name: "Uruguay" },
    { code: "+998", name: "Uzbekistan" },
    { code: "+678", name: "Vanuatu" },
    { code: "+39", name: "Vatican City" },
    { code: "+58", name: "Venezuela" },
    { code: "+84", name: "Vietnam" },
    { code: "+681", name: "Wallis and Futuna" },
    { code: "+212", name: "Western Sahara" },
    { code: "+967", name: "Yemen" },
    { code: "+260", name: "Zambia" },
    { code: "+263", name: "Zimbabwe" },
  ];

  // const handlePhoneChange = (value, country) => {
  //   const selectedCountry = countryOptions?.find(
  //     (option) => option?.code === `+${country?.dialCode}`
  //   );
  //   if (selectedCountry) {
  //   } else {
  //     console.log("No matching country found for dial code:", country.dialCode);
  //   }
  // };

  const validateEmailDomain = useCallback(
    (_, value) => {
      // Check if value is a valid email address
      const [, domain] = value.split("@");

      if (value && domain !== userData?.user?.companyDomain) {
        return Promise.reject(
          `Email domain must be "${userData?.user?.companyDomain}"!`
        );
      }
      // Email is valid
      return Promise.resolve();
    },
    [userData?.user?.companyDomain]
  );
  useEffect(() => {
    form.setFieldsValue(addUser);
  }, [form, addUser]);

  return (
    <>
      {load ? (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: "50vh" }}
        >
          <Loader />
        </div>
      ) : (
        <List
          className="mt-3"
          size="small"
          header={
            <Row className="w-100">
              <Col lg={2}>
                <strong className="fw-bolder">User Name</strong>
              </Col>
              <Col lg={3}>
                <strong className="fw-bolder">Email</strong>
              </Col>
              <Col lg={2}>
                <strong className="fw-bolder">Role</strong>
              </Col>
              <Col lg={2}>
                <strong className="fw-bolder">Contact Number</strong>
              </Col>
              <Col lg={2}>
                <strong className="fw-bolder">Added on</strong>
              </Col>
              <Col lg={1}>
                <strong className="fw-bolder">Action</strong>
              </Col>
            </Row>
          }
          bordered
          dataSource={data ?? []}
          // loading={{
          //   spinning: load,
          //   indicator: <Loader />
          // }}
          renderItem={(item, index) => (
            <List.Item>
              <Row className="w-100 mt-1 align-items-center">
                <Col lg={2}>
                  <div className="d-flex align-items-center">
                    <div className="me-2">
                      <AvatarGroup
                        avatars={[item?.name || "defualt"]}
                        uppercase={true}
                        className="team-info"
                        initialCharacters={2}
                        fontColor="#5746ec"
                        max={2}
                        size={30}
                        fontSize=".38rem"
                        backgroundColor="#fff"
                      />
                    </div>
                    <span>{item?.name}</span>
                  </div>
                </Col>
                <Col lg={3}>{item?.email}</Col>
                <Col lg={2}>{item?.role_id ? item?.role_id?.name : "-"}</Col>
                <Col lg={2}>{`${
                  item?.contact_code ? `+${item?.contact_code}` : ""
                } ${item?.contact_number}`}</Col>
                <Col lg={2}>{convertDate(item?.created_at)}</Col>
                <Col lg={1}>
                  <Space size="middle">
                    <>
                      {permission?.userManagement?.update &&
                      item?._id !== userData?.user?._id ? (
                        <Tooltip color="#5746EB" title="Edit" placement="top">
                          <button
                            className="viewBttnExpert"
                            onClick={() => {
                              setUserModal(item);
                              openModal("edit");
                            }}
                          >
                            <MdBorderColor
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            />
                          </button>
                        </Tooltip>
                      ) : (
                        <Button disabled className="blur_btn">
                          <MdBorderColor
                            style={{ fontSize: "13px", cursor: "pointer" }}
                          />
                        </Button>
                      )}
                      {permission?.userManagement?.delete &&
                      item?._id !== userData?.user?._id ? (
                        <Tooltip color="#5746EB" title="Delete" placement="top">
                          <button
                            className="viewBttnExpert me-2"
                            onClick={() => {
                              getUserId(item?._id);
                              openModal("delete");
                            }}
                          >
                            <MdDelete
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            />
                          </button>
                        </Tooltip>
                      ) : (
                        <Button disabled className="blur_btn me-2">
                          <MdDelete
                            style={{ fontSize: "13px", cursor: "pointer" }}
                          />
                        </Button>
                      )}

                      {/* {(!permission?.userManagement?.delete || item?._id === userData?.user?._id) && "-"} */}
                    </>
                  </Space>

                  {/* <Dropdown
                    overlay={menu(item)}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <MoreOutlined
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    />
                  </Dropdown> */}
                </Col>
              </Row>
            </List.Item>
          )}
          style={{ background: "#ffff" }}
        />
      )}

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal_2"
          initialValues={addUser}
          onFinish={() => {
            updateUserModal();
            form.resetFields();
          }}
        >
          <Modal.Header>
            <MdOutlineClose
              className="position-absolute"
              style={{ right: "15px", top: "15px", fontSize: "25px" }}
              onClick={() => setCreateModal(false)}
            />
            <div className="text-left">
              <h4 className="modalHeader">
                {modalMode === "edit" ? "Edit User" : "Delete User"}
              </h4>
            </div>
          </Modal.Header>
          <Modal.Body className="position-relative">
            {modalMode === "edit" ? (
              <>
                <div>
                  <div className="projects-modal-divs">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item
                            label="Name"
                            name={"firstNmae"}
                            rules={[
                              { required: true, message: "name is required!" },
                            ]}
                          >
                            <Input
                              type="text"
                              name="firstNmae"
                              size="large"
                              width={"95%"}
                              placeholder="Name"
                              // onChange={(e) => setIsName(e.target.value)}
                              onChange={(e) => {
                                let obj = {
                                  ...addUser,
                                  firstNmae: e.target.value,
                                };
                                setAddUser(obj);
                                form.setFieldValue("firstNmae", e.target.value);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group addRightInput">
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "email is required!",
                              },
                              {
                                validator: validateEmailDomain, // Custom validator function
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              name="email"
                              size="large"
                              width={"100%"}
                              // value={addUser.email}
                              placeholder="Enter email address"
                              // onChange={handleInputs}
                              addonAfter={
                                "@" + userData?.user?.email?.split("@")[1]
                              }
                              onChange={(e) => {
                                let obj = {
                                  ...addUser,
                                  email: e.target.value,
                                };
                                setAddUser(obj);
                                form.setFieldValue("email", e.target.value);
                              }}
                            />
                          </Form.Item>
                        </div>
                        {message === "" ? (
                          <></>
                        ) : (
                          <p className="text-danger">{message}</p>
                        )}
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item
                            label="Contact Number"
                            name="contact_number_full"
                            rules={[
                              {
                                required: true,
                                message: "contact number is required!",
                              },
                            ]}
                          >
                            <PhoneInput
                              country={"us"}
                              name="contact_number_full"
                              enableSearch={true}
                              // value={isNumber}
                              onChange={(value, country) => {
                                const selectedCountry = countryOptions?.find(
                                  (option) =>
                                    option?.code === `+${country?.dialCode}`
                                );
                                if (selectedCountry) {
                                  setAddUser((prevStep3) => ({
                                    ...prevStep3,
                                    contact_code: country?.dialCode,
                                    contact_number: value?.replace(
                                      country?.dialCode,
                                      ""
                                    ),
                                    country: selectedCountry?.name,
                                    contact_number_full: value,
                                  }));
                                }
                                form.setFieldValue(
                                  "contact_number_full",
                                  value
                                );
                              }}
                              inputStyle={{
                                width: "100%",
                                height: "42px",
                                // padding: "8px 12px",
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <Form.Item
                            label="Role"
                            name="role_id"
                            rules={[
                              { required: true, message: "Role is required!" },
                            ]}
                          >
                            <Select
                              className="w-100"
                              size="large"
                              placeholder="select role"
                              // aria-label="Default select example"
                              value={addUser.role_id}
                              onChange={(value) =>
                                setAddUser({ ...addUser, role_id: value })
                              }
                              name="role_id"
                              id="role_name"
                              options={rolesData?.map((item, index) => ({
                                label: item?.name,
                                value: item?._id,
                                key: index,
                              }))}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <span style={{ fontSize: "16px" }}>
                    This action cannot be undone. Are you sure you want to
                    delete this meeting ?
                  </span>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end align-items-center">
            {modalMode === "edit" ? (
              <>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    key="back"
                    type="button"
                    onClick={() => setCreateModal(false)}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    style={{
                      backgroundColor: "rgb(76, 187, 23)",
                      borderColor: "rgb(76, 187, 23)",
                      color: "#fff",
                    }}
                    type="submit"
                    htmlType="submit"
                    className=" ms-2"
                  >
                    Submit {spinner && <Spin size="small" />}
                  </Button>
                </Form.Item>
              </>
            ) : (
              <>
                {/* <Form.Item style={{ marginBottom: 0 }}> */}
                <Button
                  key="back"
                  type="button"
                  onClick={() => setCreateModal(false)}
                >
                  Cancel
                </Button>
                {/* </Form.Item> */}
                {/* <Form.Item style={{ marginBottom: 0 }}> */}
                <Button
                  type="button"
                  className="ms-2"
                  style={{
                    backgroundColor: "rgb(76, 187, 23)",
                    borderColor: "rgb(76, 187, 23)",
                    color: "#fff",
                  }}
                  onClick={() => {
                    deleteUserModal();
                  }}
                >
                  Submit
                </Button>
                {/* </Form.Item> */}
              </>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      {/* <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Are you sure ?</h4>
          </div>
          <div>
            <span style={{ fontSize: "16px" }}>
              This action cannot be undone. Are you sure you want to delete this{" "}
              meeting ?
            </span>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                deleteUserModal();
                setDeleteModal(false);
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default UserMangementTable;
