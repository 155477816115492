import { Tag } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { PiCoinVerticalDuotone } from "react-icons/pi";
import ConditionalRender from "../../Utils/ConditionalRender";
function UnlockExpertButton({ data, hoveredItemId, unlockFunction }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const creditsData = useSelector((state) => state?.user?.all_credits);
  return (
    <>
      <ConditionalRender
        condition={hoveredItemId === data?._id && permission?.experts?.view}
      >
        <Tag
          bordered={false}
          className="tagContainer expertBtn"
          onClick={() => {
            unlockFunction();
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            View
          </span>
        </Tag>
      </ConditionalRender>
      <ConditionalRender
        condition={!(hoveredItemId === data?._id && permission?.experts?.view)}
      >
        <Tag
          bordered={false}
          className="tagContainer expertBtn"
          style={{
            borderColor: "rgb(76, 187, 23)",
            color: "rgb(76, 187, 23)",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PiCoinVerticalDuotone style={{ marginRight: "4px" }} />
            {data?.country?.trim()?.toLowerCase() === "india"
              ? creditsData?.credits_for_expert
              : creditsData?.credits_for_premium_expert}{" "}
          </span>
        </Tag>
      </ConditionalRender>
    </>
  );
}

export default memo(UnlockExpertButton);
