import React from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useNews from "../../Hooks/useNews";
import moment from "moment";
import { setIsOpenAiModal, setSelectedArticle } from "../Dashboard/news.slice";
import { Pagination, Spin } from "antd";
import AiSummaryModal from "../../Components/AiSummaryModal";
import { BsStars } from "react-icons/bs";
import useDashboard from "../../Hooks/useDashboard";
import Loader from "../../Components/Loader"

const News = () => {
  const dispatch = useDispatch();
  const { isLoading, isValidating } = useNews(10);
  const {
    getNewsAiSummary,
    addDefaultSrc,
    getAllNewsDataDash,
    loading: loadingNewsPagination,
  } = useDashboard();
  const { news_data, page } = useSelector((state) => state.news);

  return (
    <>
      <div className="height100-145">
        <Row className="mt-2 mb-2">
          <Col className="w-100">
            <h5 style={{ fontWeight: 600 }}>
              <span>News</span>
            </h5>
          </Col>
        </Row>
        <Row className="g-2">
          {(isLoading || isValidating || loadingNewsPagination) && (
            <Loader />
          )}
          {!isLoading &&
            !isValidating &&
            !loadingNewsPagination &&
            news_data?.data?.map((data) => (
              <Col lg={6}>
                <div className="card time-clock mb-0 p-0">
                  <Row className="g-2">
                    <Col md={8} xxl={9}>
                      <div className="card-body">
                        <h5
                          className="trans-header fs-5 two-line"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(setIsOpenAiModal(true));
                            dispatch(setSelectedArticle(data));
                            // navigate("/news/full-article");
                          }}
                          style={{
                            color: "#5746ec",
                            cursor: "pointer",
                            fontWeight: "400",
                          }}
                        >
                          {data?.title}
                        </h5>
                        <p className="card-text mb-0">
                          <small className="text-body-secondary">
                            {" "}
                            {moment(data?.article_date).format("ll")}{" "}
                          </small>
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="card-text mb-0">
                            <small className="text-body-secondary">
                              {" "}
                              {data?.source?.name}
                            </small>
                          </p>
                          <p className="mb-0 mt-3 text-start">
                            <button
                              className="btn viewButton text-end"
                              style={{ fontSize: "12px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(setIsOpenAiModal(true));
                                dispatch(setSelectedArticle(data));
                                getNewsAiSummary({ news: data });
                              }}
                            >
                              <BsStars style={{ fontSize: "14px" }} /> AI
                              Summary
                            </button>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xxl={3}>
                      <div className="newsImgContainer">
                        <img
                          src={
                            data?.thumbnail ||
                            "https://platform.nextyn.com/static/media/company_icons3.c720b9bb86fbb87c1eb6.png"
                          }
                          className="newsImg rounded-start p-2"
                          alt="..."
                          onError={addDefaultSrc}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Pagination
              className="cstmPagination"
              showSizeChanger={false}
              hideOnSinglePage={true}
              responsive={true}
              onChange={(page, pageSize) =>
                getAllNewsDataDash({
                  page_no: page,
                  page_limit: pageSize,
                  should_paginate: true,
                })
              }
              current={page}
              total={news_data?.count}
            />
          </div>
        </Row>
        <AiSummaryModal />
      </div>
    </>
  );
};

export default News;
