import React from "react";
import { NavLink } from "react-router-dom";
import { Spinner } from "reactstrap";
import { BsChevronLeft } from "../../../Utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import useUserManagement from "./useUserManagement";
import { setIsRoleDescript, setIsRoleName } from "./userManagement.slice";
import DOMPurify from "dompurify";
const AddRoleManagement = () => {
  const dispatch = useDispatch();
  const { isChecked, isRoleName, isRoleDescrip } = useSelector(
    (state) => state.userManagementReducer
  );
  const { spinner, handleInputs, submitRolesForm } = useUserManagement();

  return (
    <>
      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages expert-cell-page">
          <div className="d-flex top-ps-seection justify-content-between align-items-center mb-2 roles">
            <h5
              style={{ fontWeight: 600 }}
              className="d-flex align-items-center"
            >
              <NavLink to="/usermangement/roles" style={{ textAlign: "left" }}>
                <BsChevronLeft className="me-2 search-btn-back" />
              </NavLink>{" "}
              Add Roles
            </h5>
            {/* <NavLink
                to="/usermangement/addroles"
                className="btn add-ps-btn"
              >
                <BsPlusLg /> Add Role
              </NavLink> */}
          </div>

          <div className="container-fluid rounded bg-white comon-fild">
            <div className="row">
              <form onSubmit={submitRolesForm} method="POST">
                <div className="">
                  <div className="">
                    <div className="d-flex position-relative align-items-center roles"></div>
                    <div className="row my-3">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label>Role *</label>
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            name="name"
                            maxLength={20}
                            placeholder="Ex. Superadmin, Manager"
                            value={isRoleName}
                            onChange={(e) => {
                              const value = e.target.value;
                              const cleanInput = DOMPurify.sanitize(value);
                              dispatch(setIsRoleName(cleanInput))
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label>Description *</label>
                          <textarea
                            type="text"
                            autoComplete="off"
                            maxLength={200}
                            className="form-control"
                            name="name"
                            placeholder="Role Description"
                            value={isRoleDescrip}
                            onChange={(e) => {
                              const value = e.target.value;
                              const cleanInput = DOMPurify.sanitize(value);
                              dispatch(setIsRoleDescript(cleanInput))
                            }
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Role Permission</label>
                            <div className="table-responsive">
                              <table className="table-flush-spacing table">
                                <tbody>
                                  <tr>
                                    <td
                                      className="text-nowrap"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Project
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{
                                            paddingLeft: "3.5rem",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.projectAdd}
                                            name="projectAdd"
                                            value={isChecked.projectAdd}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Add
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.projectView}
                                            name="projectView"
                                            value={isChecked.projectView}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            View
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.projectDelete}
                                            name="projectDelete"
                                            value={isChecked.projectDelete}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Delete
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="projectMessage"
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.projectMessage}
                                            value={isChecked.projectMessage}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Message
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="projectList"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.projectList}
                                            checked={isChecked.projectList}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Expert List
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-nowrap"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Call Recording
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{
                                            paddingLeft: "3.5rem",
                                          }}
                                        >
                                          <input
                                            name="collaborationView"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.collaborationView}
                                            checked={
                                              isChecked.collaborationView
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            View
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="">
                                    <td className="text-wrap">
                                      <span
                                        className="me-50"
                                        style={{
                                          color: "#5746ec",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        Experts
                                      </span>{" "}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{ paddingLeft: "3.5rem" }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.expertView}
                                            name="expertView"
                                            value={isChecked.expertView}
                                            onChange={handleInputs}
                                          />
                                          <label
                                            htmlFor="select-all"
                                            className="form-check-label"
                                          >
                                            View
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={isChecked.expertRequest}
                                            name="expertRequest"
                                            value={isChecked.expertRequest}
                                            onChange={handleInputs}
                                          />
                                          <label
                                            htmlFor="select-all"
                                            className="form-check-label"
                                          >
                                            Request
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      className="text-nowrap"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Transcript
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{
                                            paddingLeft: "3.5rem",
                                          }}
                                        >
                                          <input
                                            name="transcriptView"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.transcriptView}
                                            checked={isChecked.transcriptView}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            View
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="transcriptUnlock"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.transcriptUnlock}
                                            checked={isChecked.transcriptUnlock}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Unlock
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-nowrap"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: "500",
                                      }}
                                    >
                                      User Management
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{
                                            paddingLeft: "3.5rem",
                                          }}
                                        >
                                          <input
                                            name="userManagementAdd"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.userManagementAdd}
                                            checked={
                                              isChecked.userManagementAdd
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Add
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="userManagementView"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.userManagementView}
                                            checked={
                                              isChecked.userManagementView
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            View
                                          </label>
                                        </div>

                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="userManagementUpdate"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={
                                              isChecked.userManagementUpdate
                                            }
                                            checked={
                                              isChecked.userManagementUpdate
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Update
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="userManagementDelete"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={
                                              isChecked.userManagementDelete
                                            }
                                            checked={
                                              isChecked.userManagementDelete
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Delete
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-nowrap"
                                      style={{
                                        color: "#5746ec",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Credits
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{
                                            paddingLeft: "3.5rem",
                                          }}
                                        >
                                          <input
                                            name="creditTransaction"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.creditTransaction}
                                            checked={
                                              isChecked.creditTransaction
                                            }
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            Transcation
                                          </label>
                                        </div>
                                        <div className="form-check me-3 me-lg-5">
                                          <input
                                            name="creditHistory"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.creditHistory}
                                            checked={isChecked.creditHistory}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            History
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mt-5">
                        <button
                          // data-bs-dismiss="modal"
                          type="submit"
                          className="btn col-lg-5 mx-auto btnsm"
                          id="addRoleManagement"
                        >
                          {spinner ? (
                            <>
                              <Spinner
                                size="sm"
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                  marginRight: "5px",
                                }}
                              />
                              Add Role
                            </>
                          ) : (
                            "Add Role"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRoleManagement;
