import axios from "axios";
import { useCallback, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import API_PATH from "../../../../Constants/api-path";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyData,
  setConsolidatedFinanceData,
  setExpertSearchData,
  setIsLoadingExpert,
  setIsLoadingNews,
  setNewsPagination,
  setNewsSearchData,
  setQuartarlyTabs,
  setSearchedCompanyList,
  setSelectedExpertSearch,
  setSelectedNewsSearch,
  setSelectedTranscriptSearch,
  setStandaloneFinanceData,
  setTranscriptSearchData,
  setStockPrice,
  setAssociateTab,
} from "./companySearch.slice";
import { setIsLoadingSearchBox } from "../SearchPage/SearchPage.slice";
import { convertToTitleCase } from "../../../../Utils/Util";
import {
  setIsLoadingDescription,
  setSelectedArticle,
} from "../../Dashboard/news.slice";
import API_URL from "../../../../Constants/api-path";
import { toast } from "react-toastify";
import { useOnceCall } from "../../../Hooks/useOnceCall";
import {
  balance_sheet_dummy,
  profit_loss_dummy,
  cash_flow_dummy,
  ratios_dummy,
  quaterly_dummy_data,
  snapshots_dummy,
} from "./StructuredObjects";
import { ControllerContext } from "../../../../App";
import useExpert from "../../../Hooks/useExpert";
import { setSelectedExpert } from "../../ExpertPage/Expert.Slice";

function useCompanySearch(activeTab = null) {
  const [searchParams] = useSearchParams();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const abortControllerRef = useContext(ControllerContext);
  const { getExpertById } = useExpert();
  const {
    companyData,
    transcriptSearchData,
    expertSearchData,
    newsSearchData,
    isLoadingNews,
    isLoadingExpert,
    transcriptPagination,
    expertPagination,
    newsPagination,
    tab,
    checked,
    stockPrice,
  } = useSelector((state) => state.companySearch);
  const dispatch = useDispatch();
  const getCompanyDataById = useCallback(async () => {
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_COMPANY_DATA_BY_ID +
      `?company_id=${id}&user_id=${userData?.user?._id}`
    );
    return res.data;
  }, [searchParams, userData?.user?._id]);

  useOnceCall(() => {
    dispatch(setSelectedTranscriptSearch({}));
    dispatch(setAssociateTab("1"));
  });

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData?.token}`,
    }),
    [userData?.token]
  );

  const getTranscriptListById = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_TRANSCRIPT_SEARCH_DATA_BY_ID +
      `?company_id=${id}&user_id=${userData?.user?._id}&page=${transcriptPagination.page}&limit=${transcriptPagination.limit}`,
      {
        signal: controller?.signal,
      }
    );
    return res.data;
  }, [
    abortControllerRef,
    searchParams,
    transcriptPagination.limit,
    transcriptPagination.page,
    userData?.user?._id,
  ]);

  const getExpertListById = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_EXPERT_SEARCH_DATA_BY_ID +
      `?company_id=${id}&page=${expertPagination.page}&limit=${expertPagination.limit}&user_id=${userData?.user?._id}`,
      {
        signal: controller?.signal,
      }
    );
    return res?.data;
  }, [abortControllerRef, searchParams, expertPagination.page, expertPagination.limit, userData?.user?._id]);
  const getStandaloneFinanceData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_YEARLY_STANDALONE_FINANCE_DATA + `?company_id=${id}`,
      {
        signal: controller?.signal,
      }
    );
    return res?.data;
  }, [abortControllerRef, searchParams]);
  const getUnlockingStatus = useCallback(async () => {
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.CHECK_UNLOCKING_STATUS +
      `?cloud_company_id=${id}&user_id=${userData?.user?._id}&cin=${companyData?.cin}`
    );
    return res?.data;
  }, [companyData?.cin, searchParams, userData?.user?._id]);

  const getStandaloneFinanceDataQt = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_QUARTERLY_STANDALONE_FINANCE_DATA + `?company_id=${id}`,
      {
        signal: controller?.signal,
      }
    );
    return res?.data;
  }, [abortControllerRef, searchParams]);
  const getConsolidatedFinanceData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_YEARLY_CONSOLIDATED_FINANCE_DATA + `?company_id=${id}`,
      {
        signal: controller?.signal,
      }
    );
    return res?.data;
  }, [abortControllerRef, searchParams]);
  const getConsolidatedFinanceDataQt = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_QUARTERLY_CONSOLIDATED_FINANCE_DATA + `?company_id=${id}`,
      {
        signal: controller?.signal,
      }
    );
    return res?.data;
  }, [abortControllerRef, searchParams]);

  const getNewsListById = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_NEWS_SEARCH_DATA_BY_ID +
      `?company_id=${id}&page=${newsPagination.page}&limit=${newsPagination.limit}`,
      { signal: controller?.signal }
    );
    return res?.data;
  }, [
    abortControllerRef,
    searchParams,
    newsPagination.page,
    newsPagination.limit,
  ]);

  const {
    isLoading: isLoadingCompanyData,
    isValidating: isValidationCompanyData,
    mutate: mutateCompanyDetails,
  } = useSWR(
    ["searched_company_details", searchParams.get("company_id")],
    () => (searchParams.get("company_id") ? getCompanyDataById() : null),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: async (data) => {
        dispatch(setCompanyData(data?.companyData));
        // if (data?.companyData) {
        //   await getStockPrice({ symbol: data?.companyData?.kite_instrument_token });
        // }
      },
      onError: (error) => {
        console.error("ERROR", error);
      },
    }
  );
  const {
    isLoading: isLoadingTranscript,
    isValidating: isValidationTranscript,
  } = useSWR(
    ["transcript_search_results", searchParams.get("company_id"), activeTab],
    () =>
      searchParams.get("company_id") && activeTab === "Transcripts"
        ? getTranscriptListById()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        if (data?.data?.length > 0) {
          dispatch(setSelectedTranscriptSearch(data?.data?.[0]));
        }
        dispatch(
          setTranscriptSearchData({ data: data?.data, count: data?.countData })
        );
      },
      onError: (error) => {
        console.error("ERROR TRANSCRIPT", error);
      },
    }
  );

  const {
    isLoading: isLoadingNewsSearchData,
    isValidating: isValidationNewsData,
  } = useSWR(
    ["news_search_results", searchParams.get("company_id"), activeTab],
    () =>
      searchParams.get("company_id") && activeTab === "News"
        ? getNewsListById()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        dispatch(setSelectedArticle({}));
        if (data?.data?.[0]) {
          dispatch(setSelectedNewsSearch(data?.data?.[0]));
          dispatch(setSelectedArticle(data?.data?.[0]));
          getNewsAiSummary({ news: data?.data?.[0] });
        }
        dispatch(
          setNewsSearchData({ data: data?.data, count: data?.countData })
        );
      },
      onError: (error) => {
        console.error("ERROR NEWS", error);
      },
    }
  );

  const {
    isLoading: isLoadingExpertSearchData,
    isValidating: isValidatingExpertSearchData,
  } = useSWR(
    ["expert_search_results", searchParams.get("company_id"), activeTab],
    () =>
      searchParams.get("company_id") && activeTab === "Experts"
        ? getExpertListById()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        if (data?.data?.length > 0) {
          dispatch(setSelectedExpertSearch(data?.data[0]));
          dispatch(setSelectedExpert(data?.data[0]))
          getExpertById(data?.data[0]?._id);
        }
        dispatch(
          setExpertSearchData({ data: data?.data, count: data?.countData })
        );
      },
      onError: (error) => {
        console.error("ERROR EXPERT", error);
      },
    }
  );

  const transformArray = useCallback((inputArray) => {
    return inputArray?.reduce((result, obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        // Initialize the array for the current key if it doesn't exist
        result[key] = result[key] || [];

        // Add the value to the array for the current key
        result[key].push(value);
      });
      return result;
    }, {});
  }, []);

  const {
    isLoading: isLoadingStandalongData,
    isValidating: isValidatingStandalongData,
  } = useSWR(
    [
      "standalone_finance_results",
      searchParams.get("company_id"),
      tab,
      checked,
      companyData?.isCompanyRequested,
      activeTab,
    ],
    () =>
      searchParams.get("company_id") &&
        activeTab === "Financials" &&
        tab === "1" &&
        !checked &&
        companyData?.isCompanyRequested === "completed"
        ? getStandaloneFinanceData()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = data?.companyData?.[0];
        let cash_flow = transformArray(obj?.cashflow);
        let ratio = transformArray(obj?.ratios);
        let snapshot = transformArray(obj?.snapshots);
        let statements = transformArray(obj?.statements);

        let mapping_cashflow = cash_flow &&
          Object.keys(cash_flow)?.length > 0 && { ...cash_flow_dummy };
        let mapping_ratios = ratio &&
          Object.keys(ratio)?.length > 0 && { ...ratios_dummy };
        let mapping_profit_loss = statements &&
          Object.keys(statements)?.length > 0 && { ...profit_loss_dummy };
        let mapping_balance_sheet = statements &&
          Object.keys(statements)?.length > 0 && { ...balance_sheet_dummy };
        let mapping_snapshot = snapshot &&
          Object.keys(snapshot)?.length > 0 && { ...snapshots_dummy };

        if (cash_flow) {
          let cashflow_obj = { ...cash_flow };
          Object.keys(mapping_cashflow).forEach((key) => {
            if (cashflow_obj.hasOwnProperty(key)) {
              mapping_cashflow[key] = cashflow_obj[key];
            } else {
              mapping_cashflow[key] = [];
            }
          });
        }
        if (snapshot) {
          let snapshot_obj = { ...snapshot };
          Object.keys(mapping_snapshot).forEach((key) => {
            if (snapshot_obj.hasOwnProperty(key)) {
              mapping_snapshot[key] = snapshot_obj[key];
            } else {
              mapping_snapshot[key] = [];
            }
          });
        }
        if (ratio) {
          let ratios_obj = { ...ratio };
          Object.keys(mapping_ratios).forEach((key) => {
            if (ratios_obj.hasOwnProperty(key)) {
              mapping_ratios[key] = ratios_obj[key];
            } else {
              mapping_ratios[key] = [];
            }
          });
        }
        if (statements) {
          let balance_sheet_obj = { ...statements };
          Object.keys(mapping_balance_sheet).forEach((key) => {
            if (balance_sheet_obj.hasOwnProperty(key)) {
              mapping_balance_sheet[key] = balance_sheet_obj[key]; //here need to decide what to assign if the object came insted of value
            } else {
              mapping_balance_sheet[key] = [];
            }
          });

          let profilt_loss_obj = { ...statements };
          Object.keys(mapping_profit_loss).forEach((key) => {
            if (profilt_loss_obj.hasOwnProperty(key)) {
              mapping_profit_loss[key] = profilt_loss_obj[key];
            } else {
              mapping_profit_loss[key] = [];
            }
          });
        }
        let ratios = mapping_ratios;
        let balance_sheet = mapping_balance_sheet;
        let Profit_and_loss = mapping_profit_loss;
        let cashflow = mapping_cashflow;
        let snapshots = mapping_snapshot;

        dispatch(
          setStandaloneFinanceData({
            cashflow,
            ratios,
            snapshots,
            balance_sheet,
            Profit_and_loss,
          })
        );
      },
      onError: (error) => {
        console.error("ERROR standalone_finance_results", error);
      },
    }
  );
  useSWR(
    [
      "check_unlocking_status",
      searchParams.get("company_id"),
      companyData?.isCompanyRequested,
    ],
    () =>
      searchParams.get("company_id") &&
        (companyData?.isCompanyRequested === "processing" ||
          companyData?.isCompanyRequested === "requested")
        ? getUnlockingStatus()
        : null,
    {
      refreshInterval:
        companyData?.isCompanyRequested === "processing" ||
          companyData?.isCompanyRequested === "requested"
          ? 10000
          : 0,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        //ON SUCCESS CALL ALL THE API OF COMPANY DATA AND OTHER IF NEEDED
        if (data?.unlock) {
          mutateCompanyDetails("searched_company_details");
        }
      },
      onError: (error) => {
        console.error("ERROR", error);
      },
    }
  );
  const {
    isLoading: isLoadingStandalongDataQt,
    isValidating: isValidatingStandalongDataQt,
  } = useSWR(
    [
      "standalone_finance_results_qt",
      searchParams.get("company_id"),
      tab,
      checked,
      companyData?.isCompanyRequested,
      activeTab,
    ],
    () =>
      searchParams.get("company_id") &&
        activeTab === "Financials" &&
        tab === "1" &&
        checked &&
        companyData?.isCompanyRequested === "completed"
        ? getStandaloneFinanceDataQt()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = data?.companyData?.[0]?.quarterly;
        let transformed = transformArray(obj);
        let tabs = [...new Set(transformed?.financial_year)];
        let formattedData = tabs?.map((tabVal, index) => {
          let tabWiseData = {};
          obj?.forEach((data, index) => {
            if (data?.financial_year === tabVal) {
              tabWiseData[tabVal] = tabWiseData[tabVal] || [];
              // Add the value to the array for the current tabVal
              tabWiseData[tabVal].push(data);
            }
          });
          return {
            ...tabWiseData,
            [tabVal]: transformArray(tabWiseData[tabVal]),
          };
        });

        const objectOfObjects = formattedData.reduce((acc, obj, index) => {
          let dummy = { ...quaterly_dummy_data };
          const keysArray = Object.keys(obj);
          const key = keysArray[0];
          Object?.keys(dummy)?.forEach((dummyKey) => {
            if (obj[key].hasOwnProperty(dummyKey)) {
              dummy[dummyKey] = obj[key][dummyKey];
            } else {
              dummy[dummyKey] = [];
            }
          });
          acc[key] = dummy;
          return acc;
        }, {});
        dispatch(setQuartarlyTabs(tabs));
        dispatch(setStandaloneFinanceData(objectOfObjects));
      },
      onError: (error) => {
        console.error("ERROR standalone_finance_results_qt", error);
      },
    }
  );

  const {
    isLoading: isLoadingConsolidateData,
    isValidating: isValidatingConsolidateData,
  } = useSWR(
    [
      "consolidated_finance_results",
      searchParams.get("company_id"),
      tab,
      checked,
      companyData?.isCompanyRequested,
      activeTab,
    ],
    () =>
      searchParams.get("company_id") &&
        activeTab === "Financials" &&
        tab === "2" &&
        !checked &&
        companyData?.isCompanyRequested === "completed"
        ? getConsolidatedFinanceData()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = data?.companyData?.[0];
        let cash_flow = transformArray(obj?.cashflow);
        let ratio = transformArray(obj?.ratios);
        let snapshot = transformArray(obj?.snapshots);
        let statements = transformArray(obj?.statements);

        let mapping_cashflow = cash_flow &&
          Object.keys(cash_flow)?.length > 0 && { ...cash_flow_dummy };
        let mapping_ratios = ratio &&
          Object.keys(ratio)?.length > 0 && { ...ratios_dummy };
        let mapping_profit_loss = statements &&
          Object.keys(statements)?.length > 0 && { ...profit_loss_dummy };
        let mapping_balance_sheet = statements &&
          Object.keys(statements)?.length > 0 && { ...balance_sheet_dummy };
        let mapping_snapshot = snapshot &&
          Object.keys(snapshot)?.length > 0 && { ...snapshots_dummy };

        if (snapshot) {
          let snapshot_obj = { ...snapshot };
          Object.keys(mapping_snapshot).forEach((key) => {
            if (snapshot_obj.hasOwnProperty(key)) {
              mapping_snapshot[key] = snapshot_obj[key];
            } else {
              mapping_snapshot[key] = [];
            }
          });
        }

        if (cash_flow) {
          let cashflow_obj = { ...cash_flow };
          Object.keys(mapping_cashflow).forEach((key) => {
            if (cashflow_obj.hasOwnProperty(key)) {
              mapping_cashflow[key] = cashflow_obj[key];
            } else {
              mapping_cashflow[key] = [];
            }
          });
        }
        if (ratio) {
          let ratios_obj = { ...ratio };
          Object.keys(mapping_ratios).forEach((key) => {
            if (ratios_obj.hasOwnProperty(key)) {
              mapping_ratios[key] = ratios_obj[key];
            } else {
              mapping_ratios[key] = [];
            }
          });
        }
        if (statements) {
          let balance_sheet_obj = { ...statements };
          Object.keys(mapping_balance_sheet).forEach((key) => {
            if (balance_sheet_obj.hasOwnProperty(key)) {
              mapping_balance_sheet[key] = balance_sheet_obj[key];
            } else {
              mapping_balance_sheet[key] = [];
            }
          });

          let profilt_loss_obj = { ...statements };
          Object.keys(mapping_profit_loss).forEach((key) => {
            if (profilt_loss_obj.hasOwnProperty(key)) {
              mapping_profit_loss[key] = profilt_loss_obj[key];
            } else {
              mapping_profit_loss[key] = [];
            }
          });
        }
        let ratios = mapping_ratios;
        let balance_sheet = mapping_balance_sheet;
        let Profit_and_loss = mapping_profit_loss;
        let cashflow = mapping_cashflow;
        let snapshots = mapping_snapshot;
        dispatch(
          setConsolidatedFinanceData({
            cashflow,
            ratios,
            snapshots,
            balance_sheet,
            Profit_and_loss,
          })
        );
      },
      onError: (error) => {
        console.error("ERROR consolidated_finance_results", error);
      },
    }
  );
  const {
    isLoading: isLoadingConsolidateDataQt,
    isValidating: isValidatingConsolidateDataQt,
  } = useSWR(
    [
      "consolidated_finance_results_qt",
      searchParams.get("company_id"),
      tab,
      checked,
      companyData?.isCompanyRequested,
      activeTab,
    ],
    () =>
      searchParams.get("company_id") &&
        activeTab === "Financials" &&
        tab === "2" &&
        checked &&
        companyData?.isCompanyRequested === "completed"
        ? getConsolidatedFinanceDataQt()
        : null,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = data?.companyData?.[0]?.quarterly;
        let transformed = transformArray(obj);
        let tabs = [...new Set(transformed?.financial_year)];
        let formattedData = tabs?.map((tabVal, index) => {
          let tabWiseData = {};
          obj?.forEach((data, index) => {
            if (data?.financial_year === tabVal) {
              tabWiseData[tabVal] = tabWiseData[tabVal] || [];
              // Add the value to the array for the current tabVal
              tabWiseData[tabVal].push(data);
            }
          });
          return {
            ...tabWiseData,
            [tabVal]: transformArray(tabWiseData[tabVal]),
          };
        });
        // const objectOfObjects = formattedData.reduce((acc, obj, index) => {
        //   const keysArray = Object.keys(obj);
        //   const key = keysArray[0];
        //   acc[key] = obj[key];
        //   return acc;
        // }, {});
        const objectOfObjects = formattedData.reduce((acc, obj, index) => {
          let dummy = { ...quaterly_dummy_data };
          const keysArray = Object.keys(obj);
          const key = keysArray[0];
          Object?.keys(dummy)?.forEach((dummyKey) => {
            if (obj[key].hasOwnProperty(dummyKey)) {
              dummy[dummyKey] = obj[key][dummyKey];
            } else {
              dummy[dummyKey] = [];
            }
          });
          acc[key] = dummy;
          return acc;
        }, {});
        dispatch(setQuartarlyTabs(tabs));
        dispatch(setConsolidatedFinanceData(objectOfObjects));
      },
      onError: (error) => {
        console.error("ERROR consolidated_finance_results_qt", error);
      },
    }
  );

  const fetchExpertListById = useCallback(async () => {
    dispatch(setIsLoadingExpert(true));
    let id = searchParams.get("company_id");
    const res = await axios.get(
      API_PATH.GET_EXPERT_SEARCH_DATA_BY_ID + `?company_id=${id}&user_id=${userData?.user?._id}`
    );
    dispatch(
      setExpertSearchData({
        data: res?.data?.data,
        count: res?.data?.countData,
      })
    );
    dispatch(setIsLoadingExpert(false));
  }, [dispatch, searchParams, userData?.user?._id]);

  const getStockPrice = useCallback(
    async ({ symbol = "" }) => {
      const res = await axios.get(
        API_PATH.GET_STOCK_PRICE + `?symbol=${symbol}`
      );
      dispatch(setStockPrice(res?.data));
    },
    [dispatch]
  );

  const getStockPriceInInterval = useSWR(
    ["get_stock_price_interval", companyData],
    () => getStockPrice({ symbol: companyData?.kite_instrument_token }),
    {
      refreshInterval: 180000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const fetchNewsListById = useCallback(
    async ({ is_paginate = false }) => {
      dispatch(setIsLoadingNews(true));
      if (is_paginate) {
        dispatch(
          setNewsPagination({
            ...newsPagination,
            page: newsPagination.page + 1,
          })
        );
      }
      let index = newsPagination.page + 1;
      let id = searchParams.get("company_id");
      const res = await axios.get(
        API_PATH.GET_NEWS_SEARCH_DATA_BY_ID +
        `?company_id=${id}&page=${index}&limit=${newsPagination.limit}`
      );
      dispatch(
        setNewsSearchData({
          data: [...newsSearchData.data, ...res?.data?.data],
          count: res?.data?.count,
        })
      );
      dispatch(setIsLoadingNews(false));
    },
    [dispatch, newsPagination, newsSearchData, searchParams]
  );

  const search = useCallback(
    async ({ searchString = "" }) => {
      dispatch(setIsLoadingSearchBox(true));
      // if (searchString !== "") {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;
      try {
        const res = await axios.get(
          API_PATH.GET_COMPANY_LIST_BY_SEARCH +
          `?searchstring=${searchString}&page=1&limit=10`,
          {
            signal: controller?.signal,
          }
        );
        if (res.status) {
          const finalData = res?.data?.data?.map((data) => {
            return {
              ...data,
              name: convertToTitleCase(data?.name),
            };
          });

          // const filteredNamesStartingWith = finalData?.filter((obj) =>
          //   obj?.name?.toLowerCase()?.startsWith(searchString?.toLowerCase()),
          // );

          // // Filter names not starting with the entered letter
          // const remainingNames = finalData?.filter(
          //   (obj) =>
          //     !obj?.name?.toLowerCase()?.startsWith(searchString?.toLowerCase()),
          // );
          // Concatenate the two filtered arrays
          // dispatch(setSearchedCompanyList({ data: filteredNamesStartingWith?.concat(remainingNames) || [], count: res?.data?.totalData || 0 }));
          dispatch(
            setSearchedCompanyList({
              data: finalData || [],
              count: res?.data?.totalData || 0,
            })
          );
          dispatch(setIsLoadingSearchBox(false));
        } else {
          dispatch(setIsLoadingSearchBox(false));
        }
      } catch (error) {
        dispatch(setIsLoadingSearchBox(false));
        console.log("error: ", error);
      }

      // }
    },
    [abortControllerRef, dispatch]
  );

  const loadMoreNews = useCallback(() => {
    setTimeout(() => {
      fetchNewsListById({ is_paginate: true });
    }, 1000);
  }, [fetchNewsListById]);

  const getNewsAiSummary = useCallback(
    async ({ news = {} }) => {
      dispatch(setIsLoadingDescription(true));
      await axios
        .get(API_URL.GET_FINANCE_NEWS_AI_SUMMARY + `?news_id=${news?._id}`)
        .then((res) => {
          let article = { ...news, chatgpt_desc: res?.data?.data };
          dispatch(setSelectedArticle(article));
          dispatch(setIsLoadingDescription(false));
        })
        .catch((err) => {
          dispatch(setIsLoadingDescription(false));
          console.error("ERROR", err);
        });
    },
    [dispatch]
  );
  const handleUnlockFinancials = useCallback(async () => {
    try {
      let id = searchParams.get("company_id");
      const res = await axios.post(
        `${API_PATH.REQUEST_FINANCIALS}`,
        {
          cloud_company_id: id,
          user_id: userData?.user?._id,
          cin: companyData?.cin,
        },
        { headers }
      );
      await getUnlockingStatus();
      mutateCompanyDetails("searched_company_details");
      if (res?.status) {
        toast.success("Request granted!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        return res.status;
      }
    } catch (error) {
      console.log("error: ", error);
      return toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [
    companyData?.cin,
    getUnlockingStatus,
    headers,
    mutateCompanyDetails,
    searchParams,
    userData?.user?._id,
  ]);

  const addToWatchList = useCallback(
    async (meetingId) => {
      try {
        const res = await axios.post(
          `${API_PATH.ADD_COMPANY_TO_WATCHLIST}`,
          {
            company_id: meetingId,
            userId: userData?.user?._id,
          },
          { headers }
        );
        mutateCompanyDetails("searched_company_details");
        return toast.success("Company Added To Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } catch (error) {
        return toast.error("Error While Adding to Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [headers, mutateCompanyDetails, userData?.user?._id]
  );

  const removeFromWatchList = useCallback(
    async (company_id) => {
      try {
        const res = await axios.delete(
          API_PATH.REMOVE_COMPANY_FROM_WATCHLIST +
          `?company_id=${company_id}&userId=${userData?.user?._id}`,
          { headers }
        );

        if (searchParams.get("company_id")) {
          mutateCompanyDetails("searched_company_details");
        }
        return toast.success("Company Removed From Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } catch (error) {
        return toast.error("Error While Adding From Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [headers, mutateCompanyDetails, searchParams, userData?.user?._id]
  );
  const removeFromWatchListKeyword = useCallback(
    async (keyword) => {
      try {
        const res = await axios.delete(
          API_PATH.REMOVE_KEYWORD_FROM_WATCHLIST +
          `?keyword=${keyword}&userId=${userData?.user?._id}`,
          { headers }
        );

        if (searchParams.get("company_id")) {
          mutateCompanyDetails("searched_company_details");
        }
        return toast.success("Company Removed From Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } catch (error) {
        return toast.error("Error While Removing From Watchlist", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    },
    [headers, mutateCompanyDetails, searchParams, userData?.user?._id]
  );

  const values = useMemo(
    () => ({
      companyData,
      isLoadingTranscript,
      isLoadingCompanyData,
      transcriptSearchData,
      expertSearchData,
      getExpertListById,
      newsSearchData,
      getNewsListById,
      isLoadingNews,
      isLoadingExpert,
      fetchNewsListById,
      fetchExpertListById,
      isLoadingNewsSearchData,
      isLoadingExpertSearchData,
      loadMoreNews,
      search,
      getNewsAiSummary,
      isValidationCompanyData,
      isValidationTranscript,
      isValidationNewsData,
      addToWatchList,
      removeFromWatchList,
      removeFromWatchListKeyword,
      isLoadingConsolidateData,
      isLoadingConsolidateDataQt,
      isLoadingStandalongData,
      isLoadingStandalongDataQt,
      handleUnlockFinancials,
      stockPrice,
      isValidatingExpertSearchData,
      isValidatingStandalongData,
      isValidatingStandalongDataQt,
      isValidatingConsolidateData,
      isValidatingConsolidateDataQt,
    }),
    [
      companyData,
      isLoadingTranscript,
      isLoadingCompanyData,
      transcriptSearchData,
      expertSearchData,
      getExpertListById,
      newsSearchData,
      getNewsListById,
      isLoadingNews,
      isLoadingExpert,
      fetchNewsListById,
      fetchExpertListById,
      isLoadingNewsSearchData,
      isLoadingExpertSearchData,
      loadMoreNews,
      search,
      getNewsAiSummary,
      isValidationCompanyData,
      removeFromWatchListKeyword,
      isValidationTranscript,
      isLoadingConsolidateDataQt,
      isValidationNewsData,
      addToWatchList,
      removeFromWatchList,
      isLoadingConsolidateData,
      isLoadingStandalongData,
      isLoadingStandalongDataQt,
      handleUnlockFinancials,
      stockPrice,
      isValidatingExpertSearchData,
      isValidatingStandalongData,
      isValidatingStandalongDataQt,
      isValidatingConsolidateData,
      isValidatingConsolidateDataQt,
    ]
  );

  return values;
}

export default useCompanySearch;
