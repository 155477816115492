import React from "react";
import Helmet from "react-helmet";
import Expert from "./Expert";
import "../Dashboard/Dashboard.css";

const Dashboard = () => {


  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Experts"}</title>
      </Helmet>
      <div class="height-100 bg-light" id="main-screen">
        <Expert />
      </div>
    </>
  );
};

export default Dashboard;
