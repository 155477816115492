import { useMemo } from 'react'
import { useDispatch } from 'react-redux';
// import { setSelectedItem } from './transcriptExpert.slice';
import { useCallback } from 'react';
import { setSelectedItem } from './SearchPage.slice';

function useTranscriptExpertPage() {
    const dispatch = useDispatch()
    const handleItemClick = useCallback((data) => {
        dispatch(setSelectedItem(data));
    }, [dispatch]);

    function replaceTxt(txt, para) {
        let text = txt;
        for (var i = 0; i < para.length; i++) {
            text = text?.replace(new RegExp(para[i], 'g'), "");
        }
        text = text?.trimStart()
        // .replace(/^[^A-Z]+/, '');
        return text;
    }
    const expertSummery = useCallback((data) => {
        if (data !== null && data !== "" && data !== undefined) {
            // Split the input data into an array of paragraphs based on numeric indicators
            const paragraphs = data?.split(/\d+\.\s+/);
            return (
                <div className="" style={{ width: "100%", paddingRight: "20px" }}>
                    <div className="inline-show-more-text px-2">
                        <ol>
                            {paragraphs?.map((paragraph, index) => (
                                paragraph !== "" && <li key={index} style={{ fontSize: "12px" }}>
                                    {paragraph?.trim()}
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }, [])

    const values = useMemo(() => ({
        handleItemClick: handleItemClick,
        replaceTxt: replaceTxt,
        expertSummery: expertSummery
    }), [handleItemClick, expertSummery])

    return values
}

export default useTranscriptExpertPage
