import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedButtonsCB: {
    respondent: true,
    petitioner: false,
  },
  selectedButtons: {
    respondent: true,
    petitioner: false,
  },
  selectedButtonsDC: {
    respondent: true,
    petitioner: false,
  },
  selectedButtonsNCLT: {
    new: true,
    old: false,
  },
  selectedButtonsdartOrDrat: {
    respondent: true,
    petitioner: false,
  },


  sortParams: { sortColumn: "", sortType: 1 },
  creditBuroData: { data: [], count: 0 },
  courtCaseData: { data: [], count: 0 },
  districtCourtCaseData: { data: [], count: 0 },
  ncltData: { data: [], count: 0 },
  drtOrDratData: { data: [], count: 0 },
  satData: { data: [], count: 0 },

  creditBuroPagination: { page: 1, limit: 10 },
  courtCasePagination: { page: 1, limit: 10 },
  districtCourtCasePagination: { page: 1, limit: 10 },
  ncltPagination: { page: 1, limit: 10 },
  dartOrDratPagination: { page: 1, limit: 10 },
  satPagination: { page: 1, limit: 10 },
  tabCount: {},

  searchCourtcreditBuro: "",
  searchCourtcase: "",
  searchDistrictCourtcase: "",
  searchNclt: "",
  searchDrtOrDrat: "",
  searchSat: ""
};

const litigationSlice = createSlice({
  initialState: initialState,
  name: "litigationTab",
  reducers: {
    setSelectedButtonsCB: (state, { payload }) => {
      state.selectedButtonsCB = payload;
    },
    setSelectedButtons: (state, { payload }) => {
      state.selectedButtons = payload;
    },
    setSelectedButtonsDC: (state, { payload }) => {
      state.selectedButtonsDC = payload;
    },
    setSelectedButtonsNCLT: (state, { payload }) => {
      state.selectedButtonsNCLT = payload;
    },
    setSelectedButtonsDratDart: (state, { payload }) => {
      state.selectedButtonsdartOrDrat = payload;
    },
    setTabCount: (state, { payload }) => {
      state.tabCount = payload;
    },

    setCreditBuroData: (state, { payload }) => {
      state.creditBuroData = payload;
    },
    setCourtCaseData: (state, { payload }) => {
      state.courtCaseData = payload;
    },
    setDistrictCourtCaseData: (state, { payload }) => {
      state.districtCourtCaseData = payload;
    },
    setNCLTData: (state, { payload }) => {
      state.ncltData = payload;
    },
    setDratDartData: (state, { payload }) => {
      state.drtOrDratData = payload;
    },
    setSatData: (state, { payload }) => {
      state.satData = payload;
    },

    setCreditBuroPagination: (state, { payload }) => {
      state.creditBuroPagination = payload;
    },
    setCourtCasePagination: (state, { payload }) => {
      state.courtCasePagination = payload;
    },
    setDistrictCourtCasePagination: (state, { payload }) => {
      state.districtCourtCasePagination = payload;
    },
    setNCLTPagination: (state, { payload }) => {
      state.ncltPagination = payload;
    },
    setDartOrDratPagination: (state, { payload }) => {
      state.dartOrDratPagination = payload;
    },
    setSatPagination: (state, { payload }) => {
      state.satPagination = payload;
    },

    setSortParams: (state, { payload }) => {
      state.sortParams = payload;
    },

    setSearchCreditBuro: (state, { payload }) => {
      state.searchCourtcreditBuro = payload;
    },
    setSearchCourtcase: (state, { payload }) => {
      state.searchCourtcase = payload;
    },
    setsearchDistrictCourtcase: (state, { payload }) => {
      state.searchDistrictCourtcase = payload;
    },
    setsearchNCLT: (state, { payload }) => {
      state.searchNclt = payload;
    },
    setsearchDratDart: (state, { payload }) => {
      state.searchDrtOrDrat = payload;
    },
    setsearchSat: (state, { payload }) => {
      state.searchSat = payload;
    },
  },
});
export const litigationSliceReducer = litigationSlice.reducer;
export const {
  setTabCount,

  setCreditBuroData,
  setCourtCaseData,
  setDistrictCourtCaseData,
  setNCLTData,
  setDratDartData,
  setSatData,

  setSelectedButtonsDC,
  setSelectedButtonsNCLT,
  setSelectedButtonsDratDart,
  setSelectedButtonsCB,
  setSelectedButtons,

  setCourtCasePagination,
  setDistrictCourtCasePagination,
  setNCLTPagination,
  setSatPagination,
  setDartOrDratPagination,
  setCreditBuroPagination,

  setSortParams,

  setSearchCreditBuro,
  setSearchCourtcase,
  setsearchDistrictCourtcase,
  setsearchNCLT,
  setsearchDratDart,
  setsearchSat,
} =

  litigationSlice.actions;
