import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    expertList: { data: [], count: 0 },
    expertLabList: { data: [], count: 0 },
    topExpertList: { data: [], count: 0 },
    expertprofile: { data: [], count: 0 },
    selectedExpert: {},
    expertPagination: { page: 1, limit: 20 }
}

const expertSlice = createSlice({
    initialState: initialState,
    name: "ExpertData",
    reducers: {

        setExpertList: (state, { payload }) => {
            state.expertList = payload
        },
        setExpertLabList: (state, { payload }) => {
            state.expertLabList = payload
        },
        setTopExpertList: (state, { payload }) => {
            state.topExpertList = payload
        },
        setExpertProfile: (state, { payload }) => {
            state.expertprofile = payload
        },
        setSelectedExpert: (state, { payload }) => {
            state.selectedExpert = payload
        },
        setExpertPagination: (state, { payload }) => {
            state.expertPagination = payload
        },

    }
})
export const expertReducer = expertSlice.reducer
export const { setExpertList, setExpertProfile, setSelectedExpert, setExpertPagination, setTopExpertList, setExpertLabList } = expertSlice.actions