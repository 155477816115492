import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
    selectedItem: {},
};


const slice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setSelectedItem: (state, { payload }) => {
            state.selectedItem = payload
        },
    },
});


const transcriptExpertReducer = slice.reducer
export const { setSelectedItem } = slice.actions
export {
    transcriptExpertReducer
}
