import { configureStore } from "@reduxjs/toolkit";
// import { thunk } from "redux-thunk";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from "redux-persist";
import reducers from "./reducer";

//for react persist 
const persistConfig = {
    key: 'root',
    storage: storage, //localstorage by default
    whitelist: ['user'], // we will choose which reducers state we need to persist on refresh
    blacklist: ['news',], // we will choose which reducers state we need to persist on refresh

}
const persistedReducer = persistReducer(persistConfig, reducers) //here we can add reducers of our own no need to add all reducers
export const store = configureStore({
    reducer: persistedReducer,
    // devTools: process.env.NODE_ENV !== 'production',
    // middleware: [thunk]  // this needs to do some study and then implement
})

export const persistor = persistStore(store)