import { Modal, Tooltip } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenAiModal } from "../Pages/Dashboard/news.slice";
import { Row, Col } from 'react-bootstrap';
import Loader from "../Components/Loader"
import { formattedDate } from "../../Utils/Util";

function AiSummaryModal() {
  const dispatch = useDispatch();
  const { is_open_Ai_modal, selected_article, is_loading_description } = useSelector(
    (state) => state.news
  );

  const getModifiedDescription = useCallback((description) => {
    let bulletPoints;
    bulletPoints = description?.split("\n- ");
    return bulletPoints
  }, []);

  const footer = (
    <>
      <a
        target="_blank"
        style={{ color: "#5746ec", fontWeight: 500 }}
        href={selected_article?.link}
      >
        Go to source
      </a>
    </>
  );
  return (
    <div>
      {" "}
      <Modal
        title={
          <>
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={10}>
                <Tooltip title={selected_article?.title}>
                  <h6 className="cust_header truncate-text" style={{ fontWeight: "400" }}>
                    <span >{selected_article?.title}</span>
                  </h6>
                </Tooltip>
              </Col>
            </Row>

            <div>
              <small className="me-4">{selected_article?.source?.name}</small>
              <small>{formattedDate(selected_article?.date)}</small>
            </div>
          </>

        }
        centered
        open={is_open_Ai_modal}
        footer={footer}
        className="borderLessHeader"
        onCancel={() => dispatch(setIsOpenAiModal(false))}
        width={680}
        wrapClassName="custom-modal-mask"
      >
        <div className="AiSummaryBody">
          {is_loading_description ?
            <div className="d-flex justify-content-center align-item-center">
              <Loader />
            </div>
            : <ul>
              {getModifiedDescription(selected_article?.chatgpt_desc)?.map((data, index) => (
                <li key={index}>{data?.replace(/\*\*|-/g, '')}</li>
              ))}
            </ul>}
        </div>
      </Modal>
    </div>
  );
}

export default AiSummaryModal;
