// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'


import $ from "jquery";
import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import API_PATH from "../../../Constants/api-path";
import MobileMenu from "../../../MobileMenu";
import RolesMangementTable from "./RolesMangementTable";
import { useDispatch } from "react-redux";
import { resetForm } from "./userManagement.slice";

const RolesMangement = () => {
  document.title = "Nextyn IQ -  User Management";
  const [isRoleName, setIsRoleName] = useState("");
  const [isRoleDescrip, setIsRoleDescript] = useState("");
  const [loading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const [isChecked, setIsChecked] = useState({
    expertView: false,
    projectAdd: false,
    projectView: false,
    projectMessage: false,
    projectList: false,
    collaborationView: false,
    collabrationNotesAdd: false,
    collaborationNotesView: false,
    collaborationNotesDelete: false,
    collaborationFolderView: false,
    collaborationFolderAdd: false,
    collaborationFolderDelete: false,
    collaborationFilesAdd: false,
    collaborationFilesDelete: false,
    collaborationFilesView: false,
    conversationView: false,
    newsView: false,
    expertCallView: false,
    userManagementView: false,
    userManagementEdit: false,
    userManagementUpdate: false,
    userManagementDelete: false,
  });

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    isChecked[name] === false
      ? setIsChecked({ ...isChecked, [name]: true })
      : setIsChecked({ ...isChecked, [name]: false });
  };

  // const handleInputsParam = (name, obj) => {
  //   isChecked[name] === false
  //     ? (isChecked[name] = true)
  //     : (isChecked[name] = false);
  //   console.log(isChecked[name]);
  // };

  // const selectAll = (e) => {
  //   Object.keys(isChecked).forEach((elem) => {
  //     handleInputsParam(elem,obj);
  //   });
  // };
  // console.log("user", isChecked);
  //console.log(data);

  const submitRolesForm = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${API_PATH.ROLE_MANAGEMENT_POST}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({
          name: isRoleName,
          description: isRoleDescrip,
          findExperts: {
            view: isChecked.expertView,
          },
          projects: {
            add: isChecked.projectAdd,
            view: isChecked.projectView,
            message: isChecked.projectMessage,
            expertList: isChecked.projectList,
          },
          collaborate: {
            view: isChecked.collaborationView,
            notesAdd: isChecked.collabrationNotesAdd,
            notesView: isChecked.collaborationNotesView,
            notesDelete: isChecked.collaborationNotesDelete,
            folderView: isChecked.collaborationFolderView,
            foldesAdd: isChecked.collaborationFolderAdd,
            folderDelete: isChecked.collaborationFolderDelete,
            filesAdd: isChecked.collaborationFilesAdd,
            filesDelete: isChecked.collaborationFilesDelete,
            filesView: isChecked.collaborationFilesView,
          },
          conversation: {
            view: isChecked.conversationView,
          },
          expertsCall: {
            view: isChecked.expertCallView,
          },
          news: {
            view: isChecked.newsView,
          },
          userManagement: {
            view: isChecked.userManagementView,
            edit: isChecked.userManagementEdit,
            update: isChecked.userManagementUpdate,
            delete: isChecked.userManagementDelete,
          },
        }),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200) {
          return toast.success("Role Added Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // const submitRolesForm = async () => {
  //   try {
  //     const res = await axios.post(
  //       `${API_PATH.ROLE_MANAGEMENT_POST}`,
  //       {
  //         method: "POST",
  //         headers: headers,
  //       },
  //       {
  //         name: isRoleName,
  //         description: isRoleDescrip,
  //         findExperts: {
  //           view: isChecked.expertView,
  //         },
  //         projects: {
  //           add: isChecked.projectAdd,
  //           view: isChecked.projectView,
  //           message: isChecked.projectMessage,
  //           expertList: isChecked.projectList,
  //         },
  //         collaborate: {
  //           view: isChecked.collaborationView,
  //           notesAdd: isChecked.collabrationNotesAdd,
  //           notesView: isChecked.collaborationNotesView,
  //           notesDelete: isChecked.collaborationNotesDelete,
  //           folderView: isChecked.collaborationFolderView,
  //           foldesAdd: isChecked.collaborationFolderAdd,
  //           folderDelete: isChecked.collaborationFolderDelete,
  //           filesAdd: isChecked.collaborationFilesAdd,
  //           filesDelete: isChecked.collaborationFilesDelete,
  //           filesView: isChecked.collaborationFilesView,
  //         },
  //         conversation: {
  //           view: isChecked.conversationView,
  //         },
  //         expertsCall: {
  //           view: isChecked.expertCallView,
  //         },
  //         news: {
  //           view: isChecked.newsView,
  //         },
  //         userManagement: {
  //           view: isChecked.userManagementView,
  //           edit: isChecked.userManagementEdit,
  //           update: isChecked.userManagementUpdate,
  //           delete: isChecked.userManagementDelete,
  //         },
  //       }
  //     );
  //     console.log("rolesData", res.data);
  //     console.log(res.data.status);

  //     if (res.status === 200) {
  //       console.log(res.status);
  //       history.push("/find-expert");
  //       return toast.success("Roles Added Successful", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error(error.response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });
  const dispatch = useDispatch();
  return (
    <>
      <MobileMenu />
      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages expert-cell-page">
          {permission?.userManagement?.add && (
            <div className="d-flex top-ps-seection justify-content-between align-items-center">
              <h5 style={{ fontWeight: 600 }}>Roles</h5>
              <NavLink
                onClick={() => {
                  dispatch(resetForm());
                }}
                to="/usermangement/addroles"
                className="btn viewButton d-flex justify-content-center align-items-center"
              >
                <BsPlusLg /> Add Role
              </NavLink>
            </div>
          )}
          <div className="managment-table mt-2">
            <RolesMangementTable load={loading} />
          </div>
        </div>
      </div>
      {/* add user modal */}
      <div
        className="modal fade projects-modal"
        id="addRole"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <form onSubmit={submitRolesForm} method="POST">
          <div className="modal-dialog ">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ border: "none", marginTop: "-8px" }}
              >
                <div className="modal-title h4" style={{ textAlign: "center" }}>
                  {" "}
                  <h2
                    style={{ lineHeight: "30px" }}
                    className="modal-title text-center mx-auto"
                  >
                    {" "}
                    Add Role
                  </h2>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="projects-modal-divs">
                  <div className="d-flex position-relative align-items-center roles"></div>
                  {/* <p className="text-center">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>
 */}
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Role *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={isRoleName}
                          onChange={(e) => setIsRoleName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Description *</label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="name"
                          value={isRoleDescrip}
                          onChange={(e) => setIsRoleDescript(e.target.value)}
                        ></textarea>
                      </div>
                    </div>

                    <label>Role Permission *</label>
                    <div className="table-responsive">
                      <table className="table-flush-spacing table">
                        <tbody>
                          {/* <tr>
                            <td className="text-wrap fw-bolder">
                              <span className="me-50"> Find Experts</span>{" "}
                            </td>
                            <td>
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.expertView}
                                  name="expertView"
                                  // value={isChecked.expertView}
                                  onChange={handleInputs}
                                />
                                <label
                                  htmlFor="select-all"
                                  className="form-check-label"
                                >
                                  View
                                </label>
                              </div>
                            </td>
                          </tr> */}
                          <tr>
                            <td
                              className="text-nowrap"
                              style={{
                                color: "#5746ec",
                                fontWeight: "500",
                              }}
                            >
                              Project
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{
                                    paddingLeft: "3.5rem",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectAdd}
                                    name="projectAdd"
                                    value={isChecked.projectAdd}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectDelete}
                                    name="projectDelete"
                                    value={isChecked.projectDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectView}
                                    name="projectView"
                                    value={isChecked.projectView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="projectMessage"
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.projectMessage}
                                    value={isChecked.projectMessage}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Message
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="projectList"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.projectList}
                                    checked={isChecked.projectList}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Expert List
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="text-nowrap"
                              style={{
                                color: "#5746ec",
                                fontWeight: "500",
                              }}
                            >
                              Call Recording
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{
                                    paddingLeft: "3.5rem",
                                  }}
                                >
                                  <input
                                    name="collaborationView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.collaborationView}
                                    checked={isChecked.collaborationView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="text-wrap">
                              <span
                                className="me-50"
                                style={{
                                  color: "#5746ec",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                Experts
                              </span>{" "}
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{ paddingLeft: "3.5rem" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.sharedWithMe}
                                    name="sharedWithMe"
                                    value={isChecked.sharedWithMe}
                                    onChange={handleInputs}
                                  />
                                  <label
                                    htmlFor="select-all"
                                    className="form-check-label"
                                  >
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={isChecked.sharedWithMe}
                                    name="sharedWithMe"
                                    value={isChecked.sharedWithMe}
                                    onChange={handleInputs}
                                  />
                                  <label
                                    htmlFor="select-all"
                                    className="form-check-label"
                                  >
                                    Request
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td
                              className="text-nowrap"
                              style={{
                                color: "#5746ec",
                                fontWeight: "500",
                              }}
                            >
                              Transcript
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{
                                    paddingLeft: "3.5rem",
                                  }}
                                >
                                  <input
                                    name="userManagementView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementView}
                                    checked={isChecked.userManagementView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementView}
                                    checked={isChecked.userManagementView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Unlock
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="text-nowrap"
                              style={{
                                color: "#5746ec",
                                fontWeight: "500",
                              }}
                            >
                              User Management
                            </td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="form-check me-3 me-lg-5"
                                  style={{
                                    paddingLeft: "3.5rem",
                                  }}
                                >
                                  <input
                                    name="userManagementView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementView}
                                    checked={isChecked.userManagementView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Add
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementView"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementView}
                                    checked={isChecked.userManagementView}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    View
                                  </label>
                                </div>

                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementUpdate"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementUpdate}
                                    checked={isChecked.userManagementUpdate}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Update
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    name="userManagementDelete"
                                    type="checkbox"
                                    className="form-check-input"
                                    value={isChecked.userManagementDelete}
                                    checked={isChecked.userManagementDelete}
                                    onChange={handleInputs}
                                  />
                                  <label className="form-check-label">
                                    Delete
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-5">
                      <button
                        // data-bs-dismiss="modal"
                        type="submit"
                        className="btn col-lg-5 mx-auto btnsm"
                      >
                        Add Role
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RolesMangement;
