import React, { useState } from "react";
import { Card, Tag, List, Modal, Button, Popover, Tooltip } from "antd";
import { Row, Col } from "react-bootstrap";
import TranscriptCard from "../Dashboard/Transcript/TranscriptCard";
import { useDispatch, useSelector } from "react-redux";
import {
  clearChatMessages,
  setSelectedTranscript,
} from "../../Transcript/TranscriptChat.slice";
import { Link, useNavigate } from "react-router-dom";
import { CreateProjectModal } from "../../../../components/CreateProjectModal";
import { ProjectListModal } from "../../../../components/ProjectListModal";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../Dashboard/Project.Slice";
import { convertToTitleCase, formattedDate } from "../../../../Utils/Util";
import EmptyStatesData from "../../../Components/EmptyStates";
import Loader from "../../../Components/Loader";
import UnlockButton from "../../../Components/UnlockButton";
import ConditionalRender from "../../../../Utils/ConditionalRender";
import UnlockTranscriptModal from "../../../Components/UnlockTranscriptModal";

const TranscriptExpertPage = ({
  functions,
  isLoadingTrans,
  isValidatingTrans,
}) => {
  const {
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    replaceTxt,
    spendCredits,
    fetchTranscriptData,
  } = functions;
  const history = useNavigate();
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const { transcriptData, is_loading_content } = useSelector(
    (state) => state?.SearchPage
  );
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const { selectedItem, transcriptPagination } = useSelector(
    (state) => state.SearchPage
  );
  const creditsData = useSelector((state) => state?.user?.all_credits);
  const { projectListById } = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const goToDetailsPage = (curElem) => {
    history({
      pathname: `/transcript-details/${curElem?._id}`,
      state: { curElem },
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSpendCredit = React.useCallback(async (id, category) => {
    let res = await spendCredits({
      id,
      category,
      credits_spend: creditsData?.credits_for_transcript,
      navigation: false,
    });
    return res
  }, [creditsData?.credits_for_transcript, spendCredits]);

  const contentTags = (data) => (
    <>
      <div className="trans_popup" style={{ width: "500px" }}>
        {data?.meetingsData?.[0]?.company_id?.slice(6)?.map((brand) => (
          <>
            {brand && (
              <Tag className="me-1" size="large" key={brand?.name?.trim()}>
                {convertToTitleCase(brand?.name?.trim())}
              </Tag>
            )}
          </>
        ))}
      </div>
    </>
  );

  const contentTagsKeyword = (data) => (
    <>
      <div className="trans_popup cstmTg">
        {data?.keyword
          ?.split(",")
          ?.slice(6)
          ?.map((brand, index) => (
            <>
              {brand && (
                <Tag className="me-1 mb-1" size="large" key={index}>
                  {convertToTitleCase(brand)}
                </Tag>
              )}
            </>
          ))}
      </div>
    </>
  );
  const onLoadMore = () => {
    fetchTranscriptData({
      paginate: true,
      page_no: transcriptPagination.page + 1,
      page_limit: transcriptPagination.limit,
    });
  };
  const hasMore = React.useMemo(() => {
    return transcriptData?.data?.length < transcriptData?.count;
  }, [transcriptData?.data?.length, transcriptData?.count]);

  const loadMore =
    !isLoadingTrans && !is_loading_content && !isValidatingTrans && hasMore ? (
      <div className="d-flex align-items-center justify-content-center"
        style={{
          textAlign: "center",
          height: 55,
        }}
      >
        <Button onClick={onLoadMore}>Load More</Button>
      </div>
    ) : null;

  const data = EmptyStatesData?.searchTranscript;

  if (!data) return null;
  return (
    <>
      <Row className="g-0">
        {transcriptData?.data?.length === 0 &&
        !isLoadingTrans &&
        !is_loading_content &&
        !isValidatingTrans ? (
          <>
            <Col md={12}>
              <div
                className="empty-state-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <div
                  className="image-container"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  <img
                    src={data?.image}
                    alt={data?.feature}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
                <div
                  className="content-container"
                  style={{ flex: 1, textAlign: "left" }}
                >
                  <p
                    className="p-0 mt-0 pt-0 mb-3"
                    style={{ lineHeight: "28px", fontWeight: "300" }}
                  >
                    {data?.content}
                  </p>
                  <button
                    className="btn viewButton findme-btn"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      dispatch(setIsOpenCreatProject(true));
                      dispatch(setIsOpenProject(false));
                    }}
                  >
                    {data?.cta}
                  </button>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <>
            {isLoadingTrans || is_loading_content || isValidatingTrans ? (
              <>
                <Col lg={12}>
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ minHeight: "50vh" }}
                  >
                    <Loader />
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col lg={selectedItem?._id ? 4 : 12}>
                  <Row>
                    <Col md={12}>
                      <div className="searchPageTranscript">
                        <List
                          size="small"
                          dataSource={transcriptData?.data || []}
                          loadMore={loadMore}
                          renderItem={(item, index) => (
                            <TranscriptCard
                              data={item}
                              index={index}
                              spendCredits={spendCredits}
                            />
                          )}
                          style={{ borderRadius: "4px", background: "#ffff" }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                {selectedItem?._id && (
                  <Col lg={8} style={{ borderRadius: "4px" }}>
                    <div className="searchPageTranscriptExpert">
                      <Card
                        title={
                          <div className="w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="w-75">
                                <Tooltip
                                  title={capitalizeFirstLetter(
                                    replaceTxt(selectedItem?.topic, [
                                      "Nextyn",
                                      "Micro",
                                      "Consulting",
                                      "-Consulting",
                                      "Call - ",
                                      "Call",
                                      "-",
                                    ])
                                  )}
                                >
                                  <p className="fw-bolder truncate-text mb-0">
                                    {" "}
                                    {capitalizeFirstLetter(
                                      replaceTxt(selectedItem?.topic, [
                                        "Nextyn",
                                        "Micro",
                                        "Consulting",
                                        "-Consulting",
                                        "Call - ",
                                        "Call",
                                        "-",
                                      ])
                                    )}
                                  </p>
                                </Tooltip>
                              </div>
                              <div className="d-flex justify-content-end align-items-end">
                                <span className="">
                                  <div className="">
                                    <small className="text-muted fw-normal me-3">
                                      {" "}
                                      {formattedDate(
                                        selectedItem?.meetingsData?.[0]
                                          ?.call_date
                                      )}
                                    </small>
                                  </div>
                                </span>
                                <span
                                  onMouseEnter={() =>
                                    setHoveredItemId(selectedItem?._id)
                                  }
                                  onMouseLeave={() => setHoveredItemId(null)}
                                >
                                  <UnlockButton
                                    data={selectedItem}
                                    hoveredItemId={hoveredItemId}
                                    unlockFunction={() => {
                                      dispatch(clearChatMessages());
                                      showModal(selectedItem);
                                    }}
                                  />
                                  {/* {selectedItem?.unlock === true ? (
                                    <Tag
                                      bordered={false}
                                      className="tagContainer view"
                                      onClick={() => {
                                        dispatch(clearChatMessages());
                                        goToDetailsPage(selectedItem);
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <BsUnlock className="me-1 tag_icon" />
                                        View
                                      </span>
                                    </Tag>
                                  ) :
                                    hoveredItemId === selectedItem?._id ? (
                                      <Tag
                                        bordered={false}
                                        className="tagContainer unlock"
                                        onClick={showModal}
                                      >
                                        Unlock
                                      </Tag>
                                    ) : (
                                      <Tag
                                        bordered={false}
                                        className="tagContainer"
                                        style={{
                                          borderColor: "rgb(76, 187, 23)",
                                          color: "rgb(76, 187, 23)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <PiCoinVerticalDuotone className="tag_icon"
                                            style={{ marginRight: "4px" }}
                                          />
                                          {creditsData?.credits_for_transcript}
                                        </span>
                                      </Tag>
                                    )
                                  } */}
                                </span>
                              </div>
                            </div>
                          </div>
                        }
                        className="chat-card transcriptDetails border-bottom-0"
                        style={{ borderRadius: "4px" }}
                      >
                        {isLoadingTrans || is_loading_content ? (
                          <div>
                            <Loader />
                          </div>
                        ) : (
                          Object.keys(selectedItem)?.length > 0 && (
                            <div className="w-100 transcriptDetails_container">
                              <Row>
                                {/* <Col md={10}>
                              <p className="fw-bolder mb-0"><strong className="fw-bolder truncate-text"> {capitalizeFirstLetter(replaceTxt(selectedItem?.topic, [
                                "Nextyn",
                                "Micro",
                                "Consulting",
                                "-Consulting",
                                "Call - ",
                                "Call",
                                "-",
                              ]))}</strong></p>
                            </Col> */}
                                {/* <Col className='text-end' md={2} onMouseEnter={() => setHoveredItemId(selectedItem?._id)} onMouseLeave={() => setHoveredItemId(null)}>
                                                {hoveredItemId === selectedItem?._id ? (
                                                    <>
                                                        {selectedItem?.unlock === true ? (
                                                            <Tag bordered={false} style={{
                                                                backgroundColor: 'white',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                fontSize: "12px",
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderColor: "#5746ec",
                                                                color: "#5746ec",
                                                                cursor: "pointer",
                                                                padding: "0px 11px"
                                                            }} onClick={() => {
                                                                goToDetailsPage(selectedItem);
                                                                dispatch(setSelectedTranscript(selectedItem));
                                                                dispatch(clearChatMessages());
                                                            }}>
                                                                View
                                                            </Tag>
                                                        ) : available_credits > 0 ? (
                                                            <Tag bordered={false} style={{
                                                                borderColor: '#84B740',
                                                                backgroundColor: 'white',
                                                                color: '#84B740',
                                                                borderWidth: '1px',
                                                                borderStyle: 'solid',
                                                                fontSize: "12px",
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: "0 8px"
                                                            }}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}> 
                                                                    <PiCoinVerticalDuotone style={{ marginRight: '4px' }} />
                                                                    {creditsData?.credits_for_transcript}
                                                                </span>
                                                            </Tag>
                                                        ) : (
                                                            <button
                                                                className="btn viewButton"
                                                                style={{ fontSize: "12px" }}
                                                                onClick={() => {
                                                                    error(creditsData?.credits_for_transcript);
                                                                }}
                                                            >
                                                                Unlock
                                                            </button>
                                                        )}
                                                    </>

                                                ) : (
                                                    <>
                                                        <Tag bordered={false} style={{
                                                            borderColor: '#84B740',
                                                            backgroundColor: 'white',
                                                            color: '#84B740',
                                                            borderWidth: '1px',
                                                            borderStyle: 'solid',
                                                            fontSize: "12px",
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            padding: "0 8px"
                                                        }}>
                                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                <PiCoinVerticalDuotone style={{ marginRight: '4px' }} />
                                                                {creditsData?.credits_for_transcript}
                                                            </span>
                                                        </Tag>
                                                    </>
                                                )}

                                            </Col> */}
                              </Row>

                              <div className="d-flex cstmTg">
                                <p
                                  className="fw-bolder mb-0 me-2"
                                  style={{
                                    flexBasis: "80px",
                                    flexGrow: 0,
                                    flexShrink: 0,
                                  }}
                                >
                                  Keywords{" "}
                                </p>
                                <div>
                                  {selectedItem?.keyword
                                    ?.split(",")
                                    ?.slice(0, 6)
                                    .map((keyword) => (
                                      <>
                                        {" "}
                                        {keyword && (
                                          <Tag
                                            className="me-1 mb-1"
                                            size="large"
                                            key={keyword?.trim()}
                                          >
                                            {convertToTitleCase(
                                              keyword?.trim()
                                            )}
                                          </Tag>
                                        )}
                                      </>
                                    ))}
                                  {selectedItem?.keyword &&
                                    selectedItem?.keyword?.split(",")?.length >
                                      6 && (
                                      <Popover
                                        placement="right"
                                        content={contentTagsKeyword(
                                          selectedItem
                                        )}
                                        trigger="hover"
                                        style={{ width: "100px" }}
                                      >
                                        <Tag className="me-1" size="large">
                                          +
                                          {selectedItem?.keyword?.split(",")
                                            ?.length - 6}
                                        </Tag>
                                      </Popover>
                                    )}
                                </div>
                              </div>
                              <div className="d-flex cstmTg mt-1">
                                <p
                                  className="fw-bolder mb-0 me-2"
                                  style={{
                                    flexBasis: "80px",
                                    flexGrow: 0,
                                    flexShrink: 0,
                                  }}
                                >
                                  Companies{" "}
                                </p>
                                <div>
                                  {selectedItem?.meetingsData?.[0]?.company_id
                                    ?.slice(0, 6)
                                    .map((company) => (
                                      <>
                                        {" "}
                                        {company && (
                                          <Link
                                            to={`/company?company_id=${company?._id}`}
                                          >
                                            <Tag
                                              className="me-1 mb-1"
                                              size="large"
                                              key={company?._id?.trim()}
                                            >
                                              {convertToTitleCase(
                                                company?.name?.trim()
                                              )}
                                            </Tag>
                                          </Link>
                                        )}
                                      </>
                                    ))}
                                  {selectedItem?.meetingsData?.[0]
                                    ?.company_id &&
                                    selectedItem?.meetingsData?.[0]?.company_id
                                      ?.length > 6 && (
                                      <Popover
                                        placement="right"
                                        content={contentTags(selectedItem)}
                                        trigger="hover"
                                        style={{ width: "100px" }}
                                      >
                                        <Tag className="me-1" size="large">
                                          +
                                          {selectedItem?.meetingsData?.[0]
                                            ?.company_id?.length - 6}
                                        </Tag>
                                      </Popover>
                                    )}
                                </div>
                              </div>
                              <div className="expert_dtls my-3">
                                <div className="d-flex justify-content-between align-align-items-center">
                                  <p className="fw-bolder mb-0">
                                    Expert Details
                                  </p>
                                  {/* <Button >
                                            Take to This Expert
                                        </Button> */}
                                </div>
                                <div className="d-flex justify-content-between align-items-center my-2">
                                  <div>
                                    <p className="fw-light fs-6 mb-0">
                                      {selectedItem?.expertData?.[0]
                                        ?.current_designation ||
                                        selectedItem?.expertData
                                          ?.current_designation ||
                                        selectedItem?.currunt_designation}
                                    </p>
                                    <small
                                      className="text-muted"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {selectedItem?.expertData?.[0]
                                        ?.previous_company ||
                                        selectedItem?.expertData
                                          ?.previous_company ||
                                        selectedItem?.previous_company}
                                    </small>
                                  </div>
                                  <div className="d-flex justify-content-end text-end">
                                    <div>
                                      {projectListById?.data &&
                                      projectListById?.data?.length > 0 ? (
                                        // <ProjectListModal
                                        //     selectedExpertId={selectedItem?._id}
                                        //     functions={{
                                        //         projectListById,
                                        //         AddExpertProject,
                                        //         getProjectListById,
                                        //         mutateProjectList
                                        //     }}
                                        // />
                                        <button
                                          className="btn viewButton text-end"
                                          style={{
                                            fontSize: "12px",
                                            padding: "",
                                          }}
                                          onClick={() => {
                                            if (permission?.experts?.request) {
                                              dispatch(setIsOpenProject(true));
                                            }
                                          }}
                                        >
                                          Request this Expert
                                        </button>
                                      ) : (
                                        // <CreateProjectModal
                                        //     buttonText="Add Project"
                                        //     mutateProjectList={mutateProjectList}
                                        // />
                                        <Button
                                          className="btn viewButton text-end"
                                          onClick={() => {
                                            dispatch(
                                              setIsOpenCreatProject(true)
                                            );
                                            dispatch(setIsOpenProject(false));
                                            // closeProjectModal(false)
                                          }}
                                        >
                                          Request this Expert{" "}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  {/* <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>
                                            7 others interview
                                        </Tag> */}
                                </div>
                                {/* {(selectedItem?.expertData?.expert_ai_sumary ||
                        selectedItem?.expertData?.[0]?.expert_ai_sumary) && (
                          <p className="fw-normal my-3 mx-3">
                            {expertSummery(
                              selectedItem?.expertData?.expert_ai_sumary ||
                              selectedItem?.expertData?.[0]?.expert_ai_sumary
                            )}
                          </p>
                        )} */}

                                {/* <h5>Apellis pharamaceuticals, Inc. Present at Goldman Sachs Healthcare C-Suite Unscripted Conference, Jan-04-2024 02:25pm</h5>
                                    <h5 className='fw-light my-3'>Event Details</h5>
                                    <div className='d-flex'>
                                        <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>
                                            <CalendarOutlined />January 05, 2024
                                        </Tag>
                                        <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>
                                            12:55 AM GMT+5:30
                                        </Tag>
                                    </div>
                                    <div className='my-3'>
                                        <h6 className='fw-light mb-0'>Corporate Participants</h6>
                                        <p>Timothy E. Sullivan Apellis Pharmaceuticls, Inc. - CFO & Treasure</p>
                                    </div>
                                    <div>
                                        <h6 className='fw-light mb-0'>Conference Call Participants</h6>
                                        <p>Timothy E. Sullivan Apellis Pharmaceuticls, Inc. - CFO & Treasure</p>
                                    </div>
                                    <div>
                                        <h5 className='fw-light my-2'>Event Transcript Revised January 05, 2024</h5>
                                        <h6 className='fw-light my-2'>Prepared Remarks</h6>
                                        <h6 className='fw-light my-2'>SALVEEN JAISWAL RICHTER <span> GOLDMAN SACHS GROUP INC</span></h6>
                                        <h6 className='fw-bold my-2'>Thank you so much, everyone for joining us. Really pleased to have with us for the next panel, Cheif Finacial. </h6>
                                    </div> */}
                              </div>
                              <ConditionalRender
                                condition={
                                  selectedItem?.lemur_questions?.length > 0
                                }
                              >
                                <div className="my-3 tras_smmry">
                                  <div className="d-flex flex-direction-row justify-content-between">
                                    <strong
                                      className="fw-bolder mb-0"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Topics discussed in this transcript
                                    </strong>

                                    {/* <Col
                          className="text-end"
                          md={2}
                          onMouseEnter={() => setHoveredItemId(selectedItem?._id)}
                          onMouseLeave={() => setHoveredItemId(null)}
                        >
                          {!selectedItem?.unlock === true && (
                            <button
                              className="btn viewButton text-end"
                              style={{
                                fontSize: "12px",
                                padding: "",
                              }}
                              onClick={showModal}
                            >
                              Unlock this transcript
                            </button>
                          )}
                        </Col> */}
                                  </div>
                                  <ul className="listType mb-0">
                                    {selectedItem?.lemur_questions?.map(
                                      (question, index) => (
                                        <li
                                          className="fw-normal mt-2"
                                          key={index}
                                        >
                                          {question.replace(/-/g, "")}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </ConditionalRender>
                              <div className="my-3 position-relative">
                                <div className="d-flex flex-direction-row justify-content-between">
                                  <strong
                                    className="fw-bolder mb-3"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Transcript
                                  </strong>
                                </div>
                                <ul className="listType mb-0">
                                  <p
                                    className="modified_trans childParad"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        selectedItem?.modified_transcript?.slice(
                                          0,
                                          1200
                                        ),
                                    }}
                                  ></p>
                                  <div className="blurred_portion">
                                    {selectedItem?.unlock ? (
                                      <span>
                                        {permission?.transcript?.view ? (
                                          <Link
                                            to={`/transcript-details/${selectedItem?._id}`}
                                            className="btn unlockTrnscrptBttn"
                                          >
                                            {" "}
                                            Read More..
                                          </Link>
                                        ) : (
                                          <span className="btn unlockTrnscrptBttn">
                                            {" "}
                                            Read More..
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <button
                                        className="btn viewButton unlockTrnscrptBttn"
                                        onClick={() => {
                                          if (permission?.transcript?.unlock) {
                                            showModal();
                                          }
                                        }}
                                      >
                                        {" "}
                                        Unlock this transcript
                                      </button>
                                    )}
                                  </div>
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                      </Card>
                    </div>
                  </Col>
                )}
              </>
            )}
          </>
        )}
      </Row>
      <UnlockTranscriptModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        unlockFunction={async () => {
          try {
            let response = await handleSpendCredit(
              selectedItem?._id,
              "transcript",
              selectedItem
            );
            if (response) {
              goToDetailsPage(selectedItem);
              dispatch(setSelectedTranscript(selectedItem));
            }
            setIsModalOpen(false);
            return response;
          } catch (error) {
            return error;
          }
        }}
      />

      <ProjectListModal
        selectedExpertId={selectedItem?._id}
        country={selectedItem?.country}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
        selectedExpertId={selectedItem?._id}
      />
    </>
  );
};

export default TranscriptExpertPage;
