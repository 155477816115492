import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import { Tabs, Table, Pagination, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
import _ from 'lodash';
import usePayment from '../../Hooks/usePayment';
import CurrencyFormat from 'react-currency-format';
import { convertToTitleCase } from '../../../Utils/Util';

const { TabPane } = Tabs;

const Credit = () => {
    const [activeTab, setActiveTab] = React.useState("1")
    const callOnce = React.useRef(false)
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(() => userData?.user?.role_id, [userData?.user?.role_id])

    const { transactionHistoryList, transactionHistoryPagination, paymentHistoryList, paymentHistoryPagination } = useSelector((state) => state.payment)
    const { getTransactionHistoryById, getPaymentHistoryById } = usePayment()
    const dispatch = useDispatch()

    const MODULE_CONSTANTS = [
        { id: 1, name: "News" },
        { id: 2, name: "Transcript" },
        { id: 3, name: "Expert" },
        { id: 4, name: "Premium Expert" },
        { id: 5, name: "Unlock company" }
    ]

    const transactionSource = transactionHistoryList?.data?.map((data) => ({
        key: data?._id,
        user: data?.user_details?.name,
        usedFor: MODULE_CONSTANTS.find((element) => element.id === data?.credits_used_for)?.name,
        credit: data?.credits_spend,
        dateTime: moment(data?.created_at).format("lll"),
        topic: <Tooltip title={
            convertToTitleCase(data?.transcriptTopic?.topic) ||
            data?.newsTitle?.title ||
            `${data?.credits_used_for === 5 ? convertToTitleCase(data?.cloud_companies?.name) : data?.designation?.current_company ?? "NA"} ${data?.designation?.designation ? ", " + data?.designation?.designation : data?.credits_used_for === 5 ? "" : "NA"}`}>

            {_.truncate(
                convertToTitleCase(data?.transcriptTopic?.topic) || data?.newsTitle?.title ||
                `${data?.credits_used_for === 5 ? convertToTitleCase(data?.cloud_companies?.name) : data?.designation?.current_company ?? "NA"} ${data?.designation?.designation ? ", " + data?.designation?.designation : data?.credits_used_for === 5 ? "" : "NA"}` || "", {
                'length': 100,
                'omission': '...'
            }
            )}</Tooltip>
    }));

    const transactionColumn = [
        {
            title: "Name of the User",
            dataIndex: "user",
            key: "user",
            width: "15%"
        },
        {
            title: "Utilization",
            dataIndex: "usedFor",
            key: "usedFor",
            width: "15%"
        },
        {
            title: "Company/Designation",
            dataIndex: "topic",
            key: "topic",
            width: "60%"
        },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            width: "10%"
        },
        {
            title: "Date & Time",
            dataIndex: "dateTime",
            key: "dateTime",
            width: "10%"
        },
    ];

    const paginationSettings = {
        current: transactionHistoryPagination?.page,
        onChange: (page, pageSize) => getTransactionHistoryById(page, pageSize),
        pageSize: transactionHistoryPagination?.limit,
        total: transactionHistoryList?.count,
        showSizeChanger: true,
    };


    const paymentSource = paymentHistoryList?.data?.map((data) => ({
        amount: <CurrencyFormat
            disabled={true}
            displayType="text"
            thousandSeparator
            prefix="$"
            value={data?.amount || 0}
            decimalScale={2}
            fixedDecimalScale
            placeholder="Enter Amount"
            className={`text-gray-700`}
        />,
        credit: data?.credits,
        date: data?.payment_date ? moment(data?.payment_date)?.format("ll") : "NA",
        referenceId: data?.payment_refrence_id ? data?.payment_refrence_id : "NA",
        validity: data?.credit_validity !== "lifetime" ? moment(data?.credit_validity)?.format("ll") : "Lifetime",
        addedBy: data?.user_details?.name,
    }))

    const paymentColumn = [
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Credits",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Payment Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Reference Id",
            dataIndex: "referenceId",
            key: "referenceId",
        },
        {
            title: "Validity",
            dataIndex: "validity",
            key: "validity",
        },
        // {
        //     title: "Added By",
        //     dataIndex: "addedBy",
        //     key: "addedBy",
        // },

    ];

    const paymentPaginationSettings = {
        current: paymentHistoryPagination?.page,
        onChange: (page, pageSize) => getPaymentHistoryById(page, pageSize),
        pageSize: paymentHistoryPagination?.limit,
        total: paymentHistoryList?.count,
        showSizeChanger: true,
    };

    useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true
            if (permission?.credits?.transaction) {
                getTransactionHistoryById(transactionHistoryPagination?.page)
            } else {
                setActiveTab("2")
                getPaymentHistoryById(paymentHistoryPagination?.page)
            }
        }
    }, [dispatch, getPaymentHistoryById, getTransactionHistoryById, paymentHistoryPagination?.page, permission?.credits?.transaction, transactionHistoryPagination?.page]);



    return (
        <>
            <Row className="mt-2">
                <Col className="w-100 d-flex justify-content-between align-items-center">
                    <h5 style={{ fontWeight: 600 }}>
                        <span>Credits</span>
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className="w-100 mt-0">
                    <Tabs centered activeKey={activeTab} onChange={(activeKey) => {
                        if (activeKey === "1") {
                            getTransactionHistoryById(transactionHistoryPagination?.page)
                        } else if (activeKey === "2") {
                            getPaymentHistoryById(paymentHistoryPagination?.page)
                        }
                        setActiveTab(activeKey)
                    }}>
                        {permission?.credits?.transaction &&
                            <TabPane tab="Credit Utilization" key="1" >
                                <Table
                                    dataSource={transactionSource}
                                    columns={transactionColumn}
                                    pagination={false}
                                    scroll={{ y: 'calc(100vh - 280px)' }}
                                    size="small"
                                />
                                {transactionHistoryList?.count > 9 && <Pagination
                                    {...paginationSettings}
                                    size='small'
                                    style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
                                />}
                            </TabPane>}
                        {permission?.credits?.history && <TabPane tab="Payment History" key="2">
                            <Table
                                dataSource={paymentSource}
                                columns={paymentColumn}
                                pagination={false}
                                scroll={{ y: 'calc(100vh - 280px)' }}
                                size="small"
                            />
                            {paymentHistoryList?.count > 9 && <Pagination
                                {...paymentPaginationSettings}
                                showSizeChanger={true}
                                size='small'

                                style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
                            />}
                        </TabPane>}
                    </Tabs>
                </Col>
            </Row>
        </>

    )
}

export default Credit