import { Tag } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import company_icon3 from "../../../../assests/images/company_notfound.png";
import { BsChevronLeft } from "../../../../Utils/Icons";
import { Col, Row } from "react-bootstrap";
import "../Dashboard/Dashboard.css";
import { useSelector } from "react-redux";
import useUser from "../../../Hooks/useUser";

import useProject from "../../../Hooks/useProject";
import { BsStars } from "react-icons/bs";
import Loader from "../../../Components/Loader";

function ExpertProfileSummary({ selectedId = null, getStore, spendCredits }) {
  document.title = "Nextyn IQ - Expert Profile";
  const history = useNavigate();
  useProject();
  const { expertprofile, is_loading_expert_summary } = useSelector(
    (state) => state.SearchPage
  );
  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  const formatSummary = (summary) => {
    summary = summary?.replaceAll("\n\n", " \n");
    summary = summary?.replaceAll("Overview:\n-", " \n");
    summary = summary?.replaceAll("\n-", " \n");
    return summary
      .split(" \n")
      .filter(Boolean)
      .map((line) => line.replace(/^\d+\.\s*/, "")); // Remove leading numbers and periods
  };

  function expertSummery(data) {
    if (data !== null && data !== "" && data !== undefined) {
      // Split the input data into an array of paragraphs based on numeric indicators
      const paragraphs = data?.split(/\d+\.\s+/);

      return (
        <div className="mt-2" style={{ width: "100%", paddingRight: "20px" }}>
          <div className="pvs-header__title-container mt-3 mb-3">
            <span
              className="fw-bolder"
              aria-hidden="true"
              style={{ fontSize: "16px" }}
            >
              <BsStars style={{ fontSize: "14px" }} /> AI Summary
            </span>
          </div>

          <div className="inline-show-more-text">
            <ul style={{ paddingLeft: "20px" }}>
              {" "}
              {/* Add appropriate padding to align bullets */}
              {formatSummary(data).map((entry, index) => (
                // Only show the first 3 lines if showFullSummary is false
                <li
                  key={index}
                  style={{ listStyleType: "disc", fontSize: "12px" }}
                  className="mb-2"
                >
                  {entry?.replace(/-/g, "")}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  return (
    <>
      {/* {selectedId === null && <Sidebar />} */}
      <div class="height-100" id={`${selectedId === null && "main-screen"}`}>
        <main className="body-total content-wrapper float-start w-100">
          {is_loading_expert_summary ? (
            <div className="content-wrapper d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="bk-div float-start w-100">
                <div className="request-page projects-scope">
                  {selectedId === null && (
                    <h4 className="d-flex align-items-center">
                      <Link to={"#"} onClick={() => history(-1)}>
                        <BsChevronLeft className="me-3 search-btn-back" />
                      </Link>
                      Expert Profile
                    </h4>
                  )}
                  <div>
                    <section className="content">
                      {" "}
                      <div className="findExperts">
                        <div className="linkedinBody mx-0">
                          <Row>
                            <Col>
                              {expertprofile[0]?.industry !== null &&
                              expertprofile[0]?.industry !== undefined ? (
                                <div className="d-flex cstmTg mt-1">
                                  <p
                                    className="fw-bolder mb-0 me-2"
                                    style={{
                                      flexBasis: "80px",
                                      flexGrow: 0,
                                      flexShrink: 0,
                                    }}
                                  >
                                    Industries{" "}
                                  </p>
                                  <div>
                                    {ConstantsArray(
                                      expertprofile[0]?.industry
                                    ).map((curElem, id) => {
                                      return (
                                        <Tag
                                          className="me-1 mb-1"
                                          bordered={false}
                                          key={id}
                                        >
                                          {curElem}
                                        </Tag>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col>
                              {expertprofile[0]?.tags !== null &&
                              expertprofile[0]?.tags !== undefined ? (
                                <div className="d-flex cstmTg mt-1">
                                  <p
                                    className="fw-bolder mb-0 me-2"
                                    style={{
                                      flexBasis: "80px",
                                      flexGrow: 0,
                                      flexShrink: 0,
                                    }}
                                  >
                                    Keywords{" "}
                                  </p>
                                  <div>
                                    {ConstantsArray(expertprofile[0]?.tags).map(
                                      (curElem, id) => {
                                        return (
                                          <Tag
                                            className="me-1 mb-1"
                                            bordered={false}
                                            key={id}
                                          >
                                            {curElem}
                                          </Tag>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>

                          {getStore()?.length > 0 ? (
                            <div className="mt-4" style={{ width: "100%" }}>
                              <div className="inline-show-more-text">
                                {getStore()?.map(
                                  (exp, index) => {
                                    return exp[1].map((curElem, id) => {
                                      return (
                                        <Row className="g-0 pt-3">
                                          <Col md={1}>
                                            {exp[1][0].logoUrl !== null &&
                                            exp[1][0].logoUrl !== "" ? (
                                              <img
                                                src={exp[1][0].logoUrl}
                                                alt="logo"
                                                height={"40px"}
                                                width={"40px"}
                                                onError={addDefaultSrc}
                                              />
                                            ) : (
                                              <img
                                                height={"40px"}
                                                width={"40px"}
                                                src={company_icon3}
                                                alt="logo1"
                                              />
                                            )}
                                            {/* <img
                                            src={work_img}
                                            alt="..."
                                            onError={work_img}
                                        /> */}
                                          </Col>
                                          <Col md={11}>
                                            <p className="mb-0">
                                              {" "}
                                              {curElem?.designation}
                                            </p>
                                            <p className="mb-0 fw-bolder">
                                              {curElem?.title}
                                            </p>
                                            <small className="text-muted fw-normal">
                                              {curElem.dateFrom +
                                                " - " +
                                                (curElem.dateTill === null ||
                                                curElem.dateTill === ""
                                                  ? "Present"
                                                  : curElem.dateTill)}
                                            </small>
                                          </Col>
                                        </Row>
                                      );
                                    });
                                  }
                                  // {/* <Col md={10}>
                                  //     <p className='mb-0'>Director, Sales Strategy</p>
                                  //     <small className="text-muted fw-normal">Jan 2020 - Present</small>
                                  // </Col> */}
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {expertSummery(expertprofile[0]?.expert_ai_sumary)}
                        </div>
                      </div>{" "}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}

export default ExpertProfileSummary;
