import axios from "axios";
import React, { useCallback, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import API_PATH from "../../../Constants/api-path";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsChecked,
  setIsRoleDescript,
  setIsRoleId,
  setIsRoleName,
  setIsUserId,
  setRoleList,
  setRoleType,
} from "./userManagement.slice";
import { useNavigate } from "react-router-dom";

function useUserManagement() {
  const { isUserId, isChecked, isRoleName, isRoleDescrip, isRoleId } =
    useSelector((state) => state.userManagementReducer);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [spinner, setSpinner] = React.useState(false);
  const dispatch = useDispatch();
  const callOnce = useRef(false);
  const history = useNavigate();
  const headers = useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );

  const handleInputs = useCallback(
    (e) => {
      console.log("herrrr");
      let name = e.target.name;
      console.log("name: ", name);
      isChecked[name] === false
        ? dispatch(setIsChecked({ ...isChecked, [name]: true }))
        : dispatch(setIsChecked({ ...isChecked, [name]: false }));
    },
    [dispatch, isChecked]
  );

  const getUserList = useCallback(async () => {
    try {
      setSpinner(true);
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      dispatch(setRoleList(response?.data));
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  }, [dispatch, headers, userData?.user?.company_id?._id]);

  const updateRoles = useCallback(
    async (e) => {
      // e.preventDefault();
      if (isRoleName !== "" && isRoleDescrip !== "") {
        setSpinner(true);
        try {
          const result = await axios.post(
            `${API_PATH.ROLE_MANAGEMENT_UPDATE}/${isRoleId}`,
            {
              name: isRoleName,
              description: isRoleDescrip,
              projects: {
                add: isChecked.projectAdd,
                view: isChecked.projectView,
                delete: isChecked.projectDelete,
                message: isChecked.projectMessage,
                expertList: isChecked.projectList,
              },
              callRecording: {
                view: isChecked.collaborationView,
              },
              experts: {
                view: isChecked.expertView,
                request: isChecked.expertRequest,
              },
              transcript: {
                view: isChecked.transcriptView,
                unlock: isChecked.transcriptUnlock,
              },
              userManagement: {
                view: isChecked.userManagementView,
                add: isChecked.userManagementAdd,
                update: isChecked.userManagementUpdate,
                delete: isChecked.userManagementDelete,
              },
              credits: {
                transaction: isChecked.creditTransaction,
                history: isChecked.creditHistory,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": userData.access_token,
              },
            }
          );
          if (result) {
            // let newUserData = { ...userData };
            // newUserData.user.role_id = result?.data?.data;
            // localStorage.setItem("userData", JSON.stringify(newUserData));
            setSpinner(false);
            if (result.status === 200) {
              getUserList();
              return toast.success("Role Updated Successfully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          } else {
            setSpinner(false);
            return toast.error("something went wrong server error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } catch (error) {
          setSpinner(false);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.warning("All fields are mandatory!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    },
    [
      getUserList,
      isChecked.collaborationView,
      isChecked.creditHistory,
      isChecked.creditTransaction,
      isChecked.expertRequest,
      isChecked.expertView,
      isChecked.projectAdd,
      isChecked.projectDelete,
      isChecked.projectList,
      isChecked.projectMessage,
      isChecked.projectView,
      isChecked.transcriptUnlock,
      isChecked.transcriptView,
      isChecked.userManagementAdd,
      isChecked.userManagementDelete,
      isChecked.userManagementUpdate,
      isChecked.userManagementView,
      isRoleDescrip,
      isRoleId,
      isRoleName,
      userData,
    ]
  );

  const submitRolesForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (isRoleName !== "" && isRoleDescrip !== "") {
        setSpinner(true);
        document.getElementById("addRoleManagement").disabled = true;
        try {
          const result = await axios.post(
            `${API_PATH.ROLE_MANAGEMENT_POST}`,
            {
              name: isRoleName,
              description: isRoleDescrip,
              company_id: userData?.user?.company_id?._id,
              type: "company",
              projects: {
                add: isChecked.projectAdd,
                view: isChecked.projectView,
                delete: isChecked.projectDelete,
                message: isChecked.projectMessage,
                expertList: isChecked.projectList,
              },
              callRecording: {
                view: isChecked.collaborationView,
              },
              experts: {
                view: isChecked.expertView,
                request: isChecked.expertRequest,
              },
              transcript: {
                view: isChecked.transcriptView,
                unlock: isChecked.transcriptUnlock,
              },
              userManagement: {
                view: isChecked.userManagementView,
                add: isChecked.userManagementAdd,
                update: isChecked.userManagementUpdate,
                delete: isChecked.userManagementDelete,
              },
              credits: {
                transaction: isChecked.creditTransaction,
                history: isChecked.creditHistory,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": userData.access_token,
              },
            }
          );
          if (result) {
            console.log(result, "result");
            setSpinner(false);
            document.getElementById("addRoleManagement").disabled = false;
            if (result.status === 200) {
              history("/usermangement/roles");
              return toast.success("Role Added Successfully!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              return toast.error(result?.data?.msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          } else {
            return toast.error(result?.data?.msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } catch (error) {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        return toast.warning("All fields are mandatory!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    },
    [
      history,
      isChecked.collaborationView,
      isChecked.creditHistory,
      isChecked.creditTransaction,
      isChecked.expertRequest,
      isChecked.expertView,
      isChecked.projectAdd,
      isChecked.projectDelete,
      isChecked.projectList,
      isChecked.projectMessage,
      isChecked.projectView,
      isChecked.transcriptUnlock,
      isChecked.transcriptView,
      isChecked.userManagementAdd,
      isChecked.userManagementDelete,
      isChecked.userManagementUpdate,
      isChecked.userManagementView,
      isRoleDescrip,
      isRoleName,
      userData.access_token,
      userData?.user?.company_id?._id,
    ]
  );

  const setRoleModal = useCallback(
    (curElem) => {
      dispatch(setIsRoleName(curElem.name));
      dispatch(setIsRoleDescript(curElem.description));
      dispatch(
        setIsChecked({
          projectAdd: curElem?.projects?.add,
          projectView: curElem?.projects?.view,
          projectDelete: curElem?.projects?.delete,
          projectMessage: curElem?.projects?.message,
          projectList: curElem?.projects?.expertList,

          collaborationView: curElem?.callRecording?.view,

          expertView: curElem?.experts?.view,
          expertRequest: curElem?.experts?.request,

          transcriptView: curElem?.transcript?.view,
          transcriptUnlock: curElem?.transcript?.unlock,

          userManagementAdd: curElem?.userManagement?.add,
          userManagementView: curElem?.userManagement?.view,
          userManagementUpdate: curElem?.userManagement?.update,
          userManagementDelete: curElem?.userManagement?.delete,

          creditTransaction: curElem?.credits?.transaction,
          creditHistory: curElem?.credits?.history,
        })
      );
      dispatch(setIsRoleId(curElem._id));
      dispatch(setRoleType(curElem?.type))
    },
    [dispatch]
  );

  const deleteUserModal = useCallback(async () => {
    // console.log(data);
    // document.getElementById("deletedRoleManagement").disabled = true;
    axios
      .delete(`${API_PATH.ROLE_MANAGEMENT_DELETE}/${isUserId}`, {
        method: "DELETE",
        headers: headers,
      })
      .then((res) => {
        getUserList();
        if (res.data.status === 200) {
          // console.log(res.status);
          document.getElementById("deletedRoleManagement").disabled = false;
          toast.success("RoleData Deleted Successful", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getUserList, headers, isUserId]);

  const getUserId = useCallback(
    (curElem) => {
      dispatch(setIsUserId(curElem._id));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      getUserList();
    }
  }, [getUserList]);

  const values = useMemo(
    () => ({
      spinner,
      deleteUserModal,
      getUserList,
      handleInputs,
      setRoleModal,
      updateRoles,
      getUserId,
      submitRolesForm,
    }),
    [
      deleteUserModal,
      getUserList,
      handleInputs,
      setRoleModal,
      updateRoles,
      getUserId,
      spinner,
      submitRolesForm,
    ]
  );
  return values;
}

export default useUserManagement;
