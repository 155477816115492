import React from "react";
import { Button, Card, List } from "antd";
import { Row, Col } from "react-bootstrap";
import useProject from "../../../Hooks/useProject";
import ExpertCard from "../Dashboard/Expert/ExpertCard";
import { useDispatch, useSelector } from "react-redux";
import ExpertProfileSummary from "./ExpertProfileSummary";
import { CreateProjectModal } from "../../../../components/CreateProjectModal";
import { ProjectListModal } from "../../../../components/ProjectListModal";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../Dashboard/Project.Slice";
import company_icon3 from "../../../../assests/images/company_notfound.png";
import EmptyStatesData from '../../../Components/EmptyStates';
import Loader from "../../../Components/Loader"
import { truncate } from "../../../../Utils/Util";


const ExpertSection = ({ isLoadingExp, isValidatingExp, functions }) => {
  const {
    handleItemClick,
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    getStore,
    fetchExpertData,
    spendCredits
  } = functions;
  const { selectedExpert, expertList, is_loading_content, expertPagination } = useSelector(
    (state) => state?.SearchPage
  );

  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(() => userData?.user?.role_id, [userData?.user?.role_id])
  useProject();
  const { projectListById } = useSelector((state) => state.project);
  const dispatch = useDispatch();

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };

  const data = EmptyStatesData?.companyExpert;

  const hasMore = React.useMemo(() => {
    return expertList?.data?.length < expertList?.count;
  }, [expertList?.data?.length, expertList?.count]);


  const onLoadMore = () => {
    fetchExpertData({ page_no: expertPagination.page + 1, page_limit: expertPagination.limit });
  }
  const loadMore =
    !isLoadingExp && !is_loading_content && !isValidatingExp && hasMore ? (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          textAlign: 'center',
          height: 55,
        }}
      >
        <Button onClick={onLoadMore}>Load More</Button>
      </div>
    ) : null;

  if (!data) return null;

  return (
    <>
      <Row className="g-0">
        {expertList?.data?.length === 0 &&
          !isLoadingExp &&
          !isValidatingExp &&
          !is_loading_content ? (
          <>
            <Col md={12}>
              <div
                className="empty-state-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <div
                  className="image-container"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  <img
                    src={data?.image}
                    alt={data?.feature}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
                <div
                  className="content-container"
                  style={{ flex: 1, textAlign: "left" }}
                >
                  <p
                    className="p-0 mt-0 pt-0 mb-3"
                    style={{ lineHeight: "28px", fontWeight: "300" }}
                  >
                    {data?.content}
                  </p>
                  <button
                    className="btn viewButton findme-btn"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      dispatch(setIsOpenCreatProject(true));
                      dispatch(setIsOpenProject(false));
                      // closeProjectModal(false)
                    }}
                  >
                    {data?.cta}
                  </button>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <>
            {isLoadingExp || isValidatingExp || is_loading_content ? (
              <Col
                lg={12}
              // className="d-flex justify-content-center align-items-center"
              >
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ minHeight: "50vh" }}
                >
                  <Loader />
                </div>
              </Col>
            ) : (
              <>
                <Col lg={selectedExpert?._id ? 4 : 12}>
                  <Row>
                    <Col md={12}>
                      <div className="searchPageTranscript">
                        <List
                          size="small"
                          // header={<h5 className="trans-header px-3">{dataArray?.length} results</h5>}
                          // bordered
                          dataSource={expertList?.data || []}
                          loadMore={loadMore}
                          locale={{
                            emptyText: () => (
                              <div
                                style={{ height: 100, paddingTop: 26 }}
                                className="flex justify-content-center align-items-center pt-20"
                              >
                                <span>No data found.</span>
                              </div>
                            ),
                          }}
                          renderItem={(item, index) => (
                            // <ListItem />
                            <ExpertCard
                              data={item}
                              index={index}
                              handleItemClick={handleItemClick}
                            />
                          )}
                          style={{ borderRadius: "4px", background: "#ffff" }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                {selectedExpert?._id && (
                  <Col lg={8} style={{ borderRadius: "4px" }}>
                    <div className="searchPageTranscript">
                      <Card
                        title={
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                              <span>
                                {
                                  selectedExpert?.current_experience?.[0]
                                    ?.logo_url !== null &&
                                  selectedExpert?.current_experience?.[0]
                                    ?.logo_url !== undefined &&
                                  selectedExpert?.current_experience?.[0]
                                    ?.logo_url !== "" && (
                                    <img
                                      src={
                                        selectedExpert
                                          ?.current_experience?.[0]?.logo_url
                                      }
                                      alt="logo"
                                      className="me-2"
                                      style={{
                                        width: "50px",
                                        borderRadius: "5px",
                                        maxWidth: "50px",
                                      }}
                                      onError={addDefaultSrc}
                                    />
                                  )
                                  //  : (
                                  //     <img
                                  //         src={company_icon3}
                                  //         alt="logo1"
                                  //         className="me-2"
                                  //         style={{
                                  //             width: "95px",
                                  //             borderRadius: "5px",
                                  //         }}
                                  //     />
                                  // )
                                }
                              </span>
                              {selectedExpert?._id && (
                                <div>
                                  <p className="trans-header mb-0">
                                    <strong className="fw-bolder truncate-text">
                                      {" "}
                                      {truncate(selectedExpert?.currunt_designation, 50)}
                                    </strong>
                                  </p>
                                  <p className="fw-normal m-0">
                                    {selectedExpert?.currunt_designation !==
                                      null ? (
                                      <>{selectedExpert?.currunt_company}</>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>

                            {selectedExpert?._id && (
                              <div>
                                {projectListById?.data &&
                                  projectListById?.data?.length > 0 ? (
                                  // <ProjectListModal
                                  //     functions={{
                                  //         projectListById,
                                  //         AddExpertProject,
                                  //         getProjectListById,
                                  //         mutateProjectList
                                  //     }}
                                  //     selectedExpertId={selectedExpert?._id} />
                                  <button
                                    className="btn viewButton text-end"
                                    style={{
                                      fontSize: "12px",
                                      padding: "",
                                    }}
                                    onClick={() => {
                                      if (permission?.experts?.request) {
                                        dispatch(setIsOpenProject(true));
                                        dispatch(setIsOpenCreatProject(false));
                                      }
                                    }}
                                  >
                                    Request this Expert
                                  </button>
                                ) : (
                                  // <CreateProjectModal buttonText="Add Project" mutateProjectList={mutateProjectList} />
                                  <Button
                                    className="btn viewButton text-end"
                                    onClick={() => {
                                      if (permission?.experts?.request) {
                                        dispatch(setIsOpenProject(false));
                                        dispatch(setIsOpenCreatProject(true));
                                      }
                                      // closeProjectModal(false)
                                    }}
                                  >
                                    Request this Expert
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        }
                        className="chat-card sd_Sde_"
                        style={{ borderRadius: "4px" }}
                      >
                        {selectedExpert?._id && (
                          <ExpertProfileSummary
                            selectedId={selectedExpert?._id}
                            getStore={getStore}
                            spendCredits={spendCredits}
                          />
                        )}
                        {/* <div className="w-100 py-2 transcriptDetails_container"> */}
                        {/* <div className='d-flex'>
                                    <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>

                                        Lit Protocol
                                    </Tag>
                                    <small className="text-muted fw-normal me-3">Posted {formatDate(selectedItem?.start_time)}</small>
                                    <small className="text-muted fw-normal me-3">{formatDuration(selectedItem?.duration)} read</small>
                                </div> */}
                        {/* <h4 className='my-3'>
                                    {replaceTxt(selectedItem?.topic, [
                                        "Nextyn",
                                        "Micro",
                                        "Consulting",
                                        "-Consulting",
                                        "Call - ",
                                        "Call",
                                        "-",
                                    ])}
                                </h4> */}
                        {/* <div className='trans_tags d-flex mb-3'>
                                    {selectedItem?.brand?.split(',').slice(0, 6).map((brand) => (
                                        <> {brand && (
                                            <Tag key={brand?.trim()} rounded color={"rgba(0, 0, 0, 0.04)"} className='me-1' style={{ borderRadius: "4px", color: "#000", fontSize: "12px" }}>
                                                {brand?.trim()}
                                            </Tag>
                                        )}
                                        </>
                                    ))}
                                    {selectedItem?.brand && selectedItem?.brand?.split(',')?.length > 1 && (
                                        <Tooltip placement='right' title={selectedItem?.brand?.split(',')?.slice(6).join(', ').trim()}>
                                            <Tag className="me-1" size="large">
                                                +{selectedItem?.brand.split(',')?.slice(6).length - 1}
                                            </Tag>
                                        </Tooltip>
                                    )}

                                </div> */}
                        {/* <div className='tras_smmry my-3'> */}
                        {/* <h5>Summary</h5> */}
                        {/* <p className="fw-normal">
                                        {selectedItem?.ai_transcript}
                                    </p> */}
                        {/* <div className='d-flex'>
                                        <small className="text-muted fw-normal me-3">Learn how summaries are generated</small>
                                        <small className="text-muted fw-normal">Report an issue </small>
                                    </div> */}
                        {/* </div> */}

                        {/* <div className='expert_dtls my-3'> */}
                        {/* <div className='d-flex justify-content-between align-align-items-center'> */}
                        {/* <h5 className='fw-light'>Expert Detials Compliance</h5> */}
                        {/* <Button >
                                            Take to This Expert
                                        </Button> */}
                        {/* </div> */}
                        {/* <div className='d-flex my-2'> */}
                        {/* <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>
                                            <UserOutlined /> {selectedItem?.expertData?.[0]?.current_designation}
                                        </Tag> */}
                        {/* <Tag rounded className='fw-normal me-3' color={"rgba(0, 0, 0, 0.04)"} style={{ borderRadius: "4px", fontSize: "12px", color: "#000" }}>
                                            7 others interview
                                        </Tag> */}
                        {/* </div> */}
                        {/* {selectedItem?.expertData?.[0]?.expert_ai_sumary && <p className='fw-normal my-3'>
                                        {selectedItem?.expertData?.[0]?.expert_ai_sumary}
                                    </p>} */}

                        {/* </div> */}
                        {/* </div> */}
                      </Card>
                    </div>
                  </Col>
                )}
              </>
            )}
          </>
        )}
      </Row>

      <ProjectListModal
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
        selectedExpertId={selectedExpert?._id}
      />
      <CreateProjectModal
        buttonText="Request this Expert"
        mutateProjectList={mutateProjectList}
        selectedExpertId={selectedExpert?._id}
      />
    </>
  );
};

export default ExpertSection;
