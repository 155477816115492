import React from 'react'
import loader from "../../assests/images/loader-unscreen.gif"


const Loader = () => {

    return (
        <>
            <div style={{ textAlign: "center", padding: "20px", zIndex: 999 }}>
                <img src={loader} alt="Loading..." style={{ width: '50px' }} />
            </div>
        </>
    )
}

export default Loader


export const TableLoader = () => {

    return (
        <>
            <div style={{ zIndex: 999 }}>
                <img src={loader} alt="Loading..."  />
            </div>
        </>
    )
}
