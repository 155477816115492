import React from "react";
import Helmet from "react-helmet";
// import Sidebar from "../../../Includes/Sidebar";
import TopSelectOptions from "./TopSelectOptions";

const SearchPage = () => {
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Search Result"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        {/* <Sidebar /> */}
        <div className="content-wrapper bg-light" id="main-screen">
          <TopSelectOptions />
        </div>
      </div>
    </>
  );
};

export default SearchPage;
