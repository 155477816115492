import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
    toggle: false
}

const slice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setToggle: (state, { payload }) => {
            state.toggle = payload
        },
    }
})

const dashboardReducer = slice.reducer
export const { setToggle } = slice.actions
export {
    dashboardReducer
}