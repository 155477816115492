import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import API_URL from "../../Constants/api-path";
import {
  setCompanyDirector,
  setCompanyAuditors,
  setCompanyCharges,
  setCompanyRatings,
  setShareHoldingAllotmentHoldingData,
  setCompanyAssociateData,
  setCompanyAssociateRelatedTxnData,
  setSortParams,
  setIsLoadingDir,
  setRatingPagination,
  setAuditPagination,
  setDirectorPagination,
  setChargePagination,
  setCorporateAnnouncementBSC,
  setCorpPagination,
  setCompanyDirectorDetails,
  setDirectorDetailsPagination,
  setCompanyAssociateRelatedTxnPagination,
  setConferenceCalls,
  setConfPagination,
  setIsLoadingCharges,
  setIsLoadingRating,
  setIsLoadingAuditor,
} from "../Pages/V2/CompanyPage/CompanyTab.Slice";
import useSWR from "swr";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { ControllerContext } from "../../App";

const useCompanyTabs = ({ activeTab = null }) => {
  const [searchParams] = useSearchParams();
  const abortControllerRef = useContext(ControllerContext);
  const { companyData, associatetab } = useSelector((state) => state.companySearch);
  const { corporateActiveKey } = useSelector((state) => state.companyTabSliceReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const callOnce = useRef(false);
  const callOnceCharges = useRef(false);
  const callOnceRating = useRef(false);
  const callOnceAudit = useRef(false);
  const callOnceCorporate = useRef(false);
  const directorDetails = useRef(false);
  useEffect(() => {
    if (activeTab === "Directors") {
      callOnceCharges.current = false;
      callOnceRating.current = false;
      callOnceAudit.current = false;
      callOnceCorporate.current = false;
    }
    if (activeTab === "Charges") {
      callOnce.current = false;
      callOnceRating.current = false;
      callOnceAudit.current = false;
      callOnceCorporate.current = false;
      directorDetails.current = false;
    }
    if (activeTab === "Rating") {
      callOnceCharges.current = false;
      callOnce.current = false;
      callOnceAudit.current = false;
      callOnceCorporate.current = false;
      directorDetails.current = false;
    }
    if (activeTab === "Auditors") {
      callOnceCharges.current = false;
      callOnceRating.current = false;
      callOnce.current = false;
      callOnceCorporate.current = false;
      directorDetails.current = false;
    }
    if (activeTab === "Corporate Announcements") {
      callOnceCharges.current = false;
      callOnceRating.current = false;
      callOnce.current = false;
      callOnceAudit.current = false;
      directorDetails.current = false;
    }
    if (activeTab === "directorDetails") {
      callOnceCharges.current = false;
      callOnceRating.current = false;
      callOnce.current = false;
      callOnceAudit.current = false;
      callOnceCorporate.current = false;
    } else {
      callOnceCharges.current = false;
      callOnceRating.current = false;
      callOnce.current = false;
      callOnceAudit.current = false;
      callOnceCorporate.current = false;
      directorDetails.current = false;
    }
  }, [activeTab]);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let company_id = query.get("company_id");
  const {
    shareholderHistoryPagination,
    shareHoldingAllotmentHoldingData,
    companyAssociateData,
    companyAssociateRelatedTxnData,
    companyAssociateRelatedTxnPagination,
    sortParams,
    searchParamsDir,
    searchParamsCharges,
    searchParamsRating,
    searchParamsAudit,
    ratingPagination,
    auditPagination,
    directorPagination,
    chargePagination,
    corpPagination, confPagination,
    companyDirectorDetails,
    directorDetailsPagination,
    searchParamsDirDetails,
    isLoadingDir,
  } = useSelector((state) => state.companyTabSliceReducer);

  const getDirectorCompany = useCallback(
    async ({ company_id_dir = company_id, searchParams = searchParamsDir, sortParams_dir = sortParams, pagination = directorPagination }) => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;

      dispatch(setIsLoadingDir(true));
      const requestBody = {
        company_id: company_id_dir,
        search: searchParams,
        sortColumn: sortParams_dir?.sortColumn,
        sortType: sortParams_dir?.sortType,
        page: pagination?.page,
        limit: pagination?.limit,
      };
      try {
        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.post(API_URL.COMPANY_DIRECTORS_API, requestBody, {
            signal: controller?.signal
          });
          dispatch(setCompanyDirector(response?.data));
          dispatch(setIsLoadingDir(false));
        }

      } catch (error) {
        dispatch(setIsLoadingDir(false));
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, directorPagination, dispatch, searchParamsDir, sortParams]
  );
  const getDirectorCompanyByDin = useCallback(
    async ({ company_din = id, searchParams = searchParamsDirDetails, sortParams_dir = sortParams, pagination = directorDetailsPagination }) => {
      dispatch(setIsLoadingDir(true));
      const requestBody = {
        din: company_din,
        search: searchParams,
        sortColumn: sortParams_dir?.sortColumn,
        sortType: sortParams_dir?.sortType,
        // page: pagination?.page,
        // limit: pagination?.limit,
      };
      try {
        const response = await axios.post(API_URL.GET_DIRECTOR_DETAILS_BY_DIN, requestBody);
        dispatch(setCompanyDirectorDetails(response?.data));
        dispatch(setIsLoadingDir(false));
      } catch (error) {
        dispatch(setIsLoadingDir(false));
        console.error("ERROR", error);
      }
    },
    [directorDetailsPagination, dispatch, id, searchParamsDirDetails, sortParams]
  );

  const getAuditorCompany = useCallback(
    async ({ company_id_audit = company_id, searchParams = searchParamsAudit, sortParams_audit = sortParams, pagination = auditPagination }) => {

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;
      dispatch(setIsLoadingAuditor(true))
      const requestBody = {
        company_id: company_id_audit,
        search: searchParams,
        sortColumn: sortParams_audit?.sortColumn,
        sortType: sortParams_audit?.sortType,
        page: pagination?.page,
        limit: pagination?.limit,
      };
      try {
        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.post(API_URL.COMPANY_AUDITORS_API, requestBody, {
            signal: controller?.signal
          });
          dispatch(setCompanyAuditors(response?.data));
          dispatch(setIsLoadingAuditor(false))
        }
        // return response?.data
      } catch (error) {
        dispatch(setIsLoadingAuditor(false))
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, auditPagination, companyData?.isCompanyRequested, company_id, dispatch, searchParamsAudit, sortParams]
  );
  const getCorporateAnnouncement = useCallback(
    async ({ company_id_corp = company_id, pagination = corpPagination }) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const controller = new AbortController();
        abortControllerRef.current = controller;
        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.get(API_URL.GET_CORPORATE_ANNOUNCEMENT + `?company_id=${company_id_corp}&page=${pagination?.page}&limit=${pagination?.limit}`, {
            signal: controller?.signal
          });
          return response?.data
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, corpPagination]
  );
  const fetchCorporateAnnouncement = useCallback(
    async ({ company_id_corp = company_id, pagination = corpPagination }) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const controller = new AbortController();
        abortControllerRef.current = controller;

        if (companyData?.isCompanyRequested === "completed") {
          dispatch(setCorpPagination({ page: pagination.page, limit: pagination.limit }))

          const response = await axios.get(API_URL.GET_CORPORATE_ANNOUNCEMENT + `?company_id=${company_id_corp}&page=${pagination?.page}&limit=${pagination?.limit}`, {
            signal: controller?.signal
          });
          dispatch(setCorporateAnnouncementBSC({ data: response?.data?.data, count: response?.data?.countData }));

          // return response?.data
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, corpPagination, dispatch]
  );
  const getCorporateConferenceCalls = useCallback(
    async ({ company_id_corp = company_id, pagination = confPagination }) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const controller = new AbortController();
        abortControllerRef.current = controller;

        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.get(API_URL.GET_CONFERENCE_CALLS + `?company_id=${company_id_corp}&page=${pagination?.page}&limit=${pagination?.limit}`, {
            signal: controller?.signal
          });

          return response?.data
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, confPagination]
  );
  // fetchCorporateAnnouncement
  const fetchConferenceCalls = useCallback(
    async ({ company_id_corp = company_id, pagination = confPagination }) => {
      try {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const controller = new AbortController();
        abortControllerRef.current = controller;

        if (companyData?.isCompanyRequested === "completed") {
          dispatch(setConfPagination({ page: pagination.page, limit: pagination.limit }))
          const response = await axios.get(API_URL.GET_CONFERENCE_CALLS + `?company_id=${company_id_corp}&page=${pagination?.page}&limit=${pagination?.limit}`, {
            signal: controller?.signal
          });

          dispatch(setConferenceCalls({ data: response?.data?.data, count: response?.data?.countData }));

          // return response?.data
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, confPagination, dispatch]
  );

  const getChargesCompany = useCallback(
    async ({ company_id_char = company_id, searchParams = searchParamsCharges, sortParams_char = sortParams, pagination = chargePagination }) => {
      dispatch(setIsLoadingCharges(true))
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;

      const requestBody = {
        company_id: company_id_char,
        search: searchParams,
        sortColumn: sortParams_char?.sortColumn,
        sortType: sortParams_char?.sortType,
        page: pagination?.page,
        limit: pagination?.limit,
      };
      try {
        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.post(API_URL.COMPANY_CHARGES_API, requestBody, {
            signal: controller?.signal
          });
          dispatch(setCompanyCharges(response?.data));
          dispatch(setIsLoadingCharges(false))
        }
        // return response?.data
      } catch (error) {
        dispatch(setIsLoadingCharges(false))
        console.error("ERROR", error);
      }
    },
    [abortControllerRef, chargePagination, companyData?.isCompanyRequested, company_id, dispatch, searchParamsCharges, sortParams]
  );

  const getRatingsCompany = useCallback(
    async ({ company_id_rating = company_id, searchParams = searchParamsRating, sortParams_rating = sortParams, pagination = ratingPagination }) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;
      dispatch(setIsLoadingRating(true))
      const requestBody = {
        company_id: company_id_rating,
        search: searchParams,
        sortColumn: sortParams_rating?.sortColumn,
        sortType: sortParams_rating?.sortType,
        limit: pagination?.limit,
        page: pagination?.page,
      };
      try {
        if (companyData?.isCompanyRequested === "completed") {
          const response = await axios.post(API_URL.COMPANY_RATINGS_API, requestBody, {
            signal: controller?.signal
          });
          dispatch(setCompanyRatings(response?.data));
          dispatch(setIsLoadingRating(false))
        }
        // return response?.data
      } catch (error) {
        console.error("ERROR", error);
        dispatch(setIsLoadingRating(false))

      }
    },
    [abortControllerRef, companyData?.isCompanyRequested, company_id, dispatch, ratingPagination, searchParamsRating, sortParams]
  );

  const formatDate = useCallback((isoDate) => {
    const dateOptions = { day: "2-digit", month: "2-digit", year: "2-digit" };
    const formattedDate = new Date(isoDate).toLocaleDateString("en-GB", dateOptions);
    return formattedDate;
  }, []);

  const getCompanyShareholdingsAllotmentsHistory = useCallback(async () => {

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const response = await axios.get(API_URL.GET_COMPANY_SHAREHOLDINGS_ALLOTMENTS_HISTORY + `?company_id=${searchParams.get("company_id")}&page=${shareholderHistoryPagination.page}&limit=${shareholderHistoryPagination.limit}`, {
      signal: controller?.signal
    });
    return response.data;
  }, [abortControllerRef, searchParams, shareholderHistoryPagination.limit, shareholderHistoryPagination.page]);

  const getCompanyAssociateData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const response = await axios.get(API_URL?.GET_COMPANY_ASSOCIATE_DATA + `?company_id=${searchParams.get("company_id")}`, {
      signal: controller?.signal
    });
    return response.data;
  }, [abortControllerRef, searchParams]);

  const getAssociateRelatedPartyTxn = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;

    const response = await axios.get(
      API_URL.GET_COMPANY_ASSOCIATE_PARTLY_TRANSACTION +
      `?company_id=${searchParams.get("company_id")}&page=${companyAssociateRelatedTxnPagination.page}&limit=${companyAssociateRelatedTxnPagination.limit}&sortColumn=${sortParams.sortColumn}&sortType=${sortParams?.sortType}`, {
      signal: controller?.signal
    }
    );
    return response.data;
  }, [abortControllerRef, companyAssociateRelatedTxnPagination.limit, companyAssociateRelatedTxnPagination.page, searchParams, sortParams.sortColumn, sortParams?.sortType]);

  const fetchAssociateRelatedPartyTxn = useCallback(
    async ({ page_no = companyAssociateRelatedTxnPagination.page, page_limit = companyAssociateRelatedTxnPagination.limit, sorter = sortParams }) => {
      dispatch(setCompanyAssociateRelatedTxnPagination({ page: page_no, limit: page_limit }))
      const response = await axios.get(API_URL.GET_COMPANY_ASSOCIATE_PARTLY_TRANSACTION + `?company_id=${searchParams.get("company_id")}&page=${page_no}&limit=${page_limit}&sortColumn=${sorter.field}&sortType=${sorter.order === "ascend" ? 1 : -1}`);
      dispatch(setCompanyAssociateRelatedTxnData({ data: response?.data?.data, count: response?.data?.countData }));
    },
    [companyAssociateRelatedTxnPagination.limit, companyAssociateRelatedTxnPagination.page, dispatch, searchParams, sortParams]
  );

  const { isLoading: isLoadingShareholdingData } = useSWR(
    ["get_company_shareholding_allotment_history", searchParams.get("company_id"), activeTab],
    () => (searchParams.get("company_id") && activeTab === "Shareholding" ? getCompanyShareholdingsAllotmentsHistory() : null),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        dispatch(setShareHoldingAllotmentHoldingData({ data: data?.data, count: data?.countData }));
      },
      onError: (error) => {
        console.error("ERROR", error);
      },
    }
  );
  const { isLoading: isLoadingAssociate, isValidating: isValidatingAssociate } = useSWR(["get_company_associate_data", searchParams.get("company_id"), activeTab, companyData?.isCompanyRequested, associatetab], () => (searchParams.get("company_id") && activeTab === "Associates" && associatetab === "1" && companyData?.isCompanyRequested === "completed" ? getCompanyAssociateData() : null), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setCompanyAssociateData(data?.data));
    },
    onError: (error) => {
      console.error("ERROR", error);
    },
  });
  const { isLoading: isLoadingAssociateRelatedTxn, isValidating: isValidatingAssociateRelatedTxn } = useSWR(["get_associate_related_party_txn", searchParams.get("company_id"), activeTab, companyData?.isCompanyRequested, associatetab], () => (searchParams.get("company_id") && activeTab === "Associates" && associatetab === "2" && companyData?.isCompanyRequested === "completed" ? getAssociateRelatedPartyTxn() : null), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {

      dispatch(setCompanyAssociateRelatedTxnData({ data: data?.data, count: data?.countData }));
    },
    onError: (error) => {
      console.error("ERROR", error);
    },
  });
  const { isLoading: isLoadingcorp, isValidating: isValidatingcorp } = useSWR(["get_corp_announce", searchParams.get("company_id"), activeTab, companyData?.isCompanyRequested, corporateActiveKey], () => (searchParams.get("company_id") && activeTab === "Corporate Announcements" && corporateActiveKey === "1" && companyData?.isCompanyRequested === "completed" ? getCorporateAnnouncement({ company_id_corp: id }) : null), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setCorporateAnnouncementBSC({ data: data?.data, count: data?.countData }));
    },
    onError: (error) => {
      console.error("ERROR", error);
    },
  });
  const { isLoading: isLoadingconf, isValidating: isValidatingconf } = useSWR(["get_conf_calls", searchParams.get("company_id"), activeTab, companyData?.isCompanyRequested, corporateActiveKey], () => (searchParams.get("company_id") && activeTab === "Corporate Announcements" && corporateActiveKey === "2" && companyData?.isCompanyRequested === "completed" ? getCorporateConferenceCalls({ company_id_corp: id }) : null), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setConferenceCalls({ data: data?.data, count: data?.countData }));
    },
    onError: (error) => {
      console.error("ERROR", error);
    },
  });

  const onSearch = useCallback(
    async (searchDir) => {
      await getDirectorCompany({ company_id_dir: company_id, searchParams: { ...searchDir }, sortParams_dir: sortParams });
    },
    [company_id, getDirectorCompany, sortParams]
  );
  const onSearchDirDetails = useCallback(
    async (searchDirDetails) => {
      await getDirectorCompanyByDin({ company_din: id, searchParams: { ...searchDirDetails }, sortParams_dir: sortParams });
    },
    [getDirectorCompanyByDin, id, sortParams]
  );
  const onSearchCharges = useCallback(
    (searchCharge) => {
      getChargesCompany({ company_id_char: company_id, searchParams: { ...searchCharge }, sortParams_char: sortParams });
    },
    [company_id, getChargesCompany, sortParams]
  );
  const onSearchRating = useCallback(
    (searchRating) => {
      getRatingsCompany({ company_id_rating: company_id, searchParams: { ...searchRating }, sortParams_rating: sortParams });
    },
    [company_id, getRatingsCompany, sortParams]
  );
  const onSearchAudit = useCallback(
    (searchAudit) => {
      getAuditorCompany({ company_id_audit: company_id, searchParams: { ...searchAudit }, sortParams_audit: sortParams });
    },
    [company_id, getAuditorCompany, sortParams]
  );

  const handleSortChange = useCallback(
    async ({ sorter = sortParams, page = directorPagination?.page, limit = directorPagination?.limit }) => {
      await getDirectorCompany({ company_id_dir: company_id, searchParams: searchParamsDir, sortParams_dir: { sortColumn: sorter.field, sortType: sorter.order === "ascend" ? 1 : -1 }, pagination: { page: page, limit: limit } });
      dispatch(
        setSortParams({
          sortColumn: sorter.field,
          sortType: sorter.order === "ascend" ? 1 : -1,
        })
      );
      dispatch(
        setDirectorPagination({
          page: page,
          limit: limit,
        })
      );
    },
    [company_id, directorPagination?.limit, directorPagination?.page, dispatch, getDirectorCompany, searchParamsDir, sortParams]
  );
  const handleSortChangeDirDetails = useCallback(
    async ({ sorter = sortParams, page = directorDetailsPagination?.page, limit = directorDetailsPagination?.limit }) => {
      await getDirectorCompanyByDin({ company_din: id, searchParams: searchParamsDirDetails, sortParams_dir: { sortColumn: sorter.field, sortType: sorter.order === "ascend" ? 1 : -1 }, pagination: { page: page, limit: limit } });
      dispatch(
        setSortParams({
          sortColumn: sorter.field,
          sortType: sorter.order === "ascend" ? 1 : -1,
        })
      );
      dispatch(
        setDirectorDetailsPagination({
          page: page,
          limit: limit,
        })
      );
    },
    [directorDetailsPagination?.limit, directorDetailsPagination?.page, dispatch, getDirectorCompanyByDin, id, searchParamsDirDetails, sortParams]
  );
  const handleSortChangeCharges = useCallback(
    async ({ sorter = sortParams, page = chargePagination?.page, limit = chargePagination?.limit }) => {
      await getChargesCompany({ company_id_char: company_id, searchParams: searchParamsCharges, sortParams_char: { sortColumn: sorter.field, sortType: sorter.order === "ascend" ? 1 : -1 }, pagination: { page: page, limit: limit } });
      dispatch(
        setSortParams({
          sortColumn: sorter.field,
          sortType: sorter.order === "ascend" ? 1 : -1,
        })
      );
      dispatch(
        setChargePagination({
          page: page,
          limit: limit,
        })
      );
    },
    [chargePagination?.limit, chargePagination?.page, company_id, dispatch, getChargesCompany, searchParamsCharges, sortParams]
  );
  const handleSortChangeRating = useCallback(
    async ({ sorter = sortParams, page = ratingPagination?.page, limit = ratingPagination?.limit }) => {
      await getRatingsCompany({
        company_id_rating: company_id,
        searchParam: searchParamsRating,
        sortParams_rating: { sortColumn: sorter.field, sortType: sorter.order === "ascend" ? 1 : -1 },
        pagination: { page: page, limit: limit },
      });
      dispatch(
        setSortParams({
          sortColumn: sorter.field,
          sortType: sorter.order === "ascend" ? 1 : -1,
        })
      );
      dispatch(
        setRatingPagination({
          page: page,
          limit: limit,
        })
      );
    },
    [company_id, dispatch, getRatingsCompany, ratingPagination?.limit, ratingPagination?.page, searchParamsRating, sortParams]
  );
  const handleSortChangeAudit = useCallback(
    async ({ sorter = sortParams, page = auditPagination?.page, limit = auditPagination?.limit }) => {
      await getAuditorCompany({
        company_id_audit: company_id,
        searchParams: searchParamsAudit,
        pagination: { page: page, limit: limit },
        sortParams_audit: { sortColumn: sorter.field, sortType: sorter.order === "ascend" ? 1 : -1 },
      });
      dispatch(
        setSortParams({
          sortColumn: sorter.field,
          sortType: sorter.order === "ascend" ? 1 : -1,
        })
      );
      dispatch(
        setAuditPagination({
          page: page,
          limit: limit,
        })
      );
    },
    [auditPagination?.limit, auditPagination?.page, company_id, dispatch, getAuditorCompany, searchParamsAudit, sortParams]
  );

  useEffect(() => {
    const callonceFunction = async () => {
      if (!callOnce.current && activeTab === "Directors" && companyData?.isCompanyRequested === "completed") {
        callOnce.current = true;
        dispatch(setDirectorPagination({ page: 1, limit: 10 }));
        getDirectorCompany({ company_id_dir: company_id, searchParams: searchParamsDir, sortParams_dir: sortParams, pagination: { page: 1, limit: 10 } });
      } else if (!callOnceCharges.current && activeTab === "Charges" && companyData?.isCompanyRequested === "completed") {
        callOnceCharges.current = true;
        dispatch(setChargePagination({ page: 1, limit: 10 }));
        getChargesCompany({ company_id_char: company_id, searchParams: searchParamsCharges, sortParams_char: sortParams, pagination: { page: 1, limit: 10 } });
      } else if (!callOnceRating.current && activeTab === "Rating" && companyData?.isCompanyRequested === "completed") {
        callOnceRating.current = true;
        dispatch(setRatingPagination({ page: 1, limit: 10 }));
        getRatingsCompany({ company_id_rating: company_id, searchParams: searchParamsRating, sortParams_rating: sortParams, pagination: { page: 1, limit: 10 } });
      } else if (!callOnceAudit.current && activeTab === "Auditors" && companyData?.isCompanyRequested === "completed") {
        callOnceAudit.current = true;
        dispatch(setAuditPagination({ page: 1, limit: 10 }));
        getAuditorCompany({ company_id_audit: company_id, searchParams: searchParamsAudit, sortParams_audit: sortParams, pagination: { page: 1, limit: 10 } });
      } else if (!directorDetails.current && activeTab === "directorDetails") {
        directorDetails.current = true;
        dispatch(setCorpPagination({ page: 1, limit: 10 }));
        getDirectorCompanyByDin({ company_din: id, pagination: { page: 1, limit: 10 } });
      }
      // else if (!callOnceCorporate.current && activeTab === "Corporate Announcements" && companyData?.isCompanyRequested === "completed") {
      //   callOnceCorporate.current = true;
      //   dispatch(setCorpPagination({ page: 1, limit: 10 }));
      //   getCorporateAnnouncement({ company_id_corp: company_id, pagination: { page: 1, limit: 10 } });
      // }
    };
    callonceFunction();
  }, [searchParamsDir, getDirectorCompany, company_id, sortParams, activeTab, getChargesCompany, searchParams, searchParamsCharges, getRatingsCompany, searchParamsRating, getAuditorCompany, searchParamsAudit, dispatch, getCorporateAnnouncement, getDirectorCompanyByDin, id, companyData?.isCompanyRequested, getCompanyAssociateData]);

  const values = useMemo(
    () => ({
      getDirectorCompany,
      formatDate,
      getAuditorCompany,
      onSearchAudit,
      getChargesCompany,
      getRatingsCompany,
      shareHoldingAllotmentHoldingData,
      isLoadingShareholdingData,
      companyAssociateData,
      companyAssociateRelatedTxnData,
      fetchAssociateRelatedPartyTxn,
      onSearch,
      onSearchCharges,
      handleSortChange,
      handleSortChangeCharges,
      handleSortChangeRating,
      onSearchRating,
      handleSortChangeAudit,
      getDirectorCompanyByDin,
      handleSortChangeDirDetails,
      companyDirectorDetails,
      onSearchDirDetails,
      isLoadingDir, companyAssociateRelatedTxnPagination, isLoadingAssociate,
      isValidatingAssociate,
      isLoadingAssociateRelatedTxn,
      isValidatingAssociateRelatedTxn, getCorporateConferenceCalls, fetchCorporateAnnouncement,
      fetchConferenceCalls, isLoadingcorp,
      isValidatingcorp,
      isLoadingconf,
      isValidatingconf,
    }),
    [
      getDirectorCompany,
      formatDate,
      getAuditorCompany,
      onSearchAudit,
      getChargesCompany,
      getRatingsCompany,
      shareHoldingAllotmentHoldingData,
      isLoadingShareholdingData,
      companyAssociateData,
      companyAssociateRelatedTxnData,
      fetchAssociateRelatedPartyTxn,
      onSearch,
      onSearchCharges,
      handleSortChange,
      handleSortChangeCharges,
      handleSortChangeRating,
      onSearchRating,
      handleSortChangeAudit,
      getDirectorCompanyByDin,
      handleSortChangeDirDetails,
      companyDirectorDetails,
      onSearchDirDetails,
      isLoadingDir, companyAssociateRelatedTxnPagination, isLoadingAssociate,
      isValidatingAssociate,
      isLoadingAssociateRelatedTxn,
      isValidatingAssociateRelatedTxn, getCorporateConferenceCalls, fetchCorporateAnnouncement,
      fetchConferenceCalls,
      isLoadingcorp,
      isValidatingcorp,
      isLoadingconf,
      isValidatingconf,
    ]
  );

  return values;
};

export default useCompanyTabs;
