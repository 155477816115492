import { createSlice } from "@reduxjs/toolkit";

const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: {
    chatMessages: [],
    status: 'idle',
    isLoading: false,
    error: null,
    selectedTranscript: {},
    viewOnce: false,
  },
  reducers: {
    sendUserMessage: (state, { payload }) => {
      state.chatMessages = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setSelectedTranscript: (state, { payload }) => {
      state.selectedTranscript = payload;
    },
    setViewOnce: (state, { payload }) => {
      state.viewOnce = payload;
    },
    setChatMessages: (state, { payload }) => {
      let arr = [...state.chatMessages]
      arr.splice(-1)
      let newArr = [...arr, payload]
      state.chatMessages = newArr
    }, clearChatMessages: (state, { payload }) => {
      state.chatMessages = []
      state.viewOnce = false
    }
  },

});

export const { sendUserMessage, setChatMessages, setIsLoading, clearChatMessages, setSelectedTranscript, setViewOnce } = transcriptSlice.actions;

export default transcriptSlice.reducer;