import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complianceGSTData: { data: [], count: 0 },
  complianceGSTEstablishmentData: { data: [], count: 0 },
  complianceEPFEstablishmentData: { data: [], count: 0 },
  complianceGSTEstablishmentParams: {
    company_id: "",
    financial_year: "",
    search: {
      gstin: "",
      date_of_registration: "",
      state: "",
      status: "",
      nature_of_delay: "",
      tax_payer_type: "",
      address: "",
    },
    sortColumn: "",
    sortType: -1, // 1=Asc, -1= Desc
    last_month_with_delay: 0,
    limit: 10,
    page: 1,
  },

  complianceEPFEstablishmentParams: {
    company_id: "",
    financial_year: "",
    search: {
      establishment_id: "",
      status: "",
      nature_of_delay: "",
      address: "",
      city: "",
      average_number_of_employee: "",
      expected_epf_liability_formatted: "",
      last_month_with_delay: "",
    },
    sortColumn: "",
    sortType: -1, // 1=Asc, -1= Desc
    last_month_with_delay: 0,
    limit: 10,
    page: 1,
  },

  complianceEPFData: { data: [], count: 0 },
  complianceEPFTabs: { nonPrivateTrust: true, privateTrust: false },
  complianceGSTParams: {
    financial_year: "",
    establishments_count: null,
    delayedCount: null,
    return_type: "",
  },
  selectedRowGST: {},
  selectedRowEPF: {},
  complianceEPFParams: {
    financial_year_epf: "",
    establishments_count_epf: null,
    delayedCount_epf: null,
    return_type_epf: "",
    amount_paid_epf: "",
    no_of_employee_epf: ""
  },
  isLoadingGSTEstablishment: false,
  isLoadingEPFEstablishment: false,
  isModalOpen: false,
  isModalOpenEPF: false,
  gstEstablishmentDetails: {},
  epfEstablishmentDetails: {},
  selectedFilingDetails: [],
  selectedFilingDetailsEPF: [],
  sortParams: { sortColumn: "", sortType: 1 },
};

const complianceSlice = createSlice({
  initialState: initialState,
  name: "ComplianceTab",
  reducers: {
    setComplianceGSTData: (state, { payload }) => {
      state.complianceGSTData = payload;
    },
    setComplianceGSTParams: (state, { payload }) => {
      state.complianceGSTParams = payload;
    },
    setComplianceEPFData: (state, { payload }) => {
      state.complianceEPFData = payload;
    },
    setComplianceEPFTabs: (state, { payload }) => {
      state.complianceEPFTabs = payload;
    },
    setComplianceEPFParams: (state, { payload }) => {
      state.complianceEPFParams = payload;
    },
    setSelectedRowGST: (state, { payload }) => {
      state.selectedRowGST = payload;
    },
    setSelectedRowEPF: (state, { payload }) => {
      state.selectedRowEPF = payload;
    },
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    setIsModalOpenEPF: (state, { payload }) => {
      state.isModalOpenEPF = payload;
    },
    setIsLoadingGSTEstablishment: (state, { payload }) => {
      state.isLoadingGSTEstablishment = payload;
    },
    setIsLoadingEPFEstablishment: (state, { payload }) => {
      state.isLoadingEPFEstablishment = payload;
    },
    setComplianceGSTEstablishmentData: (state, { payload }) => {
      state.complianceGSTEstablishmentData = payload;
    },
    setComplianceEPFEstablishmentData: (state, { payload }) => {
      state.complianceEPFEstablishmentData = payload;
    },
    setComplianceGSTEstablishmentParams: (state, { payload }) => {
      state.complianceGSTEstablishmentParams = payload;
    },
    setComplianceEPFEstablishmentParams: (state, { payload }) => {
      state.complianceEPFEstablishmentParams = payload;
    },
    setGstEstablishmentDetails: (state, { payload }) => {
      state.gstEstablishmentDetails = payload;
    },
    setEpfEstablishmentDetails: (state, { payload }) => {
      state.epfEstablishmentDetails = payload;
    },
    setSelectedFilingDetails: (state, { payload }) => {
      state.selectedFilingDetails = payload;
    },
    setSelectedFilingDetailsEPF: (state, { payload }) => {
      state.selectedFilingDetailsEPF = payload;
    },
  },
});
export const complianceSliceReducer = complianceSlice.reducer;
export const {
  setComplianceGSTData,
  setComplianceGSTParams,
  setComplianceEPFData,
  setComplianceEPFParams,
  setSelectedRowGST,
  setComplianceEPFEstablishmentData,
  setComplianceGSTEstablishmentData,
  setComplianceGSTEstablishmentParams,
  setComplianceEPFEstablishmentParams,
  setSelectedRowEPF,
  setIsLoadingGSTEstablishment,
  setIsLoadingEPFEstablishment,
  setIsModalOpen,
  setGstEstablishmentDetails,
  setSelectedFilingDetails,
  setSelectedFilingDetailsEPF,
  setEpfEstablishmentDetails,
  setIsModalOpenEPF, setComplianceEPFTabs
} = complianceSlice.actions;
