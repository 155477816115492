import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Col, Row } from "react-bootstrap";
import "react-h5-audio-player/lib/styles.css";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../../../Constants/api-path";
import { Collapse, Card, Tag } from "antd";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";
import { IoIosArrowBack } from "react-icons/io";
import { convertToTitleCase, formattedDate } from "../../../Utils/Util";
import ReactPlayerCustomForRecording from "../Transcript/CustomPlayer/ReactPlayerCustomForRecording";
import NextynAIAssistant from "../../Components/NextynAIAssistant";

const TranscriptDetail = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );
  const videoPlayerRef = useRef(null);
  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  const [recordingData, setRecordingData] = useState({});
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [occurrencesLength, setOccurrencesLength] = useState(0);
  const [updateSliderInterval, setUpdateSliderInterval] = useState(null);

  const audioPlayerRef = useRef();

  useEffect(() => {
    console.log("in scrolling event");
    const handleScroll = (event) => {
      event.stopPropagation();
    };
    const audioPlayer = audioPlayerRef.current;
    audioPlayer?.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      audioPlayer?.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [kywrdHeight, setKywrdHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      const middleTitle = document.getElementById("middleTitle");
      console.log("middleTitle: ", middleTitle);
      if (middleTitle) {
        console.log("middleTitle.offsetHeight: ", middleTitle.offsetHeight);
        const height = middleTitle.offsetHeight;
        console.log("height: ", height);
        setKywrdHeight(181 + height);
      }
    };
    // Call the function after the initial render

    const setHeight = setInterval(() => {
      calculateHeight();
    }, 1000);

    if (kywrdHeight !== 0) {
      clearInterval(setHeight);
    }

    // Add event listener for resize, in case the height changes dynamically
    window.addEventListener("resize", calculateHeight);
    // Clean up the event listener on unmount
    return () => {
      clearInterval(setHeight);
      window.removeEventListener("resize", calculateHeight);
    };
  }, [kywrdHeight]);

  // Set the style dynamically
  const style = useMemo(
    () => ({
      height: `calc(100vh - ${kywrdHeight}px)`,
    }),
    [kywrdHeight]
  );

  // useEffect(() => {
  //   const loadAudio = async () => {
  //     if (
  //       recordingData &&
  //       recordingData.recording_files &&
  //       recordingData.recording_files[0] &&
  //       recordingData.recording_files[0].s3_download_url
  //     ) {
  //       const newSound = new Howl({
  //         src: [recordingData.recording_files[0].s3_download_url],
  //         volume: volume,
  //         onload: () => {
  //           setDuration(newSound.duration());
  //           setLoading(false);
  //         },
  //         onplay: () => {
  //           Howler.ctx.resume();
  //           setIsPlaying(true);

  //           const updateSliderInterval = setInterval(() => {
  //             const newTime = newSound.seek();
  //             if (Math.abs(newTime - currentTime) > 1) {
  //               setCurrentTime(newTime);
  //               highlightTimestamp(newTime);
  //             }
  //           }, 250); // Update every 250 milliseconds

  //           setUpdateSliderInterval(updateSliderInterval);
  //         },
  //         onpause: () => {
  //           setIsPlaying(false);
  //           clearInterval(updateSliderInterval);
  //         },
  //         onend: () => {
  //           setCurrentTime(0);
  //           setIsPlaying(false);
  //           clearInterval(updateSliderInterval);
  //         },
  //       });

  //       setSound(newSound);

  //       await new Promise((resolve) => {
  //         newSound.load(() => {
  //           resolve();
  //         });
  //       });

  //       setSound(newSound);
  //     }
  //   };

  //   loadAudio();

  //   return () => {
  //     if (sound) {
  //       sound.off(); // Remove all event listeners
  //       sound.unload(); // Unload the sound
  //     }
  //   };
  // }, [recordingData, volume]);

  // useEffect(() => {
  //   return () => {
  //     if (sound) {
  //       sound.stop();
  //     }
  //   };
  // }, [sound]);

  // useEffect(() => {
  //   if (sound) {
  //     sound.unload();
  //   }
  //   if (
  //     recordingData &&
  //     recordingData?.recording_files &&
  //     recordingData?.recording_files[0] &&
  //     recordingData?.recording_files[0]?.s3_download_url
  //   ) {
  //     const newSound = new Howl({
  //       src: [recordingData?.recording_files[0]?.s3_download_url],
  //       volume: volume,
  //       onload: () => {
  //         setDuration(newSound.duration());
  //       },
  //       onplay: () => {
  //         Howler.ctx.resume();
  //         setIsPlaying(true);

  //         // Periodically update the slider while playing
  //         const updateSliderInterval = setInterval(() => {
  //           setCurrentTime(newSound.seek());
  //         }, 1000); // Update every second (adjust the interval as needed)

  //         setUpdateSliderInterval(updateSliderInterval); // Save the interval ID
  //       },
  //       onpause: () => {
  //         setIsPlaying(false);
  //         clearInterval(updateSliderInterval);
  //       },
  //       onend: () => {
  //         setCurrentTime(0);
  //         setIsPlaying(false);
  //         clearInterval(updateSliderInterval);
  //       },
  //     });

  //     setSound(newSound);

  //     return () => {
  //       newSound.unload();
  //     };
  //   }
  // }, [recordingData, volume]);
  const formatTimeForJump = useCallback((milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000);
    return `${String(seconds).padStart(2, "0")}`;
  }, []);

  // Function to check if an element is in the viewport
  const isElementInViewport = useCallback((el) => {
    const rect = el.getBoundingClientRect();
    // console.log('rect: ', rect);
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }, []);
  const highlightTimestamp = useCallback(
    (currentTime) => {
      const words = document.getElementsByClassName("words");
      const currentTimeInMs = currentTime * 1000;
      // console.log('currentTimeInMs: ', currentTimeInMs);

      let closestTimestamp = words[0];
      let minTimeDifference = Math?.abs(
        currentTimeInMs - parseFloat(closestTimestamp?.id?.slice(4))
      );
      // console.log('minTimeDifference: ', minTimeDifference);

      for (let i = 1; i < words.length; i++) {
        const timeDifference = Math.abs(
          currentTimeInMs - parseFloat(words[i].id.slice(4))
        );
        // console.log('timeDifference: ', timeDifference);
        if (timeDifference < minTimeDifference) {
          minTimeDifference = timeDifference;
          closestTimestamp = words[i];
        }
      }

      // Highlight the closest timestamp
      for (let i = 0; i < words.length; i++) {
        // console.log('words[i]: ', words[i].id.slice(4));
        const time = Number(words[i].id.slice(4));

        if (time < currentTimeInMs) {
          if (i > 0) {
            words[i - 1].classList.remove("highlight");
          }
          words[i].classList.add("highlight");
        }
      }

      // Scroll to the closest timestamp if it's not in view
      if (closestTimestamp && !isElementInViewport(closestTimestamp)) {
        // console.log('closestTimestamp: ', closestTimestamp);
        closestTimestamp.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    },
    [isElementInViewport]
  );

  const createHighlighted = useCallback(
    (value) => {
      const formattedTime = formatTimeForJump(value);
      videoPlayerRef.current.seekTo(formattedTime);
      highlightTimestamp(formattedTime);
      if (!videoState.playing) {
        setVideoState({ ...videoState, playing: true });
      }
    },
    [formatTimeForJump, highlightTimestamp, videoState]
  );

  // let occurrences = [];
  const occurrences = useRef([]);

  const getRecordingDetails = useCallback(async () => {
    try {
      const res = await fetch(
        `${API_PATH.MEETING_ID_GET}/${searchParams.get("meetingId")}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        console.log("result: ", result);
        setRecordingData(result?.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, searchParams]);

  const onceCall = useRef(false);

  useEffect(() => {
    const fetchDetails = async () => {
      await getRecordingDetails();
    };
    if (!onceCall.current) {
      onceCall.current = true;
      fetchDetails();
    }
  }, [getRecordingDetails]);

  const scrollToHighlight = useCallback((i) => {
    let pickHighlights = document.querySelectorAll("mark.selected");
    console.log("pickHighlights: ", pickHighlights);
    if (pickHighlights.length > 0) {
      var viewportOffset = pickHighlights[0].getBoundingClientRect();
      // these are relative to the viewport
      var top = viewportOffset.top;
      var wndw = document.querySelector("#messagesEndRef .mainComment2");
      wndw.scrollTo(0, wndw.scrollTop + top - 300);
    }
  }, []);

  const selectMarkedAsSelected = useCallback(
    (crrn, ln = null) => {
      let aln = ln !== null ? ln : occurrencesLength;
      if (crrn > 0 && crrn <= aln) {
        let mark = document.querySelectorAll("#scriptBody mark");
        mark.forEach((mrk) => {
          mrk.classList.remove("selected");
        });

        // Highlight all occurrences, not just the current one
        mark.forEach((mrk, index) => {
          if (index >= crrn - 1 && index < crrn - 1 + aln) {
            mrk.classList.add("selected");
          }
        });

        scrollToHighlight(crrn - 1);
        setCurrent(crrn);
      }
    },
    [occurrencesLength, scrollToHighlight]
  );

  const displayOcc = useCallback(() => {
    let lengthOccurrences = occurrences.current.length;
    setOccurrencesLength(lengthOccurrences);
    let currViewMatch = current;
    currViewMatch = currViewMatch > 0 ? currViewMatch : 0;
    currViewMatch = currViewMatch > lengthOccurrences ? 1 : currViewMatch;
    currViewMatch =
      currViewMatch === 0 && lengthOccurrences > 0 ? 1 : currViewMatch;

    // let insertNbrOcc =
    //   lengthOccurrences > 0 ? " of " + lengthOccurrences : " matches found";

    // setCount(insertNbrOcc);
    setCurrent(currViewMatch);
    selectMarkedAsSelected(currViewMatch, lengthOccurrences);
  }, [current, selectMarkedAsSelected]);

  // highlight text when click on highlighted text
  const searchHighlights = useCallback(
    (txt) => {
      occurrences.current = [];
      const scriptBody = document.querySelectorAll("#scriptBody p");
      let regExp = new RegExp(txt, "gi");
      // setIsSearchType(false);
      // setHighlight(true);

      scriptBody.forEach(function (p) {
        p.innerHTML = p.textContent;
      });

      // setTimeout(function () {
      //   setHighlightText(txt);
      // }, 400);

      scriptBody.forEach(function (p, index) {
        if (p.textContent.match(regExp)) {
          var matches = p.textContent.match(regExp);
          matches.forEach(function (match, n) {
            occurrences.current.push(index);
          });
          p.innerHTML = p.textContent.replace(regExp, "<mark>$&</mark>");
        }
      });
      displayOcc();
    },
    [displayOcc]
  );

  const doubleDigit = useCallback((digit) => {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }, []);

  const convertMS = useCallback(
    (time) => {
      var hour = "";
      var min = Math.floor((time / (1000 * 60)) % 60),
        hours = Math.floor((time / (1000 * 60 * 60)) % 24),
        sec = Math.floor((time / 1000) % 60);
      if (hours) {
        hour = `${doubleDigit(hours)}:`;
      }
      return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
    },
    [doubleDigit]
  );

  const logTimestamps = useCallback(
    (timestamps) => {
      const newTimestamps = timestamps.map(({ end }) => {
        return end;
      });

      // setTimestampData(newTimestamps);
      // setCurrentTimestampIndex(0);
      createHighlighted(newTimestamps[0]);
    },
    [createHighlighted]
  );

  const replaceTxt = useCallback((txt, para) => {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }, []);

  const [, setIsScreenSmall] = useState(window.innerWidth <= 1350);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth <= 1350);
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* New design start */}
      <div
        className="content-wrapper bg-light"
        id="main-screen"
        style={{ paddingTop: 10 }}
      >
        <Row className="app-container g-2">
          <Col md={8}>
            <Card
              title={
                <div className="trans_popup ">
                  <div className="jf_ds d-flex align-items-center w-100">
                    <div className="fxdLeft">
                      <Link
                        to={"/call-recordings"}
                        className="bckIcon bckIcon_trans"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "inline-block",
                        }}
                      // onClick={handleIconClick}
                      >
                        <IoIosArrowBack className="me-1 search-btn-back" />
                      </Link>
                      <div className="title text-muted">Title</div>
                    </div>
                    <div className="middleTitle">
                      <div className="title-data">
                        {convertToTitleCase(
                          replaceTxt(recordingData?.topic, [
                            "nextyn",
                            "micro",
                            "consulting",
                            "-consulting",
                            "call - ",
                            "call",
                            // "-",
                          ])
                        )}
                        {/* <Popover
                          style={{ cursor: "pointer" }}
                          placement="bottom"
                          content={contentDisclaimer}
                          trigger="click"
                          className="ms-2"
                        >
                          <IoMdInformationCircle
                            style={{ cursor: "pointer" }}
                          />
                        </Popover> */}
                      </div>
                    </div>
                    <div className="fxdRght text-end">
                      {/* <div className="d-flex align-items-center">
                        {isSearchType ? (
                          <div
                            className={
                              searchActive
                                ? "me-3 searchIconRecording active position-relative"
                                : "me-3 searchIconRecording position-relative"
                            }
                          >
                            <Input
                              size="large"
                              style={{ width: "100%" }}
                              placeholder="Search"
                              className="searchInput"
                              id="searchResults"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchInputSearchs();
                                }
                              }}
                              onChange={(e) => setSearchKeyword(e.target.value)}
                              onClick={() => setSearchActive(true)}
                              onBlur={() => setSearchActive(false)}
                              value={searchKeyword}
                              prefix={
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 24 24"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10.25 2a8.25 8.25 0 0 1 6.34 13.53l5.69 5.69a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-5.69-5.69A8.25 8.25 0 1 1 10.25 2ZM3.5 10.25a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0Z"></path>
                                </svg>
                              }
                              addonAfter={
                                searchActive && (
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-width="0"
                                    viewBox="0 0 15 15"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                      setSearchActive(false);
                                      handleClear();
                                    }}
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                )
                              }
                            />
                            {dropdownActive && (
                              <CustomDropdown
                                items={dropdownItems}
                                onSelect={handleDropdownSelect}
                                searchKeyword={searchKeyword}
                                closeDropdown={closeDropdown}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            className={
                              highlight
                                ? "me-3 searchIconRecording position-relative active"
                                : "me-3 searchIconRecording position-relative"
                            }
                          >
                            <span className="ant-input-group-wrapper ant-input-group-wrapper-lg">
                              <span className="ant-input-wrapper ant-input-group">
                                <span className="ant-input-affix-wrapper ant-input-affix-wrapper-focused ant-input-affix-wrapper-lg">
                                  <span className="ant-input-prefix">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10.25 2a8.25 8.25 0 0 1 6.34 13.53l5.69 5.69a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-5.69-5.69A8.25 8.25 0 1 1 10.25 2ZM3.5 10.25a6.75 6.75 0 1 0 13.5 0 6.75 6.75 0 0 0-13.5 0Z"></path>
                                    </svg>
                                  </span>
                                  <span className="ant-input ant-input-lg">
                                    <div>{highlightText}</div>
                                  </span>
                                </span>
                                <span className="ant-input-group-addon">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center highlightedControl me-3">
                                      <span
                                        className="me-2"
                                        onClick={() => {
                                          selectMarkedAsSelected(current - 1);
                                          handlePrevTimestamp();
                                        }}
                                      >
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          stroke-width="0"
                                          viewBox="0 0 1024 1024"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M8.2 751.4c0 8.6 3.4 17.401 10 24.001 13.2 13.2 34.8 13.2 48 0l451.8-451.8 445.2 445.2c13.2 13.2 34.8 13.2 48 0s13.2-34.8 0-48L542 251.401c-13.2-13.2-34.8-13.2-48 0l-475.8 475.8c-6.8 6.8-10 15.4-10 24.2z"></path>
                                        </svg>
                                      </span>
                                      <span className="me-2">
                                        {current}
                                        {count}
                                      </span>
                                      <span
                                        onClick={() => {
                                          selectMarkedAsSelected(current + 1);
                                          handleNextTimestamp();
                                        }}
                                      >
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          stroke-width="0"
                                          viewBox="0 0 1024 1024"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M8.2 275.4c0-8.6 3.4-17.401 10-24.001 13.2-13.2 34.8-13.2 48 0l451.8 451.8 445.2-445.2c13.2-13.2 34.8-13.2 48 0s13.2 34.8 0 48L542 775.399c-13.2 13.2-34.8 13.2-48 0l-475.8-475.8c-6.8-6.8-10-15.4-10-24.199z"></path>
                                        </svg>
                                      </span>
                                    </div>
                                    <svg
                                      onClick={() => {
                                        closeHightlightedText();
                                        handleClear();
                                      }}
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-width="0"
                                      viewBox="0 0 15 15"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                </span>
                              </span>
                            </span>
                          </div>
                        )}
                      </div> */}
                      <span className="fw-normal fs-7 mb-0">
                        {formattedDate(recordingData?.start_time)}
                      </span>
                    </div>
                  </div>

                  <div className="jf_ds d-flex align-items-start">
                    <div className="fxdLeft">
                      <div className="title text-muted">Keywords</div>
                    </div>
                    <div
                      className="middleTitle"
                      id="middleTitle"
                      style={{ maxHeight: "fit-content" }}
                    >
                      <div
                        className="title-data cstmTg fw-normal"
                        style={{
                          flex: 1,
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {recordingData?.assembly_response?.auto_highlights_result?.results.map(
                          function (object, i) {
                            const { text, timestamps } = object;
                            return (
                              <>
                                <Tag
                                  onClick={() => {
                                    searchHighlights(text);
                                    logTimestamps(timestamps, 0);
                                  }}
                                  className="me-1 mb-1 fs-7"
                                  style={{ cursor: "pointer" }}
                                  size="large"
                                >
                                  {convertToTitleCase(text?.trim())}
                                </Tag>
                                {/* <span className="summry_keywords">,</span> */}
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
              className="time-clock trans-disc p-0"
            >
              <div className="d-flex">
                <div className="w-100">
                  <div
                    className="summary-container mt-3"
                    id="summary-container"
                    style={style}
                  >
                    <Row>
                      <Col lg={12}>
                        <h5
                          className="mb-2 txtGredient"
                          style={{ fontSize: "20px" }}
                        >
                          Call Transcript
                        </h5>
                        <p className="modified_trans">
                          {recordingData?.assembly_response?.utterances?.map(
                            (value) => (
                              <>
                                <div
                                  className="position-relative mt-4 wordPara"
                                  id={value.start}
                                  data-end={value.end}
                                >
                                  <div className="speakerName">
                                    {value.speaker}
                                  </div>
                                  <div style={{ marginLeft: "50px" }}>
                                    <Row className="speaker_wrapper gx-2">
                                      <Col>
                                        <span
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "12px",
                                          }}
                                        >
                                          Speaker {value.speaker}
                                        </span>
                                        <span
                                          style={{
                                            color: "#8294a5",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            display: "inline-block",
                                            marginLeft: "14px",
                                            marginRight: "15px",
                                          }}
                                        >
                                          {convertMS(value?.start)}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#05294b",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            createHighlighted(value?.start);
                                          }}
                                        >
                                          {value.words.map((word) => (
                                            <>
                                              <span
                                                id={`txt_${word.start}`}
                                                className="words"
                                              >
                                                {word.text}{" "}
                                              </span>
                                            </>
                                          ))}
                                        </span>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="music-player" ref={audioPlayerRef}>
                    {/* {selectedTranscriptData?.transcript_audio?.fullAudio ? ( */}
                    <span className="music-span">
                      <div className="full-control">
                        <ReactPlayerCustomForRecording
                          resData={recordingData}
                          // setCurrentTime={setCurrentTime}
                          highlightTimestamp={highlightTimestamp}
                          videoPlayerRef={videoPlayerRef}
                          videoState={videoState}
                          setVideoState={setVideoState}
                          updateSliderInterval={updateSliderInterval}
                          setUpdateSliderInterval={setUpdateSliderInterval}
                        />
                      </div>
                    </span>
                    {/* ) : ( */}
                    {/* <span className="music-not-found">
                        Audio file not available for this transcript
                      </span> */}
                    {/* )} */}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <NextynAIAssistant
              userData={userData}
              assembly_response_id={recordingData?.assembly_response_id}
            />
            {/* <Card
              title={
                <div>
                  <h5 className="mb-1 txtGredient" style={{ fontSize: "20px" }}>
                    Summary
                  </h5>
                </div>
              }
              className="chat-card transcriptDetails fsfsed"
              style={{
                height: "calc(100vh - 18px)",
                maxHeight: "calc(100vh - 18px)",
              }}
            >
              <div className="recording_summary">
                {recordingData?.assembly_response?.auto_chapters === true ? (
                  <>
                    <div className="smry-para">
                      {recordingData?.assembly_response?.chapters.map(
                        (summrData, id) => {
                          return (
                            <>
                              <Collapse
                                bordered={false}
                                defaultActiveKey={null}
                                style={{ flex: 1 }}
                                key={id}
                                className="collapseBid"
                              >
                                <Collapse.Panel
                                  header={
                                    <p
                                      onClick={() =>
                                        createHighlighted(summrData?.start)
                                      }
                                      className="mb-0 collapse-header fs-12"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {summrData?.headline}{" "}
                                      <small className="mr-4">
                                        ({convertMS(summrData?.start)})
                                      </small>
                                    </p>
                                  }
                                  key="1"
                                >
                                  <ul className="listType">
                                    <li
                                      style={{
                                        boxShadow: "none",
                                        paddingBottom: 0,
                                        fontSize: "12px",
                                        color: "#000000D9",
                                      }}
                                    >
                                      {summrData?.summary}
                                    </li>
                                  </ul>
                                </Collapse.Panel>
                              </Collapse>
                            </>
                          );
                        }
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </Card> */}
          </Col>
        </Row>
      </div>
      {/* New Design end */}
    </>
  );
};

export default TranscriptDetail;
