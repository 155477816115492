import {
  Button,
  Tag,
  Tooltip,
  Popover,
  Divider,
  Modal,
  List,
  Pagination,
} from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CreateProjectModal } from "../../../components/CreateProjectModal";
import { ProjectListModal } from "../../../components/ProjectListModal";
import { PiCoinVerticalDuotone, PiLockSimpleOpenDuotone } from "react-icons/pi";
import Loader from "../../Components/Loader";
import EmptyStatesData from "../../Components/EmptyStates";

import {
  clearChatMessages,
  setSelectedTranscript,
} from "./TranscriptChat.slice";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import { convertToTitleCase, formattedDate } from "../../../Utils/Util";
import { Link } from "react-router-dom";
import TranscriptPopOver from "../../Components/TranscriptPopOver";
import UnlockTranscriptModal from "../../Components/UnlockTranscriptModal";

function LockedTranscript({ functions, data, count, isLoading, isValidating }) {
  const {
    goToDetailsPage,
    spendCredits,
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    fetchTranscriptData,
  } = functions;
  const { projectListById } = useSelector((state) => state.project);
  const { transcriptPagination, isLoadingPagination, searchVal } = useSelector(
    (state) => state.transcript
  );
  const [showAll, setShowAll] = React.useState(false);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const { available_credits } = useSelector((state) => state?.user);
  const toggleShowAll = React.useCallback((id) => {
    setShowAll((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);

  const creditsData = useSelector((state) => state.user.all_credits);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const showModal = React.useCallback((elem) => {
    setSelected(elem);
    setIsModalOpen(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const capitalizeFirstLetter = React.useCallback((string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }, []);

  const handleSpendCredit = React.useCallback(
    async (id, category, curElem) => {
      let res = await spendCredits({
        id,
        category,
        credits_spend: creditsData?.credits_for_transcript,
        curElem,
      });
      return res;
    },
    [creditsData?.credits_for_transcript, spendCredits]
  );
  // const content = React.useCallback(
  //   (curElem) => (
  //     <>
  //       <div className="trans_popup">
  //         <span
  //           className="truncate-text fw-bolder"
  //           style={{ fontSize: "13px" }}
  //         >
  //           <Tooltip title={capitalizeFirstLetter(curElem?.topic)}>
  //             {capitalizeFirstLetter(curElem?.topic)}
  //           </Tooltip>
  //         </span>
  //         <Divider></Divider>
  //         <div className="d-flex justify-content-between align-items-center">
  //           {curElem?.expertData && curElem?.expertData.length > 0 ? (
  //             <small className="fw-normal">
  //               <strong className="fw-bolder">Expert:</strong>{" "}
  //               {`${curElem?.expertData[0]?.current_designation || "N/A"}${
  //                 curElem?.expertData[0]?.current_designation &&
  //                 curElem?.expertData[0]?.previous_company
  //                   ? ", "
  //                   : ""
  //               }`}
  //               {curElem?.expertData[0]?.previous_company ? (
  //                 <>{curElem?.expertData[0].previous_company || "N/A"}</>
  //               ) : (
  //                 ""
  //               )}
  //             </small>
  //           ) : (
  //             <small className="fw-normal">
  //               Designation/Company information unavailable
  //             </small>
  //           )}

  //           {projectListById?.data?.length > 0 ? (
  //             // <ProjectListModal
  //             //     functions={{
  //             //         projectListById,
  //             //         AddExpertProject,
  //             //         getProjectListById,
  //             //         mutateProjectList
  //             //     }}
  //             // />
  //             <button
  //               className="btn viewButton text-end"
  //               style={{
  //                 fontSize: "12px",
  //                 padding: "",
  //               }}
  //               onClick={() => {
  //                 dispatch(setIsOpenProject(true));
  //               }}
  //             >
  //               Request this Expert
  //             </button>
  //           ) : (
  //             <Button
  //               className="btn viewButton text-end"
  //               onClick={() => {
  //                 dispatch(setIsOpenCreatProject(true));
  //                 dispatch(setIsOpenProject(false));
  //                 // closeProjectModal(false)
  //               }}
  //             >
  //               {"Add Project"}
  //             </Button>
  //           )}
  //         </div>
  //         <Divider></Divider>
  //         <strong style={{ fontWeight: "500", fontSize: "12px" }}>
  //           Topics covered in this transcript
  //         </strong>
  //         <ul className="listType mt-2 mb-0">
  //           {curElem?.lemur_questions?.result
  //             ?.slice(0, showAll[curElem?._id] ? undefined : 5)
  //             .map((question, index) => (
  //               <li className="fw-normal mb-2" key={index}>
  //                 {question.replace(/-/g, "")}
  //               </li>
  //             ))}
  //         </ul>

  //         {curElem?.lemur_questions?.result?.length > 5 && (
  //           <Button
  //             className="text-start p-0"
  //             type="link"
  //             block
  //             onClick={() => toggleShowAll(curElem?._id)} // Assuming curElem.id is the correct identifier for each item
  //             style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
  //           >
  //             {showAll[curElem?._id] ? "View Less" : "View More"}
  //           </Button>
  //         )}
  //         <Divider className="mt-1"></Divider>

  //         <div className="d-flex cstmTg">
  //           <div
  //             style={{
  //               flexBasis: "70px",
  //               flexGrow: 0,
  //               flexShrink: 0,
  //             }}
  //           >
  //             {curElem?.keyword?.split(",")?.length > 1 && (
  //               <small className="fw-bolder">Keywords</small>
  //             )}
  //           </div>
  //           <div>
  //             {curElem?.keyword
  //               ?.split(",")
  //               ?.slice(0, 6)
  //               ?.map((tag, index) => (
  //                 <>
  //                   {tag && (
  //                     <Tag className="me-1 mb-1" size="large" key={index}>
  //                       {tag}
  //                     </Tag>
  //                   )}
  //                 </>
  //               ))}

  //             {curElem?.keyword &&
  //               curElem?.keyword?.split(",")?.slice(6)?.length > 0 && (
  //                 <Tooltip
  //                   title={curElem?.keyword?.split(",")?.slice(6)?.join(", ")}
  //                 >
  //                   <Tag className="me-1 mb-1" size="large">
  //                     + {curElem?.keyword?.split(",")?.slice(6)?.length}
  //                   </Tag>
  //                 </Tooltip>
  //                 // </Popover>
  //               )}
  //           </div>
  //         </div>
  //       </div>
  //       <div className="d-flex mt-1 cstmTg">
  //         <div
  //           style={{
  //             flexBasis: "70px",
  //             flexGrow: 0,
  //             flexShrink: 0,
  //           }}
  //         >
  //           {curElem?.meetingsData?.[0]?.company_id?.length > 0 && (
  //             <small className="fw-bolder">Companies</small>
  //           )}
  //         </div>
  //         <div>
  //           {curElem?.meetingsData?.[0]?.company_id
  //             ?.slice(0, 6)
  //             ?.map((comp, index) => (
  //               <>
  //                 {comp && (
  //                   <Link to={`/company?company_id=${comp?._id}`} key={index}>
  //                     <Tag className="me-1 mb-1" size="large" key={comp?._id}>
  //                       {convertToTitleCase(comp?.name)}
  //                     </Tag>
  //                   </Link>
  //                 )}
  //               </>
  //             ))}
  //           {curElem?.meetingsData &&
  //             curElem?.meetingsData?.[0]?.company_id?.slice(6)?.length > 0 && (
  //               <Tooltip
  //                 title={curElem?.meetingsData?.[0]?.company_id
  //                   ?.slice(6)
  //                   ?.map((comp, index) => (
  //                     <span key={index}>{comp?.name}</span>
  //                   ))}
  //               >
  //                 <Tag className="me-1 mb-1" size="large">
  //                   +{curElem?.meetingsData?.[0]?.company_id?.slice(6)?.length}
  //                 </Tag>
  //               </Tooltip>
  //             )}
  //         </div>
  //       </div>
  //     </>
  //   ),
  //   [
  //     capitalizeFirstLetter,
  //     dispatch,
  //     projectListById?.data?.length,
  //     showAll,
  //     toggleShowAll,
  //   ]
  // );

  const dataTrans = React.useMemo(() => EmptyStatesData?.companyTranscript, []);
  if (!dataTrans) return null;

  return (
    <>
      {isLoading || isValidating || isLoadingPagination ? (
        <>
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{ minHeight: "50vh" }}
          >
            <Loader />
          </div>
        </>
      ) : data?.length > 0 && !isLoading && !isValidating ? (
        <div className="">
          <List
            size="small"
            header={
              <Row className="w-100 mt-1">
                <Col lg={10}>
                  <Row md={12}>
                    <Col md={6}>
                      <strong className="fw-bolder">Title</strong>
                    </Col>
                    <Col md={3}>
                      <strong className="fw-bolder">Expert title</strong>
                    </Col>
                    <Col md={3}>
                      <strong className="fw-bolder">Expert company</strong>
                    </Col>
                  </Row>
                </Col>

                <Col lg={2}>
                  <Row md={12}>
                    <Col md={6}>
                      <strong className="fw-bolder">Date</strong>
                    </Col>
                    {/* <Col md={5}>
                    <strong className="fw-bolder">Duration</strong>
                  </Col> */}
                    <Col md={6}>
                      <strong className="fw-bolder">Credits</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            bordered
            // loading={isLoading || isValidating}
            pagination={false}
            dataSource={data}
            renderItem={(curElem, index) => (
              <Col xl={12} key={index}>
                <List.Item className="border-bottom">
                  <Row className="w-100">
                    <Col xl={10} lg={9}>
                      <Row md={12}>
                        <Col md={6}>
                          <Popover
                            placement="left"
                            content={
                              <TranscriptPopOver
                                curElem={curElem}
                                functions={{
                                  AddExpertProject,
                                  getProjectListById,
                                  mutateProjectList,
                                }}
                                dataVariables={{ projectListById }}
                              />
                            }
                          >
                            <Tooltip
                              title={capitalizeFirstLetter(curElem?.topic)}
                            >
                              <p
                                className="fw-bolder truncate-text mb-0"
                                style={{
                                  fontSize: "12px",
                                  cursor: permission?.transcript?.unlock
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                                onClick={() => {
                                  if (permission?.transcript?.unlock) {
                                    dispatch(clearChatMessages());
                                    showModal(curElem);
                                  }
                                }}
                              >
                                {" "}
                                {capitalizeFirstLetter(curElem?.topic)}
                              </p>
                            </Tooltip>
                          </Popover>
                        </Col>
                        <Col md={3}>
                          <Popover
                            placement="right"
                            content={
                              <TranscriptPopOver
                                curElem={curElem}
                                functions={{
                                  AddExpertProject,
                                  getProjectListById,
                                  mutateProjectList,
                                }}
                                dataVariables={{ projectListById }}
                              />
                            }
                          >
                            <small className="fw-normal truncate-text pointer" onClick={() => {
                              if (permission?.experts?.request) {
                                dispatch(setIsOpenProject(true));
                              }
                            }}>
                              {curElem?.expertData?.[0]?.current_designation ||
                                "NA"}
                            </small>
                          </Popover>
                        </Col>
                        <Col md={3}>
                          <Popover
                            placement="right"
                            content={
                              <TranscriptPopOver
                                curElem={curElem}
                                functions={{
                                  AddExpertProject,
                                  getProjectListById,
                                  mutateProjectList,
                                }}
                                dataVariables={{ projectListById }}
                              />
                            }
                          >
                            <small className="fw-normal truncate-text pointer" onClick={() => {
                              if (permission?.experts?.request) {
                                dispatch(setIsOpenProject(true));
                              }
                            }}>
                              {curElem?.expertData?.[0]?.previous_company ||
                                "NA"}
                            </small>
                          </Popover>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={2} lg={3}>
                      {/* <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Popover placement="left" content={content(curElem)}>
                          <small className="fw-normal">
                            {formatDate(curElem?.start_time)}
                          </small>
                        </Popover>
                      </div>
                      <div>
                        <Tag
                          bordered={false}
                          className="tagContainer view"
                          onClick={() => {
                            goToDetailsPage(curElem);
                            dispatch(clearChatMessages());
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <PiLockSimpleOpenDuotone className="me-1" />
                            View
                          </span>
                        </Tag>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Popover placement="left" content={content(curElem)}>
                          <small className="fw-normal">
                            {formatDate(curElem?.start_time)}
                          </small>
                        </Popover>
                      </div>
                      <div>
                        <Tag
                          bordered={false}
                          className="tagContainer view"
                          onClick={() => {
                            goToDetailsPage(curElem);
                            dispatch(clearChatMessages());
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <PiLockSimpleOpenDuotone className="me-1" />
                            View
                          </span>
                        </Tag>
                      </div>
                    </div> */}
                      <Row md={12}>
                        <Col md={6}>
                          {/* <Popover placement="left" content={content(curElem)}> */}
                          <small className="fw-normal">
                            {formattedDate(curElem?.start_time)}
                          </small>
                          {/* </Popover> */}
                        </Col>
                        {/* <Col md={5}>
                        <Popover placement="right" content={content(curElem)}>
                          <small className="fw-normal">
                            {formatDuration(curElem?.duration)}
                          </small>
                        </Popover>
                      </Col> */}

                        <Col
                          className="d-flex justify-content-start align-items-center"
                          lg={6}
                          onMouseEnter={() => setHoveredItemId(index)}
                          onMouseLeave={() => setHoveredItemId(null)}
                        >
                          <>
                            {hoveredItemId === index &&
                              permission?.transcript?.unlock ? (
                              <Tag
                                bordered={false}
                                className="tagContainer unlock"
                                onClick={() => {
                                  dispatch(clearChatMessages());
                                  showModal(curElem);
                                }}
                              >
                                Unlock
                              </Tag>
                            ) : (
                              // Show credit tag when not hovering
                              <Tag
                                bordered={false}
                                className="tagContainer"
                                style={{
                                  borderColor: "rgb(76, 187, 23)",
                                  color: "rgb(76, 187, 23)",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <PiCoinVerticalDuotone
                                    style={{ marginRight: "4px" }}
                                  />
                                  {creditsData?.credits_for_transcript}
                                </span>
                              </Tag>
                            )}
                          </>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </List.Item>
              </Col>
            )}
            style={{ background: "#ffff" }}
          ></List>
          <Pagination
            className="text-end mt-2"
            size="small"
            total={count}
            current={transcriptPagination?.page}
            pageSize={transcriptPagination?.limit}
            onChange={(page, pageSize) => {
              fetchTranscriptData({
                paginate: true,
                page: page,
                limit: pageSize,
                key: searchVal,
              });
            }}
            showSizeChanger
          />

          <UnlockTranscriptModal
            handleCancel={handleCancel}
            isModalOpen={isModalOpen}
            unlockFunction={async () => {
              let response = await handleSpendCredit(
                selected?._id,
                "transcript",
                selected
              );
              if (response) {
                goToDetailsPage(selected);
                dispatch(setSelectedTranscript(selected));
              }
              setIsModalOpen(false);
              return response
            }}
          />

          <ProjectListModal
            functions={{
              projectListById,
              AddExpertProject,
              getProjectListById,
              mutateProjectList,
            }}
          />
          <CreateProjectModal
            tp={"render"}
            buttonText="Add Project"
            mutateProjectList={mutateProjectList}
          />
        </div>
      ) : (
        <>
          <Row>
            <Col md={12}>
              <div
                className="empty-state-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <div
                  className="image-container"
                  style={{ flex: 1, textAlign: "center" }}
                >
                  <img
                    src={dataTrans?.image}
                    alt={dataTrans?.feature}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
                <div
                  className="content-container"
                  style={{ flex: 1, textAlign: "left" }}
                >
                  <p
                    className="p-0 mt-0 pt-0 mb-3"
                    style={{ lineHeight: "28px", fontWeight: "300" }}
                  >
                    {dataTrans?.content}
                  </p>
                  <button
                    className="btn viewButton findme-btn"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      dispatch(setIsOpenCreatProject(true));
                      dispatch(setIsOpenProject(false));
                      // closeProjectModal(false)
                    }}
                  >
                    {dataTrans?.cta}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default LockedTranscript;
