import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import News from "./News";
import "../Dashboard/Dashboard.css";
import "../../../App.css";
import Loader from "../../Components/Loader";

const NewsPage = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <>
      {/* <MobileMenu /> */}
      <Helmet>
        <title>{"Nextyn IQ - Dashboard"}</title>
      </Helmet>
      <div id="main-screen">
        {open ? (
          <News />
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};

export default NewsPage;
