import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
    available_credits: 0,
    all_credits: []
}

// slice which contain reducers ,its initial state and all
const slice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setAvailableCredits: (state, { payload }) => {
            state.available_credits = payload;
        },
        setAllCredits: (state, { payload }) => {
            state.all_credits = payload;
        },
    }
})

const userReducer = slice.reducer
export const { setAvailableCredits, setAllCredits } = slice.actions
export {
    userReducer
}