import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpiredPage from "../components/ExpiredPage";
import API_PATH from "../Constants/api-path";
import ResetPasswordForm from "./ResetPasswordForm";
import nextyn_logo from "../assests/images/Nextyn_logo.png";
import Nextyn_IQ_Black from "../assests/images/Nextyn_IQ_Black.png";
import sliderImg1 from "../assests/images/cmpny.png";
import sliderImg2 from "../assests/images/transcript.png";
import sliderImg3 from "../assests/images/en.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function ResetPasssword() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });
  const [isExpired, setExpired] = useState(false);
  const [isLinkUsed, setIsLinkUsed] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    getExpiredTime();
    if (isExpired) {
      history("/expire");
    }
  }, [isExpired]);

  const getExpiredTime = async () => {
    try {
      let d = new Date();
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const res = await fetch(`${API_PATH.EXPIRE_TIME}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: atob(params.email),
        }),
      });

      const result = await res.json();
      console.log("result", result);
      if (result.data) {
        let expiredDate = result.data;
        console.log(result, "result");
        if (expiredDate < d.getTime()) {
          setExpired(true);
        }
      } else if (result.data === 0) {
        setExpired(true);
      }
    } catch (error) {
      console.log(error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  document.title = "Nextyn IQ - ResetPassword";
  return (
    <>
      {isExpired ? (
        <ExpiredPage />
      ) : (
        <div className="login-page">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2">
              <div className="col d-none d-md-block">
                <div className="login-left-part">
                  <NavLink to="/" className="logo">
                    <img src={nextyn_logo} alt="logo" />
                  </NavLink>
                  <div className="logo-in-div col-lg-8 mx-auto">
                    <h1
                      className="text-left text-white loginText"
                      data-aos="fade-down"
                    >
                      Consulting experts is <br /> as easy as a click of a
                      button.
                    </h1>
                  </div>

                  <div className="col-lg-8 mx-auto">
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={1}
                      className="authenticationSlider"
                      loop={true}
                      scrollbar={true}
                      pagination={{
                        clickable: true,
                      }}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      modules={[Pagination, Autoplay]}
                    >
                      <SwiperSlide>
                        <img src={sliderImg1} />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={sliderImg2} />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={sliderImg3} />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>

              <div className="col position-relative">
                <div className="comon-fild col-lg-8 login-page-div">
                  <div className="inside-login">
                    <NavLink to="/" className="logo d-block d-lg-none mb-5">
                      <img src={Nextyn_IQ_Black} alt="logo" />
                    </NavLink>
                    <h4> Reset Password? 🔒 </h4>
                    <p className="mb-2" style={{ color: "#5746ec" }}>
                      Your new password must be different from previously used
                      passwords
                    </p>
                    <ResetPasswordForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ResetPasssword;
