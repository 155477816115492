import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPostDate } from '../SearchPage/SearchPage.slice';

function SearchFilters({ fetchTranscriptData, activeTab, fetchNewsData }) {
    const { selectedPostDate } = useSelector((state) => state.SearchPage)
    const dispatch = useDispatch()

    const posted = [
        {
            label: "Past 15 days",
            key: "15",
        },
        {
            label: "Past 30 days",
            key: "30",
        },

        {
            label: "Past 60 days",
            key: "60",
        },

        {
            label: "Past 90 days",
            key: "90",
        },

        {
            label: "Past year",
            key: "1_year",
        },
        {
            label: "All time",
            key: "all",
        },
    ];

    return (
        <div className='d-flex justify-content-flex-start'>
            {/* <div>
                <Dropdown
                    key={"1"}
                    menu={{
                        items, selectedKeys: selectedCompanyType, onClick: (e) => {
                            dispatch(setSelectedCompanyType(e.key))
                            if (activeTab === "2") {
                                fetchTranscriptData({ selectedType: e.key })
                            } else if (activeTab === '1') {
                                fetchNewsData({ selectedType: e.key })
                            }
                        }
                    }}

                    className="common-filter-dropdown me-2"
                    trigger={["click"]}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {selectedCompanyType ?? "Company Types"}
                            <CaretDownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            </div> */}
            <div>
                <Dropdown
                    key={
                        "2"
                    }
                    menu={{
                        items: posted,
                        selectedKeys: selectedPostDate,
                        onClick: (e) => {
                            dispatch(setSelectedPostDate(e.key))

                            if (activeTab === "transcript") {
                                fetchTranscriptData({ selectedDate: e.key, page_no: 1, page_limit: 20 })
                            } else if (activeTab === 'news') {
                                fetchNewsData({ selectedDate: e.key, page_no: 1, page_limit: 20 })
                            }
                        }
                    }}
                    className="common-filter-dropdown me-2"
                    trigger={["click"]}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {`Posted: ${posted?.filter((obj) => obj.key === selectedPostDate)?.[0]?.label}`}
                            <CaretDownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    )


}

export default SearchFilters