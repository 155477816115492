import moment from "moment";
import React, { useState } from "react";
import {
  Dropdown,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { NavLink, useNavigate, createSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import recording_and_transcripts from "../../../assests/images/Call_recordings.png";
import userColored from "../../../assests/images/userColored.svg";
import API_PATH from "../../../Constants/api-path";
import { Calendar, Timer } from "../../../Utils/Icons";
import { useDispatch } from "react-redux";
import { FiDownload } from "react-icons/fi";
import { BsStars } from "react-icons/bs";
import { Button, Input, Pagination } from "antd";
import Loader from "../../Components/Loader";
import _ from "lodash";

import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import { CreateProjectModal } from "../../../components/CreateProjectModal";
import useProject from "../../Hooks/useProject";
import { Col, Row } from "react-bootstrap";
import EmptyStatesData from "../../Components/EmptyStates";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import ConditionalRender from "../../../Utils/ConditionalRender";
import { convertToHtmlTranscript } from "../../../Utils/Util";
import jsPDF from "jspdf";
import nextyn_logo_old from "../../../assests/images/Nextyn_logo_old.png";
import { clearChatMessages } from "../Transcript/TranscriptChat.slice";
import axios from "axios";

const { Search } = Input;
function Transcript({ recordingList, spinner, getRecordings, setPagination, pagination }) {
  const [expanded, setExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [userEmail, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const history = useNavigate();
  const [workData] = useState({});
  const dispatch = useDispatch();

  const { mutateProjectList } = useProject();

  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
    _id: "",
  });

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "---------";
    }
  };

  function getTimeDifference(start, end) {
    if (start && end) {
      var timeDiff = moment(end).diff(moment(start));
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);

      var total = hh + ":" + mm + ":" + ss;
      return total;
    }
  }

  const makeUpdate = async () => {
    try {
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${workData._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({
          permission: payload.permission,
          email: payload.email,
        }),
      });

      const result = await res.json();
      if (result) {
        setEmail("");
        // getNotes();
        window.location.reload();
        return toast.success("User added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleInputChange = React.useMemo(
    () =>
      _.debounce((e) => {
        // if (e?.target?.value?.trim()) {
        setPagination({
          ...pagination,
          page: 1
        })
        getRecordings({ searchString: e?.target?.value, currentPage: 1, isSearching: true });
        // }
      }, 1000),
    [getRecordings, pagination, setPagination]
  );

  const updatePermission = async (permission, email) => {
    try {
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${workData._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({ permission: permission, email: email }),
      });

      const result = await res.json();
      if (result) {
        document.getElementById("DeleteModal1").click();
        // window.history.go(-1);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const goToDetailsPage = (meetingId) => {
    dispatch(clearChatMessages())
    history({
      pathname: "/call-recording/transcript",
      search: `?${createSearchParams({ meetingId })}`,
    });
  };

  const deleteTranscript = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${API_PATH.MEETING_DELETE}/${workData._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
      });

      const result = await res.json();
      if (result) {
        document.getElementById("DeleteModal1").click();
        // setPayload(payload.id)
        // window.history.go(-1);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getPUserList = () => {
    if (workData.permittedUsers instanceof Array) {
      return workData.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                      alt={email}
                    />
                  </div>
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  const stopSharing = async (e) => {
    e.preventDefault();

    try {
      let payload = {};
      payload.permittedUsers = [];
      const res = await fetch(`${API_PATH.MEETING_ID_UPDATE}/${workData._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        // document.getElementById("DeleteModal1").click();
        // window.location.reload();
        return toast.success("User removed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("errrrrrr", error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const downloadAudio = (audioUrl) => {
    const link = document.createElement("a");
    link.href = audioUrl;
    link.download = "downloaded_audio.mp3";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function replaceTxt(txt, para) {
    txt = txt?.toUpperCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }

  const getFolderTable = () => {
    // if (recordingList instanceof Array) {
    return recordingList?.data?.map(function (object, i) {
      const {
        _id,
        transcript,
        recording_start,
        topic,
        permittedUsers,
        recording_end,
        chapters,
        s3_download_url,
        meetingId,
        expert_company,
        expert_designation
      } = object;
      return (
        <>
          <div className="transcriptNew my-2 p-2">
            <div className="cursor-pointer">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p
                    style={{
                      color: "#5746ec",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      goToDetailsPage(_id);
                    }}
                    className="fw-bolder m-0 p-0"
                  >
                    {replaceTxt(topic, [
                      "NEXTYN",
                      "MICRO",
                      "CONSULTING",
                      "-CONSULTING",
                      "CALL - ",
                      // "CALL",
                      // "-",
                    ])}{" "}

                  </p>
                  <div>
                    {(expert_company || expert_designation) &&
                      <span className="text-muted fw-normal">
                        {expert_company?.split(",")?.map((comp, index) => {
                          return <>
                            <span>{expert_designation?.split(",")?.[index]}, {comp}
                              {expert_company?.split(",")?.length - 1 !== index && <span> | </span>}
                            </span>
                          </>
                        })}
                      </span>
                    }
                  </div>

                </div>

                <div>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <span className="d-flex align-items-center me-4">
                      <Calendar />
                      <span className="ms-1">
                        {convertDate(recording_start)}
                      </span>
                    </span>
                    <span className="d-flex align-items-center">
                      <Timer />
                      <span className="ms-1">
                        {getTimeDifference(recording_start, recording_end)}
                      </span>
                    </span>
                  </div>
                  <div className="text-end" style={{
                    fontSize: "12px",
                  }}>
                    #{meetingId}
                  </div>
                </div>

              </div>

              <div className="my-3 tras_smmry">
                <div className="d-flex flex-direction-row justify-content-between">
                  <span
                    className="fw-bolder"
                    aria-hidden="true"
                    style={{ fontSize: "16px", marginBottom: "10px" }}
                  >
                    <BsStars style={{ fontSize: "14px" }} /> AI Summary
                  </span>
                </div>

                <ul className="listType mb-0">
                  {chapters
                    ?.map((question, index) => (
                      <li className="mb-2" key={index}>
                        {question?.summary}
                      </li>
                    ))}
                  {/* {expanded && selectedIndex === i && (
                    <>
                      {chapters.map((question, index) => (
                        <li className="mb-2" key={index}>
                          {question?.summary}
                        </li>
                      ))}
                    </>
                  )} */}
                </ul>
                {/* {chapters ? (
                  <>
                    <Button
                      className="text-start p-0"
                      type="link"
                      block
                      onClick={() => {
                        //  handleViewMoreClick(i)
                        if (expanded && selectedIndex === i) {
                          setExpanded(false);
                          setSelectedIndex(i);
                        } else {
                          setExpanded(true);
                          setSelectedIndex(i);
                        }
                      }}
                      style={{ fontSize: "12px", color: "#5746ec" }}
                    >
                      {expanded && selectedIndex === i
                        ? "View less"
                        : "View more..."}
                    </Button>
                  </>
                ) : null} */}
              </div>

              <div
                className="d-flex align-items-center"
                style={{
                  fontSize: "12px",
                }}
              >
                <span className="d-flex align-items-center me-2">
                  <button
                    className="btn viewButton"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      goToDetailsPage(_id);

                    }}
                  >
                    View Transcript
                  </button>
                </span>
                {/* <span className="d-flex align-items-center ms-3" style={{ color: "#5746ec" }}>
                    <HiMiniArrowRightOnRectangle /><span className="ms-1">Export.docx</span>
                  </span> */}
                <span
                  className="d-flex align-items-center ms-3 link-btn"
                  onClick={() => downloadAudio(s3_download_url)}
                >
                  <FiDownload className="" />
                  <span className="ms-1" >Audio</span>
                </span>
                <span
                  className="d-flex align-items-center ms-3 link-btn"
                  // style={{ color: "#5746ec", cursor: "pointer" }}
                  onClick={() => {
                    setIndex(i);
                    downloadTranscript(object);
                  }}
                >
                  {loading && index === i ? (
                    <LoadingOutlined />
                  ) : (
                    <FiDownload />
                  )}
                  <span className="ms-1">Transcript</span>
                </span>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center">
                <IoMdShar2
                  style={{ width: "20px", cursor: "pointer" }}
                  href="#"
                  className="actionNav me-4"
                  data-bs-toggle="modal"
                  data-bs-target="#shareModal2"
                  onClick={() => setworkData(object)}
                />
                <GrFormView
                  className="me-4"
                  style={{ width: "20px", cursor: "pointer" }}
                  onClick={() => goToDetailsPage(_id)}
                />
                <MdDelete
                  style={{ width: "20px", cursor: "pointer" }}
                  href="#"
                  className="actionNav me-4"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteModal1"
                  onClick={() => setworkData(object)}
                />
              </div> */}

            {/* <Dropdown>
                <Dropdown.Toggle className="moreOptions" id="dropdown-basic">
                  <MoreHorizontal className="moreIcon" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    className="actionNav me-4"
                    data-bs-toggle="modal"
                    data-bs-target="#shareModal2"
                    onClick={() => setworkData(object)}
                  >
                    Share
                  </Dropdown.Item>
                  <Dropdown.Item
                    // href="#/action-2"
                    onClick={() => goToDetailsPage(_id)}
                  >
                    View
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="actionNav me-4"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal1"
                    onClick={() => setworkData(object)}
                  >
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
          </div>

          <NavLink
            to={`/collaborate/transcript?meetingId=${_id}`}
            style={{ display: "none" }}
          >
            <div className="col folderSection mb-4">
              <div className="comon-notes">
                <div className="d-flex justify-content-between align-items-center">
                  <figure className="m-0">
                    <img src="images/folder-icon.png" alt="folder" />
                  </figure>
                  <div className="auto-sec ms-2">
                    <p
                      className="m-0"
                      style={{ fontSize: "12px", fontWeight: 500 }}
                    >
                      {" "}
                      {/* Create By{" "}
                        <span className="createdByName">
                          edededed
                        </span> */}
                    </p>
                  </div>
                </div>
                <h6 className="note-titel mt-4">
                  {/* {getName(s3_download_url)} */}
                  {topic}
                </h6>
                <p className="paragraph">
                  {" "}
                  <p
                    className="text-wrap-afte-three-line"
                  //   dangerouslySetInnerHTML={{ __html: description }}
                  >
                    {transcript}{" "}
                  </p>
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mt-3 mb-0">
                    {convertDate(recording_start)}
                  </h6>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned`}>
                        <Popover.Body className="p-2">
                          {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                              Public link enabled
                            </h6> */}
                          <div>
                            <small>
                              {" "}
                              Shared with {permittedUsers?.length} people
                            </small>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <img
                      style={{ position: "relative", top: "11px" }}
                      src={userColored}
                      alt="img"
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </NavLink>
        </>
      );
    });
    // }
  };

  const dataTrans = React.useMemo(
    () => EmptyStatesData?.callRecordingsData,
    []
  );
  if (!dataTrans) return null;

  const splitContent = dataTrans?.content
    ?.split(". ")
    .map((item, index, array) =>
      index === array?.length - 1 ? (
        item
      ) : (
        <>
          {item}.<br />
        </>
      )
    );

  function assignImage(image) {
    var img = new Image();
    img.src = image;
    return img;
  }
  const getTranscriptData = async (id) => {
    try {
      let response = await axios.get(`${API_PATH.GET_ASSEMBLY_RESPONSE}/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        }
      })
      return response?.data?.data
    } catch (error) {
      console.error('error: ', error);

    }
  }
  const downloadTranscript = async (transcriptData) => {
    setLoading(true);

    let assemblyResponceData = await getTranscriptData(transcriptData?.meetingId)
    let data = { ...transcriptData, assembly_response: assemblyResponceData?.assembly_response }
    let content = convertToHtmlTranscript(data);
    content = content.replaceAll("</div>,", "</div>");
    content = content.replaceAll(" ,", ",");
    content = content.replaceAll(" .", ".");
    try {
      // const element = document.createElement("div");
      // element.classList.add("downloadPfg");
      // element.innerHTML = content;
      // document.body.appendChild(element);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
        compress: true,
        width: 775,
      });

      pdf.setFontSize(12);
      pdf.html(content, {
        async callback(pdf) {
          addFooters();
          await pdf.save(
            `Nextyn-Transcript of - ${replaceTxt(transcriptData?.topic, [
              "NEXTYN",
              "MICRO",
              "CONSULTING",
              "-CONSULTING",
              "CALL - ",
              // "CALL",
              // "-",
            ])}`
          );
          setLoading(false);
        },
        x: 10,
        y: 10,
        margin: [10, 10, 80, 10],
        width: 800,
        windowWidth: 1300,
        pageSplit: true,
        autoPaging: "text",
      });

      function addFooters() {
        const pageCount = pdf.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
          pdf?.setPage(i);
          pdf?.addImage(assignImage(nextyn_logo_old), "png", 486, 760, 100, 0);
          pdf?.rect(13, 800, 570, 0.5, "F");

          pdf?.setFontSize(7);
          pdf?.setTextColor(196, 10, 54);
          pdf?.text(
            `Consulting call transcript generated for ${userData?.user?.name}(${userData?.user?.email
            }) of ${userData?.user?.company_id?.companyName} on ${convertDate(
              new Date()
            )}`,
            300,
            815,
            "center"
          );

          pdf?.setFontSize(7);
          pdf?.setTextColor(196, 10, 54);
          pdf?.text(
            `This transcript is for internal use by ${userData?.user?.company_id?.companyName} only. It contains confidential information and should not be publicly released or circulated.`,
            300,
            825,
            "center"
          );
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column top-ps-seection justify-content-between ">
          <h5 style={{ fontWeight: 600 }}>
            <span className="text-dark">Call Recordings</span>
          </h5>
          <h6
            className="cust_header"
            style={{ fontWeight: "400", fontSize: 12 }}
          >
            <span className="text-muted fw-normal">
              Access call recordings and transcripts of internal calls conducted
              by your team{" "}
            </span>
          </h6>
        </div>

        <ConditionalRender condition={recordingList?.data?.length !== 0}>
          <Input
            placeholder="Search your call recordings, and transcripts"
            size="large"
            className="search-input w-50"
            autoFocus={false}
            autoCorrect={false}
            type="text"
            autoComplete="input-value"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              handleInputChange(e);
            }}
            prefix={<SearchOutlined />}
          />
        </ConditionalRender>
      </div>
      <ConditionalRender condition={spinner}>
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: "100vh" }}
        >
          <Loader />
        </div>
      </ConditionalRender>

      <ConditionalRender condition={recordingList?.data?.length !== 0 && !spinner}>
        <div className="notes-d-section">{getFolderTable()}</div>

      </ConditionalRender>
      <ConditionalRender condition={recordingList?.countData > recordingList?.data?.length}>
        <Pagination
          className="text-end mt-2"
          size="small"
          total={recordingList?.countData}
          current={pagination?.page}
          // pageSize={pagination?.size}
          onChange={(page, pageSize) => {
            getRecordings({ currentPage: page, limit: pageSize })
            setPagination({
              page: page,
              size: pageSize
            })
          }}
          showSizeChanger={false}
        />

      </ConditionalRender>

      <ConditionalRender condition={recordingList?.data?.length === 0 && !spinner}>
        <Row>
          <Col md={12}>
            <div
              className="empty-state-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div
                className="image-container"
                style={{ flex: 1, textAlign: "center" }}
              >
                <img
                  src={recording_and_transcripts}
                  alt="recording_and_transcript"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
              <div
                className="content-container"
                style={{ flex: 1, textAlign: "left" }}
              >
                <p
                  className="p-0 mt-0 pt-0 mb-3"
                  style={{ lineHeight: "28px", fontWeight: "300" }}
                >
                  {splitContent}
                </p>
                <button
                  className="btn viewButton"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    dispatch(setIsOpenCreatProject(true));
                    dispatch(setIsOpenProject(false));
                    // closeProjectModal(false)
                  }}
                >
                  {dataTrans?.cta}
                </button>
              </div>
            </div>
          </Col>
        </Row>{" "}
      </ConditionalRender>

      <div
        className="modal fade addmodal cm-modal-all"
        id="shareModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "10px" }}
            >
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90" />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90"
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div> */}

                <div className="mt-4 inviteSection text-left">
                  <div className="inviteTitle text-start">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      value={userEmail}
                      name="email"
                      className="inputGroupInput"
                      onChange={(event) => {
                        handleRequestInputs(event);
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#000",
                          }}
                        >
                          {getDefaultText(payload.permission)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() =>
                              updatePermission("editi", payload.email)
                            }
                          >
                            Can invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() =>
                              updatePermission("view", payload.email)
                            }
                          >
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {show ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ border: "none" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                    // onClick={() => {
                    //   setEmail("");
                    // }}
                    >
                      Cancel
                    </button>
                    {/* <button
                      type="button"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#5746ec", border: "none" }}
                      onClick={() =>
                        updatePermission(payload.permission, payload.email)
                      }
                    >
                      Submit
                    </button> */}

                    {workData.permittedUsers.filter(
                      (o) => o.email === userEmail.trim()
                    ).length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        disabled
                      // onClick={() =>makeUpdate()}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        // onClick={() =>updatePermission(
                        //   payload.permission,
                        //   payload.email
                        // )}
                        onClick={() => makeUpdate()}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">{getPUserList()}</div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={stopSharing}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <div
        className="modal fade"
        id="DeleteModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "12px", marginBottom: "-1rem" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-secondary"
                style={{ border: "none" }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={deleteTranscript}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
      />
    </>
  );
}
export default Transcript;
