import React, { useState } from "react";
import { Card, Button, Drawer, Tag, Popover } from "antd";
import { Row, Col } from "react-bootstrap";

// import "antd/dist/antd.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Transcript.css";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import useTranscriptChat from "../../Hooks/useTranscriptChat";
import useTranscript from "../../Hooks/useTranscript";
import ReactPlayerCustom from "./CustomPlayer/ReactPlayerCustom";
import { CreateProjectModal } from "../../../components/CreateProjectModal";
import { ProjectListModal } from "../../../components/ProjectListModal";
import { Link, useNavigate } from "react-router-dom";
import useProject from "../../Hooks/useProject";
import { convertToTitleCase, formattedDate } from "../../../Utils/Util";
import "./Transcript.css";
import { IoMdInformationCircle } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import Loader from "../../Components/Loader";
import NextynAIAssistant from "../../Components/NextynAIAssistant";

const Transcript = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    isLoading: isLoadingProjects,
  } = useProject();
  const { isLoadingTransData, isValidatingTransData } = useTranscript();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const { selectedTranscriptData, sumryList, sumryListLoading } = useSelector(
    (state) => state?.transcript
  );
  const { projectListById } = useSelector((state) => state.project);

  const onClose = () => {
    setDrawerVisible(false);
  };

  function replaceTxt(txt, para) {
    txt = txt?.toUpperCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }

  const contentTags = (data) => (
    <>
      <div className="trans_popup cstmTg">
        {data?.meetingsData?.[0]?.company_id?.slice(3)?.map((brand) => (
          <>
            {brand && (
              <Link to={`/company?company_id=${brand?._id}`}>
                <Tag
                  className="me-1 mb-1"
                  size="large"
                  key={brand?.name?.trim()}
                >
                  {convertToTitleCase(brand?.name?.trim())}
                </Tag>
              </Link>
            )}
          </>
        ))}
      </div>
    </>
  );

  const contentDisclaimer = () => (
    <>
      <div className="trans_popup">
        <p className="fw-normal mb-0">
          <strong>Disclaimer: </strong>
          This transcript contains the opinions and insights of the expert,
          expressed during the primary interview conducted. While we have
          transcribed the recording with care, we do not warrant the accuracy or
          completeness of the information provided. These are solely the
          expert's views and should not be taken as definitive or factual.
        </p>
      </div>
    </>
  );

  const contentTagsKeyword = (data) => (
    <>
      <div className="trans_popup cstmTg">
        {data?.keyword
          ?.split(",")
          ?.slice(3)
          ?.map((brand) => (
            <>
              {brand && (
                <Tag className="me-1 mb-1" size="large" key={brand}>
                  {brand}
                </Tag>
              )}
            </>
          ))}
      </div>
    </>
  );

  const handleIconClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Transcript"}</title>
      </Helmet>
      {/* <div className="content-wrapper" id="main-screen"> */}
      <div
        className="content-wrapper bg-light"
        id="main-screen"
        style={{ paddingTop: 10 }}
      >
        {/* <Transcript /> */}
        {(isLoadingTransData || isValidatingTransData) && (
          <div
            md={12}
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <Loader />
          </div>
        )}
        {!isLoadingTransData && !isValidatingTransData && (
          <Row className="app-container g-2">
            <Col md={8} className="transcript_wrap">
              <Card
                title={
                  <div className="trans_popup ">
                    <div className="jf_ds d-flex align-items-center w-100">
                      <div className="fxdLeft">
                        <span
                          className="bckIcon bckIcon_trans"
                          style={{
                            cursor: "pointer",
                            fontSize: "14px",
                            display: "inline-block",
                          }}
                          onClick={handleIconClick}
                        >
                          <IoIosArrowBack className="me-1 search-btn-back" />
                        </span>
                        <div className="title text-muted">Title</div>
                      </div>
                      <div className="middleTitle">
                        <div className="title-data">
                          {replaceTxt(selectedTranscriptData?.topic, [
                            "NEXTYN",
                            "MICRO",
                            "CONSULTING",
                            "-CONSULTING",
                            "CALL - ",
                            "CALL",
                            "-",
                          ])}
                          <Popover
                            style={{ cursor: "pointer" }}
                            placement="bottom"
                            content={contentDisclaimer}
                            trigger="click"
                            className="ms-2"
                          >
                            <span className="ms-2 mb-1">
                              <IoMdInformationCircle
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </Popover>
                        </div>
                      </div>

                      <div className="fxdRght text-end">
                        {/* <span className="fw-normal fs-7 mb-0 me-3">
                        {selectedTranscriptData?.duration &&
                          formatDuration(selectedTranscriptData?.duration)}
                      </span> */}
                        <span className="fw-normal fs-7 mb-0">
                          {selectedTranscriptData?.start_time &&
                            formattedDate(selectedTranscriptData?.start_time)}
                        </span>
                      </div>
                    </div>
                    <div className="jf_ds d-flex align-items-center">
                      <div className="fxdLeft">
                        <div className="title text-muted">Expert</div>
                      </div>
                      <div className="middleTitle">
                        {selectedTranscriptData?.expertData &&
                        selectedTranscriptData?.expertData.length > 0 ? (
                          <div
                            style={{
                              flex: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <small className="fw-normal">
                              {`${
                                selectedTranscriptData?.expertData[0]
                                  ?.current_designation || "N/A"
                              }${
                                selectedTranscriptData?.expertData[0]
                                  ?.current_designation &&
                                selectedTranscriptData?.expertData[0]
                                  ?.previous_company
                                  ? ", "
                                  : ""
                              }`}
                              {selectedTranscriptData?.expertData[0]
                                ?.previous_company ? (
                                <>
                                  {selectedTranscriptData?.expertData[0]
                                    .previous_company || "N/A"}
                                </>
                              ) : (
                                ""
                              )}
                            </small>
                          </div>
                        ) : (
                          <div
                            style={{
                              flex: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <small className="fw-normal">
                              Designation/Company information unavailable
                            </small>
                          </div>
                        )}
                      </div>
                      <div className="fxdRght text-end">
                        <div className="d-flex justify-content-end align-items-center">
                          {projectListById?.data?.length > 0 ? (
                            <button
                              className="btn viewButton text-end"
                              style={{
                                fontSize: "12px",
                                padding: "",
                              }}
                              onClick={() => {
                                if (permission?.experts?.request) {
                                  dispatch(setIsOpenProject(true));
                                }
                              }}
                            >
                              Request this Expert
                            </button>
                          ) : (
                            <Button
                              className="btn viewButton text-end"
                              onClick={() => {
                                if (permission?.experts?.request) {
                                  dispatch(setIsOpenProject(true));
                                  dispatch(setIsOpenCreatProject(true));
                                }
                              }}
                            >
                              {"Request this Expert"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    {selectedTranscriptData?.keyword?.split(",")?.length >
                      1 && (
                      <div className="jf_ds d-flex align-items-center">
                        <div className="fxdLeft">
                          <div className="title text-muted">Keywords</div>
                        </div>
                        <div className="middleTitle">
                          <div
                            className="title-data cstmTg fw-normal"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                            }}
                          >
                            {selectedTranscriptData?.keyword
                              ?.split(",")
                              ?.slice(0, 3)
                              ?.map((tag, index) => (
                                <React.Fragment key={index}>
                                  {tag && (
                                    <Tag
                                      className="me-1 mb-1 fs-7"
                                      size="large"
                                    >
                                      {tag.trim()}
                                    </Tag>
                                  )}
                                </React.Fragment>
                              ))}

                            {selectedTranscriptData?.keyword &&
                              selectedTranscriptData?.keyword?.split(",")
                                ?.length > 3 && (
                                <Popover
                                  placement="left"
                                  className="cstmTg"
                                  content={contentTagsKeyword(
                                    selectedTranscriptData
                                  )}
                                  trigger="hover"
                                >
                                  <Tag className="me-1 mb-1 fs-7" size="large">
                                    +
                                    {selectedTranscriptData?.keyword?.split(",")
                                      ?.length - 3}{" "}
                                    more
                                  </Tag>
                                </Popover>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedTranscriptData?.meetingsData?.[0]?.company_id
                      ?.length > 0 && (
                      <div className="jf_ds d-flex">
                        <div className="fxdLeft">
                          <div className="title text-muted">Companies</div>
                        </div>
                        <div className="middleTitle">
                          <div
                            className="title-data fw-normal cstmTg"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {selectedTranscriptData?.meetingsData?.[0]?.company_id
                              ?.slice(0, 3)
                              ?.map((comp) => (
                                <React.Fragment key={comp?._id?.trim()}>
                                  {comp && (
                                    <Link
                                      to={`/company?company_id=${comp?._id}`}
                                    >
                                      <Tag
                                        className="me-1 mb-1 fs-7"
                                        size="large"
                                      >
                                        {convertToTitleCase(comp?.name?.trim())}
                                      </Tag>
                                    </Link>
                                  )}
                                </React.Fragment>
                              ))}

                            {selectedTranscriptData?.meetingsData?.[0]
                              ?.company_id &&
                              selectedTranscriptData?.meetingsData?.[0]
                                ?.company_id?.length > 3 && (
                                <Popover
                                  placement="left"
                                  content={contentTags(selectedTranscriptData)}
                                  trigger="hover"
                                  style={{ width: "100px" }}
                                >
                                  <Tag className="me-1 mb-1 fs-7" size="large">
                                    +
                                    {selectedTranscriptData?.meetingsData?.[0]
                                      ?.company_id?.length - 3}
                                  </Tag>
                                </Popover>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
                className="time-clock trans-disc p-0"
                bodyStyle={{ paddingRight: 0 }}
              >
                <div className="d-flex">
                  <div className="w-100">
                    <div className="summary-container mt-3">
                      <Row>
                        <Col lg={12}>
                          <h5
                            className="mb-2 txtGredient"
                            style={{ fontSize: "20px" }}
                          >
                            Transcript Discussion
                          </h5>
                          <p
                            className="modified_trans childParad"
                            dangerouslySetInnerHTML={{
                              __html:
                                selectedTranscriptData?.modified_transcript,
                            }}
                          ></p>
                        </Col>
                      </Row>
                    </div>
                    <div className="music-player">
                      {selectedTranscriptData?.transcript_audio?.fullAudio ? (
                        <span className="music-span">
                          <div className="full-control">
                            <ReactPlayerCustom
                              resData={selectedTranscriptData}
                            />
                          </div>
                        </span>
                      ) : (
                        <span className="music-not-found">
                          Audio file not available for this transcript
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={4}>
              <NextynAIAssistant
                userData={userData}
                assembly_response_id={
                  selectedTranscriptData?.assembly_response_id
                }
              />{" "}
            </Col>
          </Row>
        )}
        <Drawer
          title={replaceTxt(selectedTranscriptData?.topic, [
            "NEXTYN",
            "MICRO",
            "CONSULTING",
            "-CONSULTING",
            "CALL - ",
            "CALL",
            "-",
          ])}
          placement="right"
          closable={true}
          onClose={onClose}
          open={drawerVisible}
          width={"50%"}
        >
          {sumryListLoading ? (
            <div rootClassName="d-flex justify-content-center align-items-center h-100">
              <Loader />
            </div>
          ) : (
            <div rootClassName="p-3">
              <p rootClassName="fw-normal fs-7">{sumryList}</p>
            </div>
          )}
        </Drawer>
      </div>
      <ProjectListModal
        isLoading={isLoadingProjects}
        selectedExpertId={selectedTranscriptData?.meetingsData?.[0]?.expert_id}
        country={selectedTranscriptData?.meetingsData?.[0]?.country}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />
      <CreateProjectModal
        buttonText="Request"
        mutateProjectList={mutateProjectList}
        selectedExpertId={selectedTranscriptData?.meetingsData?.[0]?.expert_id}
      />
    </>
  );
};

export default Transcript;
