import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
  data: [],
  unlockedData: { data: [], count: 0 },
  lockedData: { data: [], count: 0 },
  isLoadingPagination: false,
  count: 0,
  status: 'idle',
  error: null,
  selectedTranscriptData: {},
  sumryList: [],
  sumryListLoading: false,
  searchVal: "",
  transcriptPagination: { page: 1, limit: 20 }
};


const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setTranscriptData: (state, { payload }) => {
      state.data = payload
    },
    setIsLoadingPagination: (state, { payload }) => {
      state.isLoadingPagination = payload
    },
    setUnlockedData: (state, { payload }) => {
      state.unlockedData = payload
    },
    setLockedData: (state, { payload }) => {
      state.lockedData = payload
    },
    setCount: (state, { payload }) => {
      state.count = payload
    },
    setSearchVal: (state, { payload }) => {
      state.searchVal = payload
    },
    setSumryList: (state, { payload }) => {
      state.sumryList = payload
    },
    setSumryListLoading: (state, { payload }) => {
      state.sumryListLoading = payload
    },
    setSelectedTranscriptData: (state, { payload }) => {
      state.selectedTranscriptData = payload
    },
    setTranscriptPagination: (state, { payload }) => {
      state.transcriptPagination = payload
    },
  },
});


const transcriptReducer = dataSlice.reducer
export const { setTranscriptData, setCount, setSelectedTranscriptData, setSumryList, setSumryListLoading, setTranscriptPagination, setUnlockedData,
  setLockedData, setIsLoadingPagination, setSearchVal } = dataSlice.actions
export {
  transcriptReducer
}
