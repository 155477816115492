import { Tag } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsUnlock } from "react-icons/bs";
import { PiCoinVerticalDuotone } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { clearChatMessages } from "../Pages/Transcript/TranscriptChat.slice";

function UnlockButton({ data, hoveredItemId, unlockFunction }) {
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const creditsData = useSelector((state) => state?.user?.all_credits);
  const goToDetailsPage = React.useCallback(
    (curElem) => {
      history({
        pathname: `/transcript-details/${curElem?._id}`,
        state: { curElem },
      });
    },
    [history]
  );
  const dispatch = useDispatch();
  return (
    <>
      {data?.unlock === true ? (
        <Tag
          bordered={false}
          className="tagContainer view"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            if (permission?.transcript?.view) {
              goToDetailsPage(data);
              dispatch(clearChatMessages());
            }
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsUnlock className="me-1 tag_icon" />
            View
          </span>
        </Tag>
      ) : hoveredItemId === data?._id && permission?.transcript?.unlock ? (
        <Tag
          bordered={false}
          className="tagContainer unlock"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            unlockFunction();
          }}
        >
          Unlock
        </Tag>
      ) : (
        <Tag
          bordered={false}
          className="tagContainer"
          style={{
            borderColor: "rgb(76, 187, 23)",
            color: "rgb(76, 187, 23)",
            marginLeft: "auto",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <PiCoinVerticalDuotone
              className="tag_icon"
              style={{ marginRight: "4px" }}
            />
            {creditsData?.credits_for_transcript}
          </span>
        </Tag>
      )}
    </>
  );
}

export default UnlockButton;
