import {
  Button,
  Divider,
  List,
  Modal,
  Pagination,
  Popover,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import API_PATH from "../../../Constants/api-path";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useProject from "../../Hooks/useProject";
import useExpert from "../../Hooks/useExpert";
import useDashboard from "../../Hooks/useDashboard";
import useUser from "../../Hooks/useUser";
import { setSelectedExpert } from "../ExpertPage/Expert.Slice";
import { checkForValidURL, convertToTitleCase, formattedDate } from "../../../Utils/Util";
import { PiCoinVerticalDuotone, PiTrashSimpleDuotone } from "react-icons/pi";
import { setIsOpenAiModal, setSelectedArticle } from "../Dashboard/news.slice";
import {
  clearChatMessages,
  setSelectedTranscript,
} from "../Transcript/TranscriptChat.slice";
import { setToggle } from "../V2/Dashboard/dashboard.slice";
import {
  setIsOpenProject,
  setIsOpenCreatProject,
} from "../V2/Dashboard/Project.Slice";
import useCompanySearch from "../V2/CompanyPage/useCompanySearch";
import ExpertSidebar from "../ExpertPage/ExpertSidebar";
import { Col, Row } from "react-bootstrap";
import AiSummaryModal from "../../Components/AiSummaryModal";
import { BsUnlock } from "react-icons/bs";
import { tabInfo } from "../../../Utils/Util";
import Loader from "../../Components/Loader";
import UnlockExpertButton from "../../Components/UnlockExpertButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import TranscriptPopOver from "../../Components/TranscriptPopOver";
import { IoMdInformationCircle } from "react-icons/io";
import UnlockTranscriptModal from "../../Components/UnlockTranscriptModal";
import company_icon3 from "../../../assests/images/company_notfound.png";

const ListItem = ({
  activeTab,
  data = {},
  GetWatchlistList,
  id,
  functions,
}) => {
  const {
    removeFromWatchList,
    removeFromWatchListKeyword,
    getExpertById,
    getNewsAiSummary,
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    spendCredits,
  } = functions;
  const history = useNavigate();

  const creditsData = useSelector((state) => state?.user?.all_credits);
  const { projectListById } = useSelector((state) => state.project);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const dispatch = useDispatch();
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [selected, setSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showAll, setShowAll] = useState(false);
  const showModal = (elem) => {
    setSelected(elem);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const toggleShowAll = (id) => {
    setShowAll((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const goToDetailsPage = (curElem) => {
    history({
      pathname: `/transcript-details/${curElem?._id}`,
      state: { curElem },
    });
  };

  const handleSpendCredit = async (id, category) => {
    let res = await spendCredits({
      id,
      category,
      credits_spend: creditsData?.credits_for_transcript,
      navigation: false,
    });
    return res;
  };

  // const content = (curElem) => (
  //   <>
  //     <div className="trans_popup">
  //       <small className="fw-bolder truncate-text">
  //         Title:{" "}
  //         <Tooltip title={capitalizeFirstLetter(curElem?.topic)}>
  //           <span className="fw-normal">
  //             {capitalizeFirstLetter(curElem?.topic)}
  //           </span>
  //         </Tooltip>
  //       </small>
  //       <Divider></Divider>
  //       <div className="d-flex justify-content-between align-items-center">
  //         {curElem?.expertData && curElem?.expertData.length > 0 ? (
  //           <small className="fw-normal">
  //             <strong className="fw-bolder">Expert:</strong>{" "}
  //             {`${curElem?.expertData[0]?.current_designation || "N/A"}${curElem?.expertData[0]?.current_designation &&
  //               curElem?.expertData[0]?.previous_company
  //               ? ", "
  //               : ""
  //               }`}
  //             {curElem?.expertData[0]?.previous_company ? (
  //               <>{curElem?.expertData[0].previous_company || "N/A"}</>
  //             ) : (
  //               ""
  //             )}
  //           </small>
  //         ) : (
  //           <small className="fw-normal">
  //             Designation/Company information unavailable
  //           </small>
  //         )}

  //         {projectListById?.data?.length > 0 ? (
  //           // <ProjectListModal
  //           //     functions={{
  //           //         projectListById,
  //           //         AddExpertProject,
  //           //         getProjectListById,
  //           //         mutateProjectList
  //           //     }}
  //           //     selectedExpertId={curElem?.meetingsData?.[0]?.expert_id} />
  //           <button
  //             className="btn viewButton text-end"
  //             style={{
  //               fontSize: "12px",
  //               padding: "",
  //             }}
  //             onClick={() => {
  //               dispatch(setIsOpenProject(true));
  //             }}
  //           >
  //             Request this Expert
  //           </button>
  //         ) : (
  //           <Button
  //             className="btn viewButton text-end"
  //             onClick={() => {
  //               dispatch(setIsOpenCreatProject(true));
  //               dispatch(setIsOpenProject(false));
  //               // closeProjectModal(false)
  //             }}
  //           >
  //             {"Request this Expert"}
  //           </Button>
  //           // <CreateProjectModal buttonText="Add Project" mutateProjectList={mutateProjectList} />
  //         )}
  //       </div>
  //       <Divider></Divider>
  //       <strong style={{ fontWeight: "500", fontSize: "12px" }}>
  //         Topics covered in this transcript
  //       </strong>
  //       <ul className="listType mt-2 mb-0">
  //         {curElem?.lemur_questions?.result
  //           ?.slice(0, showAll[curElem?._id] ? undefined : 5)
  //           .map((question, index) => (
  //             <li className="fw-normal mb-0" key={index}>
  //               {question.replace(/-/g, "")}
  //             </li>
  //           ))}
  //       </ul>

  //       {curElem?.lemur_questions?.result?.length > 5 && (
  //         <Button
  //           className="text-start p-0"
  //           type="link"
  //           block
  //           onClick={() => toggleShowAll(curElem?._id)} // Assuming curElem.id is the correct identifier for each item
  //           style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
  //         >
  //           {showAll[curElem?._id] ? "View Less" : "View More"}
  //         </Button>
  //       )}
  //       <Divider className="mt-1"></Divider>
  //       <div className="d-flex cstmTg">
  //         <div
  //           style={{
  //             flexBasis: "70px",
  //             flexGrow: 0,
  //             flexShrink: 0,
  //           }}
  //         >
  //           {curElem?.keyword?.split(",")?.length > 1 && (
  //             <>
  //               <small className="fw-bolder">Keywords:</small>
  //             </>
  //           )}
  //         </div>
  //         <div>
  //           {curElem?.keyword
  //             ?.split(",")
  //             ?.slice(0, 6)
  //             ?.map((tag) => (
  //               <>
  //                 {tag && (
  //                   <Tag className="me-1 mb-1" size="large" key={tag}>
  //                     {tag}
  //                   </Tag>
  //                 )}
  //               </>
  //             ))}
  //           {curElem?.keyword &&
  //             curElem?.keyword?.split(",")?.slice(6)?.length > 0 && (
  //               // <Popover placement="left" content={contentTags(selectedExpertSearch)} trigger="hover" style={{ width: "100px" }}>
  //               <Tooltip
  //                 title={curElem?.keyword?.split(",")?.slice(6)?.join(", ")}
  //               >
  //                 <Tag className="me-1 mb-1" size="large">
  //                   +{curElem?.keyword?.split(",")?.slice(6)?.length}
  //                 </Tag>
  //               </Tooltip>
  //               // </Popover>
  //             )}
  //         </div>
  //       </div>
  //       <div className="mt-1 d-flex cstmTg">
  //         <div
  //           style={{
  //             flexBasis: "70px",
  //             flexGrow: 0,
  //             flexShrink: 0,
  //           }}
  //         >
  //           {curElem?.meetingsData?.[0]?.company_id?.length > 0 && (
  //             <>
  //               <small className="fw-bolder">Companies:</small>
  //             </>
  //           )}
  //         </div>
  //         <div>
  //           {curElem?.meetingsData?.[0]?.company_id
  //             ?.slice(0, 6)
  //             ?.map((comp) => (
  //               <>
  //                 {comp && (
  //                   <Link to={`/company?company_id=${comp?._id}`}>
  //                     <Tag className="me-1 mb-1" size="large" key={comp?._id}>
  //                       {convertToTitleCase(comp?.name)}
  //                     </Tag>
  //                   </Link>
  //                 )}
  //               </>
  //             ))}
  //           {curElem?.meetingsData &&
  //             curElem?.meetingsData?.[0]?.company_id?.slice(6)?.length > 0 && (
  //               <Tooltip
  //                 title={curElem?.meetingsData?.[0]?.company_id
  //                   ?.slice(6)
  //                   ?.map((comp) => comp?.name)}
  //               >
  //                 <Tag className="me-1 mb-1" size="large">
  //                   +{curElem?.meetingsData?.[0]?.company_id?.slice(6)?.length}
  //                 </Tag>
  //               </Tooltip>
  //             )}
  //         </div>
  //       </div>
  //     </div>
  //     <ProjectListModal
  //       functions={{
  //         projectListById,
  //         AddExpertProject,
  //         getProjectListById,
  //         mutateProjectList,
  //       }}
  //       selectedExpertId={curElem?.meetingsData?.[0]?.expert_id}
  //     />
  //     <CreateProjectModal
  //       buttonText="Add Project"
  //       mutateProjectList={mutateProjectList}
  //     />
  //   </>
  // );

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="">
        {activeTab === "Experts" ? (
          <>
            <>
              {/* {Array.isArray(expertList) &&
                  expertList.slice(0, 10).map((data, id) => ( */}
              <List.Item key={id}>
                {/* <Popover content={expertContent(data?.expert_ai_sumary)} > */}
                <Row className="w-100 align-items-center" key={id}>
                  <Col lg={11}>
                    <Row>
                      <Col md={4}>
                        <Tooltip title={data?.currunt_designation}>
                          <strong className="fw-normal truncate-text pe-4">
                            {data?.currunt_designation || "-"}
                          </strong>
                        </Tooltip>
                        {/* </Col> */}
                        {/* </Row> */}
                      </Col>
                      <Col md={3}>
                        <span className="d-flex align-items-center gap-2">
                          <span>
                            <img
                              src={
                                checkForValidURL(data?.current_experience?.[0]?.logo_url)
                              }
                              alt="logo"
                              height={"25px"}
                              width={"25px"}
                            />
                          </span>
                          <Tooltip title={data?.currunt_company}>
                            <small className="fw-normal truncate-text pe-4">
                              {data?.currunt_company || "-"}
                            </small>
                          </Tooltip>
                        </span>
                      </Col>

                      <Col md={3}>
                        <Tooltip
                          title={
                            data?.previous_company?.split("+")[1]
                              ? data?.previous_company?.split("+")[1]
                              : data?.previous_company?.split("+")[0]
                          }
                        >
                          <span className="d-flex align-items-center gap-2">
                            {(data?.previous_company?.split("+")[1] ||
                              data?.previous_company?.split("+")[0]) && (
                                <span>
                                  <img
                                    src={checkForValidURL(data?.previous_company_logo)}
                                    alt="logo"
                                    height={"25px"}
                                    width={"25px"}

                                  />
                                </span>
                              )}
                            <small className="fw-normal truncate-text pe-4">
                              {data?.previous_company?.split("+")[1]
                                ? data?.previous_company?.split("+")[1]
                                : data?.previous_company?.split("+")[0] || "-"}
                            </small>
                          </span>
                        </Tooltip>
                      </Col>
                      <Col md={2}>
                        <small className="fw-normal truncate-text">
                          {data?.country || "-"}
                        </small>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={1}
                    onMouseEnter={() => setHoveredItemId(data?._id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                  >
                    <UnlockExpertButton
                      data={data}
                      hoveredItemId={hoveredItemId}
                      unlockFunction={() => {
                        getExpertById(data?._id);
                        dispatch(setToggle(true));
                        dispatch(setSelectedExpert(data));
                      }}
                    />
                  </Col>
                </Row>
                {/* </Popover> */}
              </List.Item>
              {/* ))} */}
            </>
          </>
        ) : activeTab === "News" ? (
          <>
            <>
              {/* {news_data?.map((data, index) => ( */}
              <List.Item
                key={id}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setIsOpenAiModal(true));
                  dispatch(setSelectedArticle(data));
                  getNewsAiSummary({ news: data });
                  // navigate("/news/full-article");
                }}
              >
                <Row className="w-100 align-items-center">
                  <Col lg={9}>
                    <Tooltip title={data?.title}>
                      <Link className="me-2 pe-4 truncate-text">

                        <span className="fw-normal p-2">{data?.title}</span>
                      </Link>
                    </Tooltip>
                  </Col>
                  <Col lg={2}>
                    <span className="d-flex align-items-center gap-2">
                      <span>
                        <img
                          src={
                            checkForValidURL(data?.source?.icon)
                          }
                          alt="logo"
                          className="img-contain"
                          height={"25px"}
                          width={"35px"}
                          onError={(e) => {
                            e.target.src = company_icon3;
                          }}
                        />
                      </span>
                      <small className="fw-normal truncate-text">
                        {data?.source?.name}
                      </small>
                    </span>
                  </Col>
                  <Col lg={1}>
                    <small className="fw-normal text-muted">
                      {formattedDate(data?.article_date)}{" "}
                    </small>
                  </Col>
                </Row>
              </List.Item>
              {/* ))} */}
            </>
          </>
        ) : activeTab === "Transcripts" ? (
          <>
            <>
              {/* {data?.map((curElem, id) => ( */}
              <List.Item key={id}>
                <Row className="w-100 align-items-center">
                  <Col lg={10}>
                    <Row>
                      <Col lg={6}>
                        <Popover
                          placement="left"
                          content={
                            <TranscriptPopOver
                              curElem={data}
                              functions={{
                                AddExpertProject,
                                getProjectListById,
                                mutateProjectList,
                              }}
                              dataVariables={{ projectListById }}
                            />
                          }
                        // trigger={"click"}
                        >
                          <Tooltip title={capitalizeFirstLetter(data?.topic)}>
                            <p
                              className="fw-bolder truncate-text mb-0 pe-4"
                              style={{ fontSize: "12px", cursor: "pointer" }}
                              onClick={() => {
                                if (data?.unlock === true) {
                                  if (permission?.transcript?.view) {
                                    goToDetailsPage(data);
                                    dispatch(clearChatMessages());
                                  }
                                } else {
                                  if (permission?.transcript?.unlock) {
                                    dispatch(clearChatMessages());
                                    showModal(data);
                                  }
                                }
                              }}
                            >
                              {capitalizeFirstLetter(data?.topic)}
                            </p>
                          </Tooltip>
                        </Popover>
                      </Col>
                      <Col lg={6}>
                        <Popover
                          placement="left"
                          content={
                            <TranscriptPopOver
                              curElem={data}
                              functions={{
                                AddExpertProject,
                                getProjectListById,
                                mutateProjectList,
                              }}
                              dataVariables={{ projectListById }}
                            />
                          }
                        >
                          <small className="fw-normal truncate-text  pe-4">
                            {data?.expertData?.[0]?.current_designation || "NA"}
                            , {data?.expertData?.[0]?.previous_company || "NA"}
                          </small>
                        </Popover>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={2}>
                    <Row>
                      <Col md={6}>
                        {/* <Popover placement="right" content={content(data)}> */}
                        <small className="fw-normal text-muted">
                          {formattedDate(data?.start_time)}
                        </small>
                        {/* </Popover> */}
                      </Col>
                      {/* <Col md={2}>
                        <Popover
                          placement="right"
                          content={content(data)}
                        >
                          <small className="fw-normal text-muted">
                            {formatDuration(data?.duration)}
                          </small>
                        </Popover>
                      </Col> */}
                      <Col
                        md={6}
                        onMouseEnter={() => setHoveredItemId(id)}
                        onMouseLeave={() => setHoveredItemId(null)}
                      >
                        <>
                          {data?.unlock === true ? (
                            <Tag
                              bordered={false}
                              className="tagContainer view"
                              onClick={() => {
                                if (permission?.transcript?.view) {
                                  goToDetailsPage(data);
                                  dispatch(clearChatMessages());
                                  // dispatch(setSelectedTranscript(data)) //ADDED IF POSSIBLE WE USE
                                }
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <BsUnlock className="me-1 tag_icon" />
                                View
                              </span>
                            </Tag>
                          ) : hoveredItemId === id &&
                            permission?.transcript?.unlock ? (
                            <Tag
                              bordered={false}
                              className="tagContainer unlock"
                              onClick={() => {
                                dispatch(clearChatMessages());
                                // dispatch(setSelectedTranscript(data)) //ADDED IF POSSIBLE WE USE
                                showModal(data);
                              }}
                            >
                              Unlock
                            </Tag>
                          ) : (
                            <Tag
                              bordered={false}
                              className="tagContainer"
                              style={{
                                borderColor: "rgb(76, 187, 23)",
                                color: "rgb(76, 187, 23)",
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PiCoinVerticalDuotone
                                  className="tag_icon"
                                  style={{ marginRight: "2px" }}
                                />
                                {creditsData?.credits_for_transcript}
                              </span>
                            </Tag>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </List.Item>
              {/* ))} */}
            </>
          </>
        ) : activeTab === "Watchlist" ? (
          <>
            <>
              {/* {watchListData?.map((curElem, id) => ( */}
              <List.Item key={id}>
                <Row className="w-100 align-items-center">
                  <Col md={9}>
                    <Tooltip
                      title={capitalizeFirstLetter(
                        data?.keyword || data?.company?.[0]
                      )}
                    >
                      <Link to={data?.company?.[0] ? `/company?company_id=${data?.company_id}` : data?.keyword ? `/search?keyword=${data?.keyword}` : ""}
                        className="fw-bolder truncate-text mb-0"
                        style={{ fontSize: "12px" }}
                      >
                        {capitalizeFirstLetter(
                          data?.keyword || data?.company?.[0]
                        )}
                      </Link>
                    </Tooltip>
                  </Col>
                  <Col lg={2}>
                    <p
                      className="fw-bolder truncate-text mb-0"
                      style={{ fontSize: "12px" }}
                    >
                      {data?.keyword ? "Keyword" : "Company"}
                    </p>
                  </Col>
                  {data?.type !== "default" && (
                    <Col md={1}>
                      <>
                        <Tag
                          bordered={false}
                          className="tagContainer view"
                          onClick={async () => {
                            if (data?.keyword) {
                              await removeFromWatchListKeyword(data?._id);
                              GetWatchlistList({ page: 1, limit: 50 });
                            } else {
                              await removeFromWatchList(data?.company_id);
                              GetWatchlistList({ page: 1, limit: 50 });
                            }
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "2px",
                            }}
                          >
                            <PiTrashSimpleDuotone className="me-1" />
                            Delete
                          </span>
                        </Tag>
                      </>
                    </Col>
                  )}
                </Row>
              </List.Item>
              {/* ))} */}
            </>
          </>
        ) : null}
        <UnlockTranscriptModal
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          unlockFunction={async () => {
            try {
              let response = await handleSpendCredit(
                selected?._id,
                "transcript",
                selected
              );
              if (response) {
                goToDetailsPage(selected);
                dispatch(setSelectedTranscript(selected));
              }
              setIsModalOpen(false);
              return response
            } catch (error) {
              return error
            }

          }}

        />
      </div>
    </>
  );
};

function WatchlistPage() {
  const [activeTab, setActiveTab] = useState("Transcripts");
  const [loading, setLoading] = useState(false);
  const [expertList, setExpertList] = useState({ data: [], count: 0 });
  const [expertPagination, setExpertPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [watchlistPagination, setWatchlistPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [transcriptPagination, setTranscriptPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [newsPagination, setNewsPagination] = useState({ page: 1, limit: 20 });
  const [data, setData] = useState({ data: [], count: 0 });
  console.log(data, "transData");
  const { getStore } = useExpert();
  const [news_data, setNewsData] = useState({ data: [], count: 0 });
  const [watchListData, setWatchlistData] = useState({ data: [], count: 0 });
  console.log(watchListData, "watchListData");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isCalledRef = useRef(false);
  const { selectedExpert } = useSelector((state) => state.expert);

  const { removeFromWatchList, removeFromWatchListKeyword } =
    useCompanySearch();
  const {
    loading: loadingDashboard,
    getExpertById,
    getNewsAiSummary,
  } = useDashboard();
  const { spendCredits } = useUser();
  const { AddExpertProject, getProjectListById, mutateProjectList } =
    useProject();
  const functions = {
    removeFromWatchList,
    removeFromWatchListKeyword,
    getExpertById,
    getNewsAiSummary,
    spendCredits,
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    loadingDashboard,
    loading,
  };

  const GetTransList = useCallback(
    async ({
      page = transcriptPagination.page,
      limit = transcriptPagination.limit,
    }) => {
      setLoading(true);
      setTranscriptPagination({ page: page, limit: limit });

      try {
        const res = await fetch(
          `${API_PATH.GET_TRANS_WATCHLIST_DATA}?userId=${userData?.user?._id}&page=${page}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //   Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setData({ data: result?.data, count: result?.countData });
        }
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    },
    [transcriptPagination.limit, transcriptPagination.page, userData?.user?._id]
  );

  const GetExpertList = useCallback(
    async ({
      page = expertPagination.page,
      limit = expertPagination.limit,
    }) => {
      setLoading(true);
      setExpertPagination({ page: page, limit: limit });
      try {
        const res = await fetch(
          `${API_PATH.GET_EXPERT_WATCHLIST_DATA}?userId=${userData?.user?._id}&page=${page}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //   Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        console.log("result: ", result);
        if (result) {
          setExpertList({ data: result?.data, count: result?.countData });
        }
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    },
    [expertPagination.limit, expertPagination.page, userData?.user?._id]
  );

  const GetNewsList = useCallback(
    async ({ page = newsPagination.page, limit = newsPagination.limit }) => {
      setLoading(true);
      setNewsPagination({ page: page, limit: limit });
      try {
        const res = await fetch(
          `${API_PATH.GET_NEWS_WATCHLIST_DATA}?userId=${userData?.user?._id}&page=${page}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //   Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setNewsData({ data: result?.data, count: result?.count });
        }
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    },
    [newsPagination.limit, newsPagination.page, userData?.user?._id]
  );

  const GetWatchlistList = useCallback(
    async ({
      page = watchlistPagination.page,
      limit = watchlistPagination.limit,
    }) => {
      setLoading(true);
      setWatchlistPagination({ page: page, limit: limit });

      try {
        const res = await fetch(
          `${API_PATH.GET_WATCHLIST_DATA}?user_id=${userData?.user?._id}&page=${page}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              //   Authorization: `Bearer ${userData?.token}`,
            },
          }
        );
        const result = await res.json();
        if (result) {
          let arr1 = [...result?.watchlist_company];
          let arr2 = [...result?.watchlist_keyword];
          let arr3 = [...arr1, ...arr2];
          setWatchlistData({ data: arr3, count: arr3?.length });
        }
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    },
    [userData?.user?._id, watchlistPagination.limit, watchlistPagination.page]
  );

  const handleTabClick = (tab) => {
    if (tab === "Experts") {
      GetExpertList({
        page: expertPagination.page,
        limit: expertPagination.limit,
      });
    } else if (tab === "Transcripts") {
      GetTransList({
        page: transcriptPagination.page,
        limit: transcriptPagination.limit,
      });
    } else if (tab === "News") {
      GetNewsList({ page: newsPagination.page, limit: newsPagination.limit });
    } else if (tab === "Watchlist") {
      GetWatchlistList({
        page: watchlistPagination.page,
        limit: watchlistPagination.limit,
      });
    }

    setActiveTab(tab);
  };
  useEffect(() => {
    if (!isCalledRef.current) {
      isCalledRef.current = true;
      GetTransList({
        page: transcriptPagination.page,
        limit: transcriptPagination.limit,
      });
    }
  }, [GetTransList, transcriptPagination.limit, transcriptPagination.page]);

  const findIconForTitle = (title) => {
    const tab = tabInfo?.find((t) => t?.title === title);
    return tab ? tab?.logo : null;
  };

  const items = [
    {
      key: "Transcripts",
      label: <span>{findIconForTitle("Transcripts")} Transcripts</span>,
      children: (
        <>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ minHeight: "50vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <List
                size="small"
                header={
                  <div>
                    <>
                      <Row className="w-100 mt-1">
                        <Col lg={10}>
                          <Row>
                            <Col md={6}>
                              <strong className="fw-bolder">Title</strong>
                            </Col>
                            <Col md={6}>
                              <strong className="fw-bolder">Expert</strong>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={2}>
                          <Row>
                            <Col md={6}>
                              <strong className="fw-bolder">Date</strong>
                            </Col>
                            <Col md={6}>
                              <strong className="fw-bolder">Credits</strong>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  </div>
                }
                bordered
                dataSource={data?.data || []}
                loading={{
                  spinning: loading,
                  indicator: (
                    <div
                      className="d-flex justify-content-center align-items-center text-center"
                      style={{ minHeight: "50vh" }}
                    >
                      <Loader />
                    </div>
                  ),
                }}
                renderItem={(item, index) => (
                  <ListItem
                    activeTab={activeTab}
                    data={item}
                    GetWatchlistList={GetWatchlistList}
                    id={index}
                    functions={functions}
                  // getNewsAiSummary={getNewsAiSummary}
                  />
                )}
                style={{ background: "#ffff" }}
              ></List>
              <Pagination
                className="text-end mt-2"
                size="small"
                total={data?.count}
                current={transcriptPagination?.page}
                pageSize={transcriptPagination?.limit}
                onChange={(page, pageSize) => {
                  GetTransList({ page: page, limit: pageSize });
                }}
                showSizeChanger={true}
                disabled={false}
              />
            </>
          )}
        </>
      ),
    },
    {
      key: "Experts",
      label: <span>{findIconForTitle("Experts")} Experts</span>,
      children: (
        <>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ minHeight: "50vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <Row className="w-100">
                <Col lg={selectedExpert?._id ? 8 : 16}>
                  <div className="height100-145">
                    <List
                      size="small"
                      header={
                        <div>
                          <>
                            <Row className="w-100 mt-1">
                              <Col lg={11}>
                                <Row>
                                  <Col lg={4}>
                                    <strong className="fw-bolder">
                                      Designation
                                    </strong>
                                  </Col>
                                  <Col lg={3}>
                                    <strong className="fw-bolder">
                                      Company
                                    </strong>
                                  </Col>
                                  <Col lg={3}>
                                    <strong className="fw-bolder">
                                      Previous Company
                                    </strong>
                                  </Col>
                                  <Col lg={2}>
                                    <strong className="fw-bolder">
                                      Country
                                    </strong>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={1}>
                                <strong className="fw-bolder">Credits</strong>
                              </Col>
                            </Row>
                          </>
                        </div>
                      }
                      bordered
                      dataSource={expertList?.data || []}
                      loading={{
                        spinning: loading,
                        indicator: (
                          <div
                            className="d-flex justify-content-center align-items-center text-center"
                            style={{ minHeight: "50vh" }}
                          >
                            <Loader />
                          </div>
                        ),
                      }}
                      // pagination={{
                      //   current: expertPagination.page,
                      //   pageSize: expertPagination.limit,
                      //   total: expertList?.count,
                      //   size: "small",
                      //   showSizeChanger: true,
                      //   onChange: (page, pageSize) => {
                      //     GetExpertList({ page: page, limit: pageSize });
                      //   },
                      // }}
                      renderItem={(item, index) => (
                        <ListItem
                          activeTab={activeTab}
                          data={item}
                          GetWatchlistList={GetWatchlistList}
                          id={index}
                          functions={functions}
                        // getNewsAiSummary={getNewsAiSummary}/
                        />
                      )}
                      style={{ background: "#ffff" }}
                    ></List>
                    <Pagination
                      className="text-end mt-2"
                      size="small"
                      total={expertList?.count}
                      current={expertPagination?.page}
                      pageSize={expertPagination?.limit}
                      onChange={(page, pageSize) => {
                        GetExpertList({ page: page, limit: pageSize });
                      }}
                      showSizeChanger={true}
                      disabled={false}
                    />
                  </div>
                </Col>
                <Col
                  lg={4}
                  className={`${selectedExpert?._id ? "" : "d-none"}`}
                >
                  <ExpertSidebar getStore={getStore} />
                </Col>
              </Row>
            </>
          )}
        </>
      ),
    },
    {
      key: "News",
      label: <span>{findIconForTitle("News")} News</span>,
      children: (
        <>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ minHeight: "50vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <List
                size="small"
                header={
                  <div>
                    <>
                      <Row className="w-100 mt-1">
                        <Col lg={9}>
                          <strong className="fw-bolder">Headline</strong>
                        </Col>
                        <Col lg={2}>
                          <strong className="fw-bolder">Source</strong>
                        </Col>
                        <Col lg={1}>
                          <strong className="fw-bolder">Date</strong>
                        </Col>
                      </Row>
                    </>
                  </div>
                }
                bordered
                loading={{
                  spinning: loading,
                  indicator: (
                    <div
                      className="d-flex justify-content-center align-items-center text-center"
                      style={{ minHeight: "50vh" }}
                    >
                      <Loader />
                    </div>
                  ),
                }}
                dataSource={news_data?.data || []}
                // pagination={{
                //   current: newsPagination.page,
                //   pageSize: newsPagination.limit,
                //   total: news_data?.count,
                //   size: "small",
                //   showSizeChanger: true,
                //   onChange: (page, pageSize) => {
                //     GetNewsList({ page: page, limit: pageSize });
                //   },
                // }}
                renderItem={(item, index) => (
                  <ListItem
                    activeTab={activeTab}
                    data={item}
                    GetWatchlistList={GetWatchlistList}
                    functions={functions}
                    id={index}
                  />
                )}
                style={{ background: "#ffff" }}
              ></List>
              <Pagination
                className="text-end mt-2"
                size="small"
                total={news_data?.count}
                current={newsPagination?.page}
                pageSize={newsPagination?.limit}
                onChange={(page, pageSize) => {
                  GetNewsList({ page: page, limit: pageSize });
                }}
                showSizeChanger={true}
                disabled={false}
              />
            </>
          )}
        </>
      ),
    },
    {
      key: "Watchlist",
      label: <span>{findIconForTitle("Following")} Following</span>,
      children: (
        <>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ minHeight: "50vh" }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <List
                size="small"
                header={
                  <div>
                    <>
                      <Row className="w-100 mt-1">
                        <Col lg={9}>
                          <strong className="fw-bolder">Watchlist Item</strong>
                        </Col>
                        <Col lg={2}>
                          <strong className="fw-bolder">Type</strong>
                        </Col>
                        <Col lg={1}>
                          <strong className="fw-bolder">Action</strong>
                        </Col>
                      </Row>
                    </>
                  </div>
                }
                bordered
                loading={{
                  spinning: loading,
                  indicator: (
                    <div
                      className="d-flex justify-content-center align-items-center text-center"
                      style={{ minHeight: "50vh" }}
                    >
                      <Loader />
                    </div>
                  ),
                }}
                dataSource={watchListData?.data || []}
                renderItem={(item, index) => (
                  <ListItem
                    activeTab={activeTab}
                    data={item}
                    GetWatchlistList={GetWatchlistList}
                    functions={functions}
                    id={index}
                  />
                )}
                style={{ background: "#ffff" }}
              ></List>
            </>
          )}
        </>
      ),
    },
  ];

  const contentDisclaimer = () => (
    <>
      <div className="trans_popup">
        <p className="fw-normal mb-0">
          In the watchlist, you will see all the transcripts, experts and news
          related to the topics/companies you are following. You can regulate
          your watchlist from the In the "Following" tab. To add more items in
          your watchlist just search the term in the search bar and click on the
          "Follow" button.
        </p>
      </div>
    </>
  );
  return (
    <div className="watchListH">
      <Row className="mt-2">
        <Col className="w-100">
          <h5 style={{ fontWeight: 600 }}>
            <div className="d-flex align-items-center justify-content-start gap-1">
              <span>Watchlist</span>
              <Popover
                style={{ cursor: "pointer" }}
                placement="right"
                content={contentDisclaimer}
                className="ms-2"
              >
                <IoMdInformationCircle style={{ cursor: "pointer" }} />
              </Popover>
            </div>
          </h5>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" items={items} onChange={handleTabClick} />
      <AiSummaryModal />
    </div>
  );
}

export default WatchlistPage;
