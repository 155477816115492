import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { Tabs, Button } from 'antd';
import "./Search.css";
import TranscriptExpertPage from './TranscriptExpertPage';
import SearchFilters from '../Dashboard/SearchFilters';
import ExpertSection from './ExpertSection';
import NewsSection from './NewsSection';
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import useSearchPage from '../../../Hooks/useSearchPage';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "../../../../Utils/Icons";
import useProject from '../../../Hooks/useProject';
import { setActiveTab, setExpertPagination, setNewsPagination, setSelectedCompanyType, setSelectedItem, setSelectedPostDate, setTranscriptPagination } from './SearchPage.slice';
import { useDispatch, useSelector } from 'react-redux';
import { tabInfo } from "../../../../Utils/Util"
import useUser from '../../../Hooks/useUser';

const { TabPane } = Tabs;

const TopSelectOptions = () => {
    const [searchParams] = useSearchParams()
    const { activeTab, searchKeywordData } = useSelector((state) => state.SearchPage)
    const { isLoadingExp, handleItemClick, isLoading, addDefaultSrc, handleItemClickNews, isLoadingNews, isValidatingNews, fetchTranscriptData, addToWatchListKeyword, getStore, fetchNewsData, isLoadingTrans, replaceTxt, isValidatingExp, isValidatingTrans, fetchExpertData } = useSearchPage(activeTab)
    const { spendCredits } = useUser()
    const { AddExpertProject, getProjectListById, mutateProjectList } = useProject();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    // const { news_data } = useSelector((state) => state.SearchPage)
    const onChange = (key) => {
        dispatch(setActiveTab(key))
        dispatch(setSelectedItem({}))
        dispatch(setSelectedPostDate('all'));
        dispatch(setSelectedCompanyType(undefined));
        dispatch(setExpertPagination({ page: 1, limit: 20 }));
        dispatch(setTranscriptPagination({ page: 1, limit: 20 }));
        dispatch(setNewsPagination({ page: 1, limit: 20 }));

    };

    const handleIconClick = () => {
        navigate(-1);
    };

    const findIconForTitle = (title) => {
        const tab = tabInfo?.find((t) => t?.title === title);
        return tab ? tab?.logo : null;
    };

    return (
        <Row className="my-2 ">
            <Col md={12} className="w-100 d-flex align-items-center">
                <h6 className='m-0 gap-1 d-flex align-items-center' style={{ fontWeight: 500 }} >
                    <span
                        className="bckIcon"
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        onClick={handleIconClick}
                    >
                        <BsChevronLeft className="me-1 search-btn-back" />
                    </span>
                    Results for <span className='cust_header'>{searchParams.get("keyword")}</span>
                </h6>
                {!searchKeywordData?.watchlistAdded ? (
                    <>
                        <button className="btn common-filter-btn viewButton d-flex align-items-center justify-content-center text-center ms-4" style={{ fontSize: "12px", padding: "2px 7px" }} onClick={() => {
                            addToWatchListKeyword(searchParams.get("keyword"))
                        }}>
                            <span className="text-center me-1" style={{ fontSize: "12px" }}><PlusOutlined /></span>
                            <an csplassName="text-center" style={{ lineHeight: "1" }}>Follow</an>
                        </button>
                    </>
                ) : (
                    <div className="following-btn common-filter-btn d-flex align-items-center justify-content-center text-center ms-4" style={{ fontSize: "12px", padding: "2px 7px" }}>
                        <span className="text-center me-1" style={{ fontSize: "12px" }}><CheckOutlined /></span>
                        <an csplassName="text-center" style={{ lineHeight: "1" }}>Following</an>
                    </div>
                )}

                {/* <button className='common-filter-btn viewButton ms-4' style={{ fontSize: "12px", padding: "0 7px" }} onClick={() => {
                    addToWatchListKeyword(searchParams.get("keyword"))
                }}>
                    <span className='me-2'><PlusOutlined /></span>
                    <span>Watch</span>
                </button> */}
            </Col>

            <div className="w-100 position-relative" >
                <Tabs
                    onChange={onChange}
                    activeKey={activeTab}
                    tabPosition="top">
                    <TabPane tab={
                        <div>
                            <span className='me-2'>{findIconForTitle("News")} News</span>
                            {/* <Tag rounded color={activeTab === "4" ? "#003137FF" : "rgba(0, 0, 0, 0.04)"} style={{ fontSize: "12px", borderRadius: "4px", color: activeTab === "4" ? "#fff" : "#000" }}>
                                {news_data?.count}
                            </Tag> */}
                        </div>
                    } key="news">
                        <div className=''>
                            <div>
                                <NewsSection
                                    isLoading={isLoading}
                                    addDefaultSrc={addDefaultSrc}
                                    handleItemClickNews={handleItemClickNews}
                                    fetchNewsData={fetchNewsData}
                                    isLoadingNews={isLoadingNews}
                                    isValidatingNews={isValidatingNews}

                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={
                        <div>
                            <span className='me-2'>{findIconForTitle("Transcripts")} Transcripts</span>
                        </div>
                    } key="transcript">
                        <div className=''>
                            <div>
                                <TranscriptExpertPage
                                    functions={{
                                        AddExpertProject,
                                        getProjectListById,
                                        mutateProjectList,
                                        replaceTxt,
                                        spendCredits,
                                        fetchTranscriptData
                                    }}
                                    isLoadingTrans={isLoadingTrans}
                                    isValidatingTrans={isValidatingTrans}
                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={
                        <div>
                            <span className='me-2'>{findIconForTitle("Experts")} Experts</span>
                        </div>
                    } key="expert">
                        <div className=''>

                            <div>
                                <ExpertSection
                                    isLoadingExp={isLoadingExp}
                                    isValidatingExp={isValidatingExp}
                                    functions={{
                                        handleItemClick,
                                        AddExpertProject,
                                        getProjectListById,
                                        mutateProjectList,
                                        getStore,
                                        fetchExpertData,
                                        spendCredits

                                    }}
                                />
                            </div>
                        </div>
                    </TabPane>
                    {/* <TabPane
                        tab={
                            <div>
                                <span className='me-2'>Corporate Announcements</span>

                            </div>
                        } key="4">
                        <div className=''>
                            <div>
                            </div>  
                        </div>
                    </TabPane> */}
                </Tabs>
                {activeTab !== "expert" && <span className='position-absolute position-filter'>
                    <SearchFilters fetchTranscriptData={fetchTranscriptData} fetchNewsData={fetchNewsData} activeTab={activeTab} />
                </span>}
            </div>

        </Row>
    )
}

export default TopSelectOptions