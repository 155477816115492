import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import MobileMenu from "../../../MobileMenu";
import Sidebar from "../../Includes/Sidebar";
import { BsChevronLeft } from "../../../Utils/Icons";
import CompleteTable from "./CompleteTable";
import DeclineTable from "./DeclineTable";
import RequestTable from "./RequestTable";
import ScheduledTable from "./ScheduledTable";
import SubmitTable from "./SubmitTable";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { Segmented } from "antd";

function ProjectsDetailsExpert() {
  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [selectedOption, setSelectedOption] = useState("Submitted");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  let query = useQuery();
  let project_id = query.get("project_id");

  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState(() => {
    switch (location.pathname) {
      case "/projects-details":
        return "overview";
      case "/projects-details-message":
        return "messages";
      case "/projects-details-expert":
        return "experts";
      default:
        return "experts";
    }
  });

  useEffect(() => {
    switch (location.pathname) {
      case "/projects-details":
        setSelected("overview");
        break;
      case "/projects-details-message":
        setSelected("messages");
        break;
      case "/projects-details-expert":
        setSelected("experts");
        break;
      default:
        setSelected("experts");
    }
  }, [location.pathname]);

  const handleNavigation = (value) => {
    setSelected(value);

    let pathname;
    switch (value) {
      case "overview":
        pathname = "/projects-details";
        break;
      case "messages":
        pathname = "/projects-details-message";
        break;
      case "experts":
        pathname = "/projects-details-expert";
        break;
      default:
        pathname = "/projects-details";
    }

    navigate({
      pathname,
      search: `?${createSearchParams({ project_id })}`,
    });
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <MobileMenu />
      {/* <div className="page-wrapper chiller-theme toggled"> */}
      {/* <Sidebar /> */}

      <div className="bg-light w-100 content-wrapper" id="main-screen">
        <div className="body-expart-div request-page projects-scope">
          <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
            <h5
              style={{ fontWeight: 600 }}
              className="d-flex align-items-center"
            >
              <NavLink
                to={{
                  pathname: "/projects-details",
                  search: `?${createSearchParams({ project_id })}`,
                }}
                style={{ textAlign: "left" }}
              >
                <BsChevronLeft className="me-3 search-btn-back" />
              </NavLink>
              Experts
            </h5>
            <div className="d-flex justify-content-center align-items-center">
              <Segmented
                value={selected}
                options={[
                  { label: "Overview", value: "overview" },
                  { label: "Messages", value: "messages" },
                  { label: "Experts", value: "experts" },
                ]?.filter((obj) => {
                  if (
                    !permission?.projects?.message &&
                    !permission?.projects?.expertList
                  ) {
                    return obj.value === "overview";
                  } else if (
                    !permission?.projects?.message &&
                    permission?.projects?.expertList
                  ) {
                    return obj.value !== "messages";
                  } else if (
                    permission?.projects?.message &&
                    !permission?.projects?.expertList
                  ) {
                    return obj.value !== "experts";
                  } else {
                    return obj;
                  }
                })}
                onChange={handleNavigation}
              />
            </div>

            <button
              className="btn add-ps-btn"
              data-bs-toggle="modal"
              data-bs-target="#carteProjectModal"
              style={{ visibility: "hidden" }}
            >
              {" "}
              <BsPlusLg /> Create Project{" "}
            </button>
          </div>

          <span className="sp-hr w-100" />

          <div className="request-body-sec projects-details-page details-message-part">
            <div className="expert-tables">
              <div className="d-flex justify-content-center align-items-center">
                <Segmented
                  options={[
                    "Submitted",
                    "Requested",
                    "Scheduled",
                    "Completed",
                    "Declined",
                  ]}
                  value={selectedOption}
                  onChange={handleOptionChange}
                />
              </div>
              <div className="tab-content mt-3" id="pills-tabContent">
                {selectedOption === "Submitted" && (
                  <>
                    <div>
                      <div className="submit-table comon-tabs-expert">
                        <SubmitTable project_id={project_id} />
                      </div>
                    </div>
                  </>
                )}
                {selectedOption === "Requested" && (
                  <>
                    <div>
                      <div className="submit-table comon-tabs-expert">
                        <RequestTable project_id={project_id} />
                      </div>
                    </div>
                  </>
                )}

                {selectedOption === "Scheduled" && (
                  <>
                    <div>
                      <div className="submit-table comon-tabs-expert">
                        <ScheduledTable project_id={project_id} />
                      </div>
                    </div>
                  </>
                )}

                {selectedOption === "Completed" && (
                  <>
                    <div>
                      <div className="submit-table comon-tabs-expert">
                        <CompleteTable project_id={project_id} />
                      </div>
                    </div>
                  </>
                )}

                {selectedOption === "Declined" && (
                  <>
                    <div>
                      <div className="submit-table comon-tabs-expert">
                        <DeclineTable project_id={project_id} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default ProjectsDetailsExpert;
