import { Select, Input, Spin } from "antd";

// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'


import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import API_PATH from "../../../Constants/api-path";
import MobileMenu from "../../../MobileMenu";
import UserMangementTable from "./UserMangementTable";
import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { Form, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

const UserMangement = () => {
  document.title = "Nextyn IQ -  User Management";
  let UserData = JSON.parse(localStorage.getItem("userData"));
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const [spinner, setSpinner] = useState(false);
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [phone, setPhone] = useState("");
  const [showCreateModal, setCreateModal] = useState(false);
  const [form] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [data, setUserData] = useState([]);
  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    password: "",
    role_id: null,
    registrationType: "cloud",
    contact_number_full: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    form.setFieldsValue(addUser);
  }, [form, addUser]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${UserData.user.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        console.log(res, "ress");
        setUserData([...res.data.data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // console.log("currentData", data);

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddUser({ ...addUser, [key]: value });
  };

  const handlePhoneChange = (value, country) => {
    console.log(value, "ctnumber");
    console.log("Received dial code:", country?.dialCode);
    console.log("Country options:", countryOptions);

    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    console.log("Selected country:", selectedCountry);

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      console.log("Selected country:", selectedCountry?.name);
      setAddUser((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));

      // setContactValid(isValidContact);
    } else {
      console.log("No matching country found for dial code:", country.dialCode);
    }
  };

  const handleSubmit = async () => {
    let obj = { ...addUser }
    const { firstNmae, email, role_id } = obj;
    let fullEmail = `${addUser?.email?.toLowerCase()?.split("@")?.[0]}@${userData?.user?.email?.split("@")[1]}`

    if (firstNmae !== "" && email !== "" && phone !== "" && role_id !== "") {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regEx.test(fullEmail)) {
        return setMessage("Email is invalid");
      } else {
        setMessage("");
      }

      setSpinner(true);


      obj.email = fullEmail;
      await axios
        .post(`${API_PATH.USER_MANAGEMENT_POST}`, JSON.stringify(obj), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res: ", res);
          getUsers();
          // console.log(res);
          setSpinner(false);

          //navigate("/privacy")
          if (res.status === 200) {
            // console.log(res.status);

            toast.success("UserData Added Successful", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setCreateModal(false);
            // window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setCreateModal(false);
          setSpinner(false);
        });
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      // console.log(response);
      setRoleList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const deleteUser = async (data) => {
    // console.log(data);
    axios
      .delete(`${API_PATH.USER_MANAGEMENT_DELETE}/${data}`, {
        method: "DELETE",
        headers: headers,
      })
      .then((res) => {
        getUsers();
        if (res) {
          if (res?.data?.success === true) {
            // console.log(res.status);
            toast.success("UserData Deleted Successful", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              // window.location.reload();
            }, 3000);
          } else {
            if (
              res?.data?.success === false &&
              res?.data?.message === "Your account has been saved"
            ) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const validateEmailDomain = (_, value) => {
    // Check if value is a valid email address
    let isValidEmail = value?.includes("@")

    if (value && isValidEmail) {
      return Promise.reject(
        `please provide the username only.`
      );
    }
    // Email is valid
    return Promise.resolve();
  };

  return (
    <>
      <MobileMenu />

      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages expert-cell-page">
          <div className="d-flex top-ps-seection justify-content-between align-items-center">
            <h5
              style={{ fontWeight: 600 }}
              className="d-flex align-items-center"
            >
              Users
            </h5>
            {permission?.userManagement?.add && (
              <button
                className="btn viewButton"
                onClick={() => {
                  setAddUser({
                    firstNmae: "",
                    email: "",
                    contact_code: "",
                    contact_number: "",
                    country: "",
                    password: "",
                    role_id: null,
                    registrationType: "cloud",
                  });
                  setPhone("");
                  setCreateModal(true);
                }}
              >
                {" "}
                <BsPlusLg /> Add New{" "}
              </button>
            )}
          </div>

          <div className="managment-table mt-2">
            <UserMangementTable
              data={data}
              deleteUser={deleteUser}
              load={loading}
              getUsers={getUsers}
            />
          </div>
        </div>
      </div>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={addUser}

          onFinish={() => {
            handleSubmit();
            form.resetFields();
          }}
        >
          <Modal.Header>
            <MdOutlineClose
              className="position-absolute"
              style={{ right: "15px", top: "15px", fontSize: "25px" }}
              onClick={() => setCreateModal(false)}
            />
            <div className="text-left">
              <h4 className="modalHeader">Add Users</h4>
            </div>
          </Modal.Header>
          <Modal.Body className="position-relative ">
            <div>
              <div className="projects-modal-divs UserModal">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Form.Item
                        label="Name"
                        name={"firstNmae"}
                        rules={[
                          { required: true, message: "name is required!" },
                        ]}
                      >
                        <Input
                          type="text"
                          name="firstNmae"
                          maxLength={40}
                          size="large"
                          width={"95%"}
                          placeholder="Name"
                          // value={addUser.firstNmae}
                          // onChange={handleInputs}
                          onChange={(e) => {
                            let obj = {
                              ...addUser,
                              firstNmae: e.target.value,
                            };
                            setAddUser(obj);
                            form.setFieldValue("firstNmae", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group addRightInput">
                      <Form.Item
                        label="Email"
                        name="email"
                        maxLength={50}
                        rules={[
                          {
                            required: true,
                            message: "email is required!",
                          },
                          {
                            validator: validateEmailDomain, // Custom validator function
                          },
                        ]}
                      >
                        <Input
                          // type="email"
                          name="email"
                          size="large"
                          maxLength={50}
                          width={"95%"}
                          // value={addUser.email}
                          placeholder="Enter email address"
                          // onChange={handleInputs}
                          addonAfter={
                            "@" + userData?.user?.email?.split("@")[1]
                          }
                          onChange={(e) => {
                            let obj = {
                              ...addUser,
                              email: e.target.value,
                            };
                            setAddUser(obj);
                            form.setFieldValue("email", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      {/* <label> Contact Number * </label> */}
                      <Form.Item
                        label="Contact Number"
                        name="contact_number_full"
                        rules={[
                          {
                            required: true,
                            message: "contact number is required!",
                          },
                        ]}
                      >
                        <PhoneInput
                          country={"us"}
                          name="contact_number_full"
                          enableSearch={true}
                          // value={phone}
                          onChange={(value, country) => {
                            setPhone(value);
                            const selectedCountry = countryOptions?.find(
                              (option) =>
                                option?.code === `+${country?.dialCode}`
                            );
                            if (selectedCountry) {
                              setAddUser((prevStep3) => ({
                                ...prevStep3,
                                contact_code: country?.dialCode,
                                contact_number: value?.replace(
                                  country?.dialCode,
                                  ""
                                ),
                                country: selectedCountry?.name,
                                contact_number_full: value,
                              }));
                            }
                            form.setFieldValue("contact_number_full", value);
                          }}
                          // onChange={(value, country) => {
                          //   handlePhoneChange(value, country);
                          // }}
                          inputStyle={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Form.Item
                        label="Role"
                        name="role_id"
                        rules={[
                          { required: true, message: "Role is required!" },
                        ]}
                      >
                        <Select
                          className="w-100"
                          size="large"
                          placeholder="select role"
                          // aria-label="Default select example"
                          value={addUser.role_id}
                          onChange={(value) =>
                            setAddUser({ ...addUser, role_id: value })
                          }
                          name="role_id"
                          id="role_name"
                          options={roleList?.map((item, index) => ({
                            label: item?.name,
                            value: item?._id,
                            key: index,
                          }))}
                        />
                      </Form.Item>
                      {/* <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={handleInputs}
                        name="role_id"
                      >
                        {roleList?.map((option, index) => (
                          <option value={option._id} key={index}>
                            {option.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end align-items-center">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                key="back"
                type="button"
                onClick={() => {
                  console.log("cancelling");
                  setCreateModal(false)
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="submit"
                htmlType="submit"
                style={{
                  backgroundColor: "rgb(76, 187, 23)",
                  borderColor: "rgb(76, 187, 23)",
                  color: "#fff",
                }}
                className=" ms-2"
              >
                Submit {spinner && <Spin size="small" indicator={<LoadingOutlined />} />}
              </Button>
            </Form.Item>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* // add user modal */}
    </>
  );
};

export default UserMangement;
