import API_URL from '../../Constants/api-path'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setExpertLabList, setExpertList, setExpertPagination, setExpertProfile, setSelectedExpert } from '../Pages/ExpertPage/Expert.Slice';
import useSWR from 'swr';
import { useOnceCall } from './useOnceCall';
import { getQueryParams, toQueryString } from '../../Utils/Util';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from "query-string";

const useExpert = () => {
    const { expertList, expertLabList, expertPagination } = useSelector((state) => state?.expert)
    const { expertprofile } = useSelector((state) => state?.expert)
    const callOnce = useRef(false)
    const callOnceFilter = useRef(false)
    const location = useLocation()
    const dispatch = useDispatch()
    const userData = JSON.parse(localStorage.getItem("userData"));
    //---------------------EXPERT FILTER STATE------------------------
    let queryParams = getQueryParams(window.location.search);

    const getFilterCount = useCallback(() => {
        let count = 0;
        Object.entries(queryParams || {})?.forEach(([key, value]) => {
            if (!["page", "limit", "key", "expert_type", ""].includes(key)) {
                count = count + value?.length;
            }
        });
        return count;
    }, [queryParams]);

    const filterRef = useRef({
        count: getFilterCount() || 0,
        search: false,
    });
    const callFilter = useRef(false);
    const onlyFirstTimeAndSearch = useRef(false);

    const [loadingOption, setLoadingOption] = useState(false);
    const [loadingExperts, setLoadingExperts] = useState(false);

    const [current_company, setCompany] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.current_company)
            ? queryParams?.current_company
            : []
    );
    const [previous_company, setPreviousCompany] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.previous_company)
            ? queryParams?.previous_company
            : []
    );

    const [industry, setIndustry] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.industry)
            ? queryParams?.industry
            : []
    );
    const [country, setCountry] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.country)
            ? queryParams?.country
            : []
    );
    const [designation, setDesignation] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.designation)
            ? queryParams?.designation
            : []
    );
    const [searchRgx, setSearchRgx] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.key)
            ? queryParams?.key
            : []
    );
    const [experties, setExperties] = useState(
        ![undefined, "undefined", ""].includes(queryParams?.tags)
            ? queryParams?.tags
            : []
    );
    const [currentPage, setCurrentPage] = useState(
        ![undefined, "undefined"].includes(queryParams?.page)
            ? parseInt(queryParams.page)
            : 1
    );
    const [pageSize, setPageSize] = useState(
        ![undefined, "undefined"].includes(queryParams?.limit)
            ? parseInt(queryParams.limit)
            : 20
    );
    const [allGeog, setAllGeog] = useState([]);
    const [allIndustry, setAllIndustry] = useState([]);
    const [allCompany, setAllCompany] = useState([]);
    const [allPreviousCompany, setAllPreviousCompany] = useState([]);
    const [allDesig, setAllDesig] = useState([]);
    const [allExperties, setAllExperties] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [searchParams] = useSearchParams();


    const stateListFilter = useMemo(() => ({
        allGeog,
        allIndustry,
        allCompany,
        allPreviousCompany,
        allDesig,
        allExperties, wordEntered
    }), [allCompany, allDesig, allExperties, allGeog, allIndustry, allPreviousCompany, wordEntered]);
    const stateFunctionList = useMemo(() => ({
        setCompany,
        setPreviousCompany,
        setIndustry,
        setCountry,
        setDesignation,
        setExperties,
        setCurrentPage,
        setWordEntered,
        setSearchRgx,
    }), [])
    const stateFunctionListFilter = useMemo(() => ({
        setAllGeog,
        setAllIndustry,
        setAllCompany,
        setAllPreviousCompany,
        setAllDesig,
        setAllExperties,
    }), [])

    const stateList = useMemo(() => ({
        current_company,
        previous_company,
        industry,
        country,
        designation,
        experties,
        searchRgx,
        currentPage, pageSize,
    }), [country, currentPage, current_company, designation, experties, industry, pageSize, previous_company, searchRgx])
    const queryParamater = useMemo(
        () => ({
            industry: industry ? industry : "",
            country: country ? country : "",
            designation: designation ? designation : "",
            key: searchRgx ? searchRgx : "",
            tags: experties ? experties : "",
            current_company: current_company ? current_company : "",
            previous_company: previous_company ? previous_company : "",
            page: currentPage ? currentPage : "1",
            limit: pageSize ? pageSize : "20",
        }),
        [industry, country, designation, searchRgx, experties, current_company, previous_company, currentPage, pageSize]
    );
    function filterDuplicates(array) {
        return Array.from(new Set(array));
    }
    const history = useNavigate();
    const filterValues = useMemo(() => {
        let applied = [];
        searchParams?.forEach((value, key) => {
            if (!["key", "page", "limit", "expert_type"].includes(key)) {
                if (value !== "") {
                    applied.push(value);
                }
            }
        });
        if (applied?.length > 0) {
            return true;
        } else {
            return false;
        }
    }, [searchParams]);

    const getExperts = useCallback(async ({ clearAll = false, clearSearch = false, page_no = null, pageSize = 20, key = "" }) => {
        try {
            setLoadingExperts(true)
            let params_obj = {};
            if (clearAll) {
                params_obj = {
                    industry: "",
                    country: "",
                    designation: "",
                    key: clearSearch ? "" : searchRgx, //! need to add this here
                    tags: "",
                    current_company: "",
                    previous_company: "",
                    page: "1",
                    limit: pageSize,
                };
            } else {
                if (page_no !== null) {
                    params_obj = { ...queryParamater, page: page_no };
                } else {
                    params_obj = { ...queryParamater, page: 1 };
                }
            }
            let paramsforQuery = new URLSearchParams([
                ...Object.entries({
                    ...toQueryString(params_obj),
                }),
            ]);
            history({
                pathname: `/find-expert`,
                search: `${paramsforQuery}`,
                // search: `${new_params?.toString()}`,
            });
            const response = await axios.get(API_URL.GET_EXPERT_DATA + `?${paramsforQuery}`);
            dispatch(setExpertLabList({ data: response?.data?.data, count: response?.data?.countData }));
            // setExperts([...res.data.data]);
            // setAlldatas(res.data.countData);
            if (callOnceFilter.current) {
                setAllCompany(filterDuplicates(response?.data?.company));
                setAllPreviousCompany(filterDuplicates(response?.data?.previousCompany));
                setAllGeog(filterDuplicates(response?.data?.country));
                setAllIndustry(filterDuplicates(response?.data?.industry));
                setAllDesig(filterDuplicates(response?.data?.designationNew));
                setAllExperties(filterDuplicates(response?.data?.tags));
            }
            setLoadingExperts(false)

            // setLoading(false);
            return true;
            // if (search) {
            //     dispatch(setExpertPagination({ page: 1, limit: 20 }))
            //     const response = await axios.get(API_URL.GET_EXPERT_DATA + `?search=${key}&page=${page}&limit=${limit}&user_id=${userData?.user?._id}`);
            //     dispatch(setExpertList({ data: response?.data?.data, count: response?.data?.countData }));
            // } else {
            //     dispatch(setExpertPagination({ page: page, limit: limit }))
            //     const response = await axios.get(API_URL.GET_EXPERT_DATA + `?&page=${page}&limit=${limit}&user_id=${userData?.user?._id}`);
            //     dispatch(setExpertList({ data: response?.data?.data, count: response?.data?.countData }));
            // }

        } catch (error) {
            setLoadingExperts(false)

            console.error("ERROR", error);
        }
    }, [dispatch, history, queryParamater, searchRgx]);

    useEffect(() => {
        if (!onlyFirstTimeAndSearch.current && location.pathname === "/find-expert") {
            onlyFirstTimeAndSearch.current = true;
            let queryParams = queryString.parse(window.location.search);
            let handler;
            setCurrentPage(1)
            if (Object.keys(queryParams)?.length > 0 && ![undefined, null, ""]?.includes(queryParams?.key)) {
                handler = setTimeout(async () => {
                    getExperts({ clearAll: false, clearSearch: false });
                }, 1000);
            } else {
                getExperts({ clearAll: false, clearSearch: false });
            }
            return () => {
                clearTimeout(handler);
            };
        }
    }, [getExperts, location.pathname]);

    const clearInput = useCallback(() => {
        setWordEntered("");
        setSearchRgx("");
        if (!filterValues) {
            callFilter.current = false;
            callOnceFilter.current = false;
            getExperts({ clearAll: true, clearSearch: true });
        }
    }, [filterValues, getExperts]);

    const getExpertsOptions = useCallback(() => {
        const queryParamater = {
            industry: industry ? industry : "",
            country: country ? country : "",
            designation: designation ? designation : "",
            tags: experties ? experties : "",
            current_company: current_company ? current_company : "",
            previous_company: previous_company ? previous_company : "",
            key: searchRgx ? searchRgx : "",
            expert_type: "",
        };

        setLoadingOption(true);

        let paramsforQuery = new URLSearchParams([
            ...Object.entries({
                ...toQueryString({ ...queryParamater }),
            }),
        ]);
        axios.get(`${API_URL.EXPERT_FILTER_OPTION}?${paramsforQuery.toString()}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.token}`,
            },
        })
            .then((res) => {
                setAllCompany(filterDuplicates(res?.data?.company));
                setAllPreviousCompany(filterDuplicates(res?.data?.previousCompany));
                setAllGeog(filterDuplicates(res?.data?.country));
                setAllIndustry(filterDuplicates(res?.data?.industry));
                setAllDesig(filterDuplicates(res?.data?.designationNew));
                setAllExperties(filterDuplicates(res?.data?.tags));
                setLoadingOption(false); // Move setLoading to false here to trigger a re-render after data is fetched
            })
            .catch((error) => {
                console.error(error);
                setLoadingOption(false);
            });
        setLoadingOption(false);
    }, [
        current_company,
        designation,
        experties,
        country,
        industry,
        previous_company,
        searchRgx,
        setAllCompany,
        setAllDesig,
        setAllExperties,
        setAllGeog,
        setAllIndustry,
        setAllPreviousCompany,
        userData?.token,
    ]);

    const handleSearchExperts = useCallback((clearAll = false) => {
        let queryParams = queryString.parse(window.location.search);
        let handler;
        setCurrentPage(1);
        if (queryParams) {
            handler = setTimeout(async () => {
                getExperts({ clearAll: clearAll, clearSearch: true });
                filterRef.current = {
                    ...filterRef.current,
                    search: clearAll ? false : true,
                };
            }, 1000);
        } else {
            getExperts({ clearAll: clearAll, clearSearch: true });
        }
        return () => {
            clearTimeout(handler);
        };
    }, [getExperts]);

    const handlePageChange = useCallback(async (pageNumber, pageSize) => {
        setCurrentPage(pageNumber);
        setPageSize(pageSize)
        await getExpertsOptions();
        await getExperts({ clearAll: false, clearSearch: false, page_no: pageNumber, pageSize: pageSize });
    }, [getExperts, getExpertsOptions]);
    //---------------------EXPERT FILTER STATE------------------------


    useOnceCall(() => {
        dispatch(setExpertPagination({ page: 1, limit: 20 }))
        dispatch(setSelectedExpert({}));
    })
    let experienceDetails = useMemo(() => {
        let currentExperience = expertprofile?.[0]?.current_experience || [];
        let previousExperience = expertprofile?.[0]?.previous_experience || [];
        return [
            ...currentExperience,
            ...previousExperience
        ]
    }, [expertprofile]);

    const getStore = useCallback(() => {
        var experience = [];
        for (var i = 0; i < experienceDetails?.length; i++) {
            experience?.push(experienceDetails[i]);
        }
        var CN = [];
        // function uniqueData(data) {
        // var companyName = [];
        for (var i = 0; i < experience.length; i++) {
            if (CN.indexOf(experience[i].company_name) == -1) {
                CN.push(experience[i].company_name);
            }
        }
        // CN.push(...companyName);
        var store = [];

        for (let i = 0; i < CN.length; i++) {
            let local = [];
            let print = [];
            for (let j = 0; j < experience.length; j++) {
                if (CN[i] == experience[j].company_name) {
                    let obj = {};
                    obj.title = experience[j].company_name;
                    obj.dateFrom = experience[j].date_from;
                    obj.dateTill = experience[j].date_till;
                    obj.whatWork = experience[j].what_work;
                    obj.location = experience[j].location;
                    obj.tillPresent = experience[j].till_present;
                    obj.logoUrl = experience[j].logo_url;
                    obj.designation = experience[j].career_title;
                    local.push(obj);
                }
            }
            print.push(CN[i], local);
            store.push(print);
        }
        return store
        // }
        // uniqueData(experience);
    }, [experienceDetails])

    const getAllExperts = useCallback(async () => {
        const response = await axios.get(API_URL.GET_EXPERT_DATA + `?page=1&limit=20&user_id=${userData?.user?._id}`);
        return response.data
    }, [userData?.user?._id]);

    const getAllExpertsData = useSWR("get_all_expert_data", () => location.pathname !== "/find-expert" && getAllExperts(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            if (!callOnce.current) {
                callOnce.current = true
                // if (data?.data?.length > 0) {
                //     dispatch(setSelectedExpert(data?.data?.[0]));
                // }
                dispatch(setExpertList({ data: data?.data || [], count: data?.countData || 0 }));//for selecting the default one
            }
        },
        onError: (err) => {
            console.error("ERROR", err)
        }
    })
    const { isLoading } = getAllExpertsData

    const fetchExperts = useCallback(async ({ search = false, key = "", page = expertPagination?.page, limit = expertPagination?.limit }) => {
        try {
            if (search) {
                dispatch(setExpertPagination({ page: 1, limit: 20 }))
                const response = await axios.get(API_URL.GET_EXPERT_DATA + `?search=${key}&page=${page}&limit=${limit}&user_id=${userData?.user?._id}`);
                dispatch(setExpertList({ data: response?.data?.data, count: response?.data?.countData }));
            } else {
                dispatch(setExpertPagination({ page: page, limit: limit }))
                const response = await axios.get(API_URL.GET_EXPERT_DATA + `?&page=${page}&limit=${limit}&user_id=${userData?.user?._id}`);
                dispatch(setExpertList({ data: response?.data?.data, count: response?.data?.countData }));
            }

        } catch (error) {
            console.error("ERROR", error);
        }
    }, [dispatch, expertPagination?.limit, expertPagination?.page, userData?.user?._id]);


    const handleItemClick = useCallback((data) => {
        dispatch(setSelectedExpert(data));
    }, [dispatch]);

    const getExpertById = useCallback(async (id) => {
        try {
            const response = await axios.get(API_URL.GET_EXPERT_DATA_BY_ID + `?expert_id=${id}`);
            dispatch(setExpertProfile(response?.data?.data));
        } catch (error) {
            console.error("ERROR", error);
        }
    }, [dispatch]);

    const expertSummery = useCallback((data) => {
        if (data !== null && data !== "" && data !== undefined) {
            // Split the input data into an array of paragraphs based on numeric indicators
            const paragraphs = data?.split(/\d+\.\s+/);

            return (
                <div className="comon-divs-re" style={{ width: "100%", paddingRight: "20px" }}>
                    <div className="pvs-header__title-container">
                        <h2 className="pvs-header__title text-heading-large">
                            <span aria-hidden="true" style={{ fontSize: "20px", fontWeight: "400" }}>
                                Summary
                            </span>
                        </h2>
                    </div>

                    <div className="inline-show-more-text">
                        {paragraphs?.map((paragraph, index) => (
                            <p key={index} style={{ fontSize: "14px" }}>
                                {paragraph?.trim()}
                            </p>
                        ))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }, [])


    const values = useMemo(() => ({
        fetchExperts,
        isLoading,
        getExpertById,
        expertList, expertLabList,
        handleItemClick,
        expertprofile,
        getStore,
        expertSummery,
        stateListFilter,
        stateFunctionList,
        stateFunctionListFilter, queryParamater, handleSearchExperts, handlePageChange, clearInput, loadingOption, stateList, callOnceFilter, callFilter, getExpertsOptions, filterRef, onlyFirstTimeAndSearch, getExperts, loadingExperts, setLoadingExperts

    }), [fetchExperts, isLoading, getExpertById, expertList, expertLabList, handleItemClick, expertprofile, getStore, expertSummery, stateListFilter, stateFunctionList, stateFunctionListFilter, queryParamater, handleSearchExperts, handlePageChange, clearInput, loadingOption, stateList, getExpertsOptions, filterRef, callFilter, onlyFirstTimeAndSearch, getExperts, loadingExperts, setLoadingExperts])

    return values
}

export default useExpert