import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../App.css";
import $ from "jquery";
import API_PATH from "../../../Constants/api-path";
import Transcript from "./Transcript";

function Collaborate() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setFolderData] = useState("");
  const [recordingData, setRecordingData] = useState({
    countData: 0,
    data: []
  });
  const [pagination, setPagination] = useState({
    page: 1,
    size: 5
  });

  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const headers = React.useMemo(() => ({
    "x-access-token": userData.access_token,
  }), [userData.access_token]);

  const getFolders = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_LIST}/${userData.user._id}/false`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setFolderData(result);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getRecordings = useCallback(async ({ searchString = "", currentPage = pagination.page, isSearching = false, limit = pagination.size }) => {
    setSpinner(true);
    try {
      let domain = userData.user.email.split("@")[1];
      const res = await fetch(
        `${API_PATH.MEETING_LIST}/${domain}/${userData.user._id}/false?search=${searchString}&page=${currentPage}&limit=${limit}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const result = await res.json();
      if (result) {
        let obj
        if (isSearching) {
          obj = {
            countData: result?.countData,
            data: [...result?.data]
          }
          setRecordingData(obj);
        } else {
          obj = {
            countData: result?.countData,
            data: [...result?.data]
            // data: [...recordingData?.data, ...result?.data]
          }
          setRecordingData(obj);
        }

        setSpinner(false);
      } else {
        setSpinner(false);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setSpinner(false);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, pagination.page, pagination.size, userData.user._id, userData.user.email]);



  const onceCalled = useRef(false)
  useEffect(() => {
    if (!onceCalled.current) {
      onceCalled.current = true
      getRecordings({ searchString: "", currentPage: 1 });
    }
  }, [getRecordings]);



  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  document.title = "Nextyn IQ -  Call Recordings";
  return (
    <>
      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflowY: "scroll" }}
      >
        <div className="request-page projects-scope">
          <div className="request-body-sec collaborate-page">
            { }
            <div className="folder-sections">
              <Transcript
                recordingList={recordingData}
                setSpinner={setSpinner}
                spinner={spinner}
                getRecordings={getRecordings}
                setPagination={setPagination}
                pagination={pagination}
              />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Collaborate;
