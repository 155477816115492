import { Button, Divider, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Loader from './Loader';

const UnlockTranscriptModal = ({ isModalOpen, handleCancel, unlockFunction }) => {
    const creditsData = useSelector((state) => state?.user?.all_credits);
    const [loading, setLoading] = useState(false)
    return (
        <Modal
            centered
            title={"Unlock Transcript"}
            open={isModalOpen}
            onCancel={handleCancel}

            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    style={{
                        backgroundColor: "rgb(76, 187, 23)",
                        borderColor: "rgb(76, 187, 23)",
                        color: "#fff",
                    }}
                    onClick={async () => {
                        setLoading(true)
                        await unlockFunction()
                        setLoading(false)
                    }}
                >
                    {loading ? "Unlocking" : "Unlock"} {loading && <Spin className='text-center ms-2' size='small' />}
                </Button>,
            ]}
        >
            <Divider style={{ margin: "10px" }} />
            <p className='mb-2'>
                Unlocking this transcript will utilize{" "}
                <span className="fw-bolder ">
                    {creditsData?.credits_for_transcript} Credits.
                </span>
            </p>
            <p className='mb-2'>
                Once unlocked, you will have permanent access to the transcript,
                audio player and our in built AI assistant.
            </p>
            <p>
                You can access the
                same in the "Unlocked Transcripts" section in the "Transcripts"
                tab in the sidebar of your dashboard.
            </p>
            <Divider style={{ margin: "10px" }} />
        </Modal>
    )
}

export default UnlockTranscriptModal
