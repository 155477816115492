import React, { useState } from "react";
import "../../../App.css";
import "../../../assests/css/custom.css";
import { Form, Tooltip, Space, List, Button } from "antd";
import { Col, Modal, Row } from "react-bootstrap";
import { MdDelete, MdBorderColor } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useUserManagement from "./useUserManagement";
import Loader from "../../Components/Loader";
import { setIsRoleDescript, setIsRoleName, setRoleType } from "./userManagement.slice";

function RolesMangementTable({ load }) {
  const dispatch = useDispatch();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [form] = Form.useForm();
  const { isChecked, roleList, isRoleName, isRoleDescrip, roleType } = useSelector(
    (state) => state.userManagementReducer
  );
  const {
    spinner,
    deleteUserModal,
    handleInputs,
    setRoleModal,
    updateRoles,
    getUserId,
  } = useUserManagement();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const columns = [
    {
      title: "ID",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const addedBy = record?.name;
        if (!addedBy || !addedBy[0]) {
          // Handle the case when 'addedBy' or 'addedBy[0]' is not defined
          return null; // or any appropriate fallback
        }

        return (
          <div>
            <span>{addedBy}</span>
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",

      render: (record) => (
        <>
          {record?.type !== "default" ? (
            <Space size="middle">
              {permission?.userManagement?.update && (
                <Tooltip color="#5746EB" title="Edit" placement="top">
                  <MdBorderColor
                    onClick={() => {
                      setRoleModal(record);
                      setCreateModal(true);
                    }}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  ></MdBorderColor>
                </Tooltip>
              )}
              {permission?.userManagement?.delete && (
                <Tooltip color="#5746EB" title="Delete" placement="top">
                  <MdDelete
                    onClick={() => {
                      getUserId(record);
                      setDeleteModal(true);
                    }}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                </Tooltip>
              )}
            </Space>
          ) : (
            "-"
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {spinner ? (
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: "50vh" }}
        >
          <Loader />
        </div>
      ) : (
        <List
          className="mt-3"
          size="small"
          header={
            <div>
              <>
                <Row className="w-100">
                  <Col lg={1}>
                    <strong className="fw-bolder">ID</strong>
                  </Col>
                  <Col lg={3}>
                    <strong className="fw-bolder">Role</strong>
                  </Col>
                  <Col lg={7}>
                    <strong className="fw-bolder">Description</strong>
                  </Col>
                  <Col lg={1}>
                    <strong className="fw-bolder">Action</strong>
                  </Col>
                </Row>
              </>
            </div>
          }
          bordered
          loading={{
            spinning: spinner,
            indicator: <Loader />,
          }}
          dataSource={roleList ?? []}
          renderItem={(item, index) => (
            <List.Item>
              <Row className="w-100 mt-1">
                <Col lg={1}>{index + 1}</Col>
                <Col lg={3}>{item?.name}</Col>
                <Col lg={7}>{item?.description}</Col>
                <Col lg={1}>
                  <Space size="middle">
                    {/* {item?.type !== "default" && ( */}
                    <>
                      <Tooltip color="#5746EB" title="Edit" placement="top">
                        <button
                          className="viewBttnExpert"
                          onClick={() => {
                            dispatch(setRoleType(item?.type))
                            setRoleModal(item);
                            setCreateModal(true);
                          }}
                        >
                          <MdBorderColor
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          />
                        </button>
                      </Tooltip>

                      {/* <Tooltip color="#5746EB" title={item?.type === "default" ? "Default roles can't be deleted" : "Delete"} placement="top"> */}
                      <button
                        className="viewBttnExpert me-2"
                        disabled={item?.type === "default"}
                        style={{
                          opacity: item?.type === "default" ? 0.5 : ""
                        }}
                        onClick={() => {
                          getUserId(item);
                          setDeleteModal(true);
                        }}
                      >
                        <MdDelete
                          style={{ fontSize: "18px", cursor: item?.type === "default" ? "not-allowed" : "pointer" }}
                        />
                      </button>
                      {/* </Tooltip> */}
                    </>
                    {/* )} */}
                    {/* {item?.type === "default" && "-"} */}
                  </Space>
                </Col>
              </Row>
            </List.Item>
          )}
          style={{ background: "#ffff" }}
        ></List>
      )}

      {/* <Table
        dataSource={roleList}
        columns={columns}
        loading={load}
        pagination={false}
        // onChange={handleTableChange}
        scroll={{ x: 800 }}
        size="large"
      /> */}

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"md"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Header>
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Delete Role</h4>
          </div>
        </Modal.Header>
        <Modal.Body className="position-relative">
          <div>
            <span style={{ fontSize: "16px" }}>
              {" "}
              Are you sure you want to delete this role? This action cannot be
              undone.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Button key="back" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            key="submit"
            style={{
              backgroundColor: "rgb(76, 187, 23)",
              borderColor: "rgb(76, 187, 23)",
              color: "#fff",
            }}
            onClick={() => {
              deleteUserModal();
              setDeleteModal(false);
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"xl"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Header>
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit User</h4>
          </div>
        </Modal.Header>
        <Modal.Body className="position-relative">
          <div>
            <Form form={form} layout="vertical">
              <div className="projects-modal-divs">
                {/* <div className="d-flex position-relative align-items-center">
                    <h2 className="text-center mx-auto"> Edit Role </h2>
                  </div> */}
                {/* <p className="text-center">
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur{" "}
                  </p>  
 */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Role *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={isRoleName}
                        onChange={(e) =>
                          dispatch(setIsRoleName(e.target.value))
                        }
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Description *</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        value={isRoleDescrip}
                        onChange={(e) =>
                          dispatch(setIsRoleDescript(e.target.value))
                        }
                      ></textarea>
                    </div>
                  </div>

                  <label>Role Permission *</label>
                  <div className="table-responsive">
                    <table className="table-flush-spacing table">
                      <tbody>
                        <tr>
                          <td
                            className="text-nowrap"
                            style={{
                              color: "#5746ec",
                              fontWeight: "500",
                            }}
                          >
                            Project
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{
                                  paddingLeft: "3.5rem",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectAdd}
                                  name="projectAdd"
                                  value={isChecked.projectAdd}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Add</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectView}
                                  name="projectView"
                                  value={isChecked.projectView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectDelete}
                                  name="projectDelete"
                                  value={isChecked.projectDelete}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Delete
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="projectMessage"
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.projectMessage}
                                  value={isChecked.projectMessage}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Message
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="projectList"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.projectList}
                                  checked={isChecked.projectList}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Expert List
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-nowrap"
                            style={{
                              color: "#5746ec",
                              fontWeight: "500",
                            }}
                          >
                            Call Recording
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{
                                  paddingLeft: "3.5rem",
                                }}
                              >
                                <input
                                  name="collaborationView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.collaborationView}
                                  checked={isChecked.collaborationView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-wrap">
                            <span
                              className="me-50"
                              style={{
                                color: "#5746ec",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              Experts
                            </span>{" "}
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{ paddingLeft: "3.5rem" }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.expertView}
                                  name="expertView"
                                  value={isChecked.expertView}
                                  onChange={handleInputs}
                                />
                                <label
                                  htmlFor="select-all"
                                  className="form-check-label"
                                >
                                  View
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isChecked.expertRequest}
                                  name="expertRequest"
                                  value={isChecked.expertRequest}
                                  onChange={handleInputs}
                                />
                                <label
                                  htmlFor="select-all"
                                  className="form-check-label"
                                >
                                  Request
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="text-nowrap"
                            style={{
                              color: "#5746ec",
                              fontWeight: "500",
                            }}
                          >
                            Transcript
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{
                                  paddingLeft: "3.5rem",
                                }}
                              >
                                <input
                                  name="transcriptView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.transcriptView}
                                  checked={isChecked.transcriptView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="transcriptUnlock"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.transcriptUnlock}
                                  checked={isChecked.transcriptUnlock}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Unlock
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-nowrap"
                            style={{
                              color: "#5746ec",
                              fontWeight: "500",
                            }}
                          >
                            User Management
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{
                                  paddingLeft: "3.5rem",
                                }}
                              >
                                <input
                                  name="userManagementAdd"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementAdd}
                                  checked={isChecked.userManagementAdd}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">Add</label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementView"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementView}
                                  checked={isChecked.userManagementView}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">View</label>
                              </div>

                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementUpdate"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementUpdate}
                                  checked={isChecked.userManagementUpdate}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Update
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="userManagementDelete"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.userManagementDelete}
                                  checked={isChecked.userManagementDelete}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Delete
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="text-nowrap"
                            style={{
                              color: "#5746ec",
                              fontWeight: "500",
                            }}
                          >
                            Credits
                          </td>
                          <td>
                            <div className="d-flex">
                              <div
                                className="form-check me-3 me-lg-5"
                                style={{
                                  paddingLeft: "3.5rem",
                                }}
                              >
                                <input
                                  name="creditTransaction"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.creditTransaction}
                                  checked={isChecked.creditTransaction}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  Transcation
                                </label>
                              </div>
                              <div className="form-check me-3 me-lg-5">
                                <input
                                  name="creditHistory"
                                  type="checkbox"
                                  className="form-check-input"
                                  value={isChecked.creditHistory}
                                  checked={isChecked.creditHistory}
                                  onChange={handleInputs}
                                />
                                <label className="form-check-label">
                                  History
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Button key="back" onClick={() => setCreateModal(false)}>
            Cancel
          </Button>
          <Tooltip title={roleType === "default" && isRoleName === "Administrator" ? "Administrator role can't be edited" : ""}>
            <Button
              key="submit"
              style={{
                backgroundColor: "rgb(76, 187, 23)",
                borderColor: "rgb(76, 187, 23)",
                color: "#fff",
              }}
              disabled={roleType === "default" && isRoleName === "Administrator"}
              onClick={() => {
                setCreateModal(false);
                updateRoles();
              }}
            >
              Submit
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default RolesMangementTable;
