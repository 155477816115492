import { List, Pagination, Tooltip } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenAiModal, setSelectedArticle } from "../../Dashboard/news.slice";
import AiSummaryModal from "../../../Components/AiSummaryModal";
import useDashboard from "../../../Hooks/useDashboard";
import { checkForValidURL, formattedDate } from "../../../../Utils/Util";
import Loader from "../../../Components/Loader"
import company_icon3 from "../../../../assests/images/company_notfound.png"

function NewsSection({
  isLoadingNews,
  fetchNewsData,
  isValidatingNews
}) {
  const { news_data, newsPagination, is_loading_content } = useSelector(
    (state) => state.SearchPage
  );
  const dispatch = useDispatch();

  const { getNewsAiSummary } = useDashboard();
  return (
    isLoadingNews || is_loading_content || isValidatingNews ? (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '50vh' }}>
          <Loader />
        </div>
      </>
    ) : (
      <>
        <div className="page-height">
          <List
            size="small"
            header={
              <>
                <div>
                  <Row className="w-100 mt-1">
                    <Col lg={9}>
                      <strong className="fw-bolder">Headline</strong>
                    </Col>
                    <Col lg={2}>
                      <strong className="fw-bolder">Source</strong>
                    </Col>
                    <Col lg={1}>
                      <strong className="fw-bolder">Date</strong>
                    </Col>
                  </Row>
                </div>
              </>
            }
            bordered
            dataSource={news_data?.data || []}
            renderItem={(data, index) => (
              <div className="">
                <>
                  <List.Item
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setIsOpenAiModal(true));
                      dispatch(setSelectedArticle(data));
                      getNewsAiSummary({ news: data });
                      // navigate("/news/full-article");
                    }}
                  >
                    <Row className="w-100 align-items-center">
                      <Col lg={9}>
                        <Tooltip title={"Click for AI Summary"}>

                          <span className="fw-normal p-2">
                            {data?.title}
                          </span>

                        </Tooltip>
                      </Col>
                      <Col lg={2}>
                        <span className="d-flex align-items-center gap-2">
                          <span>
                            <img
                              src={
                                checkForValidURL(data?.source?.icon)
                              }
                              alt="logo"
                              className="img-contain"
                              height={"25px"}
                              width={"35px"}
                              onError={(e) => {
                                e.target.src = company_icon3;
                              }}
                            />
                          </span>
                          <small className="fw-normal truncate-text">
                            {data?.source?.name}
                          </small>
                        </span>
                      </Col>
                      <Col lg={1}>
                        <small className="fw-normal text-muted">
                          {formattedDate(data?.article_date)}{" "}
                        </small>
                      </Col>
                    </Row>
                  </List.Item>
                </>
              </div>
            )}
            style={{ borderRadius: "4px", background: "#ffff" }}
          />
          {news_data?.count > 20 && <Pagination
            className="text-end mt-2"
            size="small"
            total={news_data?.count}
            current={newsPagination?.page}
            pageSize={newsPagination?.limit}
            onChange={(page, pageSize) => {
              fetchNewsData({ page_no: page, page_limit: pageSize });
            }}
            showSizeChanger={true}
            disabled={false}
          />}
        </div>
        < AiSummaryModal />
      </>
    )
  );
} export default NewsSection;
