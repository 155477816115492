import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import API_URL from '../../Constants/api-path'
import { setProjectListById } from "../Pages/V2/Dashboard/Project.Slice"
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { toast } from 'react-toastify';


const useProject = () => {

    const dispatch = useDispatch()
    const userData = JSON.parse(localStorage.getItem("userData"));

    const getProjectListById = useCallback(async (searchInput = "") => {
        try {
            const response = await axios.get(API_URL.GET_PROJECT_LIST_BY_ID + `/${userData?.user?.email}?page=${1}&limit=${20}&search=${searchInput}`);
            // return response?.data
            dispatch(setProjectListById({ data: response?.data?.data || [], count: response?.data?.countData || 0 }));
        } catch (error) {
            console.error("ERROR", error);
        }
    }, [dispatch, userData?.user?.email]);
    const projectListById = useCallback(async (searchInput = "") => {
        try {
            const response = await axios.get(API_URL.GET_PROJECT_LIST_BY_ID + `/${userData?.user?.email}?page=${1}&limit=${20}&search=${searchInput}`);
            return response?.data
            // dispatch(setProjectListById(response?.data?.data));
        } catch (error) {
            console.error("ERROR", error);
        }
    }, [userData?.user?.email]);

    const { isLoading, isValidating, mutate: mutateProjectList } = useSWR("projectList", () => projectListById(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (response) => {
            dispatch(setProjectListById({ data: response?.data || [], count: response?.countData || 0 }));
        },
        onError: (error) => {
            console.error("ERROR", error)
        }
    })

    const AddExpertProject = useCallback(
        async ({ project_id, expert_id }) => {
            try {
                const payload = {
                    expert_id: expert_id,
                    project_id: project_id,
                    user_id: userData?.user?._id,
                };
                const res = await fetch(API_URL.ADD_EXPERT_PROJECT, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const result = await res.json();
                if (result?.success) {
                    mutateProjectList("projectList")
                    toast.success("Expert is requested successfully!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    return result
                } else {
                    notification.error({
                        message: result?.message,
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                    return result
                }
            } catch (error) {
                console.error('ERROR', error);
                return error
            }
        },
        [mutateProjectList, userData?.user?._id]
    );

    const values = useMemo(() => ({
        isLoading: isLoading,
        isValidating: isValidating,
        getProjectListById: getProjectListById,
        AddExpertProject: AddExpertProject,
        mutateProjectList: mutateProjectList
    }), [isLoading, isValidating, getProjectListById, AddExpertProject, mutateProjectList])

    return values
}

export default useProject