import React, { useState } from "react";
import { makeStyles, Slider, withStyles } from "@material-ui/core";
import { VolumeUp, VolumeOff } from "@material-ui/icons";
import "./Control.css";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import { Popover, Space, Tooltip } from "antd";
import forward from "../../../../assests/images/forward.png";
import backward from "../../../../assests/images/backward.png";
import playback from "../../../../assests/images/playback.png";
const useStyles = makeStyles({
  volumeSlider: {
    width: "100px",
    color: "#5746ec",
  },

  bottomIcons: {
    color: "#999",
    padding: "12px 8px",
    "&:hover": {
      color: "#5746ec",
    },
  },
});

const PrettoSlider = withStyles({
  root: {
    height: "10px",
    color: "#5746ec",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#5746ec",
    border: "2px solid currentColor",
    marginTop: -3,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: "100%",
  },
  rail: {
    height: 5,
    borderRadius: 4,
    backgroundColor: "grey",
  },
})(Slider);

const Control = ({
  onPlayPause,
  playing,
  onRewind,
  onForward,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  controlRef,
  playRate,
  handleSetPlaybackRate,
}) => {
  const classes = useStyles();
  const [isShowPlaybackPanel, setIsShowPlaybackPanel] = useState();

  const handleTogglePlaybackPanel = () => {
    setIsShowPlaybackPanel(!isShowPlaybackPanel);
  };
  return (
    <div
      className="control_Container"
      style={{ paddingRight: "15px" }}
      ref={controlRef}
    >
      <div className="bottom__container">
        <div className="slider__container gap-3">
          <span className="timing">{currentTime}</span>
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onMouseSeekDown}
          />
          <span className="timing"> {duration}</span>
        </div>
        <div className="control__box">
          <div className="inner__controls">
            {/* <Tooltip title="Backward"> */}
            <div
              className="icon__btn rotate"
              onClick={onRewind}
              style={{ cursor: "pointer" }}
            >
              <img style={{ width: "30px" }} src={backward} alt="backward" />
            </div>
            {/* </Tooltip> */}
            {/* <Tooltip title={playing ? "Pause" : "Play"}> */}
            <div className="icon__btn" onClick={onPlayPause}>
              {playing ? (
                <BsPauseCircleFill fontSize="medium" />
              ) : (
                <BsPlayCircleFill fontSize="medium" />
              )}{" "}
            </div>
            {/* </Tooltip> */}
            {/* <Tooltip title="Forward"> */}
            <div
              className="icon__btn"
              onClick={onForward}
              style={{ cursor: "pointer" }}
            >
              <img style={{ width: "30px" }} src={forward} alt="forward" />
            </div>
            {/* </Tooltip> */}

            <div className="icon__btn">
              <Popover
                placement="top"
                trigger={"click"}
                arrow={false}
                // open={true}
                open={isShowPlaybackPanel}
                onOpenChange={handleTogglePlaybackPanel}
                content={
                  <>
                    <Space direction="vertical">
                      {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map(
                        (speed, index) => (
                          <>
                            <span
                              value={speed}
                              onClick={() => {
                                handleSetPlaybackRate(speed);
                                setIsShowPlaybackPanel(false);
                              }}
                              style={{
                                color: playRate === speed ? "#5746ec" : "",
                                fontWeight: playRate === speed ? "600" : "",
                                cursor: "pointer",
                              }}
                            >
                              <span className="font-semibold">
                                {speed === 1 ? "Normal" : `${speed} x`}
                              </span>
                            </span>
                          </>
                        )
                      )}
                    </Space>
                  </>
                }
              >
                {/* <span className={"cursor-pointer"}>
                  <span className="font-medium text-[13px] flex px-0.5">
                    {playRate} x
                  </span>
                </span> */}
                {/* <Tooltip title="Playback Speed"> */}
                <img
                  src={playback}
                  alt={"playback"}
                  style={{ width: "30px", cursor: "pointer" }}
                />
                {/* </Tooltip> */}
              </Popover>
            </div>

            <div className="icon__btn" onClick={onMute}>
              {mute ? (
                <VolumeOff fontSize="medium" />
              ) : (
                <VolumeUp fontSize="medium" />
              )}
            </div>

            <div className="flex__slider">
              <span className="slider__color">
                <Slider
                  className={`${classes.volumeSlider}`}
                  onChange={onVolumeChangeHandler}
                  value={volume * 100}
                  onChangeCommitted={onVolumeSeekUp}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Control;
