import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import Credit from "./Credit";
import "../Dashboard/Dashboard.css";
import "../../../App.css";

const CreditPage = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Credits"}</title>
      </Helmet>
      <div class="height-100 bg-light creditTab" id="main-screen">
        {open ? (
          <Credit />
        ) : (
          <>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div role="status">
                <Spinner animation="border" />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreditPage;
