import React from "react";

import "./ReactPlayerCustom.css";
import ReactPlayer from "react-player";
import Control from "./Control";
import { useRef } from "react";
import { formatTime } from "./Format";

function ReactPlayerCustomForRecording({
    resData,
    highlightTimestamp,
    videoPlayerRef,
    videoState,
    setVideoState,
    updateSliderInterval,
    setUpdateSliderInterval }) {
    const controlRef = useRef(null);


    //Destructuring the properties from the videoState
    const { playing, muted, volume, playbackRate, played, seeking } = videoState;

    const currentTime = videoPlayerRef?.current
        ? videoPlayerRef?.current.getCurrentTime()
        : "00:00";
    const duration = videoPlayerRef?.current
        ? videoPlayerRef?.current?.getDuration()
        : "00:00";

    const formatCurrentTime = formatTime(currentTime);
    const formatDuration = formatTime(duration);



    const rewindHandler = () => {
        //Rewinds the video player reducing 5
        // setCurrentTime(videoPlayerRef?.current?.getCurrentTime() - 10)
        videoPlayerRef?.current?.seekTo(videoPlayerRef?.current?.getCurrentTime() - 10);
    };

    const handleFastFoward = () => {
        //FastFowards the video player by adding 10
        // setCurrentTime(videoPlayerRef?.current?.getCurrentTime() + 10)
        videoPlayerRef?.current?.seekTo(videoPlayerRef?.current?.getCurrentTime() + 10);

    };

    const progressHandler = (state) => {
        if (!seeking) {
            setVideoState({ ...videoState, ...state });
        }
    };

    const seekHandler = (e, value) => {
        setVideoState({ ...videoState, played: parseFloat(value / 100) });
        videoPlayerRef?.current?.seekTo(parseFloat(value / 100));
    };

    const seekMouseUpHandler = (e, value) => {
        setVideoState({ ...videoState, seeking: false });
        videoPlayerRef?.current?.seekTo(value / 100);
    };

    const volumeChangeHandler = (e, value) => {
        const newVolume = parseFloat(value) / 100;

        setVideoState({
            ...videoState,
            volume: newVolume,
            muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
        });
    };

    const handleSetPlaybackRate = (value) => {
        setVideoState({ ...videoState, playbackRate: parseFloat(value) });
    };

    const volumeSeekUpHandler = (e, value) => {
        const newVolume = parseFloat(value) / 100;

        setVideoState({
            ...videoState,
            volume: newVolume,
            muted: newVolume === 0 ? true : false,
        });
    };

    const muteHandler = () => {
        //Mutes the video player
        setVideoState({ ...videoState, muted: !videoState.muted });
    };

    const onSeekMouseDownHandler = (e) => {
        setVideoState({ ...videoState, seeking: true });
    };

    const mouseMoveHandler = () => {
        controlRef.current.style.visibility = "visible";
    };
    const handleOnPlay = () => {
        updateSliderInterval = setInterval(() => {
            const newTime = videoPlayerRef?.current?.getCurrentTime();
            if (Math.abs(newTime - currentTime) > 1) {
                highlightTimestamp(newTime);
            }
            setUpdateSliderInterval(updateSliderInterval)
        }, 250); // Update every 250 milliseconds
    }

    const playPauseHandler = () => {
        //plays and pause the video (toggling)
        setVideoState({ ...videoState, playing: !videoState.playing });
        clearInterval(updateSliderInterval);
    };
    const handleEnded = () => {
        setVideoState({ ...videoState, playing: false });
        clearInterval(updateSliderInterval);
        // setCurrentTime(0);
    };

    return (
        <div className="d-flex w-100 flex-column" onMouseMove={mouseMoveHandler}>
            <ReactPlayer
                ref={videoPlayerRef}
                // className="player"
                url={resData?.transcript_audio?.fullAudio || resData?.recording_files?.[0]?.s3_download_url}
                playbackRate={playbackRate}
                width="100%"
                height="100%"
                playing={playing}
                volume={volume}
                muted={muted}
                onProgress={progressHandler}
                onEnded={handleEnded}
                onPlay={handleOnPlay}
            />
            <Control
                controlRef={controlRef}
                onPlayPause={playPauseHandler}
                playing={playing}
                onRewind={rewindHandler}
                onForward={handleFastFoward}
                played={played}
                onSeek={seekHandler}
                onSeekMouseUp={seekMouseUpHandler}
                volume={volume}
                onVolumeChangeHandler={volumeChangeHandler}
                onVolumeSeekUp={volumeSeekUpHandler}
                mute={muted}
                onMute={muteHandler}
                playRate={playbackRate}
                duration={formatDuration}
                currentTime={formatCurrentTime}
                onMouseSeekDown={onSeekMouseDownHandler}
                handleSetPlaybackRate={handleSetPlaybackRate}
            />
        </div>
    );
}

export default ReactPlayerCustomForRecording;
