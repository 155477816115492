import { Card, Input } from "antd";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarGroup from "react-avatar-group";
import { BsRobot } from "react-icons/bs";
import {
  convertToTitleCase,
  formattedDate,
  getModifiedDescription,
} from "../../Utils/Util";
import useProject from "../Hooks/useProject";
import {
  sendUserMessage,
  setViewOnce,
} from "../Pages/Transcript/TranscriptChat.slice";
import useTranscriptChat from "../Hooks/useTranscriptChat";

function NextynAIAssistant({ userData, assembly_response_id }) {
  const { isLoading, viewOnce } = useSelector(
    (state) => state.transcriptChatReducer
  );
  const {
    AddExpertProject,
    getProjectListById,
    mutateProjectList,
    isLoading: isLoadingProjects,
  } = useProject();
  const chatMessages = useSelector(
    (state) => state?.transcriptChatReducer?.chatMessages
  );

  const dispatch = useDispatch();
  const { fetchTransChatData } = useTranscriptChat();

  const [userInput, setUserInput] = useState("");
  const handleSendMessage = useCallback(
    ({ message = "" }) => {
      if (message === "") {
        dispatch(
          sendUserMessage([
            ...chatMessages,
            { question: userInput, answer: "" },
          ])
        );
        fetchTransChatData({
          transcriptId: assembly_response_id,
          question: userInput,
        });
        setUserInput("");
      } else {
        dispatch(
          sendUserMessage([...chatMessages, { question: message, answer: "" }])
        );
        fetchTransChatData({
          transcriptId: assembly_response_id,
          question: message,
        });
        setUserInput("");
      }
    },
    [
      chatMessages,
      dispatch,
      fetchTransChatData,
      assembly_response_id,
      userInput,
    ]
  );

  let divRef = useRef(null);

  const staticQuestions = useMemo(
    () => [
      {
        question: (
          <div
            className="chat-content mt-2 w-100"
            onClick={() => {
              setUserInput(
                "Summarize key points from this transcript in 5 bullets."
              );
              handleSendMessage({
                message:
                  "Summarize key points from this transcript in 5 bullets.",
              });
              dispatch(setViewOnce(true));
            }}
          >
            <div className="chat-empty-card">
              <p className="mb-0">
                “Summarize key points from this transcript in 5 bullets.”
              </p>
            </div>
          </div>
        ),
      },
      {
        question: (
          <div
            className="chat-content mt-2"
            onClick={() => {
              setUserInput(
                "In a couple sentences, what is this transcript about?"
              );
              handleSendMessage({
                message:
                  "In a couple sentences, what is this transcript about?",
              });
              dispatch(setViewOnce(true));
            }}
          >
            <div className="chat-empty-card">
              <p className="mb-0">
                "In a couple sentences, what is this transcript about?"
              </p>
            </div>
          </div>
        ),
      },
      {
        question: (
          <div
            className="chat-content mt-2"
            onClick={() => {
              setUserInput(
                "Generate a set of action items from this transcript."
              );
              handleSendMessage({
                message: "Generate a set of action items from this transcript.",
              });
              dispatch(setViewOnce(true));
            }}
          >
            <div className="chat-empty-card">
              <p className="mb-0">
                “Generate a set of action items from this transcript.”
              </p>
            </div>
          </div>
        ),
      },
    ],
    [dispatch, handleSendMessage]
  );

  return (
    <div>
      <Card
        title={
          <div>
            <h5 className="mb-1 txtGredient" style={{ fontSize: "20px" }}>
              Nextyn AI Assistant
            </h5>
            <p
              className="fw-normal text-muted mb-0 p-0"
              style={{ whiteSpace: "wrap" }}
            >
              Ask questions, get custom summaries or generate additional content
              from your transcript.
            </p>
          </div>
        }
        className="chat-card transcriptDetails"
        style={{
          height: "calc(100vh - 108px)",
          maxHeight: "calc(100vh - 108px)",
        }}
      >
        <div className="chat-container mb-2 d-flex align-items-end">
          {chatMessages?.length > 0 && (
            <div className="chat-messages py-3 w-100">
              <>
                <div
                  className="chat-container1"
                  ref={divRef}
                  style={{ overflowY: "scroll" }}
                >
                  <div className="w-100">
                    {chatMessages?.map((data, index) => (
                      <React.Fragment key={index}>
                        <div className="chat-message gap-1 py-2 d-flex align-items-start justify-content-center">
                          <span className="pe-1 user-message">
                            {data?.question}
                          </span>
                          <span className="text-center">
                            <AvatarGroup
                              avatars={[userData?.user?.name?.substring(0, 2)]}
                              uppercase={true}
                              className="team-info"
                              // initialCharacters={2}
                              fontColor="#5746ec"
                              // max={2}
                              size={25}
                              fontSize=".38rem"
                              backgroundColor="#fff"
                              displayAllOnHover={false}
                            />

                            {/* <img
                                      width="25"
                                      src={
                                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                                        userData?.user?.name
                                      }
                                      alt="username"
                                    /> */}
                          </span>
                        </div>
                        {data?.answer !== "" && (
                          <div className="chat-message  gap-1 d-flex align-items-start justify-content-center">
                            <span className="bot-avatar">
                              <BsRobot />
                            </span>
                            <span className="ai-message">
                              {getModifiedDescription(data?.answer)?.length >
                              1 ? (
                                data?.question?.includes("bullet points") ||
                                data?.question?.includes("bullet point") ||
                                data?.question?.includes("bullets") ? (
                                  <ul style={{ paddingLeft: "16px" }}>
                                    {getModifiedDescription(data?.answer)?.map(
                                      (data) => (
                                        <li style={{ listStyle: "disc" }}>
                                          {data?.replaceAll("-", "")}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <ul style={{ paddingLeft: "16px" }}>
                                    {getModifiedDescription(data?.answer)?.map(
                                      (data, index) => (
                                        <li
                                          style={{
                                            listStyleType: "decimal",
                                          }}
                                        >
                                          {data
                                            ?.split(`${index + 1}. `)[0]
                                            ?.replaceAll("-", "")}
                                          <p>
                                            {data
                                              ?.split(`${index + 1}. `)[1]
                                              ?.replaceAll("-", "")}
                                          </p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )
                              ) : (
                                getModifiedDescription(data?.answer)
                              )}
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <div>
                    {isLoading && (
                      <div className="chat-bubble">
                        <div className="typing">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </div>
          )}

          <div
            className="flex-column w-100"
            style={{
              display: viewOnce ? "none" : "flex",
            }}
          >
            {staticQuestions?.map((obj) => obj.question)}
          </div>
        </div>
      </Card>
      <Card className="message-container">
        <div
          className="chat-input"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Input
            placeholder="Ask something..."
            className="message-input rounded-input"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !e.altKey) {
                if (userInput?.trim().length > 0) {
                  dispatch(setViewOnce(true));
                  handleSendMessage({ message: "" });
                }
              }
            }}
            style={{ flexGrow: 1 }}
          />
          <button
            className="send-button"
            onClick={() => {
              if (userInput?.trim().length > 0) {
                dispatch(setViewOnce(true));
                handleSendMessage({ message: "" });
              }
            }}
          >
            Ask
          </button>
        </div>
      </Card>
    </div>
  );
}

export default NextynAIAssistant;
