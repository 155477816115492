import { List, Tag, Popover, Modal, Button, Tooltip } from "antd";
import React, { useState } from "react";
import useTranscriptExpertPage from "../../SearchPage/useTranscriptExpertPage";
import { useSelector, useDispatch } from "react-redux";
import {
  clearChatMessages,
  setSelectedTranscript,
} from "../../../Transcript/TranscriptChat.slice";
import { useNavigate } from "react-router-dom";
import { convertToTitleCase, formattedDate } from "../../../../../Utils/Util";
import { BsUnlock } from "react-icons/bs";
import UnlockButton from "../../../../Components/UnlockButton";
import UnlockTranscriptModal from "../../../../Components/UnlockTranscriptModal";

function TranscriptCard({ data, spendCredits, index }) {
  const history = useNavigate();
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const { selectedItem } = useSelector((state) => state.SearchPage);
  const { handleItemClick, replaceTxt } = useTranscriptExpertPage();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const creditsData = useSelector((state) => state?.user?.all_credits);
  const dispatch = useDispatch();

  const error = (credit_amount) => {
    Modal.error({
      // title: 'This is an error message',
      content: `By unlocking this transcript, ${credit_amount} credits will be utilized`,
    });
  };

  const goToDetailsPage = (curElem) => {
    history({
      pathname: `/transcript-details/${curElem?._id}`,
      state: { curElem },
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSpendCredit = async (id, category) => {
    let res = await spendCredits({
      id,
      category,
      credits_spend: creditsData?.credits_for_transcript,
      navigation: false,
    });
    return res;
  };

  const contentTags = (data) => (
    <div className="trans_popup cstmTg">
      {data?.meetingsData?.[0]?.company_id?.slice(1)?.map((company, index) => (
        <>
          {company && (
            <Tag className="me-1 mb-1" size="large" key={index}>
              {convertToTitleCase(company?.name?.trim())}
            </Tag>
          )}
        </>
      ))}
    </div>
  );

  return (
    <List.Item
      key={data?._id || "1"}
      onClick={() => handleItemClick(data)}
      className={`w-100 ${selectedItem?._id === data?._id
        ? "clickable-list-item clicked"
        : "clickable-list-item"
        }`}
    >
      <div className="w-100">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ gap: "16px" }}
        >
          <div className="w-75">
            <Tooltip title={data?.topic}>
              <p
                className="fw-bolder truncate-text mb-2 p-0"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  if (data?.unlock === true) {
                    if (permission?.transcript?.view) {
                      goToDetailsPage(data);
                      dispatch(clearChatMessages());
                    }
                  } else {
                    if (permission?.transcript?.unlock) {
                      dispatch(clearChatMessages());
                      showModal(data);
                    }
                  }
                }}
              >
                {capitalizeFirstLetter(
                  replaceTxt(data?.topic, [
                    "Nextyn",
                    "Micro",
                    "Consulting",
                    "-Consulting",
                    "Call - ",
                    "Call",
                    "-",
                  ])
                )}
              </p>
            </Tooltip>
            <p className="fw-normal mb-2 p-0">
              {data?.expertData[0]?.current_designation}
            </p>
            <div className="d-flex align-items-center cstmTg">
              {data?.meetingsData?.[0]?.company_id
                ?.slice(0, 1)
                .map((company, index) => (
                  <Tag className="me-1" size="large" key={index}>
                    {convertToTitleCase(company?.name?.trim())}
                  </Tag>
                ))}
              {data?.meetingsData?.[0]?.company_id &&
                data?.meetingsData?.[0]?.company_id.length > 1 && (
                  <Popover
                    placement="right"
                    content={contentTags(data)}
                    trigger="hover"
                  >
                    <Tag className="me-1" size="large">
                      +{data?.meetingsData?.[0]?.company_id?.length - 1}
                    </Tag>
                  </Popover>
                )}
            </div>
          </div>

          <div
            className="d-flex flex-column align-items-end w-25"
            onMouseEnter={() => setHoveredItemId(data?._id)}
            onMouseLeave={() => setHoveredItemId(null)}
          >
            <div className="d-flex justify-content-end align-items-center w-100 mb-2">
              <UnlockButton
                data={data}
                hoveredItemId={hoveredItemId}
                unlockFunction={() => {
                  showModal();
                }}
              />
              {/* {data?.unlock === true ? (
                <Tag
                  bordered={false}
                  className="tagContainer view"
                  onClick={() => {
                    if (permission?.transcript?.view) {
                      goToDetailsPage(data);
                      dispatch(clearChatMessages());
                    }
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BsUnlock className="me-1 tag_icon" />
                    View
                  </span>
                </Tag>
              ) :
                hoveredItemId === data?._id && permission?.transcript?.unlock ? (
                  <Tag
                    bordered={false}
                    className="tagContainer unlock"
                    onClick={showModal}
                  >
                    Unlock
                  </Tag>
                ) : (
                  <Tag
                    bordered={false}
                    className="tagContainer"
                    style={{
                      borderColor: "rgb(76, 187, 23)",
                      color: "rgb(76, 187, 23)",
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <PiCoinVerticalDuotone className="tag_icon" style={{ marginRight: "4px" }} />
                      {creditsData?.credits_for_transcript}
                    </span>
                  </Tag>
                )
              } */}
            </div>
            <small className="text-muted fw-normal">
              {formattedDate(data?.meetingsData?.[0]?.call_date)}
            </small>
            {/* <div>
              <small className="text-muted fw-normal">
                {formatDuration(data?.meetingsData?.[0]?.call_duration)}
              </small>
            </div> */}
          </div>
        </div>
      </div>
      <UnlockTranscriptModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        unlockFunction={async () => {
          try {
            let response = await handleSpendCredit(
              data?._id,
              "transcript",
              data
            );
            if (response) {
              goToDetailsPage(data);
              dispatch(setSelectedTranscript(data));
            }
            setIsModalOpen(false);
            return response
          } catch (error) {
            return error
          }

        }}

      />
      {/* <Modal
        centered
        title="Unlock Transcript"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              backgroundColor: "rgb(76, 187, 23)",
              borderColor: "rgb(76, 187, 23)",
              color: "#fff",
            }}
            onClick={async () => {
              let response = await handleSpendCredit(
                data?._id,
                "transcript",
                data
              );
              if (response) {
                goToDetailsPage(data);
                dispatch(setSelectedTranscript(data));
              }
              setIsModalOpen(false);
            }}
          >
            Unlock
          </Button>,
        ]}
      >
        <p>
          Unlocking this transcript will utilize{" "}
          <span className="fw-bolder">
            {creditsData?.credits_for_transcript} Credits.
          </span>
        </p>
        <p>
          Once unlocked, you will have permanent access to the transcript, audio
          player and our in built AI assistant. You can access the same in the
          "Unlocked Transcripts" section in the "Transcripts" tab in the sidebar
          of your dashboard.
        </p>
      </Modal> */}
    </List.Item>
  );
}

export default TranscriptCard;
