import { Tag } from "antd";
import moment from "moment";
import { AiOutlineTeam } from "react-icons/ai";
import { CgFileDocument, CgTranscript } from "react-icons/cg";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { ImProfile } from "react-icons/im";
import { PiPlusMinusBold } from "react-icons/pi";
import { VscLaw } from "react-icons/vsc";
import { GrUserExpert } from "react-icons/gr";
import { IoNewspaperOutline } from "react-icons/io5";
import { BiBarChartSquare } from "react-icons/bi";
import { TfiAnnouncement } from "react-icons/tfi";
import { CiStar } from "react-icons/ci";
import company_icon3 from "../assests/images/company_notfound.png";

export const convertToTitleCase = (inputString = "") => {
  return inputString.toLowerCase().replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const getModifiedDescription = (description) => {
  description = description?.replaceAll("-", "");
  description = description?.replaceAll("\n\n", "\n");
  description = description?.replaceAll("Overview:\n-", "\n");
  description = description?.replaceAll("\n-", "\n");
  description = description?.split("\n");
  return description;
};

export const formattedDate = (date) => {
  return moment(date).format("DD MMM YY");
};
export const formattedPrice = (price) => {
  const numberWithCommas = (number) => {
    return number?.toLocaleString("en-US");
  };

  const formattedPrice = numberWithCommas(price);
  return formattedPrice;
};

export const isFloat = (value) => {
  return Number(value) === value && value % 1 !== 0;
};
export const priceInCr = (value) => {
  let formatedValue = Number(String(value)?.replace(/,/g, ""));
  let yearValue = "";
  if (!isNaN(formatedValue)) {
    var formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
    });
    if (!isFloat(formatedValue)) {
      let convertToCr = formatedValue / 10000000;
      yearValue = formatter.format(convertToCr);
    } else {
      yearValue = formatedValue?.toFixed(2);
    }
  } else {
    yearValue = value;
  }
  return yearValue + " Cr.";
};

export const contentTags = (data) => (
  <>
    <div className="trans_popup" style={{ width: "500px" }}>
      {data?.meetingsData?.[0]?.company_id?.slice(4)?.map((brand) => (
        <>
          {brand && (
            <Tag className="me-1" size="large" key={brand?.title?.trim()}>
              {brand?.name?.trim()}
            </Tag>
          )}
        </>
      ))}
    </div>
  </>
);

export function truncate(str, n) {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
}
export function truncatelast(str) {
  return str?.length > 50 ? str?.substr(0, 50) + "..." : str;
}

export function isUpperCase(word) {
  return word === word.toUpperCase();
}

export const tabInfo = [
  {
    title: "About",
    logo: <ImProfile size={14} />,
  },
  {
    title: "Transcripts",
    logo: <CgTranscript size={14} />,
  },
  {
    title: "Experts",
    logo: <GrUserExpert size={14} />,
  },
  {
    title: "News",
    logo: <IoNewspaperOutline size={14} />,
  },
  {
    title: "Directors",
    logo: <AiOutlineTeam size={14} />,
  },
  {
    title: "Financials",
    logo: <BiBarChartSquare size={14} />,
  },
  {
    title: "Charges",
    logo: <HiOutlineCurrencyRupee size={14} />,
  },
  {
    title: "Rating",
    logo: <CgFileDocument size={14} />,
  },
  {
    title: "Auditors",
    logo: <PiPlusMinusBold size={14} />,
  },
  {
    title: "Associates",
    logo: (
      <svg
        _ngcontent-ng-c2314036997=""
        width="14"
        height="14 "
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          _ngcontent-ng-c2314036997=""
          d="M10.5 13.6H10.36C9.18384 13.6 8.59579 13.6 8.14657 13.3711C7.75143 13.1698 7.43017 12.8485 7.22884 12.4534C6.99995 12.0042 6.99995 11.4161 6.99995 10.24V5.76C6.99995 4.58389 6.99995 3.99583 7.22884 3.54662C7.43017 3.15148 7.75143 2.83022 8.14657 2.62889C8.59579 2.4 9.18384 2.4 10.36 2.4H10.5M10.5 13.6C10.5 14.3732 11.1268 15 11.9 15C12.6731 15 13.3 14.3732 13.3 13.6C13.3 12.8268 12.6731 12.2 11.9 12.2C11.1268 12.2 10.5 12.8268 10.5 13.6ZM10.5 2.4C10.5 3.1732 11.1268 3.8 11.9 3.8C12.6731 3.8 13.3 3.1732 13.3 2.4C13.3 1.6268 12.6731 1 11.9 1C11.1268 1 10.5 1.6268 10.5 2.4ZM3.49995 8L10.5 8M3.49995 8C3.49995 8.7732 2.87315 9.4 2.09995 9.4C1.32675 9.4 0.699951 8.7732 0.699951 8C0.699951 7.2268 1.32675 6.6 2.09995 6.6C2.87315 6.6 3.49995 7.2268 3.49995 8ZM10.5 8C10.5 8.7732 11.1268 9.4 11.9 9.4C12.6731 9.4 13.3 8.7732 13.3 8C13.3 7.2268 12.6731 6.6 11.9 6.6C11.1268 6.6 10.5 7.2268 10.5 8Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    ),
  },
  {
    title: "Litigations/Defaults",
    logo: <VscLaw size={14} />,
  },
  {
    title: "Compliance",
    logo: <CgFileDocument size={14} />,
  },
  {
    title: "Corporate Announcements",
    logo: <TfiAnnouncement size={14} />,
  },
  {
    title: "Following",
    logo: <CiStar size={18} />,
  },
];

export const convertToHtmlTranscript = (recordingData) => {
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }
  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "-";
    }
  };
  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }
  return `<div id="scriptBody" style="width: 880px">
  <h6 style="text-align:center;color:#5746ec;font-weight:600">${
    recordingData?.companyName
  } - ${replaceTxt(recordingData?.topic, [
    "nextyn",
    "micro",
    "consulting",
    "-consulting",
    "call - ",
    "call",
    "-",
  ])?.toUpperCase()} - ${convertDate(recordingData?.recording_start)}</h6>
  <div style="height:1px; background: #000"></div>
    ${recordingData?.assembly_response?.utterances?.map(
      (value) => `<div style='position:relative;margin-top:20px;'>
                      <div>
                          <h6 style='margin-bottom: 1;display:flex;align-items:center;font-weight:600;color:#000;font-size: 12px'>
                              <strong>Speaker ${value.speaker}</strong>
                              <span style='color:#8294a5;font-size:12px;font-weight:400;display:inline-block;margin-left: 14px'>
                                  ${convertMS(value?.end)}
                              </span>
                          </h6>
                          <p style='font-size:12px;color:#05294b;cursor:pointer';>${
                            value.text
                          }</p>
                      </div>
                  </div>`
    )}
    </div>`;
};

export const toQueryString = (paramsArray) => {
  let params_obj = {};
  Object.entries(paramsArray)
    ?.filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        value?.length !== 0
    )
    .forEach(([key1, value]) => {
      if (Array.isArray(value)) {
        params_obj = {
          ...params_obj,
          [key1]: JSON.stringify(value),
        };
      } else {
        if (
          [
            "page",
            "limit",
            "key",
            "expert_type",
            "search",
            "searchkey",
          ].includes(key1)
        ) {
          params_obj = {
            ...params_obj,
            [key1]: value,
          };
        } else {
          params_obj = {
            ...params_obj,
            [key1]: JSON.stringify([value]),
          };
        }
      }
    });
  return params_obj;
};

export const checkForValidURL = (url) => {
  let splitUrl = url?.split(".");
  if (
    ["https://media", "https://media-exp1"]?.includes(splitUrl?.[0]) ||
    !url
  ) {
    return company_icon3;
  } else {
    return url;
  }
};

export const getQueryParams = (query) => {
  if (query?.startsWith("?")) {
    query = query?.slice(1);
  }

  const result = {};
  let pairs = [];
  let buffer = "";
  let inBrackets = false;

  for (let i = 0; i < query?.length; i++) {
    const char = query[i];

    if (char === "[") inBrackets = true;
    if (char === "]") inBrackets = false;

    if (char === "&" && !inBrackets) {
      pairs?.push(buffer);
      buffer = "";
    } else {
      buffer += char;
    }
  }
  pairs?.push(buffer); // push the last part after the loop
  pairs.forEach((pair) => {
    const [key, value] = pair?.split("=");
    const decodedValue = decodeURIComponent(value?.replace(/\+/g, " "));
    // Check if the decoded value is a JSON array
    if (decodedValue?.startsWith("[") && decodedValue?.endsWith("]")) {
      try {
        result[key] = JSON.parse(decodedValue);
      } catch (e) {
        result[key] = decodedValue;
      }
    } else {
      result[key] = decodedValue;
    }
  });
  return result;
};
