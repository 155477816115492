import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    inputValue: "",
    activeTab: "news",
    selectedItem: {},
    searchKeywordData: {},
    transcriptData: { data: [], count: 0 },
    transcriptPagination: { page: 1, limit: 20 },
    selectedExpert: {},
    expertList: { data: [], count: 0 },
    expertPagination: { page: 1, limit: 20 },
    expertprofile: [],
    news_data: { data: [], count: 0 },
    newsPagination: { page: 1, limit: 20 },
    selected_article: {},
    search_filters: {
        company_types: [
            {
                label: "Public",
                key: "Public",
            },
            {
                label: "Private",
                key: "Private",
            }
        ],
        posted_filter: [
            {
                label: "Past 15 days",
                key: "15",
            },
            {
                label: "Past 30 days",
                key: "30",
            },

            {
                label: "Past 60 days",
                key: "60",
            },

            {
                label: "Past 90 days",
                key: "90",
            },

            {
                label: "Past year",
                key: "1_year",
            },
            {
                label: "All time",
                key: "all",
            },
        ],
    },
    selectedCompanyType: undefined,
    selectedPostDate: "all",
    page: 1,
    limit: 5,
    is_loading_description: false,
    is_open_Ai_modal: false,
    is_loading_expert_summary: false,
    isLoadingSearchBox: false,
    is_loading_content: false,
}

const slice = createSlice({
    name: "searchPage",
    initialState: initialState,
    reducers: {
        setActiveTab: (state, { payload }) => {
            state.activeTab = payload
        },
        setSearchKeyworkdData: (state, { payload }) => {
            state.searchKeywordData = payload
        },
        setSelectedItem: (state, { payload }) => {
            state.selectedItem = payload
        },
        setTranscriptData: (state, { payload }) => {
            state.transcriptData = payload
        },
        setInputValue: (state, { payload }) => {
            state.inputValue = payload
        },
        setExpertList: (state, { payload }) => {
            state.expertList = payload
        },
        setExpertProfile: (state, { payload }) => {
            state.expertprofile = payload
        },
        setSelectedExpert: (state, { payload }) => {
            state.selectedExpert = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setLimit: (state, { payload }) => {
            state.limit = payload
        },
        updateNewsData: (state, { payload }) => {
            state.news_data = payload
        },
        setSelectedArticle: (state, { payload }) => {
            state.selected_article = payload
        },
        setIsOpenAiModal: (state, { payload }) => {
            state.is_open_Ai_modal = payload
        },
        setIsLoadingDescription: (state, { payload }) => {
            state.is_loading_description = payload
        },
        setIsLoadingExpertSummary: (state, { payload }) => {
            state.is_loading_expert_summary = payload
        },
        setIsLoadingSearchBox: (state, { payload }) => {
            state.isLoadingSearchBox = payload
        },
        setSelectedPostDate: (state, { payload }) => {
            state.selectedPostDate = payload
        },
        setSelectedCompanyType: (state, { payload }) => {
            state.selectedCompanyType = payload
        },
        setIsLoadingContent: (state, { payload }) => {
            state.is_loading_content = payload
        },
        setNewsPagination: (state, { payload }) => {
            state.newsPagination = payload
        },
        setTranscriptPagination: (state, { payload }) => {
            state.transcriptPagination = payload
        },
        setExpertPagination: (state, { payload }) => {
            state.expertPagination = payload
        },
        resetAll: (state, { payload }) => {
            state.selectedItem = {}
            state.transcriptData = { data: [], count: 0 }
            state.selectedExpert = {}
            state.expertList = { data: [], count: 0 }
            state.expertprofile = { data: [], count: 0 }
            state.news_data = { data: [], count: 0 }
            state.selected_article = {}
            state.page = 1
            state.limit = 5
            state.activeTab = "news"
        },
    }
})
export const SearchPageReducer = slice.reducer
export const {
    setSelectedItem,
    setSearchKeyworkdData,
    setTranscriptData,
    setInputValue, setExpertList,
    setExpertProfile,
    setSelectedExpert,
    setPage,
    setLimit,
    updateNewsData,
    setSelectedArticle,
    setIsOpenAiModal,
    setIsLoadingDescription,
    setIsLoadingExpertSummary,
    setIsLoadingSearchBox,
    resetAll,
    setSelectedPostDate,
    setSelectedCompanyType,
    setIsLoadingContent,
    setActiveTab,
    setNewsPagination,
    setTranscriptPagination,
    setExpertPagination } = slice.actions
