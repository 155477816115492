import axios from 'axios'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import API_URL from '../../Constants/api-path'
import { useDispatch } from 'react-redux';
import { setAvailableCredits, setAllCredits } from '../Pages/Dashboard/user.slice';
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


function useUser() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const history = useNavigate();
    const dispatch = useDispatch()
    const callOnce = useRef(false)
    const getAvailableCredits = useCallback(async () => {
        await axios.get(API_URL.GET_AVAILABLE_CREDITS + `?company_id=${userData?.user?.company_id?._id}`)
            .then((res) => {
                dispatch(setAvailableCredits(res?.data?.remaining_credits))
            }).catch((err) => {
                console.error("ERROR", err)
            })
    }, [userData?.user?.company_id?._id, dispatch])

    const getAllCredits = useCallback(async () => {
        try {
            const response = await axios.get(API_URL.GET_ALL_CREDITS + `?company_id=${userData?.user?.company_id?._id}`);
            dispatch(setAllCredits(response?.data?.data[0]?.settings));
        } catch (error) {
            console.error("ERROR", error);
        }
    }, [userData?.user?.company_id?._id, dispatch]);

    const spendCredits = useCallback(
        async ({ category, id, credits_spend, curElem, navigation = true }) => {
            try {
                let payload = {
                    company_id: userData?.user?.company_id?._id,
                    credits_spend,
                    credits_spend_by_user: userData?.user?._id,
                };

                switch (category) {
                    case 'transcript':
                        payload.credits_used_for = 2;
                        payload.transcript_id = id;
                        break;
                    case 'expert':
                        payload.credits_used_for = 3;
                        payload.expert_id = id;
                        break;
                    default:
                        console.error('Invalid category');
                        return;
                }

                const res = await axios.post(API_URL.SPEND_CREDITS, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (res?.data?.status) {
                    getAllCredits();
                    getAvailableCredits();
                    if (navigation) {
                        history({
                            pathname: `/transcript-details/${curElem?._id}`,
                            state: { curElem },
                        });
                    } else {
                        return res?.data?.status
                    }
                } else {
                    notification.warning({
                        message: 'Operation not allowed',
                        description: 'You do not have permission or enough credits to perform this operation.',
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#f39c12",
                            color: "#fff",
                            border: "1px solid #e67e22",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
                return res?.data?.status
            } catch (error) {
                notification.error({
                    message: error?.response?.data?.message,
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
                console.error('ERROR', error);
                return error?.response?.data?.success
            }
        },
        [userData?.user?.company_id?._id, userData?.user?._id, getAllCredits, getAvailableCredits, history]
    );


    useEffect(() => {
        if (callOnce.current === false) {
            callOnce.current = true
            getAvailableCredits()
            getAllCredits()
        }
    }, [getAvailableCredits, getAllCredits])


    const values = useMemo(() => ({
        getAvailableCredits: getAvailableCredits,
        spendCredits: spendCredits,

    }), [getAvailableCredits, spendCredits])

    return values
}

export default useUser