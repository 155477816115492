import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import RegisterGif from "../assests/images/Register.gif";
import DemoForm from "./DemoForm";
import nextyn_logo from "../assests/images/Nextyn_logo.png";
import Nextyn_IQ_Black from "../assests/images/Nextyn_IQ_Black.png";
import sliderImg1 from "../assests/images/cmpny.png";
import sliderImg2 from "../assests/images/transcript.png";
import sliderImg3 from "../assests/images/en.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function GetDemo() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });
  document.title = "Nextyn IQ - Register for Demo";
  return (
    <>
      <div className="login-page2 register-page">
        <div className="">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col">
              <div className="login-left-part  d-none d-lg-block">
                <NavLink to="/" className="logo">
                  <img src={nextyn_logo} alt="logo" />
                </NavLink>
                <div className="logo-in-div col-lg-8 mx-auto">
                  <h1 className="text-left text-white  " data-aos="fade-down">
                    The easiest way for businesses to collaborate with India's
                    leading experts, access a library of expert call transcripts
                    and private & public company data.
                  </h1>
                </div>

                <div className="col-lg-8 mx-auto">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    className="authenticationSlider"
                    loop={true}
                    scrollbar={true}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination, Autoplay]}
                  >
                    <SwiperSlide>
                      <img src={sliderImg1} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={sliderImg2} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={sliderImg3} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>

            <div
              className="col position-relative d-flex align-items-center justify-content-center"
              // style={{ height: "100vh", overflowY: "scroll" }}
            >
              <div className="comon-fild col-lg-8">
                <NavLink className="logo d-block d-lg-none mb-5 active" to="/">
                  <img src={Nextyn_IQ_Black} alt="logo" />
                </NavLink>
                <div className="inside-login">
                  <h4 style={{ textAlign: "left" }}> Register for a Demo</h4>
                  {/* <p>
                    <span style={{ color: "#5746ec" }}>
                      Click, consult & collaborate with experts across the globe
                    </span>{" "}
                  </p> */}
                  <DemoForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetDemo;
