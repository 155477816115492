import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Card, Tag, Divider, Popover, Button, Tooltip } from "antd";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import company_icon3 from "../../../../assests/images/company_notfound.png";
import { CreateProjectModal } from "../../../../components/CreateProjectModal";
import { ProjectListModal } from "../../../../components/ProjectListModal";
import { setToggle } from "./dashboard.slice";
import useProject from "../../../Hooks/useProject";
import { setIsOpenCreatProject, setIsOpenProject } from "./Project.Slice";
import { BsStars } from "react-icons/bs";

const ExpertDetails = ({ getStore, request = true }) => {
  const { selectedExpert } = useSelector((state) => state.expert);
  const dispatch = useDispatch();
  const { projectListById } = useSelector((state) => state.project);
  const [showFullSummary, setShowFullSummary] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const capitalizeFirstLetter = React.useCallback((string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }, []);
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const { AddExpertProject, getProjectListById, isLoading, mutateProjectList } =
    useProject();
  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };
  const formatSummary = (summary) => {
    summary = summary?.replaceAll("\n\n", " \n");
    summary = summary?.replaceAll("Overview:\n-", " \n");
    summary = summary?.replaceAll("\n-", " \n");
    return summary
      .split("\n")
      .filter(Boolean)
      .map((line) => line.replace(/^\d+\.\s*/, "")); // Remove leading numbers and periods
  };
  const toggleShowMore = () => {
    setShowFullSummary(!showFullSummary);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const contentTags = (selectedExpert) => (
    <>
      <div className="trans_popup cstmTg">
        {selectedExpert?.industry_tags?.slice(1)?.map((brand) => (
          <>
            {brand && (
              <Tag className="me-1 mb-1" size="large" key={brand.trim()}>
                {capitalizeFirstLetter(brand.trim())}
              </Tag>
            )}
          </>
        ))}
      </div>
    </>
  );

  // const items = Array.isArray(projectListById) ? projectListById.map((project) => ({
  //     key: project?._id,
  //     label: project?.project_title,
  // })) : [];

  const items = [
    {
      key: "addProject",
      label: <CreateProjectModal buttonText="Add Project" />,
    },
  ];

  // const items = [
  //     {
  //         key: '1',
  //         label: (
  //             <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
  //                 1st menu item
  //             </a>
  //         ),
  //     },
  //     {
  //         key: '2',
  //         label: (
  //             <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
  //                 2nd menu item
  //             </a>
  //         ),
  //     },
  //     {
  //         key: '3',
  //         label: (
  //             <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
  //                 3rd menu item
  //             </a>
  //         ),
  //     },
  //     {
  //         key: '4',
  //         label: (
  //             <CreateProjectModal buttonText="Add Project" />
  //         ),
  //     },

  // ];

  return (
    <div>
      <Row>
        <Col md={12}>
          <Card className="comapny_container expert_details">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="cust_header mb-0">About the expert</h6>
              <div>
                {projectListById?.data &&
                projectListById?.data?.length > 0 &&
                request ? (
                  <button
                    className="btn viewButton text-end"
                    style={{
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      if (permission?.experts?.request) {
                        dispatch(setIsOpenProject(true));
                        dispatch(setIsOpenCreatProject(false));
                      }
                    }}
                  >
                    Request this Expert
                  </button>
                ) : (
                  request && (
                    <Button
                      className="btn viewButton text-end"
                      onClick={() => {
                        if (permission?.experts?.request) {
                          dispatch(setIsOpenCreatProject(true));
                          dispatch(setIsOpenProject(false));
                        }
                        // closeProjectModal(false)
                      }}
                    >
                      {"Request this Expert"}
                    </Button>
                  )
                )}
                {request && (
                  <IoCloseCircle
                    className="ms-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => dispatch(setToggle(false))}
                  />
                )}
              </div>
            </div>
            <Divider></Divider>
            <div className="expertExp">
              <Row>
                <Col>
                  <p className="fw-bolder fs-6 mb-0">
                    {selectedExpert?.currunt_designation}
                  </p>
                  <small className="text-muted fw-normal">
                    {selectedExpert?.currunt_company}
                  </small>
                  <div className="cstmTg">
                    {selectedExpert?.industry_tags
                      ?.slice(0, 1)
                      ?.map((tag, index) => (
                        <>
                          {tag && (
                            <Tag
                              rounded
                              className="fw-normal mt-2"
                              color={"rgba(0, 0, 0, 0.04)"}
                              style={{
                                borderRadius: "4px",
                                fontSize: "12px",
                                color: "#000",
                              }}
                            >
                              {capitalizeFirstLetter(tag)}
                            </Tag>
                          )}
                        </>
                      ))}
                    {selectedExpert?.industry_tags &&
                      selectedExpert?.industry_tags?.length > 1 && (
                        <Popover
                          placement="right"
                          content={contentTags(selectedExpert)}
                          trigger="hover"
                        >
                          {/* <Tooltip title={data?.brand.split(',').slice(1).join(', ').trim()}> */}
                          <Tag className="me-1" size="large">
                            +{selectedExpert?.industry_tags.length - 1}
                          </Tag>
                          {/* </Tooltip> */}
                        </Popover>
                        // </Tooltip>
                      )}
                  </div>
                </Col>
                {/* <Col md={4} className="d-flex justify-content-end text-end">
                  <div>
                    <button
                                            className="btn viewButton text-end"
                                            style={{ fontSize: "12px" }}
                                            // onClick={() => {
                                            //     handleSpendCredit(selectedExpert?._id, 'expert');
                                            // }}
                                        >
                                            Request
                                        </button>
                    <Dropdown
                                            menu={{
                                                items
                                            }}
                                            placement="bottomRight"
                                            trigger={['click']}
                                        >

                    </Dropdown>

                    <h6 className='mt-2'>Est. ${parseFloat(creditsData?.credits_for_expert * 100)?.toFixed(2)}/<small className="text-muted fw-normal">hour</small></h6>
                  </div>
                </Col> */}
              </Row>
              <Divider></Divider>
              <>
                {getStore()
                  .slice(0, showAll ? getStore().length : 3)
                  .map((exp, index) => {
                    return (
                      <div key={index}>
                        <div className={index !== 0 ? "d-flex mt-3" : "d-flex"}>
                          {exp[1][0].logoUrl !== null &&
                          exp[1][0].logoUrl !== "" ? (
                            <img
                              src={exp[1][0].logoUrl}
                              alt="logo"
                              height={"35px"}
                              width={"35px"}
                              onError={addDefaultSrc}
                            />
                          ) : (
                            <img
                              height={"35px"}
                              width={"35px"}
                              src={company_icon3}
                              alt="logo1"
                            />
                          )}

                          <div
                            className="ms-4 w-100"
                            style={{ position: "relative", top: "-2px" }}
                          >
                            <Tooltip title={exp[1][0]?.designation}>
                              <p
                                className="fw-bolder truncate-text mb-0"
                                style={{ fontSize: "12px", maxWidth: "95%" }}
                              >
                                {exp[1][0]?.designation}
                              </p>
                            </Tooltip>
                            <Tooltip title={exp[1][0]?.title}>
                              <p
                                className="fw-normal truncate-text mb-0"
                                style={{ maxWidth: "80%" }}
                              >
                                {exp[1][0]?.title}
                              </p>
                            </Tooltip>
                            <small className="text-muted fw-normal">
                              {exp[1][0]?.dateFrom +
                                " - " +
                                (exp[1][0]?.dateTill === null ||
                                exp[1][0]?.dateTill === ""
                                  ? "Present"
                                  : exp[1][0]?.dateTill)}
                            </small>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {getStore().length > 3 && (
                  <div className="text-center">
                    <Button
                      onClick={toggleShowAll}
                      className="text-start p-0"
                      type="link"
                      block
                      style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
                    >
                      {showAll ? "View Less" : "View More"}
                    </Button>
                  </div>
                )}
              </>
              <Divider></Divider>
              <div>
                <h6 className="mt-3 mb-3">
                  <BsStars style={{ fontSize: "14px" }} /> AI Summary{" "}
                </h6>
                {selectedExpert?.expert_ai_sumary ? (
                  <div className="fst-normal expert_smry">
                    {/* Display limited number of lines initially */}
                    <ul className="mb-0" style={{ paddingLeft: "20px" }}>
                      {" "}
                      {/* Add appropriate padding to align bullets */}
                      {formatSummary(selectedExpert?.expert_ai_sumary).map(
                        (entry, index) =>
                          // Only show the first 3 lines if showFullSummary is false
                          (showFullSummary || index < 3) && (
                            <li
                              key={index}
                              style={{ listStyleType: "disc" }}
                              className={index !== 0 && "mt-2"}
                            >
                              {entry?.replace(/-/g, "")}
                            </li>
                          )
                      )}
                    </ul>

                    {/* Show "Show More" button if there are more than 3 lines */}
                    {formatSummary(selectedExpert?.expert_ai_sumary).length >
                      3 && (
                      <Button
                        onClick={toggleShowMore}
                        className="text-start p-0"
                        type="link"
                        block
                        style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
                      >
                        {showFullSummary ? "Show Less" : "Show More"}
                      </Button>
                    )}
                  </div>
                ) : (
                  <p>No summary available.</p>
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <ProjectListModal
        selectedExpertId={selectedExpert?._id}
        country={selectedExpert?.country}
        isLoading={isLoading}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
        selectedExpertId={selectedExpert?._id}
      />
    </div>
  );
};

export default ExpertDetails;
