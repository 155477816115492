import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../../../Constants/api-path";
import "./UserProfileForm.css";

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

const UserProfileForm = ({ user }) => {
  // console.log(user);
  let UserData = JSON.parse(localStorage.getItem("userData"));
  console.log(UserData, "UserData");
  const UserPassword = JSON.parse(localStorage.getItem("ChangePassword"));
  const [contactCode, setContactCode] = useState(user[0].user.contact_code);
  const [spinner, setSpinner] = useState(false);
  const [phoneNumber, setContactNumber] = useState(
    user[0]?.user?.phoneNumber && user[0]?.user?.contact_code
      ? `${user[0]?.user?.contact_code}${user[0]?.user?.phoneNumber}`
      : `${user[0]?.user?.contact_code}${user[0]?.user?.contact_number}`
  );
  const [company, setCompany] = useState(
    user[0]?.user?.company ? user[0]?.user?.company : user[0]?.user?.companyName
  );
  const [profilePicture, setProfilePicture] = useState(user[0].profilePic);
  const [profile] = useState("");

  useEffect(() => {
    if (profile.name !== undefined) {
      //console.log(profile);
      getPicture();
      async function getPicture() {
        try {
          var data = new FormData();
          data.append("file", profile);
          const s3Res = await fetch(
            `${API_PATH.UPLOAD_MEDIA}/${UserData.user.company_id.shortName}`,
            {
              method: "POST",
              headers: headers,
              body: data,
            }
          );
          const s3Result = await s3Res.json();
          if (s3Result) {
            // console.log(s3Result);
            setProfilePicture(s3Result.data.location);
            var pp = s3Result.data.location;
          }
        } catch (error) {
          // console.log("Not working", error);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  }, [profile]);

  // console.log(profilePicture);

  const headers = {
    "x-access-token": UserData.access_token,
  };

  // console.log(profile);

  const [UserDetails, setUserDetails] = useState({
    firstNmae: user[0]?.user?.name,
    email: user[0]?.user?.email,
    country: user[0]?.user?.country,
    phoneNumber: phoneNumber,
    company: company,
    role_id: user[0]?.user?.role_id?._id,
    password: UserPassword,
    contact_code: "",
  });

  // console.log(UserDetails);
  // console.log(contactCode);
  // console.log(contactNumber);
  // console.log(profile);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...UserDetails, [name]: value };
    setUserDetails(list);
  };

  const handelSubmit = async () => {
    //console.log(pp);

    if (
      UserDetails?.firstNmae !== "" &&
      UserDetails?.email !== "" &&
      phoneNumber !== "" &&
      company !== ""
    ) {
      setSpinner(true);
      document.getElementById("profile").disabled = true;
      let payload = {
        name: UserDetails?.firstNmae,
        email: UserDetails?.email,
        contact_code: contactCode,
        contact_number: phoneNumber,
        country: UserDetails?.country,
        company: company,
        role_id: UserDetails?.role_id,
        profilePic: profilePicture,
      };

      try {
        const res = await fetch(
          `${API_PATH.USER_MANAGEMENT_UPDATE}/${UserData.user._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // console.log("rolesData", res.data);

            body: JSON.stringify(payload),
          }
        );

        const data = await res.json();

        if (data.success === true) {
          //console.log(res.data.message);
          setSpinner(false);
          document.getElementById("profile").disabled = false;
          delete payload.role_id;
          UserData.user = {
            ...UserData.user,
            ...payload,
          };
          // // console.log(data);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
          localStorage.setItem("userData", JSON.stringify(UserData));
          return toast.success("Profile Updated Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          // setResponseMSG(data.message);
          // setModalToggle(true);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // console.log("Not working", error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      setSpinner(false);
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handlePhoneChange = (value, country) => {
    console.log(value, "ctnumber");
    console.log("Received dial code:", country?.dialCode);
    console.log("Country options:", countryOptions);

    setContactNumber(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    console.log("Selected country:", selectedCountry);

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      console.log("Selected country:", selectedCountry?.name);
      setContactCode(country.dialCode);

      // setContactValid(isValidContact);
    } else {
      console.log("No matching country found for dial code:", country.dialCode);
    }
  };

  if (Object.keys(user).length !== 0) {
    return (
      <div className="container rounded bg-white">
        <div className="row px-xl-2 justify-content-center align-items-center">
          <div className="col-md-8 border-right">
            <div className="d-flex justify-content-center align-items-center mt-3">
              {/* <img
                className="rounded-circle mt-5"
                width="150px"
                src={profilePicture}
              /> */}
              <div className="user-div-top">
                <figure className="">
                  <Avatar
                    style={{
                      backgroundColor: "#fff",
                      color: "#5746ec",
                      border: "1px solid #5746ec",
                    }}
                    sx={{
                      bgcolor: blue[500],
                      height: "82px",
                      width: "82px",
                      fontSize: "35px",
                      margin: "auto",
                    }}
                  >
                    {UserData?.user?.name
                      ? UserData?.user?.name
                          .split(" ")[0]
                          ?.split("")
                          ?.map((char, index) =>
                            index < 2 ? char?.toUpperCase() : ""
                          )
                          ?.join("")
                      : ""}
                  </Avatar>
                </figure>
                <p
                  className="fw-bolder text-center"
                  style={{ fontSize: "24px", color: "rgb(87, 70, 236)" }}
                >
                  {user[0]?.user?.name}
                </p>
              </div>
            </div>
            {/* <div className="d-flex flex-column">
              <img
                className="rounded-circle"
                width="150px"
                src={profilePicture}
              />
              <span className="font-weight-bold">{user[0].firstNmae}</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                label="Upload Image"
                classes="drop_area drop_zone"
                maxSize={1}
                types={fileTypes}
              />
            </div> */}
          </div>
          <div className=" col-sm-12 col-lg-10 col-xl-8">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label> Name *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="firstNmae"
                      placeholder="Johnson Willianson"
                      onChange={handleInputs}
                      value={UserDetails?.firstNmae}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Email Address *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="email"
                      disabled
                      placeholder="Enter email address"
                      onChange={handleInputs}
                      value={UserDetails?.email}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Contact Number *</label>
                    <PhoneInput
                      country={"ind"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(value, country) => {
                        handlePhoneChange(value, country);
                      }}
                      value={phoneNumber}
                    />

                    {/* <PhoneInput
                      country={"ind"}
                      enableSearch={true}
                      name="phoneNumber"
                      placeholder="Contact Number"
                      onChange={(e) => {
                        setContactNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      inputStyle={{
                        width: "282px",
                        height: "38px",
                      }}
                    /> */}

                    {/* <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"  
                      placeholder="Contact Number"
                      onChange={(e) => {
                        setContactNumber(e.target.value);
                      }}
                      value={phoneNumber}
                    /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-3">
                    <label>Company *</label>
                    <input
                      type="text"
                      className="form-control inputs-field form-control"
                      name="company"
                      placeholder="Company"
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                      value={company}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-3 col-md-12 text-center">
                <button
                  className="btn btn-primary profile-button"
                  style={{ backgroundColor: "#5746ec" }}
                  type="button"
                  onClick={handelSubmit}
                  id="profile"
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Save Profile
                    </>
                  ) : (
                    "Save Profile"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
};

export default UserProfileForm;
