import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: {},
  transcriptPagination: { page: 1, limit: 10 },
  transcriptSearchData: { data: [], count: 0 },
  expertPagination: { page: 1, limit: 10 },
  expertSearchData: { data: [], count: 0 },
  newsPagination: { page: 1, limit: 10 },
  newsSearchData: { data: [], count: 0 },
  selectedTranscriptSearch: {},
  selectedExpertSearch: {},
  selectedNewsSearch: {},
  isLoadingNews: false,
  isLoadingExpert: false,
  isLoadingTranscript: false,
  searchedCompanyList: { data: [], count: 0 },
  standaloneFinanceDatafinanceData: {},
  consolidatedFinanceData: {},
  tab: "1",
  checked: false,
  quartarlyTabs: [],
  stockPrice: {},
  associatetab: "1"
};

const slice = createSlice({
  name: "companySearch",
  initialState: initialState,
  reducers: {
    setCompanyData: (state, { payload }) => {
      state.companyData = payload;
    },
    setTab: (state, { payload }) => {
      state.tab = payload;
    },
    setTranscriptSearchData: (state, { payload }) => {
      state.transcriptSearchData = payload;
    },
    setExpertSearchData: (state, { payload }) => {
      state.expertSearchData = payload;
    },
    setNewsSearchData: (state, { payload }) => {
      state.newsSearchData = payload;
    },
    setSelectedTranscriptSearch: (state, { payload }) => {
      state.selectedTranscriptSearch = payload;
    },
    setSelectedExpertSearch: (state, { payload }) => {
      state.selectedExpertSearch = payload;
    },
    setSelectedNewsSearch: (state, { payload }) => {
      state.selectedNewsSearch = payload;
    },
    setIsLoadingNews: (state, { payload }) => {
      state.isLoadingNews = payload;
    },
    setIsLoadingExpert: (state, { payload }) => {
      state.isLoadingExpert = payload;
    },
    setIsLoadingTranscript: (state, { payload }) => {
      state.isLoadingTranscript = payload;
    },
    setTranscriptPagination: (state, { payload }) => {
      state.transcriptPagination = payload;
    },
    setExpertPagination: (state, { payload }) => {
      state.expertPagination = payload;
    },
    setNewsPagination: (state, { payload }) => {
      state.newsPagination = payload;
    },
    setSearchedCompanyList: (state, { payload }) => {
      state.searchedCompanyList = payload;
    },
    setStandaloneFinanceData: (state, { payload }) => {
      state.standaloneFinanceDatafinanceData = payload;
    },
    setConsolidatedFinanceData: (state, { payload }) => {
      state.consolidatedFinanceData = payload;
    },
    setChecked: (state, { payload }) => {
      state.checked = payload;
    },
    setQuartarlyTabs: (state, { payload }) => {
      state.quartarlyTabs = payload;
    },
    setStockPrice: (state, { payload }) => {
      state.stockPrice = payload;
    },
    setAssociateTab: (state, { payload }) => {
      state.associatetab = payload;
    },
  },
});
export const companySearchReducer = slice.reducer;
export const {
  setAssociateTab,
  setCompanyData,
  setTranscriptSearchData,
  setSelectedTranscriptSearch,
  setExpertSearchData,
  setSelectedExpertSearch,
  setNewsSearchData,
  setSelectedNewsSearch,
  setIsLoadingNews,
  setIsLoadingExpert,
  setIsLoadingTranscript,
  setTranscriptPagination,
  setExpertPagination,
  setNewsPagination,
  setSearchedCompanyList,
  setStandaloneFinanceData,
  setConsolidatedFinanceData,
  setTab,
  setChecked,
  setQuartarlyTabs,
  setStockPrice,
} = slice.actions;
