import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import WatchlistPage from "./WatchlistPage";
import Loader from "../../Components/Loader";

function Watchlist() {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);
  return (
    <>
      {/* <MobileMenu /> */}
      <Helmet>
        <title>{"Nextyn IQ - Watchlist"}</title>
      </Helmet>
      <div class="bg-light" id="main-screen">
        {open ? (
          <WatchlistPage />
        ) : (
          <>
            <div role="status">
              <Loader />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Watchlist;
