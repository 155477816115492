import { Button, Divider, Tag, Tooltip } from 'antd';
import React, { memo } from 'react'
import ConditionalRender from '../../Utils/ConditionalRender';
import { setIsOpenCreatProject, setIsOpenProject } from '../Pages/V2/Dashboard/Project.Slice';
import { Link } from 'react-router-dom';
import { convertToTitleCase } from '../../Utils/Util';
import { ProjectListModal } from '../../components/ProjectListModal';
import { CreateProjectModal } from '../../components/CreateProjectModal';
import { useDispatch } from 'react-redux';

const TranscriptPopOver = ({ curElem, functions, dataVariables }) => {
    const { AddExpertProject, getProjectListById, mutateProjectList } = functions
    const { projectListById } = dataVariables
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(() => userData?.user?.role_id, [userData?.user?.role_id]);
    const dispatch = useDispatch()
    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = React.useCallback((id) => {
        setShowAll((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    }, []);
    const capitalizeFirstLetter = React.useCallback((string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);
    return (
        <>
            <div className="trans_popup">
                <span
                    className="truncate-text fw-bolder"
                    style={{
                        fontSize: "14px",
                        color: "#5746ec",
                    }}
                >
                    <Tooltip title={capitalizeFirstLetter(curElem?.topic)}>
                        {capitalizeFirstLetter(curElem?.topic)}
                    </Tooltip>
                </span>
                <Divider></Divider>
                <div className="d-flex justify-content-between align-items-center">
                    <ConditionalRender condition={curElem?.expertData && curElem?.expertData.length > 0}>
                        <small className="fw-normal">
                            <strong className="fw-bolder me-4">Expert</strong>{" "}
                            {`${curElem?.expertData[0]?.current_designation || "N/A"}${curElem?.expertData[0]?.current_designation &&
                                curElem?.expertData[0]?.previous_company
                                ? ", "
                                : ""
                                }`}
                            {curElem?.expertData[0]?.previous_company ? (
                                <>{curElem?.expertData[0].previous_company || "N/A"}</>
                            ) : (
                                ""
                            )}
                        </small>
                    </ConditionalRender>
                    <ConditionalRender condition={curElem?.expertData && !curElem?.expertData.length > 0}>
                        <small className="fw-normal">
                            Designation/Company information unavailable
                        </small>
                    </ConditionalRender>
                    {/* {curElem?.expertData && curElem?.expertData.length > 0 ? (
                  <small className="fw-normal">
                    <strong className="fw-bolder me-4">Expert</strong>{" "}
                    {`${curElem?.expertData[0]?.current_designation || "N/A"}${curElem?.expertData[0]?.current_designation &&
                      curElem?.expertData[0]?.previous_company
                      ? ", "
                      : ""
                      }`}
                    {curElem?.expertData[0]?.previous_company ? (
                      <>{curElem?.expertData[0].previous_company || "N/A"}</>
                    ) : (
                      ""
                    )}
                  </small>
                ) : (
                  <small className="fw-normal">
                    Designation/Company information unavailable
                  </small>
                )} */}

                    <ConditionalRender condition={projectListById?.data?.length > 0}>
                        <button
                            className="btn viewButton text-end"
                            style={{
                                fontSize: "12px",
                                padding: "",
                            }}
                            onClick={() => {
                                if (permission?.experts?.request) {
                                    dispatch(setIsOpenProject(true));
                                }
                            }}
                        >
                            Request this Expert
                        </button>
                    </ConditionalRender>
                    <ConditionalRender condition={!projectListById?.data?.length > 0}>
                        <Button
                            className="btn viewButton text-end"
                            onClick={() => {
                                if (permission?.experts?.request) {
                                    dispatch(setIsOpenCreatProject(true));
                                    dispatch(setIsOpenProject(false));
                                }

                                // closeProjectModal(false)
                            }}
                        >
                            {"Request this Expert"}
                        </Button>
                    </ConditionalRender>
                    {/* 
                {projectListById?.data?.length > 0 ? (
                  // <ProjectListModal
                  //     functions={{
                  //         projectListById,
                  //         AddExpertProject,
                  //         getProjectListById,
                  //         mutateProjectList
                  //     }}
                  //     selectedExpertId={curElem?.meetingsData?.[0]?.expert_id} />
                  <button
                    className="btn viewButton text-end"
                    style={{
                      fontSize: "12px",
                      padding: "",
                    }}
                    onClick={() => {
                      if (permission?.experts?.request) {
                        dispatch(setIsOpenProject(true));
                      }
                    }}
                  >
                    Request this Expert
                  </button>
                ) : (
                  <Button
                    className="btn viewButton text-end"
                    onClick={() => {
                      if (permission?.experts?.request) {
                        dispatch(setIsOpenCreatProject(true));
                        dispatch(setIsOpenProject(false));
                      }
  
                      // closeProjectModal(false)
                    }}
                  >
                    {"Request this Expert"}
                  </Button>
                  // <CreateProjectModal buttonText="Add Project" mutateProjectList={mutateProjectList} />
                )} */}
                </div>
                <Divider></Divider>
                <strong style={{ fontWeight: "500", fontSize: "12px" }}>
                    Topics covered in this transcript
                </strong>
                <ul className="listType mt-2 mb-0">
                    {curElem?.lemur_questions?.result
                        ?.slice(0, showAll[curElem?._id] ? undefined : 5)
                        .map((question, index) => (
                            <li className="fw-normal mb-0" key={index}>
                                {question.replace(/-/g, "")}
                            </li>
                        ))}
                </ul>

                {curElem?.lemur_questions?.result?.length > 5 && (
                    <Button
                        className="text-start p-0"
                        type="link"
                        block
                        onClick={() => toggleShowAll(curElem?._id)} // Assuming curElem.id is the correct identifier for each item
                        style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
                    >
                        {showAll[curElem?._id] ? "View Less" : "View More"}
                    </Button>
                )}
                <Divider className="mt-1"></Divider>
                <div className="d-flex cstmTg">
                    <div
                        style={{
                            flexBasis: "70px",
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        {curElem?.keyword?.split(",")?.length > 1 && (
                            <>
                                <small className="fw-bolder">Keywords</small>
                            </>
                        )}
                    </div>
                    <div>
                        {curElem?.keyword
                            ?.split(",")
                            ?.slice(0, 6)
                            ?.map((tag) => (
                                <>
                                    {tag && (
                                        <Link to={`/search?keyword=${tag}`}>
                                            <Tag className="me-1 mb-1" size="large" key={tag}>
                                                {tag}
                                            </Tag>
                                        </Link>
                                    )}
                                </>
                            ))}
                        {curElem?.keyword &&
                            curElem?.keyword?.split(",")?.slice(6)?.length > 0 && (
                                <Tooltip
                                    title={curElem?.keyword?.split(",")?.slice(6)?.join(", ")}
                                >
                                    <Tag className="me-1 mb-1" size="large">
                                        {`+ ${curElem?.keyword?.split(",")?.slice(6)?.length}`}
                                    </Tag>
                                </Tooltip>
                                // </Popover>
                            )}
                    </div>
                </div>
                <div className="mt-1 d-flex cstmTg">
                    <div
                        style={{
                            flexBasis: "70px",
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        {curElem?.meetingsData?.[0]?.company_id?.length > 0 && (
                            <>
                                <small className="fw-bolder">Companies</small>
                            </>
                        )}
                    </div>
                    <div>
                        {curElem?.meetingsData?.[0]?.company_id
                            ?.slice(0, 6)
                            ?.map((comp) => (
                                <>
                                    {comp && (
                                        <Link to={`/company?company_id=${comp?._id}`}>
                                            <Tag
                                                className="me-1 mb-1"
                                                size="large"
                                                key={comp?._id}
                                            >
                                                {convertToTitleCase(comp?.name)}
                                            </Tag>
                                        </Link>
                                    )}
                                </>
                            ))}
                        {curElem?.meetingsData &&
                            curElem?.meetingsData?.[0]?.company_id?.slice(6)?.length >
                            0 && (
                                <Tooltip
                                    title={curElem?.meetingsData?.[0]?.company_id
                                        ?.slice(6)
                                        ?.map((comp) => comp?.name)}
                                >
                                    <Tag className="me-1 mb-1" size="large">
                                        {`+ ${curElem?.meetingsData?.[0]?.company_id?.slice(6)
                                            ?.length
                                            }`}
                                    </Tag>
                                </Tooltip>
                            )}
                    </div>
                </div>
            </div>
            <ProjectListModal
                functions={{
                    projectListById,
                    AddExpertProject,
                    getProjectListById,
                    mutateProjectList,
                }}
                selectedExpertId={curElem?.meetingsData?.[0]?.expert_id}
                country={curElem?.meetingsData?.[0]?.country}
            />
            <CreateProjectModal
                buttonText="Add Project"
                mutateProjectList={mutateProjectList}
            />
        </>
    )
}
export default memo(TranscriptPopOver) 