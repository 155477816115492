import API_URL from '../../Constants/api-path'
import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setChatMessages, setIsLoading } from '../Pages/Transcript/TranscriptChat.slice';

function useTranscriptChat() {
    const dispatch = useDispatch()
    const fetchTransChatData = useCallback(async ({ transcriptId = "", question = "" }) => {
        dispatch(setIsLoading(true))
        await axios.get(`${API_URL.GET_TRANSCRIPT_CHAT_DATA}?transcriptId=${transcriptId}&question=${encodeURIComponent(question)}`)
            .then((res) => {
                dispatch(setChatMessages(res?.data?.data?.response[0]))
                dispatch(setIsLoading(false))

            }).catch((err) => {
                console.error("ERROR", err)
                dispatch(setIsLoading(false))

            })
    }, [dispatch])

    const formatDuration = useCallback((duration) => {
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);

        if (hours > 0) {
            return `${hours < 10 ? `0${hours}` : hours} hr ${minutes < 10 ? `0${minutes}` : minutes} min`;
        } else {
            return `${minutes < 10 ? `0${minutes}` : minutes} min`;
        }
    }, []);

    const formatDate = useCallback((isoDate) => {
        const dateOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
        const formattedDate = new Date(isoDate).toLocaleDateString('en-GB', dateOptions);
        return formattedDate;
    }, []);


    const values = useMemo(() => ({
        fetchTransChatData: fetchTransChatData,
        formatDuration: formatDuration,
        formatDate: formatDate,
    }), [fetchTransChatData, formatDuration, formatDate])

    return values
}

export default useTranscriptChat

