export const balance_sheet_dummy = {
    financial_year: [],
    SHAREHOLDERS_FUND: [],
    share_capital: [],
    reserves_and_surplus: [],
    money_received_against_share_warrants: [],
    networth: [],
    share_application_money_pending_allotment: [],
    deffered_government_grants: [],
    minority_interest: [],
    NON_CURRENT_LIABILITIES: [],
    // long_term_borrowings_bifurcation: [],//!commented due to break of balancesheet on Toyota Kirloskar Motor Private Limited
    long_term_provisions: [],
    total_non_current_liability: [],
    CURRENT_LIABILITIES: [],
    short_term_borrowings: [],
    trade_payables: [],
    other_current_liabilities: [],
    short_term_provisions: [],
    total_current_liabilities: [],
    total_equity_and_liabilities: [],
    NON_CURRENT_ASSETS: [],
    FIXED_ASSETS: [],
    tangible_assets: [],
    intangible_assets: [],
    net_block_assets: [],
    intangible_assets_under_development: [],
    total_fixed_assets: [],
    non_current_investments: [],
    net_dta: [],  //deferred_tax_assets_net
    long_term_loans_and_advances: [],
    other_non_current_assets: [],
    total_non_current_assets: [],
    CURRENT_ASSETS: [],
    current_investments: [],
    inventories: [],
    trade_receivables: [],
    cash_and_cash_equivalents: [],
    other_current_assets: [],
    other_total_assets: [],
    total_assets: [],
}
export const balance_sheet_dummy_column = {
    financial_year: "Financial Year",
    SHAREHOLDERS_FUND: "SHAREHOLDERS FUND",
    share_capital: "Share Capital",
    reserves_and_surplus: "Reserves & Surplus",
    money_received_against_share_warrants: "Money Received against Warrants",
    networth: "Networth",
    share_application_money_pending_allotment: "Share Application Money Pending Allotment",
    deffered_government_grants: "Deferred Government Grants",
    minority_interest: "Minority Interest",
    NON_CURRENT_LIABILITIES: "NON CURRENT LIABILITIES",
    long_term_borrowings_bifurcation: "Long-term Borrowings",
    long_term_provisions: "Long-term Provisions",
    total_non_current_liability: "Total Non Current Liabilities heading",
    CURRENT_LIABILITIES: "CURRENT LIABILITIES",
    short_term_borrowings: "Short-term Borrowings",
    trade_payables: "Trade Payables",
    other_current_liabilities: "Other Current Liabilities",
    short_term_provisions: "Short-term Provisions",
    total_equity_and_liabilities: "Total Equity & Liabilities",
    total_current_liabilities: "Total Current Liabilities",
    NON_CURRENT_ASSETS: "NON CURRENT ASSETS",
    FIXED_ASSETS: "FIXED ASSET",
    tangible_assets: "Tangible Assets",
    intangible_assets: "Intangible Assets",
    net_block_assets: "Net Block of Assets",
    intangible_assets_under_development: "Intangible Asset under Development	",
    total_fixed_assets: "Total Fixed Asset",
    non_current_investments: "Non Current Investment",
    net_dta: "Deferred Tax Assets (Net)",  //deferred_tax_assets_net
    long_term_loans_and_advances: "Long-term Loans & Advances",
    other_non_current_assets: "Other Non Current Assets",
    total_non_current_assets: "Total Non Current Assets",
    CURRENT_ASSETS: "CURRENT ASSETS",
    current_investments: "Current Investment",
    inventories: "Inventories",
    trade_receivables: "Trade Receivables",
    cash_and_cash_equivalents: "Cash & Cash Equivalents",
    other_current_assets: "Other Current Assets",
    other_total_assets: "Other Total Assets",
    total_assets: "Total Assets	",
}


export const profit_loss_dummy_column = {
    financial_year: "Financial Year",
    REVENUE: "REVENUE",
    revenue_from_sale_of_products: "Revenue from Sale of Products",
    revenue_from_sale_of_services: "Revenue from Sale of Services",
    other_operating_revenues: "Other Operating Revenue",
    gross_sales: "Gross Sales",
    duties: "Less:Duties",
    total_revenue_from_operations: "Total Revenue from Operations",
    other_income: "Other Income",
    total_revenue: "Total Revenue",
    EXPENSES: "EXPENSES",
    cost_of_material_consumed: "Cost of Materials Consumed",
    purchases_of_stock_in_trade: "Purchases of Stock in Trade",
    changes_in_inventories_of_finished_goods_and_work_in_progress_and_stock_in_trade: "Changes in Inventories of Finished Goods, Work In Progress and Stock In Trade",
    other_expenses: "Other Expenses",
    ebitda: "EBITDA",
    ebitda_percentage: "EBITDA %",
    finance_cost: "Finance Costs",
    depreciation_and_amortization_expense_formatted: "Total Depreciation, Depletion and Amortization Expense",
    total_expenses: "Total Expenses",
    profit_before_exceptional_and_extraordinary_items_and_tax: "Profit before Exceptional and Extraordinary Items and Tax",
    prior_period_items_before_tax: "Prior Period Items before Tax",
    profit_before_extraordinary_items_and_tax: "Profit before Extraordinary Items and Tax",
    exceptional_items: "Exceptional Items",
    extraordinary_items: "Extraordinary Items",
    profit_before_tax: "Profit before Tax",
    TAX_EXPENSES: "TAX EXPENSE",
    current_tax: "Current Tax",
    deferred_tax: "Deferred tax	",
    net_movement_in_reg_deferral_account_bal_related_to_pl_and_the_rel_deferred_tax_movement: "Net Movement in Regulatory Deferral Account Balances related to Profit or Loss and the Related Deferred Tax Movement",
    profit_or_loss_for_the_period_from_continuing_operations: "Profit/(Loss) for the Period from Continuing Operations",
    profit_or_loss_from_discontinuing_operations_before_tax: "Profit/(Loss) from Discontinuing Operations",
    tax_expense_of_discontinuing_operations: "Tax Expense of Discontinuing Operations",
    profit_or_loss_from_discontinuing_operations_after_tax: "Profit/(Loss) from Discontinuing Operations (After Tax)",
    total_profit_or_loss_for_period: "Profit/(Loss)",
}
export const profit_loss_dummy = {
    REVENUE: [],
    financial_year: [],
    revenue_from_sale_of_products: [],
    revenue_from_sale_of_services: [],
    other_operating_revenues: [],
    gross_sales: [],
    duties: [],
    total_revenue_from_operations: [],
    other_income: [],
    total_revenue: [],
    EXPENSES: [],
    cost_of_material_consumed: [],
    purchases_of_stock_in_trade: [],
    changes_in_inventories_of_finished_goods_and_work_in_progress_and_stock_in_trade: [],
    other_expenses: [],
    ebitda: [],
    ebitda_percentage: [],
    finance_cost: [],
    depreciation_and_amortization_expense_formatted: [],
    total_expenses: [],
    profit_before_exceptional_and_extraordinary_items_and_tax: [],
    prior_period_items_before_tax: [],
    exceptional_items: [],
    profit_before_extraordinary_items_and_tax: [],
    extraordinary_items: [],
    profit_before_tax: [],
    TAX_EXPENSES: [],
    current_tax: [],
    deferred_tax: [],
    net_movement_in_reg_deferral_account_bal_related_to_pl_and_the_rel_deferred_tax_movement: [],
    profit_or_loss_for_the_period_from_continuing_operations: [],
    profit_or_loss_from_discontinuing_operations_before_tax: [],
    tax_expense_of_discontinuing_operations: [],
    profit_or_loss_from_discontinuing_operations_after_tax: [],
    total_profit_or_loss_for_period: [],
}

export const cash_flow_dummy = {
    CASH_FLOWS_FROM_USED_IN_OPERATING_ACTIVITIES: [],
    financial_year: [],
    profit_before_tax: [],
    ADJUSTMENTS_FOR_RECONCILE_PROFIT_LOSS: [],
    adjustments_for_finance_costs: [],
    adjustments_for_decrease_increase_in_inventories: [],
    adjustments_for_decrease_increase_in_trade_receivables_current: [],
    adjustments_for_decrease_increase_in_trade_receivables_non_current: [],
    adjustments_for_decrease_increase_in_other_current_assets: [],
    adjustments_for_decrease_increase_in_other_non_current_assets: [],
    adjustments_for_other_financial_assets_non_current: [],
    adjustments_for_other_financial_assets_current: [],
    adjustments_for_other_bank_balances: [],
    adjustments_for_increase_decrease_in_trade_payables_current: [],
    adjustments_for_increase_decrease_in_trade_payables_non_current: [],
    adjustments_for_increase_decrease_in_other_current_liabilities: [],
    adjustments_for_increase_decrease_in_other_non_current_liab: [],
    adjustments_for_depreciation_and_amortisation_expense: [],
    adjustments_for_impairment_loss_reversal_of_impairment_loss_recognised_in_profit_or_loss: [],
    adjustments_for_provisions_current: [],
    adjustments_for_provisions_non_current: [],
    adjustments_for_other_financial_liabilities_current: [],
    adjustments_for_other_financial_liabilities_non_current: [],
    adjustments_for_unrealised_foreign_exchange_losses_gains: [],
    adjustments_for_dividend_income: [],
    adjustments_for_interest_income: [],
    adjustments_for_share_based_payments: [],
    adjustments_for_fair_value_losses_gains: [],
    adjustments_for_undistributed_profits_of_associates: [],
    other_adjustments_for_which_cash_effects_are_investing_or_financing_cashflow_str: [],
    other_adjustments_to_reconcile_profit_loss: [],
    other_adjustments_for_non_cash_items: [],
    share_of_profit_and_loss_from_partnership_firm_or_association_of_persons_or_llp: [],
    total_adjustments_for_reconcile_profit_loss: [],
    net_cash_flows_from_used_operations: [],
    dividends_received_operating_activities: [],
    interest_paid_operating_activities: [],
    interest_received_operating_activities: [],
    income_taxes_paid_refund_operating_activities: [],
    other_inflows_outflows_of_cash_operating_activities: [],
    net_cash_flows_from_used_in_operating_activities: [],
    CASH_FLOWS_FROM_USED_IN_INVESTING_ACTIVITIES: [],
    cash_flows_from_losing_control_of_subsidiaries_or_other_businesses: [],
    cash_flows_used_in_obtaining_control_of_subsidiaries_or_other_businesses: [],
    other_cash_receipts_from_sales_of_equity_or_debt_instruments_of_other_entities: [],
    other_cash_payments_to_acquire_equity_or_debt_instruments_of_other_entities: [],
    other_cash_receipts_from_sales_of_interests_in_joint_ventures: [],
    other_cash_payments_to_acquire_interests_in_joint_ventures: [],
    cash_receipts_from_share_of_profits_of_partnership_firm_or_association_of_persons_or_llp: [],
    cash_payment_for_investment_in_partnership_firm_or_association_of_persons_or_llp: [],
    proceeds_from_sales_of_property_plant_and_equipment: [],
    purchase_of_property_plant_and_equipment: [],
    proceeds_from_sales_of_investment_property: [],
    purchase_of_investment_property: [],
    proceeds_from_sales_of_intangible_assets: [],
    purchase_of_intangible_assets: [],
    proceeds_from_sales_of_intangible_assets_under_development: [],
    purchase_of_intangible_assets_under_development: [],
    proceeds_from_sales_of_goodwill: [],
    purchase_of_goodwill: [],
    proceeds_from_biological_assets_other_than_bearer_plants: [],
    purchase_of_biological_assets_other_than_bearer_plants: [],
    proceeds_from_government_grants: [],
    proceeds_from_sales_of_other_long_term_assets: [],
    purchase_of_other_long_term_assets: [],
    cash_advances_and_loans_made_to_other_parties: [],
    cash_receipts_from_repayment_of_advances_and_loans_made_to_other_parties: [],
    cash_payments_for_future_forward_option_swap_contracts: [],
    cash_receipts_from_future_forward_option_swap_contracts: [],
    dividends_received_investing_activities: [],
    interest_received_investing_activities: [],
    income_taxes_paid_refund_investing_activities: [],
    other_inflows_outflows_of_cash_investing_activities: [],
    net_cash_flows_from_used_in_investing_activities: [],
    CASH_FLOWS_FROM_USED_IN_FINANCING_ACTIVITIES: [],
    proceeds_from_changes_in_ownership_interests_in_subsidiaries: [],
    payments_from_changes_in_ownership_interests_in_subsidiaries: [],
    proceeds_from_issuing_other_equity_instruments: [],
    payments_to_acquire_or_redeem_entity_shares: [],
    payments_of_other_equity_instruments: [],
    proceeds_from_exercise_of_stock_options: [],
    proceeds_from_issuing_debentures_notes_bonds_etc: [],
    proceeds_from_borrowings: [],
    repayments_of_borrowings: [],
    payments_of_finance_lease_liabilities: [],
    payments_of_lease_liabilities: [],
    dividends_paid: [],
    interest_paid_financiang_activities: [],
    other_inflows_outflows_of_cash_financiang_activities: [],
    net_cash_flows_from_used_in_financing_activities: [],
    net_increase_decrease_in_cash_equivalents_before_exchange_rate_changes: [],
    effect_of_exchange_rate_changes_on_cash_equivalents_: [],
    net_increase_decrease_in_cash_equivalents: [],
    cash_equivalents_cf_statement_at_beginning_of_period: [],
    cash_equivalents_cf_statement_at_end_of_period: [],
}
export const cash_flow_dummy_column = {
    CASH_FLOWS_FROM_USED_IN_OPERATING_ACTIVITIES: "CASH FLOWS FROM USED IN OPERATING ACTIVITIES",
    financial_year: "financial_year",
    profit_before_tax: "Profit before Tax",
    ADJUSTMENTS_FOR_RECONCILE_PROFIT_LOSS: "ADJUSTMENTS FOR RECONCILE PROFIT (LOSS)",
    adjustments_for_finance_costs: "Adjustments for finance costs",
    adjustments_for_decrease_increase_in_inventories: "Adjustments for decrease (increase) in inventories",
    adjustments_for_decrease_increase_in_trade_receivables_current: "Adjustments for decrease (increase) in trade receivables, current",
    adjustments_for_decrease_increase_in_trade_receivables_non_current: "Adjustments for decrease (increase) in trade receivables, non-current",
    adjustments_for_decrease_increase_in_other_current_assets: "Adjustments for decrease (increase) in other current assets",
    adjustments_for_decrease_increase_in_other_non_current_assets: "Adjustments for decrease (increase) in other non-current assets",
    adjustments_for_other_financial_assets_non_current: "Adjustments for other financial assets, non-current	",
    adjustments_for_other_financial_assets_current: "Adjustments for other financial assets, current",
    adjustments_for_other_bank_balances: "Adjustments for other bank balances	",
    adjustments_for_increase_decrease_in_trade_payables_current: "Adjustments for increase (decrease) in trade payables, current",
    adjustments_for_increase_decrease_in_trade_payables_non_current: "Adjustments for increase (decrease) in trade payables, non-current",
    adjustments_for_increase_decrease_in_other_current_liabilities: "Adjustments for increase (decrease) in other current liabilities",
    adjustments_for_increase_decrease_in_other_non_current_liab: "Adjustments for increase (decrease) in other non-current liabilities",
    adjustments_for_depreciation_and_amortisation_expense: "Adjustments for depreciation and amortisation expense",
    adjustments_for_impairment_loss_reversal_of_impairment_loss_recognised_in_profit_or_loss: "Adjustments for impairment loss reversal of impairment loss recognised in profit or loss",
    adjustments_for_provisions_current: "Adjustments for provisions, current",
    adjustments_for_provisions_non_current: "Adjustments for provisions, non-current",
    adjustments_for_other_financial_liabilities_current: "Adjustments for other financial liabilities, current",
    adjustments_for_other_financial_liabilities_non_current: "Adjustments for other financial liabilities, non-current",
    adjustments_for_unrealised_foreign_exchange_losses_gains: "Adjustments for unrealised foreign exchange losses gains",
    adjustments_for_dividend_income: "Adjustments for dividend income",
    adjustments_for_interest_income: "Adjustments for interest income",
    adjustments_for_share_based_payments: "Adjustments for share-based payments",
    adjustments_for_fair_value_losses_gains: "Adjustments for fair value losses (gains)",
    adjustments_for_undistributed_profits_of_associates: "Adjustments for undistributed profits of associates",
    other_adjustments_for_which_cash_effects_are_investing_or_financing_cashflow_str: "Other adjustments for which cash effects are investing or financing cash flow",
    other_adjustments_to_reconcile_profit_loss: "Other adjustments to reconcile profit (loss)",
    other_adjustments_for_non_cash_items: "Other adjustments for non-cash items",
    share_of_profit_and_loss_from_partnership_firm_or_association_of_persons_or_llp: "Share of profit and loss from partnership firm or association of persons or limited liability partnerships",
    total_adjustments_for_reconcile_profit_loss: "Total adjustments for reconcile profit (loss)",
    net_cash_flows_from_used_operations: "Net cash flows from (used in) operations",
    dividends_received_operating_activities: "Dividends received",
    interest_paid_operating_activities: "Interest paid",
    interest_received_operating_activities: "Interest received",
    income_taxes_paid_refund_operating_activities: "Income taxes paid (refund)",
    other_inflows_outflows_of_cash_operating_activities: "Other inflows (outflows) of cash",
    net_cash_flows_from_used_in_operating_activities: "Net cash flows from (used in) operating activities",
    CASH_FLOWS_FROM_USED_IN_INVESTING_ACTIVITIES: "CASH FLOWS FROM USED IN INVESTING ACTIVITIES",
    cash_flows_from_losing_control_of_subsidiaries_or_other_businesses: "Cash flows from losing control of subsidiaries or other businesses	",
    cash_flows_used_in_obtaining_control_of_subsidiaries_or_other_businesses: "Cash flows used in obtaining control of subsidiaries or other businesses	",
    other_cash_receipts_from_sales_of_equity_or_debt_instruments_of_other_entities: "Other cash receipts from sales of equity or debt instruments of other entities	",
    other_cash_payments_to_acquire_equity_or_debt_instruments_of_other_entities: "Other cash payments to acquire equity or debt instruments of other entities	",
    other_cash_receipts_from_sales_of_interests_in_joint_ventures: "Other cash receipts from sales of interests in joint ventures",
    other_cash_payments_to_acquire_interests_in_joint_ventures: "Other cash payments to acquire interests in joint ventures",
    cash_receipts_from_share_of_profits_of_partnership_firm_or_association_of_persons_or_llp: "Cash receipts from share of profits of partnership firm or association of persons or limited liability partnerships",
    cash_payment_for_investment_in_partnership_firm_or_association_of_persons_or_llp: "Cash payment for investment in partnership firm or association of persons or limited liability partnerships",
    proceeds_from_sales_of_property_plant_and_equipment: "Proceeds from sales of property, plant and equipment",
    purchase_of_property_plant_and_equipment: "Purchase of property, plant and equipment",
    proceeds_from_sales_of_investment_property: "Proceeds from sales of investment property",
    purchase_of_investment_property: "Purchase of investment property	",
    proceeds_from_sales_of_intangible_assets: "Proceeds from sales of intangible assets",
    purchase_of_intangible_assets: "Purchase of intangible assets",
    proceeds_from_sales_of_intangible_assets_under_development: "Proceeds from sales of intangible assets under development",
    purchase_of_intangible_assets_under_development: "Purchase of intangible assets under development",
    proceeds_from_sales_of_goodwill: "Proceeds from sales of goodwill",
    purchase_of_goodwill: "Purchase of goodwill",
    proceeds_from_biological_assets_other_than_bearer_plants: "Proceeds from biological assets other than bearer plants",
    purchase_of_biological_assets_other_than_bearer_plants: "Purchase of biological assets other than bearer plants",
    proceeds_from_government_grants: "Proceeds from government grants",
    proceeds_from_sales_of_other_long_term_assets: "Proceeds from sales of other long-term assets",
    purchase_of_other_long_term_assets: "Purchase of other long-term assets	",
    cash_advances_and_loans_made_to_other_parties: "Cash advances and loans made to other parties	",
    cash_receipts_from_repayment_of_advances_and_loans_made_to_other_parties: "Cash receipts from repayment of advances and loans made to other parties	",
    cash_payments_for_future_forward_option_swap_contracts: "Cash payments for future contracts, forward contracts, option contracts and swap contracts",
    cash_receipts_from_future_forward_option_swap_contracts: "Cash receipts from future contracts, forward contracts, option contracts and swap contracts",
    dividends_received_investing_activities: "Dividends received	",
    interest_received_investing_activities: "Interest received	",
    income_taxes_paid_refund_investing_activities: "Income taxes paid (refund)",
    other_inflows_outflows_of_cash_investing_activities: "Other inflows (outflows) of cash",
    net_cash_flows_from_used_in_investing_activities: "Net cash flows from (used in) investing activities",
    CASH_FLOWS_FROM_USED_IN_FINANCING_ACTIVITIES: "CASH FLOWS FROM USED IN FINANCING ACTIVITIES",
    proceeds_from_changes_in_ownership_interests_in_subsidiaries: "Proceeds from changes in ownership interests in subsidiaries",
    payments_from_changes_in_ownership_interests_in_subsidiaries: "Payments from changes in ownership interests in subsidiaries",
    proceeds_from_issuing_other_equity_instruments: "Proceeds from issuing other equity instruments",
    payments_to_acquire_or_redeem_entity_shares: "Payments to acquire or redeem entity's shares",
    payments_of_other_equity_instruments: "Payments of other equity instruments",
    proceeds_from_exercise_of_stock_options: "Proceeds from exercise of stock options",
    proceeds_from_issuing_debentures_notes_bonds_etc: "Proceeds from issuing debentures notes bonds etc",
    proceeds_from_borrowings: "Proceeds from borrowings",
    repayments_of_borrowings: "Repayments of borrowings",
    payments_of_finance_lease_liabilities: "Payments of finance lease liabilities",
    payments_of_lease_liabilities: "Payments of lease liabilities",
    dividends_paid: "Dividends paid",
    interest_paid_financiang_activities: "Interest paid",
    income_taxes_paid_financiang_activities: "Income taxes paid (refund)",
    other_inflows_outflows_of_cash_financiang_activities: "Other inflows (outflows) of cash",
    net_cash_flows_from_used_in_financing_activities: "Net cash flows from (used in) financing activities",
    net_increase_decrease_in_cash_equivalents_before_exchange_rate_changes: "Net increase (decrease) in cash and cash equivalents before effect of exchange rate changes",
    effect_of_exchange_rate_changes_on_cash_equivalents_: "Effect of exchange rate changes on cash and cash equivalents	",
    net_increase_decrease_in_cash_equivalents: "Net increase (decrease) in cash and cash equivalents",
    cash_equivalents_cf_statement_at_beginning_of_period: "Cash and cash equivalents cash flow statement at beginning of period",
    cash_equivalents_cf_statement_at_end_of_period: "Cash and cash equivalents cash flow statement at end of period",
}

export const ratios_dummy = {
    financial_year: [],
    PROFITABILITY_RATIOS: [],
    revenue_growth: [],
    ebitda_margins_percent: [],
    ebt_margins_percent: [],
    pat_margins_percent: [],
    return_on_equity: [],
    return_on_fixed_assets: [],
    return_on_capital_employed: [],
    LIQUID_RATIOS: [],
    current_ratio: [],
    quick_ratio: [],
    SOLVENCY_RATIOS: [],
    interest_coverage: [],
    long_term_debt_or_equity: [],
    total_assets_or_equity: [],
    total_debt_or_equity: [],
    total_debt_or_total_assets: [],
    total_debt_or_ebitda: [],
    TURNOVER_OR_EFFICIENCY_RATIOS: [],
    fixed_assets_turnover: [],
    total_assets_turnover: [],
    working_capital_turnover: [],
    inventory_days: [],
    receivables_days: [],
    payable_days: [],
    cash_conversion_cycle: [],
    EXPENSES_RATIOS: [],
    raw_material_consumption: [],
    total_employee_cost_percent_of_sales: [],
    finance_cost_percent_of_sales: [],
    total_other_expenses_percent_of_sales: [],
}
export const ratios_dummy_column = {
    financial_year: "financial_year",
    PROFITABILITY_RATIOS: "PROFITABILITY RATIOS",
    revenue_growth: "Revenue Growth (%)",
    ebitda_margins_percent: "EBITDA Margins (%)",
    ebt_margins_percent: "EBT Margins (%)",
    pat_margins_percent: "PAT Margins (%)",
    return_on_equity: "Return on Equity (%)",
    return_on_fixed_assets: "Return on Fixed Assets (%)",
    return_on_capital_employed: "Return on Capital Employed (%)",
    LIQUID_RATIOS: "LIQUIDITY RATIOS",
    current_ratio: "Current Ratio",
    quick_ratio: "Quick Ratio",
    SOLVENCY_RATIOS: "SOLVENCY RATIOS",
    interest_coverage: "Interest Coverage Ratio",
    long_term_debt_or_equity: "Long-term Debt/Equity",
    total_assets_or_equity: "Total Assets/Equity",
    total_debt_or_equity: "Total Debt/Equity",
    total_debt_or_total_assets: "Total Debt/Total Assets",
    total_debt_or_ebitda: "Total Debt/EBITDA",
    TURNOVER_OR_EFFICIENCY_RATIOS: "TURNOVER/EFFICIENCY RATIOS",
    fixed_assets_turnover: "Fixed Assets Turnover",
    total_assets_turnover: "Total Asset Turnover",
    working_capital_turnover: "Working Capital Turnover",
    inventory_days: "Inventory Days",
    receivables_days: "Receivables Days",
    payable_days: "Payable Days",
    cash_conversion_cycle: "Cash Conversion Cycle",
    EXPENSES_RATIOS: "EXPENSES RATIOS",
    raw_material_consumption: "Raw Material Consumption (% of Sales)",
    total_employee_cost_percent_of_sales: "Total Employee Cost (% of Sales)",
    finance_cost_percent_of_sales: "Finance Cost (% of Sales)",
    total_other_expenses_percent_of_sales: "Total Other Expenses (% of Sales)",
}
export const quaterly_dummy_data = {
    "REVENUE": [],
    "financial_year": [],
    "revenue_from_sale_of_product_services": [],
    "other_operating_revenue": [],
    "duties": [],
    "total_revenue_operations": [],
    "other_operating_income": [],
    "total_revenue": [],
    "EXPENSES": [],
    "quarter_sequence": [],
    "cost_material_consumed": [],
    "purchase_stock_in_trade": [],
    "employee_benefit_expense": [],
    "other_expenses": [],
    "ebitda": [],
    "ebitda_percentage": [],
    "finance_cost": [],
    "total_depreciation_depletion_amortization_expense": [],
    "total_expenses": [],
    "pl_before_exceptional_items_tax": [],
    "prior_period_items_before_tax": [],
    "exceptional_items": [],
    "pl_before_extraordinary_items_tax": [],
    "extraordinary_items": [],
    "profite_before_tax": [],
    "TAX_EXPENSE": [],
    "total_tax_for_period": [],
    "pl_and_related_deferred_tax_movement": [],
    "profit_loss": [],
    // "miscellaneous_income": [],
    // "miscellaneous_expenses": [],
    // "miscellaneous_adjustments": [],
}
export const quaterly_dummy_data_column = {
    "REVENUE": "REVENUE",
    "financial_year": "financial_year",
    "revenue_from_sale_of_product_services": "Revenue from Sale of Products and/or Services",
    "other_operating_revenue": "Other Operating Revenue",
    "duties": "Less:Duties",
    "total_revenue_operations": "Total Revenue from Operations",
    "other_operating_income": "Other Income",
    "total_revenue": "Total Revenue",
    "EXPENSES": "EXPENSES",
    "quarter_sequence": "",
    "cost_material_consumed": "Cost of Materials Consumed   ",
    "purchase_stock_in_trade": "Purchases of Stock in Trade	",
    "increase_decrease_in_stock": "",
    "employee_benefit_expense": "Employee Benefit Expense",
    "other_expenses": "Other Expenses",
    "ebitda": "EBITDA",
    "ebitda_percentage": "EBITDA %",
    "finance_cost": "Finance Costs",
    "total_depreciation_depletion_amortization_expense": "Total Depreciation, Depletion and Amortization Expense",
    "total_expenses": "Total Expenses",
    "pl_before_exceptional_items_tax": "Profit before Exceptional and Extraordinary Items and Tax",
    "prior_period_items_before_tax": "Prior Period Items before Tax",
    "exceptional_items": "Exceptional Items",
    "pl_before_extraordinary_items_tax": "Profit before Extraordinary Items and Tax",
    "extraordinary_items": "Extraordinary Items",
    "profite_before_tax": "Profit before Tax",
    "TAX_EXPENSE": "TAX EXPENSE",
    "total_tax_for_period": "Total Tax for the Period",
    "pl_and_related_deferred_tax_movement": "Net Movement in Regulatory Deferral Account Balances related to Profit or Loss and the Related Deferred Tax Movement",
    "profit_loss": "Profit/(Loss)",
}

export const snapshots_dummy_column = {
    "revenue_from_operations": "Revenue from Operations",
    "ebitda": "EBITDA",
    "profit_or_loss": "PAT",
    "networth": "Networth",
    "long_term_borrowings": "Long-term Borrowings"

}
export const snapshots_dummy = {
    "financial_year": [],
    "revenue_from_operations": [],
    "ebitda": [],
    "profit_or_loss": [],
    "networth": [],
    "long_term_borrowings": []

}