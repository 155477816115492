import React, { useEffect, useState } from "react";
import MobileMenu from "../../../MobileMenu";
import UserProfileForm from "./UserProfileForm";

const UserProfile = () => {
  const [Loading, setLoading] = useState(true);
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [user, setUser] = useState({});
  const headers = {
    "x-access-token": UserData.access_token,
  };

  document.title = "Nextyn IQ - Profile";

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => { }, []);

  var userdata = [UserData];
  return (
    <>
      <MobileMenu />
      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages support-page">
          <div className="inside-support">
            <h5 style={{ fontWeight: 600 }}>My Profile</h5>
          </div>
          <div className="faq-section mt-4">
            {userdata.length !== 0 ? (
              <UserProfileForm user={userdata} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
