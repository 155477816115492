import transImg from "../../assests/images/Transcript_search.png";
import expertImg from "../../assests/images/Expert_search.png";
import transComp from "../../assests/images/Transcript_company.png";
import expertComp from "../../assests/images/Expert_company.png";
import companyData from "../../assests/images/company_data.png";
import callRecord from "../../assests/images/Call_recordings.png"

const EmptyStatesData = {
  searchTranscript: {
    page: "Search",
    feature: "Transcripts",
    content:
      "Nextyn IQ aims to cover the breadth & depth of topics in our transcripts library. Unfortunately, at this time, we do not have transcripts that match your search request. To gain more insights on the topic, please search our extensive database of experts or launch a custom expert search request.",
    cta: "Find me Experts",
    image: transImg,
  },
  searchExpert: {
    page: "Search",
    feature: "Experts",
    content:
      "Our database of experts is ever evolving. At this moment, we do not have an Expert for your request. Our team of analysts will be happy to custom source one for you. Please click here, to launch a custom search request.",
    cta: "Find me Experts",
    image: expertImg,
  },
  companyExpert: {
    page: "Comapny",
    feature: "Experts",
    content:
      "Our database of experts is ever evolving. At this moment, we do not have an Expert for your request. Our team of analysts will be happy to custom source one for you. Please click here, to launch a custom search request.",
    cta: "Find me Experts",
    image: expertComp,
  },
  companyTranscript: {
    page: "Company",
    feature: "Experts",
    content:
      "Nextyn IQ aims to cover the breadth & depth of topics in our transcipts library. Unfortunately, at this time, we do not have transcripts that match your search request. To gain more insights on the topic, please search our extensive database of experts or launch a custom expert search request.",
    cta: "Find me Experts",
    image: transComp,
  },
  unlockedTranscript: {
    page: "Unlocked transcripts",
    feature: "Experts",
    content:
      "Nextyn IQ aims to cover the breadth & depth of topics in our transcipts library. Unfortunately, at this time, we do not have transcripts that match your search request. To gain more insights on the topic, please search our extensive database of experts or launch a custom expert search request.",
    cta: "Find me Experts",
    image: expertImg,
  },
  companyData: {
    page: "Unlocked transcripts",
    feature: "Experts",
    content:
      "Get a plethora of company data including financials, directors, announcements and many more. Click on the below button to get the data for company within minutes.",
    error_content: "Oops! Looks like the data for this company is not available at the moment.We are working to get you the requested data. You will be notified when your data is ready",
    cta: "Request Company Data",
    image: companyData,
  },
  callRecordingsData: {
    page: "Call recordings",
    feature: "Experts",
    content:
      "Recordings & Transcripts of your consulting calls will appear here. Seems like you haven't completed any consulting calls yet. To speak to an expert, click the button below.",
    cta: "Find me expert",
    image: callRecord,
  },
};

export default EmptyStatesData;
