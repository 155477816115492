import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
    page: 1,
    limit: 5,
    news_data: { data: [], count: 0 },
    selected_article_link: '',
    selected_article: {},
    is_loading_description: false,
    is_open_Ai_modal: false,
}

// slice which contain reducers ,its initial state and all
const slice = createSlice({
    name: "news",
    initialState: initialState,
    reducers: {
        updateNewsData: (state, { payload }) => {
            state.news_data = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setLimit: (state, { payload }) => {
            state.limit = payload
        },
        setSelectedArticleLink: (state, { payload }) => {
            state.selected_article_link = payload
        },
        setSelectedArticle: (state, { payload }) => {
            state.selected_article = payload
        },
        setIsOpenAiModal: (state, { payload }) => {
            state.is_open_Ai_modal = payload
        },
        setIsLoadingDescription: (state, { payload }) => {
            state.is_loading_description = payload
        },
        clearState: (state) => {
            state = initialState;
        },
    }
})

const newsReducer = slice.reducer
export const { updateNewsData, setPage, setLimit, setSelectedArticleLink, clearState, setIsOpenAiModal, setSelectedArticle, setIsLoadingDescription } = slice.actions
export {
    newsReducer
}