import React from "react";
import "../../../App.css";
import Transcript from "./Transcript";
import { Helmet } from "react-helmet";
import TranscriptTable from "./TranscriptTable";

const TranscriptPage = () => {
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Transcript"}</title>
      </Helmet>
      <div className="content-wrapper bg-light" id="main-screen">
        {/* <Transcript /> */}
        <TranscriptTable />
      </div>
    </>
  );
};

export default TranscriptPage;
