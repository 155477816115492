import { createSlice } from "@reduxjs/toolkit";

const initialState1 = {
  directorList: { data: [], count: 0 },
  companyDirectorDetails: {},
  auditorList: { data: [], count: 0 },
  chargeList: { data: [], count: 0 },
  ratingList: { data: [], count: 0 },
  corporateAnnouncementBSC: { data: [], count: 0 },
  conferenceCalls: { data: [], count: 0 },
  sortParams: { sortColumn: "", sortType: 1 },
  searchParamsDir: {
    name: "",
    din: "",
    designation: "",
    appointment_current_designation_date: "",
    appointment_original_date: "",
    cessation_date: "",
    share_holding_percent: "",
    phone_number: ""
  },
  searchParamsDirDetails: {
    company_name: "",
    appointment_current_designation_date: "",
    appointment_original_date: "",
    cessation_date: "",
    share_holding_percent: "",
    totalOpenCharges: "",
    paid_up_capital: "",
    incorporation_date: "",
    designation: "",
    company_status: ""
  },
  searchParamsCharges: {
    charge_id: "",
    creation_date: "",
    latest_modification_date: "",
    satisfaction_date: "",
    amount_formatted: "",
    chargeholder_name: "",
    assets_under_charge: "",
    chargeholder_address: ""
  },
  searchParamsRating: {
    date_of_last_rating_action: "",
    credit_rating_agency: "",
    previous_rating_outlook: "",
    instrument: "",
    duration: "",
    rating: "",
    size_rated_in_rupees_crores: "",
  },
  searchParamsAudit: {
    financial_year: "",
    name_of_the_auditor_or_auditors_firm: "",
    auditors_firms_registration_number: "",
    name_of_the_member: "",
    membership_number_of_auditor: "",
    whether_financial_statement_qualified: "",
    whether_caro_applicable: "",
    resignation_company_date_of_resignation: "",
    resignation_company_reason_for_resignation: "",
  },
  ratingPagination: { page: 1, limit: 10 },
  auditPagination: { page: 1, limit: 10 },
  directorPagination: { page: 1, limit: 10 },
  directorDetailsPagination: { page: 1, limit: 10 },
  chargePagination: { page: 1, limit: 10 },
  corpPagination: { page: 1, limit: 10 },
  confPagination: { page: 1, limit: 10 },

  shareholderHistoryPagination: { page: 1, limit: 70 },
  shareHoldingAllotmentHoldingData: { data: [], count: 0 },
  companyAssociateRelatedTxnData: { data: [], count: 0 },
  companyAssociateRelatedTxnPagination: { page: 1, limit: 10 },
  companyAssociateData: [],
  isLoadingDir: false,
  isLoadingCharges: false,
  isLoadingRating: false,
  isLoadingAuditor: false,
  activeTab: "About",
  corporateActiveKey: "1"
};

const directorSlice = createSlice({
  initialState: initialState1,
  name: "CompanyTabs",
  reducers: {
    setCompanyDirector: (state, { payload }) => {
      state.directorList = payload;
    },
    setCompanyDirectorDetails: (state, { payload }) => {
      state.companyDirectorDetails = payload;
    },
    setCompanyAssociateRelatedTxnPagination: (state, { payload }) => {
      state.companyAssociateRelatedTxnPagination = payload;
    },
    setCompanyAuditors: (state, { payload }) => {
      state.auditorList = payload;
    },
    setCompanyCharges: (state, { payload }) => {
      state.chargeList = payload;
    },
    setCompanyRatings: (state, { payload }) => {
      state.ratingList = payload;
    },
    setSortParams: (state, { payload }) => {
      state.sortParams = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setIsLoadingDir: (state, { payload }) => {
      state.isLoadingDir = payload;
    },
    setIsLoadingCharges: (state, { payload }) => {
      state.isLoadingCharges = payload;
    },
    setIsLoadingRating: (state, { payload }) => {
      state.isLoadingRating = payload;
    },
    setIsLoadingAuditor: (state, { payload }) => {
      state.isLoadingAuditor = payload;
    },
    setSearchParamsDir: (state, { payload }) => {
      state.searchParamsDir = payload;
    },
    setSearchParamsDirDetails: (state, { payload }) => {
      state.searchParamsDirDetails = payload;
    },
    setSearchParamsCharges: (state, { payload }) => {
      state.searchParamsCharges = payload;
    },
    setSearchParamsRating: (state, { payload }) => {
      state.searchParamsRating = payload;
    },
    setSearchParamsAudit: (state, { payload }) => {
      state.searchParamsAudit = payload;
    },
    setShareholderHistoryPagination: (state, { payload }) => {
      state.shareholderHistoryPagination = payload;
    },
    setShareHoldingAllotmentHoldingData: (state, { payload }) => {
      state.shareHoldingAllotmentHoldingData = payload;
    },
    setCompanyAssociateData: (state, { payload }) => {
      state.companyAssociateData = payload;
    },
    setCompanyAssociateRelatedTxnData: (state, { payload }) => {
      state.companyAssociateRelatedTxnData = payload;
    },
    setRatingPagination: (state, { payload }) => {
      state.ratingPagination = payload;
    },
    setAuditPagination: (state, { payload }) => {
      state.auditPagination = payload;
    },
    setCorpPagination: (state, { payload }) => {
      state.corpPagination = payload;
    },
    setConfPagination: (state, { payload }) => {
      state.confPagination = payload;
    },
    setCorporateAnnouncementBSC: (state, { payload }) => {
      state.corporateAnnouncementBSC = payload;
    },
    setConferenceCalls: (state, { payload }) => {
      state.conferenceCalls = payload;
    },
    setCorporateActiveKey: (state, { payload }) => {
      state.corporateActiveKey = payload;
    },
    setChargePagination: (state, { payload }) => {
      state.chargePagination = payload;
    },
    setDirectorPagination: (state, { payload }) => {
      state.directorPagination = payload;
    },
    setDirectorDetailsPagination: (state, { payload }) => {
      state.directorDetailsPagination = payload;
    },
    resetAllCompanyData: (state) => {
      return initialState1;
    },
  },
});
export const companyTabSliceReducer = directorSlice.reducer;
export const {
  setCompanyDirector,
  setCompanyAuditors,
  setCompanyCharges,
  setCompanyRatings,
  setShareholderHistoryPagination,
  setShareHoldingAllotmentHoldingData,
  setCompanyAssociateData,
  setCompanyAssociateRelatedTxnData,
  setSortParams,
  setSearchParamsDir,
  setSearchParamsDirDetails,
  setSearchParamsCharges,
  setIsLoadingDir,
  setIsLoadingCharges,
  setActiveTab,
  setSearchParamsRating,
  setSearchParamsAudit,
  resetAllCompanyData,
  setRatingPagination,
  setAuditPagination,
  setChargePagination,
  setDirectorPagination,
  setDirectorDetailsPagination,
  setCorporateAnnouncementBSC,
  setCorpPagination, setConferenceCalls, setIsLoadingRating, setIsLoadingAuditor,
  setCompanyDirectorDetails, setCompanyAssociateRelatedTxnPagination, setCorporateActiveKey, setConfPagination
} = directorSlice.actions;
