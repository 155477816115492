import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isChecked: {
        projectAdd: false,
        projectView: false,
        projectMessage: false,
        projectList: false,
        projectDelete: false,
        collaborationView: false,
        collabrationNotesAdd: false,
        collaborationNotesView: false,
        collaborationNotesDelete: false,
        collaborationFolderView: false,
        collaborationFolderAdd: false,
        collaborationFolderDelete: false,
        collaborationFilesAdd: false,
        collaborationFilesDelete: false,
        collaborationFilesView: false,
        conversationView: false,
        newsView: false,
        expertView: false,
        expertRequest: false,
        expertCallView: false,
        transcriptView: false,
        transcriptUnlock: false,
        userManagementAdd: false,
        userManagementView: false,
        userManagementUpdate: false,
        userManagementDelete: false,
        creditTransaction: false,
        creditHistory: false,
    },
    isUserId: [],
    roleList: [],
    isRoleName: "",
    isRoleDescrip: "",
    isRoleId: "",
    roleType: ""
}

const userManagementSlice = createSlice({
    name: "userManagement",
    initialState: initialState,
    reducers: {
        setIsChecked: (state, { payload }) => {
            state.isChecked = payload
        },
        setIsUserId: (state, { payload }) => {
            state.isUserId = payload
        },
        setRoleList: (state, { payload }) => {
            state.roleList = payload
        },
        setIsRoleName: (state, { payload }) => {
            state.isRoleName = payload
        },
        setIsRoleDescript: (state, { payload }) => {
            state.isRoleDescrip = payload
        },
        setIsRoleId: (state, { payload }) => {
            state.isRoleId = payload
        },
        setRoleType: (state, { payload }) => {
            state.roleType = payload
        },
        resetForm: (state) => {
            state.isChecked = {
                projectAdd: false,
                projectView: false,
                projectMessage: false,
                projectList: false,
                collaborationView: false,
                collabrationNotesAdd: false,
                collaborationNotesView: false,
                collaborationNotesDelete: false,
                collaborationFolderView: false,
                collaborationFolderAdd: false,
                collaborationFolderDelete: false,
                collaborationFilesAdd: false,
                collaborationFilesDelete: false,
                collaborationFilesView: false,
                conversationView: false,
                newsView: false,
                expertView: false,
                expertRequest: false,
                expertCallView: false,
                transcriptView: false,
                transcriptUnlock: false,
                userManagementAdd: false,
                userManagementView: false,
                userManagementUpdate: false,
                userManagementDelete: false,
                creditTransaction: false,
                creditHistory: false,
            }
            state.isRoleDescrip = ""
            state.isRoleName = ""
            state.isRoleId = ""
            state.isUserId = []
            state.roleList = []
            state.roleType = ""
        }
    }
})

const userManagementReducer = userManagementSlice.reducer
export const { setIsChecked, setIsUserId, setRoleList, setIsRoleName, setIsRoleDescript, setIsRoleId, resetForm, setRoleType } = userManagementSlice.actions
export { userManagementReducer }
