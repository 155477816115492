// privateRoute
import React from 'react';
import { isExpired } from 'react-jwt';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, token }) => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.access_token === null) {
        return <Navigate to="/" replace />
    } else if (isExpired(userData?.access_token)) {
        return <Navigate to="/" replace />
    } else {
        return children;
    }
}

export default PrivateRoute;    