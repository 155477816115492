import axios from "axios";
import { Route, Routes, useNavigate } from "react-router-dom";
import React, { createContext, useRef } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import ChangePassword from "./NewDesign/Pages/ChangePassword/ChangePassword";
import Collaborate from "./NewDesign/Pages/Call_Recordings/Collaborate";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Login from "./Login/Login";
import NotificationPage from "./notifications/notification";
import UserProfile from "./NewDesign/Pages/profile/UserProfile.js";

// import ProjectsDetails from "./NewDesign/Pages/Projects/ProjectsDetails";
import ProjectsDetailsExpert from "./NewDesign/Pages/Projects/ProjectsDetailsExpert";
import ProjectsDetailsMessage from "./NewDesign/Pages/Projects/ProjectsDetailsMessage";
import ResetPassword from "./ResetPassword/ResetPassword";
import HelpSupport from "./Support/HelpSupport";
import AddRoleManagement from "./NewDesign/Pages/User_management/AddRoleManagement";
import RolesManagement from "./NewDesign/Pages/User_management/RolesMangement";
import UpdateRoleManagement from "./NewDesign/Pages/User_management/UpdateRoleManagement";
import UserMangement from "./NewDesign/Pages/User_management/UserMangement";
import "./assests/css/customResponsive.css";
import TranscriptDetail from "./NewDesign/Pages/Call_Recordings/TranscriptDetails";
import ErrorPage from "./components/ErrorPage";
import ExpiredPage from "./components/ExpiredPage";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Calendly from "./Register/Calendly";
import GetDemo from "./Register/GetDemo";
import OtpVerify from "./VerifyOtp/OtpVerify";
import OtpVerifyDemo from "./VerifyOtp/OtpVerifyDemo";

//New Dashboard
import NewTranscriptPage from "./NewDesign/Pages/Transcript/TranscriptPage";
import NewTranscriptDetailPage from "./NewDesign/Pages/Transcript/Transcript.js";
import NewExpertPage from "./NewDesign/Pages/ExpertPage/ExpertPage";
import NewNewsPage from "./NewDesign/Pages/News/NewsPage";
import NewsFullArticlePage from "./NewDesign/Pages/Dashboard/NewsFullArticlePage.js";
import NewCreditPage from "./NewDesign/Pages/Credit/CreditPage.js";
import NewExpertProfile from "./NewDesign/Pages/ExpertPage/ExpertProfilePage.js";

//version2
import SearchPage from "./NewDesign/Pages/V2/SearchPage/SearchPage.js";
import PrivateRouteAuth from "./PrivateRoute/PrivateRouteAuth.jsx";
import Watchlist from "./NewDesign/Pages/Watchlist/Watchlist.js";
import DirectorDetails from "./NewDesign/Pages/V2/CompanyPage/DirectorDetails.js";
import { createLazyComponent } from "./CreateLazyComponent.js";
import Layout from "./NewDesign/Includes/Layout.js";
const CompanyPage = createLazyComponent(() =>
  import("./NewDesign/Pages/V2/CompanyPage/CompanyPage.js")
);
const V2Dashboard = createLazyComponent(() =>
  import("./NewDesign/Pages/V2/Dashboard/Dashboard.js")
);
const Projects = createLazyComponent(() =>
  import("./NewDesign/Pages/Projects/Projects")
);
const ProjectsDetails = createLazyComponent(() =>
  import("./NewDesign/Pages/Projects/ProjectsDetails")
);

// import CollaborateCreateNotes from "./Collaborate/CollaborateCreateNotes";
// import CollaborateSearch from "./Collaborate/CollaborateSearch";
// import CreateNotesForm from "./Collaborate/CreateNotes";
// import FolderView from "./Collaborate/FolderView";
// import Conversations from "./Conversations/Conversations";
// import ExpertProfile from "./Dashboard/ExpertProfilePage";
// import ExpertCell from "./Expert_cell/ExpertCell";
// import ChatGpt from "./ChatGPT/ChatGpt";
// import News from "./News/News";
// import Register from "./Register/Register";
// import ShareFolderView from "./share/ShareFolderView";
// import ShareRecentView from "./share/ShareRecentView";
// import ShareWithMe from "./share/ShareWithMe";
// import TranscriptDetails from "./share/TranscriptDetail";
// import Demo2 from "./demo/Demo2.js";
// import RecentView from "./Collaborate/RecentView";
// import ExpertCallHistory from "./expert/ExpertCall/ExpertCallHistory";
// import ExpertCompliance from "./expert/ExpertCompliance/ExpertCompliance";
// import ExpertDashboard from "./expert/ExpertDashboard/ExpertDashboard";
// import ExpertProjectDetails from "./expert/ExpertDashboard/ExpertProjectDetails";
// import ExpertLogin from "./expert/ExpertLogin/ExpertLogin";
// import ExpertPayment from "./expert/ExpertPayment.js/ExpertPayment";
// import ExpertRegister from "./expert/ExpertRegister/ExpertRegister";
// import LandingRegister from "./expert/ExpertRegister/LandingRegister";
// import {useLocation } from 'react-router-dom';
// import EditCategories from "./Categories/EditCategories";
// import Starred from "./Dashboard/Dashboard2";
// import Fullcalendar from "./FullCalender/Fullcalendar";
// import RegisterStepOne from "./Register/RegisterStepOne";
// import SharedContent from "./share/SharedContents";
// import SharedNotes from "./share/SharedNotes";
// import CkeditorExample from "./CkeditorExample";

export const ControllerContext = createContext();
function App() {
  // let token =
  //   JSON.parse(localStorage.getItem("userData")).access_token || false;
  let isAuth = JSON.parse(localStorage.getItem("isAuth"));
  // const location = useLocation();
  if (!isAuth) {
    const pathname = window.location.pathname;
    localStorage.setItem("redirectPath", pathname + window.location.search);
  }
  const history = useNavigate();
  // const [tokenExpired, setTokenExpired] = useState(false);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const token = localStorage.getItem("userData");
  //     if (token) {
  //       const tokenExpiration = JSON.parse(token).exp;
  //       if (tokenExpiration < Date.now() / 1000) {
  //         localStorage.removeItem("userData");
  //         setTokenExpired(true);
  //       }
  //     }
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);

  // useEffect(() => {
  //   if (tokenExpired) {
  //     return <Navigate to="/" replace />;
  //   }
  // }, [tokenExpired]);

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.status === 402 || response?.data?.status === 403) {
        localStorage.clear();
        history("/");
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
        history("/");
      }
      return Promise.reject(error);
    }
  );

  //console.log(isAuth);
  // useEffect(() => {
  //   if (!isAuth) {
  //     return <Navigate to="/" replace />;
  //   }
  // }, []);
  const abortControllerRef = useRef(null);
  return (
    <ControllerContext.Provider value={abortControllerRef}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRouteAuth>
              <Login />
            </PrivateRouteAuth>
          }
          exact
        />
        {/* <Route path="/ckeditor" element={<CkeditorExample />} /> */}
        <Route
          exact="true"
          path="/forgot-password"
          element={
            <PrivateRouteAuth>
              <ForgotPassword />
            </PrivateRouteAuth>
          }
        />
        <Route
          exact="true"
          path="/reset-password"
          element={<ResetPassword />}
        />
        {/* Single Sidebar start */}
        <Route
          element={
            <Layout
              // Modify sidebar and header based on props
              hideHeaderPaths={[
                { url: "transcript-details", type: "hide" },
                { url: "call-recording", type: "hide" },
                { url: "dashboard", type: "cls" },
              ]}
            />
          }
        >
          <Route
            exact="true"
            path="/dashboard"
            element={
              <PrivateRoute token={isAuth}>
                <V2Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/projects"
            element={
              <PrivateRoute token={isAuth}>
                <Projects />
              </PrivateRoute>
            }
          />

          <Route
            exact="true"
            path="/projects-details"
            element={
              <PrivateRoute token={isAuth}>
                <ProjectsDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/projects-details-message"
            element={
              <PrivateRoute token={isAuth}>
                <ProjectsDetailsMessage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/projects-details-expert"
            element={
              <PrivateRoute token={isAuth}>
                <ProjectsDetailsExpert />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/find-expert"
            element={
              <PrivateRoute token={isAuth}>
                <NewExpertPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/transcript"
            element={
              <PrivateRoute token={isAuth}>
                <NewTranscriptPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/watchlist"
            element={
              <PrivateRoute token={isAuth}>
                <Watchlist />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/profile"
            element={
              <PrivateRoute token={isAuth}>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/change-password"
            element={
              <PrivateRoute token={isAuth}>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/credits"
            element={
              <PrivateRoute token={isAuth}>
                <NewCreditPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/usermangement/roles"
            element={
              <PrivateRoute token={isAuth}>
                <RolesManagement />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/usermangement/update-roles"
            element={
              <PrivateRoute token={isAuth}>
                <UpdateRoleManagement />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/usermangement/addroles"
            element={
              <PrivateRoute token={isAuth}>
                <AddRoleManagement />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/usermangement/users"
            element={
              <PrivateRoute token={isAuth}>
                <UserMangement />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/company"
            element={
              <PrivateRoute token={isAuth}>
                <CompanyPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/company/director/:id"
            element={
              <PrivateRoute token={isAuth}>
                <DirectorDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/search"
            element={
              <PrivateRoute token={isAuth}>
                <SearchPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/transcript-details/:id"
            element={
              <PrivateRoute token={isAuth}>
                <NewTranscriptDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/call-recordings"
            element={
              <PrivateRoute token={isAuth}>
                <Collaborate />
              </PrivateRoute>
            }
          />
          <Route
            exact="true"
            path="/call-recording/transcript"
            element={
              <PrivateRoute token={isAuth}>
                <TranscriptDetail />
              </PrivateRoute>
            }
          />
        </Route>
        {/* Single Sidebar end */}
        {/* <Route
          exact="true"
          path="/collaborate/collaborate-search-details"
          element={
            <PrivateRoute token={isAuth}>
              <CollaborateSearch />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/demo2"
          element={
            <PrivateRoute token={isAuth}>
              <Demo2 />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/collaborate/notes-details"
          element={
            <PrivateRoute token={isAuth}>
              <CreateNotesForm />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/collaborate/create-notes"
          element={
            <PrivateRoute token={isAuth}>
              <CollaborateCreateNotes />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/collaborate/folders-details"
          element={
            <PrivateRoute token={isAuth}>
              <FolderView />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/collaborate/recent-details"
          element={
            <PrivateRoute token={isAuth}>
              <RecentView />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/share"
          element={
            <PrivateRoute token={isAuth}>
              <ShareWithMe />
            </PrivateRoute>
          }
        />{" "} */}
        {/* <Route
          exact="true"
          path="/share/note/:id"
          element={
            <PrivateRoute token={isAuth}>
              <SharedNotes />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/share/folder/:id"
          element={
            <PrivateRoute token={isAuth}>
              <ShareFolderView />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/share/sleeve/:sleeve_id"
          element={
            <PrivateRoute token={isAuth}>
              <ShareRecentView />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/share/content/:content_id"
          element={
            <PrivateRoute token={isAuth}>
              <SharedContent />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/share/transcript"
          element={
            <PrivateRoute token={isAuth}>
              <TranscriptDetails />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/conversations"
          element={
            <PrivateRoute token={isAuth}>
              <Conversations />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-call"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertCell />
            </PrivateRoute>
          }
        /> */}
        <Route
          exact="true"
          path="/help-support"
          element={
            <PrivateRoute token={isAuth}>
              <HelpSupport />
            </PrivateRoute>
          }
        />
        <Route
          exact="true"
          path="/notification"
          element={
            <PrivateRoute token={isAuth}>
              <NotificationPage />
            </PrivateRoute>
          }
        />
        <Route exact="true" path="/otp-verify" element={<OtpVerify />}></Route>
        {/* <Route
          exact="true"
          path="/starred"
          element={
            <PrivateRoute token={isAuth}>
              <Starred />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-login"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertLogin />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-register"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertRegister />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/landing-register"
          element={
            <PrivateRoute token={isAuth}>
              <LandingRegister />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-dashboard"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertDashboard />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-callhistory"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertCallHistory />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/categories"
          element={
            <PrivateRoute token={isAuth}>
              <EditCategories />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-projectdetails"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertProjectDetails />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-payment"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertPayment />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/chat-gpt"
          element={
            <PrivateRoute token={isAuth}>
              <ChatGpt />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/expert-compliance"
          element={
            <PrivateRoute token={isAuth}>
              <ExpertCompliance />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          exact="true"
          path="/register-step"
          element={<RegisterStepOne />}
        ></Route> */}
        <Route exact="true" path="/demo" element={<GetDemo />} />
        {/* <Route exact="true" path="/fc" element={<Fullcalendar />} /> */}
        <Route
          exact="true"
          path="/verify-otp"
          element={<OtpVerifyDemo />}
        ></Route>
        <Route exact="true" path="/calendly" element={<Calendly />}></Route>
        <Route exact="true" path="/expire" element={<ExpiredPage />}></Route>
        <Route exact="true" path="*" element={<ErrorPage />} />
        {/* New dashboard */}
        {/* <Route
        exact="true"
        path="/dashboard"
        element={
          <PrivateRoute token={isAuth}>
            <NewDashboard />
          </PrivateRoute>
        }
      /> */}
        <Route
          exact="true"
          path="/expert-profile/:id"
          element={
            <PrivateRoute token={isAuth}>
              <NewExpertProfile />
            </PrivateRoute>
          }
        />
        <Route
          exact="true"
          path="/news"
          element={
            <PrivateRoute token={isAuth}>
              <NewNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          exact="true"
          path="/news/full-article"
          element={
            <PrivateRoute token={isAuth}>
              <NewsFullArticlePage />
            </PrivateRoute>
          }
        />
        {/* version2 */}
      </Routes>
    </ControllerContext.Provider>
  );
}

export default App;
